export const MediumPriorityIcon = ({
  color = '#6B6F76',
}: {
  color?: string;
}) => (
  <svg
    fill={color}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    aria-label="Low Priority"
  >
    <rect x="1" y="8" width="3" height="6" rx="1" />
    <rect x="6" y="5" width="3" height="9" rx="1" fillOpacity="0.4" />
    <rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.4" />
  </svg>
);

export const HighPriorityIcon = ({ color = '#6B6F76' }: { color?: string }) => (
  <svg
    fill={color}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    aria-label="Medium Priority"
  >
    <rect x="1" y="8" width="3" height="6" rx="1" />
    <rect x="6" y="5" width="3" height="9" rx="1" />
    <rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.4" />
  </svg>
);

export const CriticalPriorityIcon = ({
  color = '#6B6F76',
}: {
  color?: string;
}) => (
  <svg
    fill={color}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    aria-label="High Priority"
  >
    <rect x="1" y="8" width="3" height="6" rx="1" />
    <rect x="6" y="5" width="3" height="9" rx="1" />
    <rect x="11" y="2" width="3" height="12" rx="1" />
  </svg>
);
