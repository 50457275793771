import { Maybe } from '@finalytic/utils';
import {
  Box,
  CardProps,
  Center,
  Group,
  Card as MCard,
  Text,
} from '@mantine/core';
import { ReactNode } from 'react';
import { useColors } from '../../styles';
import { PercentageBadge } from '../badges';
import { LoadingIndicator } from '../loading-indicator';

type ContainerProps = {
  children: ReactNode;
  loading: boolean;
  error: Maybe<Error>;
};

const minHeight = 350;

export const Card = ({
  children,
  error,
  loading,
  ...cardProps
}: ContainerProps & CardProps) => {
  if (loading || error)
    return (
      <BaseCard {...cardProps}>
        <Center
          h="100%"
          sx={{ minHeight: (cardProps.mih as any) ?? minHeight }}
        >
          {loading ? (
            <LoadingIndicator size="sm" />
          ) : (
            <Text>{error?.message}</Text>
          )}
        </Center>
      </BaseCard>
    );

  return <BaseCard {...cardProps}>{children}</BaseCard>;
};

export const getPercentageDifference = (a: number, b: number) => {
  if (!a || !b) return null;

  return ((a - b) / b) * 100;
};

export const CardTitle = ({
  title,
  previousTotal,
  total,
  diffPercentage,
  inverseBadgeColors,
}: {
  title: string;
  total?: number | string;
  previousTotal?: number | string;
  diffPercentage?: number | null;
  inverseBadgeColors?: boolean;
}) => {
  const { themeColors } = useColors();

  return (
    <Box>
      <Text
        component="h3"
        m={0}
        mb={5}
        size="md"
        fw={400}
        c={themeColors.neutral[6]}
      >
        {title}
      </Text>
      <Group gap="xs">
        <Text component="p" size="1.7rem" m={0} mb={5} fw={500}>
          {total}
        </Text>
        <PercentageBadge
          value={diffPercentage}
          inverseColors={inverseBadgeColors}
        />
      </Group>
      <Text component="p" size="sm" m={0} mb="sm" color="#737373">
        vs. {previousTotal} previous period
      </Text>
    </Box>
  );
};

const BaseCard = ({
  children,
  ...cardProps
}: { children: ReactNode } & CardProps) => (
  <MCard
    withBorder
    radius="lg"
    display="flex"
    sx={{ flexDirection: 'column', minHeight, height: '100%' }}
    {...cardProps}
  >
    {children}
  </MCard>
);
