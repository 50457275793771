// Handle magic link verification results
import { useEffect, useState } from 'react';

import { useSignIn } from '@finalytic/authentication';
import {
  StringParam,
  showSuccessNotification,
  useQueryParam,
} from '@finalytic/ui';
import { Text } from '@mantine/core';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AuthContainer } from '../components';

type VerifyTokenViewProps = {
  isAuthenticated?: boolean;
};

export const VerifyTokenView = ({ isAuthenticated }: VerifyTokenViewProps) => {
  if (isAuthenticated) return <Authenticated />;

  return <Verify />;
};

const Authenticated = () => {
  const [token] = useQueryParam('token', StringParam);

  if (token) {
    showSuccessNotification({
      id: 'redirect',
      title: 'Successfully redirected.',
      message: 'You have been successfully redirected to the dashboard.',
      autoClose: false,
    });
  }

  return <Navigate to="/" />;
};

const Verify = () => {
  const [verificationStatus, setVerificationStatus] = useState<
    'loading' | 'verified' | 'failed'
  >('loading');

  const [token] = useQueryParam('token', StringParam);
  const [searchParams] = useSearchParams();

  const { signIn, setActive } = useSignIn();

  useEffect(() => {
    async function verify() {
      try {
        await signIn
          ?.create({
            strategy: 'ticket',
            ticket: token || '',
          })
          .then(async (result) => {
            if (result.status === 'complete') {
              await setActive({
                session: result.createdSessionId,
              });
              setVerificationStatus('verified');
            } else {
              console.log(result);
            }
          });
      } catch (err) {
        console.error(err);
        // Verification has failed.
        setVerificationStatus('failed');
      }
    }
    if (token) {
      verify();
    }
  }, [token]);

  if (!token)
    return (
      <AuthContainer bgColor="#fff" loading={false}>
        <Text>Missing token</Text>
      </AuthContainer>
    );

  if (verificationStatus === 'loading') {
    return <AuthContainer bgColor="#fff" loading={true} />;
  }

  if (verificationStatus === 'failed') {
    return (
      <AuthContainer bgColor="#fff" loading={false}>
        <Text>Token verification failed</Text>
      </AuthContainer>
    );
  }

  if (verificationStatus === 'verified') {
    const path = searchParams.get('path');
    const search = searchParams.toString();
    const to = path && path !== '/' ? `${path || ''}?${search}` : `/?${search}`;

    return <Navigate to={to} />;
  }

  return (
    <AuthContainer bgColor="#fff" loading={false}>
      <Text>Something went wrong</Text>
    </AuthContainer>
  );
};
