import { useClerk } from '@finalytic/authentication';
import { Button } from '@finalytic/components';
import { useTeamId } from '@finalytic/data';
import { EmptyViewWrapper } from '../../components';

export default () => {
  const [, setTeamId] = useTeamId();
  const { signOut } = useClerk();

  return (
    <EmptyViewWrapper
      title="No Team found"
      description="We couldn't find a team for you. Was your team deleted or did you not receive your invitation?"
      actions={
        <>
          <Button variant="primary" onClick={() => setTeamId(null)}>
            Refresh
          </Button>

          <Button variant="light" onClick={() => signOut()}>
            Sign out
          </Button>
        </>
      }
    />
  );
};
