import { Maybe, ValueOf } from '@finalytic/utils';

export function getOwnerCompanyType(companyType: Maybe<string>) {
  if (!companyType) return null;

  return OWNER_COMPANY_TYPES.find((type) => type.value === companyType) || null;
}

export type OwnerCompanyTypeLabel = ValueOf<typeof TYPES>;
export type OwnerCompanyTypeValue = keyof typeof TYPES;

const TYPES = {
  c_corporation: 'C Corporation',
  s_corporation: 'S Corporation',
  partnership: 'Partnership',
  trust_estate: 'Trust/Estate',
  limited_liability_company: 'Limited Liability Company',
  // sole_proprietorship: 'Sole Proprietorship',
  // limited_partnership: 'Limited Partnership',
  // limited_liability_partnership: 'Limited Liability Partnership',
  // non_profit: 'Non Profit',
  // other: 'Other',
} as const;
export const OWNER_COMPANY_TYPE_VALUES = [
  'c_corporation',
  's_corporation',
  'partnership',
  'trust_estate',
  'limited_liability_company',
] as const;

export const OWNER_COMPANY_TYPES = [
  ...Object.entries(TYPES).map(([value, label]) => ({
    value: value as OwnerCompanyTypeValue,
    label,
  })),
];
