import { ExtendedCustomColors } from '@finalytic/ui';
import {
  Center,
  Badge as MBadge,
  MantineSize,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { EmotionSx as Sx } from '@mantine/emotion';
import { ComponentProps, MouseEventHandler, PropsWithChildren } from 'react';

type ButtonType = {
  component: 'button';
  type?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

type BadgeType = {
  component?: 'div';
  type?: undefined;
  disabled?: undefined;
  onClick?: undefined;
};

type Props = {
  color?: ExtendedCustomColors;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  size?: MantineSize;
  sx?: Sx;
  variant?: ComponentProps<typeof MBadge<'div'>>['variant'];
} & (ButtonType | BadgeType);

export const Badge = ({
  color: c,
  disabled,
  leftIcon,
  rightIcon,
  children,
  component,
  size = 'md',
  sx,
  onClick,
  variant,
}: PropsWithChildren<Props>) => {
  const { colorScheme } = useMantineColorScheme();
  const { colors, primaryColor } = useMantineTheme();
  const color = c || primaryColor;

  const isDarkTheme = colorScheme === 'dark';

  return (
    <MBadge
      component={component}
      disabled={disabled}
      onClick={onClick as any}
      color={color}
      variant={variant}
      size={size}
      sx={(theme, u) =>
        ({
          cursor: disabled || !onClick ? undefined : 'pointer',
          paddingInline: rem(5),
          fontWeight: 500,
          textTransform: 'none',
          color: colors[color][isDarkTheme ? 4 : 9],
          ':active':
            component === 'button'
              ? {
                  transform: 'translateY(1px)',
                }
              : {},
          ...(typeof sx === 'function' ? sx(theme, u) : sx),
        }) as any
      }
      styles={{
        root: {
          backgroundColor: isDarkTheme
            ? undefined
            : `${colors[color][0]}80!important`,
        },
      }}
      leftSection={leftIcon && <Center>{leftIcon}</Center>}
      rightSection={rightIcon && <Center>{rightIcon}</Center>}
    >
      {children}{' '}
    </MBadge>
  );
};
