import { ICellRendererParams } from '@finalytic/ui-grid';
import { faEye, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Text, useMantineTheme } from '@mantine/core';
import { formatUserName } from '@vrplatform/ui-common';
import { ListingOwnerRow } from '../../useListingDetailData';

export const NameCell = ({ data }: ICellRendererParams<ListingOwnerRow>) => {
  const theme = useMantineTheme();

  return (
    <Group wrap="nowrap" pt={5} pb={8} sx={{ minHeight: 45 }}>
      <FontAwesomeIcon
        icon={data?.role === 'owner' ? faUser : faEye}
        size="xl"
        color={theme.colors.gray[9]}
      />

      <Box>
        <Text lineClamp={1} sx={{ display: 'block' }}>
          {formatUserName(
            {
              companyName: data?.companyName,
              firstName: data?.firstName,
              lastName: data?.lastName,
            },
            { lastNameFirst: true }
          )}
        </Text>
        <Text color="gray" size="xs" lineClamp={1} sx={{ display: 'block' }}>
          {data?.email}
        </Text>
      </Box>
    </Group>
  );
};
