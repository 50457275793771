// generated with @7nohe/openapi-react-query-codegen@1.4.1 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AccountsService, ConnectionsService, ListingPeriodsService, ListingsService, OwnerStatementsService, OwnersService, RecurringFeesService, ReservationsService, TaxRatesService, TeamsService, TransactionsService } from "../requests/services.gen";
import * as Common from "./common";
/**
* Get reservation By ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.refreshJournalEntries
* @returns unknown Successful response
* @throws ApiError
*/
export const useReservationsServiceGetReservation = <TData = Common.ReservationsServiceGetReservationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, refreshJournalEntries, xTenantId }: {
  id: string;
  refreshJournalEntries?: boolean;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationKeyFn({ id, refreshJournalEntries, xTenantId }, queryKey), queryFn: () => ReservationsService.getReservation({ id, refreshJournalEntries, xTenantId }) as TData, ...options });
/**
* Get reservations
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.status
* @param data.date
* @param data.refreshJournalEntries
* @param data.dateField
* @returns unknown Successful response
* @throws ApiError
*/
export const useReservationsServiceGetReservations = <TData = Common.ReservationsServiceGetReservationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, dateField, limit, page, refreshJournalEntries, status, xTenantId }: {
  date?: string;
  dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection";
  limit?: number;
  page?: number;
  refreshJournalEntries?: boolean;
  status?: "booked" | "cancelled" | "inquiry";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationsKeyFn({ date, dateField, limit, page, refreshJournalEntries, status, xTenantId }, queryKey), queryFn: () => ReservationsService.getReservations({ date, dateField, limit, page, refreshJournalEntries, status, xTenantId }) as TData, ...options });
/**
* Get listing by ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingsServiceGetListing = <TData = Common.ListingsServiceGetListingDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingKeyFn({ id, xTenantId }, queryKey), queryFn: () => ListingsService.getListing({ id, xTenantId }) as TData, ...options });
/**
* Get listings
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.status
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingsServiceGetListings = <TData = Common.ListingsServiceGetListingsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTenantId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingsKeyFn({ limit, page, status, xTenantId }, queryKey), queryFn: () => ListingsService.getListings({ limit, page, status, xTenantId }) as TData, ...options });
/**
* Get listing periods from a listing
* @param data The data for the request.
* @param data.listingId
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingPeriodsServiceGetListingPeriods = <TData = Common.ListingPeriodsServiceGetListingPeriodsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ listingId, xTenantId }: {
  listingId: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingPeriodsServiceGetListingPeriodsKeyFn({ listingId, xTenantId }, queryKey), queryFn: () => ListingPeriodsService.getListingPeriods({ listingId, xTenantId }) as TData, ...options });
/**
* Get owner by ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnersServiceGetOwner = <TData = Common.OwnersServiceGetOwnerDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnersServiceGetOwnerKeyFn({ id, xTenantId }, queryKey), queryFn: () => OwnersService.getOwner({ id, xTenantId }) as TData, ...options });
/**
* Get owners
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.status
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnersServiceGetOwnersRoute = <TData = Common.OwnersServiceGetOwnersRouteDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTenantId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnersServiceGetOwnersRouteKeyFn({ limit, page, status, xTenantId }, queryKey), queryFn: () => OwnersService.getOwnersRoute({ limit, page, status, xTenantId }) as TData, ...options });
/**
* Get team by ID
* @param data The data for the request.
* @param data.id
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServiceGetTeam = <TData = Common.TeamsServiceGetTeamDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamKeyFn({ id }, queryKey), queryFn: () => TeamsService.getTeam({ id }) as TData, ...options });
/**
* Get List of all Teams
* @param data The data for the request.
* @param data.limit
* @param data.page
* @param data.status
* @param data.date You can use elipisis: YYYY-MM-DD...YYYY-MM-DD
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServiceGetTeams = <TData = Common.TeamsServiceGetTeamsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, status }: {
  date?: string;
  limit?: number;
  page?: number;
  status?: "active" | "inactive" | "deleted" | "onboarding";
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamsKeyFn({ date, limit, page, status }, queryKey), queryFn: () => TeamsService.getTeams({ date, limit, page, status }) as TData, ...options });
/**
* Recurring Fees By Id
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServiceGetRecurringFee = <TData = Common.RecurringFeesServiceGetRecurringFeeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeeKeyFn({ id, xTenantId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFee({ id, xTenantId }) as TData, ...options });
/**
* Recurring Fees
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServiceGetRecurringFees = <TData = Common.RecurringFeesServiceGetRecurringFeesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, xTenantId }: {
  limit?: number;
  page?: number;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeesKeyFn({ limit, page, xTenantId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFees({ limit, page, xTenantId }) as TData, ...options });
/**
* Get Owner Statement by ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServiceGetOwnerStatement = <TData = Common.OwnerStatementsServiceGetOwnerStatementDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnerStatementsServiceGetOwnerStatementKeyFn({ id, xTenantId }, queryKey), queryFn: () => OwnerStatementsService.getOwnerStatement({ id, xTenantId }) as TData, ...options });
/**
* Get Owner Statements
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.status
* @param data.templateId
* @param data.ownershipPeriodIds comma separated ownership period ids
* @param data.listingIds comma separated listings
* @param data.ownerIds comma separated owners
* @param data.month
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServiceGetOwnerStatements = <TData = Common.OwnerStatementsServiceGetOwnerStatementsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, templateId, xTenantId }: {
  limit?: number;
  listingIds?: string;
  month?: string;
  ownerIds?: string;
  ownershipPeriodIds?: string;
  page?: number;
  status?: "draft" | "inReview" | "void" | "virtual" | "published";
  templateId?: string;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnerStatementsServiceGetOwnerStatementsKeyFn({ limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, templateId, xTenantId }, queryKey), queryFn: () => OwnerStatementsService.getOwnerStatements({ limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, templateId, xTenantId }) as TData, ...options });
/**
* Get Owner Statement by ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.payoutReceivedByOwner
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServiceGetOwnerStatementPdf = <TData = Common.OwnerStatementsServiceGetOwnerStatementPdfDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, payoutReceivedByOwner, xTenantId }: {
  id: string;
  payoutReceivedByOwner?: boolean;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnerStatementsServiceGetOwnerStatementPdfKeyFn({ id, payoutReceivedByOwner, xTenantId }, queryKey), queryFn: () => OwnerStatementsService.getOwnerStatementPdf({ id, payoutReceivedByOwner, xTenantId }) as TData, ...options });
/**
* Transactions List
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.type
* @param data.date Either a single date or a range of dates (YYYY-MM-DD...YYYY-MM-DD)
* @returns unknown Successful response
* @throws ApiError
*/
export const useTransactionsServiceGetTransactions = <TData = Common.TransactionsServiceGetTransactionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, type, xTenantId }: {
  date?: string;
  limit?: number;
  page?: number;
  type?: "operating" | "ownerExpense" | "ownerPayout" | "reimburse" | "reservationPayout";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionsKeyFn({ date, limit, page, type, xTenantId }, queryKey), queryFn: () => TransactionsService.getTransactions({ date, limit, page, type, xTenantId }) as TData, ...options });
/**
* Transactions List
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useTransactionsServiceGetTransaction = <TData = Common.TransactionsServiceGetTransactionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionKeyFn({ id, xTenantId }, queryKey), queryFn: () => TransactionsService.getTransaction({ id, xTenantId }) as TData, ...options });
/**
* Get connection by ID
* @param data The data for the request.
* @param data.id
* @returns unknown Successful response
* @throws ApiError
*/
export const useConnectionsServiceGetConnection = <TData = Common.ConnectionsServiceGetConnectionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionKeyFn({ id }, queryKey), queryFn: () => ConnectionsService.getConnection({ id }) as TData, ...options });
/**
* List connections
* @param data The data for the request.
* @param data.limit
* @param data.page
* @param data.tenantId
* @param data.appId
* @param data.status
* @returns unknown Successful response
* @throws ApiError
*/
export const useConnectionsServiceGetConnections = <TData = Common.ConnectionsServiceGetConnectionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ appId, limit, page, status, tenantId }: {
  appId?: string;
  limit?: number;
  page?: number;
  status?: string;
  tenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionsKeyFn({ appId, limit, page, status, tenantId }, queryKey), queryFn: () => ConnectionsService.getConnections({ appId, limit, page, status, tenantId }) as TData, ...options });
/**
* Accounts List
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.type
* @returns unknown Successful response
* @throws ApiError
*/
export const useAccountsServiceGetAccounts = <TData = Common.AccountsServiceGetAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, type, xTenantId }: {
  limit?: number;
  page?: number;
  type?: "ledger" | "bank";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountsServiceGetAccountsKeyFn({ limit, page, type, xTenantId }, queryKey), queryFn: () => AccountsService.getAccounts({ limit, page, type, xTenantId }) as TData, ...options });
/**
* Tax Rates List
* @param data The data for the request.
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @param data.limit
* @param data.page
* @param data.countryCode
* @returns unknown Successful response
* @throws ApiError
*/
export const useTaxRatesServiceGetTaxRates = <TData = Common.TaxRatesServiceGetTaxRatesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ countryCode, limit, page, xTenantId }: {
  countryCode?: string;
  limit?: number;
  page?: number;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTaxRatesServiceGetTaxRatesKeyFn({ countryCode, limit, page, xTenantId }, queryKey), queryFn: () => TaxRatesService.getTaxRates({ countryCode, limit, page, xTenantId }) as TData, ...options });
/**
* Create reservation
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useReservationsServicePostReservation = <TData = Common.ReservationsServicePostReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId: string; status?: "booked" | "cancelled" | "inquiry"; bookedAt: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials: { type: string; description: string; amount: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId: string; status?: "booked" | "cancelled" | "inquiry"; bookedAt: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials: { type: string; description: string; amount: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ReservationsService.postReservation({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create listing
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingsServicePostListing = <TData = Common.ListingsServicePostListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; address: { line1: string; line2?: string; city: string; state: string; postalCode: string; country: string; }; ownershipStartAt?: string; ownershipEndAt?: string; owners?: { ownerId: string; split?: number; }[]; name: string; status: "active" | "inactive"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; address: { line1: string; line2?: string; city: string; state: string; postalCode: string; country: string; }; ownershipStartAt?: string; ownershipEndAt?: string; owners?: { ownerId: string; split?: number; }[]; name: string; status: "active" | "inactive"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ListingsService.postListing({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create a listing period
* @param data The data for the request.
* @param data.listingId
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingPeriodsServicePostListingPeriod = <TData = Common.ListingPeriodsServicePostListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  listingId: string;
  requestBody: { startAt: string; owners: { ownerId: string; split?: number; }[]; endAt?: string; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  listingId: string;
  requestBody: { startAt: string; owners: { ownerId: string; split?: number; }[]; endAt?: string; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ listingId, requestBody, xTenantId }) => ListingPeriodsService.postListingPeriod({ listingId, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create owner
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnersServicePostOwner = <TData = Common.OwnersServicePostOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { address: { line1: string; line2?: string; city: string; state: string; postalCode: string; country: string; }; type: "individual" | "company"; email?: string; phone?: string; firstName: string; name: string; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { address: { line1: string; line2?: string; city: string; state: string; postalCode: string; country: string; }; type: "individual" | "company"; email?: string; phone?: string; firstName: string; name: string; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => OwnersService.postOwner({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create team
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServicePostTeam = <TData = Common.TeamsServicePostTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name: string; address?: { uniqueRef?: string; line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; members?: { userId: string; email?: string; role: string; name?: string; firstName?: string; }[]; copyFromTeamId?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name: string; address?: { uniqueRef?: string; line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; members?: { userId: string; email?: string; role: string; name?: string; firstName?: string; }[]; copyFromTeamId?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => TeamsService.postTeam({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Add Listing to a Recurring Fee
* @param data The data for the request.
* @param data.recurringFeeId
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServicePostListingRecurringFee = <TData = Common.RecurringFeesServicePostListingRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  recurringFeeId: string;
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  recurringFeeId: string;
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ recurringFeeId, requestBody, xTenantId }) => RecurringFeesService.postListingRecurringFee({ recurringFeeId, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create Recurring Fees
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServicePostRecurringFee = <TData = Common.RecurringFeesServicePostRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name: string; formula: string; type: "managementFee" | "additionalFee"; status: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name: string; formula: string; type: "managementFee" | "additionalFee"; status: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => RecurringFeesService.postRecurringFee({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create Owner Statements
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServicePostOwnerStatement = <TData = Common.OwnerStatementsServicePostOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; listingId: string; listingPeriodIds?: string[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; listingId: string; listingPeriodIds?: string[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => OwnerStatementsService.postOwnerStatement({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Create an transaction
* @param data The data for the request.
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useTransactionsServicePostTransaction = <TData = Common.TransactionsServicePostTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { description: string; accountId: string; type: "operating" | "ownerExpense" | "ownerPayout" | "reimburse" | "reservationPayout"; date: string; currency: string; lines: { description: string; accountId: string; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; markup?: { accountId?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { description: string; accountId: string; type: "operating" | "ownerExpense" | "ownerPayout" | "reimburse" | "reservationPayout"; date: string; currency: string; lines: { description: string; accountId: string; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; markup?: { accountId?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => TransactionsService.postTransaction({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Get a redirect URL to connect an app through OAuth2.0
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful response
* @throws ApiError
*/
export const useConnectionsServiceCreateConnection = <TData = Common.ConnectionsServiceCreateConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
}, TContext>({ mutationFn: ({ requestBody }) => ConnectionsService.createConnection({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Get a redirect URL to connect an app through OAuth2.0
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful response
* @throws ApiError
*/
export const useConnectionsServiceConnectConnection = <TData = Common.ConnectionsServiceConnectConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => ConnectionsService.connectConnection({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update Reservation By ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useReservationsServiceUpdateReservation = <TData = Common.ReservationsServiceUpdateReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; status?: "booked" | "cancelled" | "inquiry"; bookedAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials?: { type: string; description: string; amount: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; status?: "booked" | "cancelled" | "inquiry"; bookedAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials?: { type: string; description: string; amount: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ReservationsService.updateReservation({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Listing By ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingsServiceUpdateListing = <TData = Common.ListingsServiceUpdateListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; status?: "active" | "inactive"; connectionId?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; ownershipStartAt?: string; ownershipEndAt?: string; owners?: { ownerId: string; split?: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; status?: "active" | "inactive"; connectionId?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; ownershipStartAt?: string; ownershipEndAt?: string; owners?: { ownerId: string; split?: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ListingsService.updateListing({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update a listing period by ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingPeriodsServiceUpdateListingPeriod = <TData = Common.ListingPeriodsServiceUpdateListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; owners?: { ownerId: string; split?: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; owners?: { ownerId: string; split?: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ListingPeriodsService.updateListingPeriod({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Owner By ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnersServiceUpdateOwner = <TData = Common.OwnersServiceUpdateOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; email?: string; firstName?: string; name?: string; phone?: string; type?: "individual" | "company"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; email?: string; firstName?: string; name?: string; phone?: string; type?: "individual" | "company"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => OwnersService.updateOwner({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Team by ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServiceUpdateTeam = <TData = Common.TeamsServiceUpdateTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { name: string; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; members?: { userId: string; email?: string; role: string; name?: string; firstName?: string; }[]; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { name: string; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; members?: { userId: string; email?: string; role: string; name?: string; firstName?: string; }[]; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; }; };
}, TContext>({ mutationFn: ({ id, requestBody }) => TeamsService.updateTeam({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update a Listing Subscription in a Recurring Fee
* @param data The data for the request.
* @param data.listingId
* @param data.recurringFeeId
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServicePutListingRecurringFee = <TData = Common.RecurringFeesServicePutListingRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  listingId: string;
  recurringFeeId: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  listingId: string;
  recurringFeeId: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ listingId, recurringFeeId, requestBody, xTenantId }) => RecurringFeesService.putListingRecurringFee({ listingId, recurringFeeId, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Recurring Fees
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServiceUpdateRecurringFee = <TData = Common.RecurringFeesServiceUpdateRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { name?: string; formula?: string; type?: "managementFee" | "additionalFee"; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { name?: string; formula?: string; type?: "managementFee" | "additionalFee"; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => RecurringFeesService.updateRecurringFee({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Owner Statements by Id
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServiceUpdateOwnerStatement = <TData = Common.OwnerStatementsServiceUpdateOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { status: "draft" | "inReview" | "void" | "published"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { status: "draft" | "inReview" | "void" | "published"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => OwnerStatementsService.updateOwnerStatement({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update an transaction by ID
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useTransactionsServiceUpdateTransaction = <TData = Common.TransactionsServiceUpdateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; type?: "operating" | "ownerExpense" | "ownerPayout" | "reimburse" | "reservationPayout"; date?: string; currency?: string; lines?: { description: string; accountId: string; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; markup?: { accountId?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; type?: "operating" | "ownerExpense" | "ownerPayout" | "reimburse" | "reservationPayout"; date?: string; currency?: string; lines?: { description: string; accountId: string; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; markup?: { accountId?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => TransactionsService.updateTransaction({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Initialize a teams accounts and statement layout
* @param data The data for the request.
* @param data.teamId
* @param data.requestBody
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServiceInitTeam = <TData = Common.TeamsServiceInitTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { copyFromTeamId?: string; };
  teamId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { copyFromTeamId?: string; };
  teamId: string;
}, TContext>({ mutationFn: ({ requestBody, teamId }) => TeamsService.initTeam({ requestBody, teamId }) as unknown as Promise<TData>, ...options });
/**
* Delete reservation By ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useReservationsServiceDeleteReservation = <TData = Common.ReservationsServiceDeleteReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => ReservationsService.deleteReservation({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete Listing By ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingsServiceDeleteListing = <TData = Common.ListingsServiceDeleteListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => ListingsService.deleteListing({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete a listing period By ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useListingPeriodsServiceDeleteListingPeriod = <TData = Common.ListingPeriodsServiceDeleteListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => ListingPeriodsService.deleteListingPeriod({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete Owner By ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnersServiceDeleteOwner = <TData = Common.OwnersServiceDeleteOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => OwnersService.deleteOwner({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete teams by ID
* @param data The data for the request.
* @param data.id
* @returns unknown Successful response
* @throws ApiError
*/
export const useTeamsServiceDeleteTeam = <TData = Common.TeamsServiceDeleteTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => TeamsService.deleteTeam({ id }) as unknown as Promise<TData>, ...options });
/**
* Delete Listing from a Recurring Fee
* @param data The data for the request.
* @param data.id
* @param data.listingId
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServiceDeleteListingRecurringFee = <TData = Common.RecurringFeesServiceDeleteListingRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  listingId: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  listingId: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, listingId, xTenantId }) => RecurringFeesService.deleteListingRecurringFee({ id, listingId, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete Recurring Fees By Id
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useRecurringFeesServiceDeleteRecurringFee = <TData = Common.RecurringFeesServiceDeleteRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => RecurringFeesService.deleteRecurringFee({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Update Owner Statements by Id
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useOwnerStatementsServiceDeleteOwnerStatement = <TData = Common.OwnerStatementsServiceDeleteOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => OwnerStatementsService.deleteOwnerStatement({ id, xTenantId }) as unknown as Promise<TData>, ...options });
/**
* Delete an transaction by ID
* @param data The data for the request.
* @param data.id
* @param data.xTenantId Tenant ID required if you use a scoped partner api-key
* @returns unknown Successful response
* @throws ApiError
*/
export const useTransactionsServiceDeleteTransaction = <TData = Common.TransactionsServiceDeleteTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => TransactionsService.deleteTransaction({ id, xTenantId }) as unknown as Promise<TData>, ...options });
