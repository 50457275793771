import { Filter, Input } from '@finalytic/components';
import {
  gqlV2,
  useInvalidateQueries,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { PlusIcon, ShuffleIcon } from '@finalytic/icons';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { MRT_SortingState } from '@finalytic/table';
import {
  EllipsisMenu,
  EllipsisMenuItem,
  EllipsisMenuLabel,
  LoadingIndicator,
} from '@finalytic/ui';
import { ensure, groupBy, toTitleCase } from '@finalytic/utils';
import { Box, Center, Modal, Text } from '@mantine/core';
import {
  AutomationMapping,
  MappingLeftSchemaUnion,
  whereSettingAcrossAutomations,
} from '@vrplatform/ui-common';
import { Fragment, useMemo, useState } from 'react';
import { AutomationMappingEditor } from '../../../../../../components';
import { Automation } from '../../../../hooks';
import { useExceptionName } from '../../_cells';
import { useEditAutomationContext } from '../../_context';
import { useMapping } from '../../_hooks';
import { useMappingTableQuery } from './useMappingTableQuery';

type ActiveLeftType = {
  leftType: MappingLeftSchemaUnion;
  targetId: string;
  targetName: string;
};
type Props = {
  settingKey: string;
  mapping: AutomationMapping;
  automation: Automation;
};

const formatLeftTypeToTitle = (leftType: MappingLeftSchemaUnion) =>
  `${toTitleCase(leftType?.split('.').reverse()[0])?.replace(
    'Listing Connection',
    'Unit'
  )} Exceptions`;

export const ChildExceptionsManagerMenu = (props: Props) => {
  const [activeLeftType, setActiveLeftType] = useState<ActiveLeftType | null>(
    null
  );

  const { settingKey, automation, mapping } = props;
  const [teamId] = useTeamId();
  const viewSetting = automation.viewSettings[settingKey] || {};

  const isLocal = viewSetting.isLocal ?? mapping.scope === 'local';

  const automationId = automation.id;

  const types = {
    leftConnectionId: automation?.leftConnectionId,
    rightConnectionId: automation?.rightConnectionId,
    leftType: viewSetting.leftType ?? mapping.left.schema,
    rightType: mapping.right.schema,
  };

  const where = ensure<gqlV2.setting_bool_exp>({
    tenant_id: { _eq: teamId },
    key: { _eq: settingKey },
    parentSettingId: { _is_null: false },
    localAutomationId: isLocal ? { _eq: automationId } : { _is_null: true },
    // automationId: { _eq: automationId },
    _or: whereSettingAcrossAutomations({
      leftConnectionId: types.leftConnectionId,
      rightConnectionId: types.rightConnectionId,
      leftSchema: undefined,
      rightSchema: types.rightType,
      automationId: automationId,
    }),
  });

  const { data, isLoading } = useQuery(
    (q, args) => {
      const childSettings = q
        .setting({
          where: args.where,
          distinct_on: ['leftType', 'target'],
          order_by: [
            {
              leftType: 'asc_nulls_last',
              target: 'asc_nulls_last',
            },
          ],
        })
        .map((setting) => ({
          leftType: setting.leftType as MappingLeftSchemaUnion,
          targetId: setting.target as string,
        }));

      return childSettings;
    },
    {
      queryKey: 'settings',
      refetchOnWindowFocus: true,
      variables: {
        where,
      },
    }
  );

  if (isLoading) return <LoadingIndicator size="xs" />;

  if (!data?.length) return null;

  const grouped = groupBy(data, 'leftType');

  return (
    <>
      <HiddenFeatureIndicator permission="partner-admin">
        <EllipsisMenu
          width={undefined}
          target={
            <Center
              p="xs"
              sx={{
                cursor: 'pointer',
              }}
            >
              <ShuffleIcon size={18} />
            </Center>
          }
        >
          {Object.entries(grouped).map(([leftType, settings]) => {
            return (
              <Fragment key={leftType}>
                <EllipsisMenuLabel
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  {formatLeftTypeToTitle(leftType as MappingLeftSchemaUnion)}
                </EllipsisMenuLabel>
                {settings.map((setting) => (
                  <SettingOption
                    key={setting.targetId}
                    onClick={setActiveLeftType}
                    setting={setting}
                  />
                ))}
              </Fragment>
            );
          })}
        </EllipsisMenu>
      </HiddenFeatureIndicator>
      <OverrideModal
        {...props}
        activeLeftType={activeLeftType}
        closeModal={() => setActiveLeftType(null)}
      />
    </>
  );
};

const SettingOption = ({
  onClick,
  setting,
}: {
  setting: { targetId: string; leftType: MappingLeftSchemaUnion };
  onClick: (active: ActiveLeftType) => void;
}) => {
  const leftType = setting.leftType;
  const { name: n, loading } = useExceptionName(
    setting.targetId,
    setting.leftType
  );

  const name = n || 'missing name';

  return (
    <EllipsisMenuItem
      loading={loading}
      key={leftType}
      onClick={() =>
        onClick({
          leftType,
          targetId: setting.targetId,
          targetName: name,
        })
      }
      customIcon={<PlusIcon size={16} />}
    >
      {name}
    </EllipsisMenuItem>
  );
};

type ModalProps = {
  activeLeftType: null | ActiveLeftType;
  closeModal: () => void;
};

const OverrideModal = ({
  activeLeftType,
  closeModal,
  ...props
}: ModalProps & Props) => {
  const invalidate = useInvalidateQueries(['settings']);

  return (
    <Modal
      opened={!!activeLeftType}
      onClose={() => {
        closeModal();
        invalidate();
      }}
      size={1200}
      title={
        activeLeftType && (
          <>
            <Text>{activeLeftType.targetName}</Text>
            <Text size="xs" color="gray" fw={400}>
              {formatLeftTypeToTitle(activeLeftType.leftType)}
            </Text>
          </>
        )
      }
      styles={(theme) => ({
        title: {
          fontWeight: 500,
          fontSize: theme.fontSizes.lg,
        },
        header: {
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        },
        body: {
          paddingInline: theme.spacing.xs,
          paddingBottom: 0,
          marginTop: theme.spacing.md,
          display: 'flex',
          flexDirection: 'column',
          flex: '0 0 80vh',
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
        root: {
          overflowY: 'hidden',
        },
      })}
    >
      {!activeLeftType ? (
        <Center mih={'50vh'}>
          <Text>Missing default exception to override.</Text>
        </Center>
      ) : (
        <MappingTable {...props} activeLeftType={activeLeftType} />
      )}
    </Modal>
  );
};

const MappingTable = ({
  settingKey,
  activeLeftType,
}: Props & { activeLeftType: ActiveLeftType }) => {
  const [search, setSearch] = useState('');

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'name', desc: false },
  ]);

  type QueryMappingRow = NonNullable<
    (typeof queryData)['data']
  >['pages'][number]['list'][number];

  const queryData = useMappingTableQuery(settingKey, {
    filter: {
      search,
      mapping: undefined,
    },
    isModalQuery: true,
    sorting,
  });

  const columns = useMemo<MRT_ColumnDef<QueryMappingRow>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Mapping Exception',
        Cell: ({ row }) => {
          const { automation } = useEditAutomationContext();
          const { mappingScope, right, rightLabel, mapping } =
            useMapping(settingKey);

          const data = row.original;
          const setting = data.settings?.[0];

          if (!setting?.settingId)
            return (
              <Box w="100%">
                <Input disabled placeholder={'Select a parent mapping first'} />
              </Box>
            );

          return (
            <Box w="100%">
              <AutomationMappingEditor
                automation={{
                  automationId: automation.id,
                  leftConnectionId: automation.leftConnectionId,
                  rightConnectionId: automation.rightConnectionId,
                }}
                targetId={activeLeftType.targetId}
                mapping={{
                  leftType: activeLeftType.leftType,
                  rightType: right,
                  settingKey,
                  isLocal: mappingScope === 'local',
                  label: rightLabel.label,
                  altLeftTypes: mapping.left.alt,
                  exceptionFilters: mapping.params?.exceptionFilters,
                }}
                parentSetting={
                  setting?.settingId
                    ? {
                        settingId: setting.settingId,
                        value: '',
                        target: data.id,
                      }
                    : null
                }
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <InfiniteTable
        table={{}}
        columns={columns}
        queryData={queryData}
        sorting={{
          sorting,
          setSorting,
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </InfiniteTable>
    </>
  );
};
