import type { Query, gqlV2 } from '@finalytic/data';
import { getNamespaceAndType } from '@finalytic/data-ui';
import { hasValue, toTitleCase } from '@finalytic/utils';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
  getSourceDescription,
  isLineClassificationAccountingType,
  orderByListing,
  whereConnectionStatusDefault,
  whereListings,
  whereOwnersV2,
  whereSettingAcrossAutomations,
} from '@vrplatform/ui-common';
import type { ChildMappingRow } from '../_table-types';

export type MappingDatasourceParams = {
  settingKey: string;
};

export type MappingTableFilterParams = {
  search: string | undefined;
  mapping: 'mapped' | 'unmapped' | undefined;
};

export const getMappingRowData = (
  q: Query,
  {
    leftConnectionId,
    rightConnectionId,
    settingKey,
    leftSchema,
    limit,
    offset,
    leftParams,
    partnerId,
    teamId,
    search: s,
    mapping,
    rightSchema,
    automationId,
    isLocal,
    v2Owners,
    isVrpAdmin,
    connections,
  }: MappingTableFilterParams & {
    leftConnectionId: string;
    rightConnectionId: string;
    connections: Record<string, string>;
    settingKey: string;
    leftSchema: string;
    rightSchema: string;
    leftParams: Record<string, any>;
    partnerId: string;
    teamId: string;
    offset: number;
    limit: number;
    automationId: string;
    isLocal: boolean;
    v2Owners: boolean;
    isVrpAdmin: boolean;
  },
  options?: {
    emptyList: boolean;
  }
) => {
  const [appId, type] = getNamespaceAndType(leftSchema);

  const emptyList = options?.emptyList;

  const search = s?.trim();

  const whereSettings: gqlV2.setting_bool_exp = {
    tenant_id: { _eq: teamId },
    key: { _eq: settingKey },
    localAutomationId: isLocal ? { _eq: automationId } : { _is_null: true },
    parentSettingId: { _is_null: true },
    _or: whereSettingAcrossAutomations({
      automationId,
      leftConnectionId,
      leftSchema,
      rightConnectionId,
      rightSchema,
    }),
  };

  const orderBySettings: gqlV2.setting_order_by = {
    updated_at: 'desc_nulls_last',
  };

  if (appId === 'finalytic')
    switch (type) {
      case 'owner': {
        if (v2Owners) {
          const where: gqlV2.owner_bool_exp = {
            ...whereOwnersV2({
              teamId,
              search,
            }),
            _and:
              mapping === 'mapped'
                ? [
                    {
                      _or: [
                        { settingsRight: whereSettings },
                        { settingsLeft: whereSettings },
                      ],
                    },
                  ]
                : undefined,
            _not:
              mapping === 'unmapped'
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
          };

          const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

          if (emptyList) {
            return { list: [], aggregate };
          }

          const list = q
            .owners({
              where,
              limit,
              offset,
              order_by: [{ name: 'asc_nulls_first' }],
            })
            .map((i) => ({
              id: i.id,
              name: formatOwnerName(i),
              settings: [
                ...i
                  .settingsLeft({
                    where: whereSettings,
                    order_by: [orderBySettings],
                  })
                  .map((setting) => getSetting(setting, leftSchema)),
                ...i
                  .settingsRight({
                    where: whereSettings,
                    order_by: [orderBySettings],
                  })
                  .map((setting) => getSetting(setting, leftSchema)),
              ],
            }));

          return {
            list,
            aggregate,
          };
        }

        const _or: gqlV2.user_bool_exp[] = [
          ...(search
            ? [
                { firstName: { _ilike: `%${search}%` } },
                { lastName: { _ilike: `%${search}%` } },
                { companyName: { _ilike: `%${search}%` } },
              ]
            : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.user_bool_exp = {
          type: { _eq: 'owner' },
          ownerships: { listing: { tenantId: { _eq: teamId } } },
          _not:
            mapping === 'unmapped'
              ? {
                  _or: [
                    { settingsRight: whereSettings },
                    { settingsLeft: whereSettings },
                  ],
                }
              : undefined,
          _or: _or.length > 0 ? _or : undefined,
        };

        const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .user({
            where,
            limit,
            offset,
            order_by: [{ lastName: 'asc' }, { companyName: 'asc' }],
          })
          .map((i) => ({
            id: i.id,
            name: formatUserName(
              {
                companyName: i.companyName,
                firstName: i.firstName,
                lastName: i.lastName,
              },
              { lastNameFirst: true }
            ),
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return { list, aggregate };
      }
      case 'app': {
        const _or: gqlV2.app_bool_exp[] = [
          ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.app_bool_exp = {
          _and: [
            {
              connections: { tenantId: { _eq: teamId } },
              id: {
                _neq: 'finalytic',
              },
              category: { _neq: 'accountingPlatform' },
              _not:
                mapping === 'unmapped'
                  ? {
                      _or: [
                        { settingsRight: whereSettings },
                        { settingsLeft: whereSettings },
                      ],
                    }
                  : undefined,
              _or: _or.length > 0 ? _or : undefined,
            },
            leftParams?.filter,
          ].filter(hasValue),
        };

        const aggregate = q.appAggregate({ where }).aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .app({
            where,
            order_by: [{ name: 'asc' }],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id || '',
            name: i.name,
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'listingCollection': {
        const _or: gqlV2.app_bool_exp[] = [
          ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.listing_collection_bool_exp = {
          _and: [
            {
              tenantId: {
                _eq: teamId,
              },
              _not:
                mapping === 'unmapped'
                  ? {
                      _or: [
                        { settingsRight: whereSettings },
                        { settingsLeft: whereSettings },
                      ],
                    }
                  : undefined,
              _or: _or.length > 0 ? _or : undefined,
            },
            leftParams?.filter,
          ].filter(hasValue),
        };

        const aggregate =
          q.listingCollectionAggregate({ where }).aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .listingCollections({
            where,
            order_by: [{ name: 'asc' }],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id,
            name: i.name || '',
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'bookingChannel': {
        const _or: gqlV2.booking_channel_bool_exp[] = [
          ...(search ? [{ uniqueRef: { _ilike: `%${search}%` } }] : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.booking_channel_bool_exp = {
          reservations: {
            tenantId: { _eq: teamId },
          },
          _or: _or.length > 0 ? _or : undefined,
          _not:
            mapping === 'unmapped'
              ? {
                  _or: [
                    { settingsRight: whereSettings },
                    { settingsLeft: whereSettings },
                  ],
                }
              : undefined,
        };

        const aggregate =
          q
            .bookingChannelAggregate({
              where,
            })
            .aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .bookingChannels({
            where,
            limit,
            offset,
            order_by: [{ uniqueRef: 'asc' }],
          })
          .map((i) => ({
            id: i.id || '',
            name: toTitleCase(i.uniqueRef),
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'connection': {
        const _or: gqlV2.connection_bool_exp[] = [
          ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.connection_bool_exp = {
          _and: [
            {
              tenantId: { _eq: teamId },
              status: whereConnectionStatusDefault,
              app: {
                id: {
                  _neq: 'finalytic',
                },
                category: { _neq: 'accountingPlatform' },
              },
              _not:
                mapping === 'unmapped'
                  ? {
                      _or: [
                        { settingsRight: whereSettings },
                        { settingsLeft: whereSettings },
                      ],
                    }
                  : undefined,
              _or: _or.length > 0 ? _or : undefined,
            },
            leftParams?.filter,
          ].filter(hasValue),
        };

        const aggregate =
          q.connectionAggregate({ where }).aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .connection({
            where,
            order_by: [{ name: 'asc' }],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id || '',
            name: i.name,
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'listing': {
        const where: gqlV2.listing_bool_exp = {
          _and: [
            whereListings({
              currentTeamId: teamId,
              dashboard: 'propertyManager',
              partnerTeamIds: [],
              disabledByAutomationId: isLocal ? automationId : undefined,
              search,
            }),
            {
              _not:
                mapping === 'unmapped'
                  ? {
                      _or: [
                        { settingsRight: whereSettings },
                        { settingsLeft: whereSettings },
                      ],
                    }
                  : undefined,
            },
            {
              _or:
                mapping === 'mapped'
                  ? [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ]
                  : undefined,
            },
          ],
        };
        const aggregate = q.listingAggregate({ where }).aggregate?.count();

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .listings({
            where,
            order_by: [orderByListing],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id || '',
            name: getListingName(i),
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'listingConnection': {
        const _or: gqlV2.listing_connection_bool_exp[] = [
          ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.listing_connection_bool_exp = {
          tenantId: { _eq: teamId },
          _not:
            mapping === 'unmapped'
              ? {
                  _or: [
                    { settingsRight: whereSettings },
                    { settingsLeft: whereSettings },
                  ],
                }
              : undefined,
          _or: _or.length > 0 ? _or : undefined,
        };

        const aggregate = q
          .listingConnectionAggregate({ where })
          .aggregate?.count();

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .listingConnections({
            where,
            order_by: [{ name: 'asc' }],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id || '',
            name: i.name,
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'listingOwner': {
        const _or: gqlV2.listing_owner_bool_exp[] = [
          ...(search
            ? [
                { owner: { firstName: { _ilike: `%${search}%` } } },
                { owner: { lastName: { _ilike: `%${search}%` } } },
                { owner: { companyName: { _ilike: `%${search}%` } } },
                { listing: { calculated_title: { _ilike: `%${search}%` } } },
              ]
            : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const where: gqlV2.listing_owner_bool_exp = {
          listing: whereListings({
            currentTeamId: teamId,
            dashboard: 'propertyManager',
            partnerTeamIds: [],
            disabledByAutomationId: isLocal ? automationId : undefined,
          }),
          newOwnerId: v2Owners ? { _is_null: false } : undefined,
          _not:
            mapping === 'unmapped'
              ? {
                  _or: [
                    { settingsRight: whereSettings },
                    { settingsLeft: whereSettings },
                  ],
                }
              : undefined,
          _or: _or.length > 0 ? _or : undefined,
        };

        const aggregate =
          q.listingOwnerAggregate({ where }).aggregate?.count() || 0;

        if (emptyList) {
          return { list: [], aggregate };
        }

        const list = q
          .listingOwners({
            where,
            order_by: [{ listing: { name: 'asc' } }],
            limit,
            offset,
          })
          .map((i) => ({
            id: i.id,
            name: `${getListingName(i.listing)} - ${formatUserName(
              {
                companyName: i.owner?.companyName,
                firstName: i.owner?.firstName,
                lastName: i.owner?.lastName,
              },
              { lastNameFirst: true }
            )}`,
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        return {
          list,
          aggregate,
        };
      }
      case 'lineType': {
        const accountingType = leftParams?.accountingType as
          | 'invoice'
          | 'journalEntry'
          | undefined;

        const _or: gqlV2.payment_line_classification_bool_exp[] = [
          ...(search
            ? [
                {
                  name: { _ilike: `%${search}%` },
                },
              ]
            : []),
          ...(mapping === 'mapped'
            ? [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ]
            : []),
        ];

        const getPaymentIdFilter = ():
          | gqlV2.uuid_comparison_exp
          | undefined => {
          if (isLocal || isVrpAdmin) return undefined; //TODO - accounts introduction: remove this line => currently overwrite for custom fees

          switch (accountingType) {
            case 'journalEntry':
              return { _is_null: false };
            case 'invoice':
              return { _is_null: true };
            default:
              return undefined;
          }
        };

        const where: gqlV2.payment_line_classification_bool_exp = {
          lines: {
            tenantId: { _eq: teamId },
            paymentId: getPaymentIdFilter(),
          },
          _not:
            mapping === 'unmapped'
              ? {
                  _or: [
                    { settingsRight: whereSettings },
                    { settingsLeft: whereSettings },
                  ],
                }
              : undefined,
          _or: _or.length > 0 ? _or : undefined,
        };
        const list = q
          .paymentLineClassifications({
            order_by: [{ name: 'asc' }],
            where,
          })
          .map((line) => {
            const included = isLineClassificationAccountingType(
              line,
              accountingType,
              {
                tenantId: teamId,
                partnerId,
              }
            );
            return {
              id: line.name,
              name:
                line.name === 'reservation'
                  ? 'Reservation Payment'
                  : toTitleCase(line.name),
              included: isLocal ? true : included, // TODO accounts introduction: remove this line => currently overwrite for custom fees
              // included,
              settings: [
                ...line
                  .settingsLeft({
                    where: whereSettings,
                    order_by: [orderBySettings],
                  })
                  .map((setting) => getSetting(setting, leftSchema)),
                ...line
                  .settingsRight({
                    where: whereSettings,
                    order_by: [orderBySettings],
                  })
                  .map((setting) => getSetting(setting, leftSchema)),
              ],
            };
          })
          .filter((x) => x.included);
        return {
          list,
          aggregate: list.length,
        };
      }

      default:
        return { list: [], aggregate: 0 };
    }
  else {
    const _or: gqlV2.source_bool_exp[] = [
      ...(search
        ? [
            { description: { _ilike: `%${search.trim()}%` } },
            { remoteId: { _eq: `${search.trim()}` } },
          ]
        : []),
      ...(mapping === 'mapped'
        ? [{ settingsRight: whereSettings }, { settingsLeft: whereSettings }]
        : []),
    ];

    const connectionId = connections[appId] || leftConnectionId;

    const where: gqlV2.source_bool_exp = {
      tenantId: { _eq: teamId },
      connectionId: { _eq: connectionId },
      type: { _eq: type },
      status: {
        _eq: 'active',
      },
      _not:
        mapping === 'unmapped'
          ? {
              _or: [
                { settingsRight: whereSettings },
                { settingsLeft: whereSettings },
              ],
            }
          : undefined,
      _or: _or.length > 0 ? _or : undefined,
    };

    const aggregate = q.sourceAggregate({ where }).aggregate?.count() || 0;

    if (emptyList) {
      return { list: [], aggregate };
    }

    const list = q
      .source({
        order_by: [{ description: 'asc' }],
        where,
        offset,
        limit,
      })
      .map((i) => ({
        id: i.id || '',
        name: getSourceDescription(i),
        settings: [
          ...i
            .settingsLeft({
              where: whereSettings,
              order_by: [orderBySettings],
            })
            .map((setting) => getSetting(setting, leftSchema)),
          ...i
            .settingsRight({
              where: whereSettings,
              order_by: [orderBySettings],
            })
            .map((setting) => getSetting(setting, leftSchema)),
        ],
      }));

    return {
      list,
      aggregate,
    };
  }
};

export type Setting = {
  value: string | undefined;
  target: string | undefined;
  leftType: string | undefined;
  rightType: string | undefined;

  settingId: string;
  parentSettingId: string | undefined;
  childSettings?: ChildMappingRow[];
};

const getSetting = (setting: gqlV2.setting, leftSchema: string): Setting => {
  const originalSetting = {
    target: setting.target as string | undefined,
    settingId: setting.id,
    value: setting.value as string | undefined,
    leftType: setting.leftType || '',
    rightType: setting.rightType as string | undefined,
    parentSettingId: setting.parentSettingId,
    childSettings: setting.childSettings().map((childSet) => ({
      settingId: childSet.id || '',
      parentSettingId: childSet.parentSettingId || '',
      target: childSet.target || '',
      value: childSet.value || '',
      leftType: childSet.leftType || '',
      rightType: childSet.rightType || '',
      childSettings: [],
      parentRowTypeId: setting.target,
      name: '',
    })),
  };

  function invertSetting<T extends typeof originalSetting>(value: T): T {
    if (value.leftType !== leftSchema) {
      return {
        ...value,
        leftType: value.rightType,
        rightType: value.leftType,
        value: value.target,
        target: value.value,
      };
    }

    return value;
  }

  return invertSetting({
    ...originalSetting,
    childSettings: originalSetting.childSettings?.map((child) => child),
  });
};
