import { connection_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';

export const connectionsFilterInput = {
  teamId: z.string().nullish(),
  appId: z.string().nullish(),
  // fetchStatus: z.string().nullish(),
  search: z.string().nullish(),
  status: z.enum(['pending', 'error', 'archived', 'active']).nullish(),
};

const connectionsFilter = z.object(connectionsFilterInput);

export type ConnectionsFilterParams = z.infer<typeof connectionsFilter>;

type BaseWhere = {
  partnerTeamIds: string[];
  dashboard: Dashboard;
  currentTeamId: string;
};

export function whereConnections({
  search: s,
  currentTeamId,
  dashboard,
  partnerTeamIds,
  teamId,
  status,
  appId,
}: BaseWhere & ConnectionsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<connection_bool_exp[]>([
        {
          name: { _ilike: `%${search.trim()}%` },
        },
      ])
    : undefined;

  const _and: connection_bool_exp[] = [];
  if (searchQuery) {
    _and.push({
      _or: searchQuery,
    });
  }
  const isError: connection_bool_exp = {
    _or: [
      {
        jobPlans: {
          status: { _eq: 'failed' },
          isCurrentOnConnection: { _eq: true },
          jobs: {
            kind: { _in: ['extract', 'extractLegacy'] },
          },
        },
      },
      {
        status: { _eq: 'error' },
        _or: [
          {
            jobPlans: {
              status: { _in: ['failed', 'completed'] },
              isCurrentOnConnection: { _eq: true },
              jobs: {
                kind: { _in: ['extract', 'extractLegacy'] },
              },
            },
          },
          {
            _not: { jobPlans: {} },
          },
        ],
      },
    ],
  };

  const isPending: connection_bool_exp = {
    jobPlans: {
      status: { _nin: ['failed', 'completed'] },
      isCurrentOnConnection: { _eq: true },
      jobs: {
        kind: { _in: ['extract', 'extractLegacy'] },
      },
    },
  };

  if (status === 'pending') {
    _and.push(isPending);
  }
  if (status === 'error') {
    _and.push(isError);
  }

  return ensure<connection_bool_exp>({
    tenantId: teamId
      ? { _eq: teamId }
      : { _in: dashboard === 'partner' ? partnerTeamIds : [currentTeamId] },
    status:
      status === 'archived'
        ? { _in: whereConnectionStatusDefault?._nin }
        : whereConnectionStatusDefault,
    appId: appId ? { _eq: appId, _neq: 'finalytic' } : { _neq: 'finalytic' },
    _and,
    _not:
      status === 'active'
        ? {
            _or: [isPending, ...isError._or!],
          }
        : undefined,
  });
}
