import { StackProps } from '@mantine/core';
import { ConnectionsIcon } from '../../icons';
import { OverlayContainer } from './_components';

export const NoConnectionsTableOverlay = ({
  text = 'No connections have been found',
  size,
  ...stackProps
}: {
  text?: string;
  size?: number;
} & StackProps) => (
  <OverlayContainer size={size} icon={<ConnectionsIcon />} {...stackProps}>
    {text}
  </OverlayContainer>
);
