import { ArrowLeftIcon, CrossIcon } from '@finalytic/icons';
import { EllipsisMenu, IconButton } from '@finalytic/ui';
import { Box, Group, Text, Title, useMantineColorScheme } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

type Props = {
  closeDrawer: () => void;
  title: ReactNode;
  type?: string;
  menuItems?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  containerSx?: EmotionSx;
  onReturnClick?: () => void;
};

export const DrawerHeader = ({
  closeDrawer,
  title,
  type,
  menuItems,
  loading,
  children,
  containerSx,
  onReturnClick,
}: Props) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box
      sx={(theme, u) =>
        ({
          position: 'sticky',
          top: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexWrap: 'nowrap',
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          zIndex: 30,
          width: '100%',
          paddingTop: theme.spacing.md,
          paddingBottom: theme.spacing.xs,
          marginBottom: theme.spacing.xs,
          ...(typeof containerSx === 'function'
            ? containerSx(theme, u)
            : containerSx),
        }) as any
      }
      px={10}
    >
      {onReturnClick && !loading && (
        <IconButton
          onClick={onReturnClick}
          sx={{
            alignSelf: 'center',
          }}
          mr="xs"
        >
          <ArrowLeftIcon size={20} />
        </IconButton>
      )}
      {!loading && (
        <Group gap={5} wrap="nowrap" sx={{ flex: 1 }}>
          <Box>
            {type && (
              <Text
                component="span"
                display="block"
                c={colorScheme === 'dark' ? 'dimmed' : 'gray'}
                size="xs"
              >
                {type}
              </Text>
            )}
            {typeof title === 'string' ? (
              <Title order={3} display="block">
                {title}
              </Title>
            ) : (
              title
            )}
          </Box>
        </Group>
      )}
      <Group ml="auto">
        {!loading && children}
        {menuItems && (
          <EllipsisMenu width={undefined}>{menuItems}</EllipsisMenu>
        )}
        <IconButton onClick={closeDrawer}>
          <CrossIcon size={20} />
        </IconButton>
      </Group>
    </Box>
  );
};
