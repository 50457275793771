export type FormulaField = {
  id: string;
  label: string;
  displayValue: string;
  type: string; // 'function' | 'account' | 'field';
  description?: string | any;
};

export const defaultStatementFormulaFields: FormulaField[] = [
  {
    displayValue: '"res_nights"',
    id: 'reservation.nights',
    label: 'Reservation Nights',
    type: 'field',
  },
  {
    displayValue: '"res_guests"',
    id: 'reservation.guests',
    label: 'Reservation Guests',
    type: 'field',
  },
];
