import { Maybe, utc } from '@finalytic/utils';
import { StatusPill } from './StatusPill';
import { StatusPillType } from './_types';

type Props = {
  status: Maybe<string>;
  bookedAt: Maybe<string>;
};

export const ReservationStatusPill = ({ status, bookedAt }: Props) => {
  let color: StatusPillType = 'yellow';
  let label = status;

  switch (status) {
    case 'booked':
      color = 'green';
      break;
    case 'cancelled':
      color = 'red';
      break;
    case 'inquiry':
      color = 'yellow';
      break;
    case 'payed':
      label = 'booked';
      color = 'green';
      break;
    default:
      break;
  }

  if (!label) return null;

  return (
    <StatusPill
      type={color}
      label={`${label}${
        bookedAt ? ` on ${utc(bookedAt).format('DD MMM YYYY')}` : ''
      }`}
    />
  );
};
