import { InputProps } from '@mantine/core';

export const defaultInputStyles: InputProps['styles'] = {
  input: {
    padding: '20px 16px',
    fontSize: '13px',
    '&::placeholder': {
      color: '#BEC0C9',
      fontWeight: 500,
    },
    '&:focus': {
      border: '1px solid #BEC0C9',
      boxShadow: '0px 0px 0px 1px #BEC0C9',
    },
    ':disabled': {
      color: '#000',
    },
  },
};
