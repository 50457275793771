import { gqlV2 } from '@finalytic/data';
import { toTitleCase } from '@finalytic/utils';
import { Badge } from '@mantine/core';
import { useStatementStatusColor } from '../../hooks';

export const StatementStatusBadge = ({
  status,
}: {
  status: gqlV2.owner_statement_status_enum;
}) => {
  const [colors, key] = useStatementStatusColor(status);

  return (
    <Badge
      color={key}
      radius={'sm'}
      px={5}
      styles={{
        root: { backgroundColor: colors[1], color: colors[9] },
      }}
    >
      {toTitleCase(status)}
    </Badge>
  );
};
