import { Icon } from '@finalytic/icons';
import { SelectItem } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  Skeleton,
  Stack,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import { SuggestionProps } from '@tiptap/suggestion';
import {
  ElementRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInfiniteDataContext } from './_context';

export const FormulaSuggestions = forwardRef((props: SuggestionProps, ref) => {
  const queryData = useInfiniteDataContext();
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    queryData.setSearch(props.query);
  }, [props.query]);

  return (
    <Box
      sx={(theme) => ({
        boxShadow: theme.shadows.md,
        width: 300,
        borderRadius: theme.radius.md,
        backgroundColor:
          colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      })}
    >
      <Options
        {...queryData}
        containerRef={ref as any}
        command={props.command}
      />
    </Box>
  );
});

const Options = ({
  data,
  hasNextPage,
  error,
  fetchNextPage,
  isFetching,
  isFetchingNextPage,
  containerRef,
  command,
}: {
  containerRef: ElementRef<'div'>;
  command: (v: { id: string; label: string }) => void;
} & ReturnType<typeof useInfiniteDataContext>) => {
  const rows = useMemo(() => {
    const list = data?.pages.flatMap((x) => x.list) || [];

    return list;
  }, [JSON.stringify(data?.pages)]);

  const parentRef = useRef<ElementRef<'div'>>(null);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 5,
  });

  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, rows.length, isFetchingNextPage, items]);

  const defaultHeight = 300;

  const listHeight = virtualizer.getTotalSize();

  const showSkeletons = isFetching && !isFetchingNextPage;

  const getParentHeight = () => {
    if (showSkeletons) return 80;

    return listHeight > defaultHeight ? defaultHeight : listHeight || 50;
  };

  const parentHeight = getParentHeight();

  const { focusedIndex } = useKeyboardNavigation({
    containerRef,
    items: rows,
    handleSelect: (index) => {
      const item = rows[index];
      if (item) {
        command({ id: item.value, label: item.label });
      }
    },
    scrollToIndex: virtualizer.scrollToIndex,
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: parentHeight,
        width: '100%',
        overflowY: 'auto',
        contain: 'strict',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {showSkeletons ? (
            <Stack gap={'xs'} p={5}>
              <Skeleton height={15} w="100%" />
              <Skeleton height={15} w="100%" />
              <Skeleton height={15} w="100%" />
            </Stack>
          ) : error ? (
            <Group
              sx={{ height: '100%' }}
              p={5}
              pt="sm"
              wrap="nowrap"
              justify="center"
            >
              <Center>
                <Icon
                  icon="AlertTriangleIcon"
                  color={(theme) => theme.colors.red[6]}
                  size={18}
                />
              </Center>
              <Text component="span" size="sm" color="red">
                {error.message}
              </Text>
            </Group>
          ) : !items.length ? (
            <>
              <Center sx={{ height: '100%' }} p={5} pt="sm">
                <Text component="span" size="sm" color="gray">
                  No suggestions found
                </Text>
              </Center>
            </>
          ) : (
            items.map((virtualRow) => {
              const index = virtualRow.index;

              const isLoaderRow = index > rows.length - 1;
              const item = rows[index];

              const isFocused = index === focusedIndex;

              const onClick = () => {
                command({ id: item.value, label: item.label });
              };

              return (
                <div
                  key={virtualRow.key}
                  data-index={index}
                  ref={virtualizer.measureElement}
                >
                  {isLoaderRow ? (
                    hasNextPage ? (
                      <Box px={'xs'} pb={5}>
                        <Skeleton height={15} w="100%" />
                      </Box>
                    ) : null
                  ) : (
                    <ListItem
                      isFocused={isFocused}
                      item={item}
                      onClick={onClick}
                    />
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const ListItem = ({
  isFocused,
  item,
  onClick,
}: { item: SelectItem; isFocused: boolean; onClick: () => void }) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const description = item.description;

  const badge = useMemo(() => {
    const type = item.group?.toLowerCase();

    if (type === 'accounts' || type === 'account') {
      return {
        name: 'Account',
        color: colors.blue[6],
      };
    }
    if (type === 'variables') {
      return {
        name: 'Variable',
        color: colors.grape[6],
      };
    }
    if (type === 'formulas') {
      return {
        name: 'Formula',
        color: colors.orange[6],
      };
    }
    if (type === 'fields') {
      return {
        name: 'Field',
        color: colors.teal[6],
      };
    }
    if (type === 'collections') {
      return {
        name: 'Collection',
        color: colors.cyan[6],
      };
    }

    if (type === 'functions') {
      return {
        name: 'Function',
        color: colors.green[6],
      };
    }

    return {
      name: toTitleCase(type),
      color: colors.blue[6],
    };
  }, [item.group]);

  return (
    <Box
      component="button"
      onMouseDown={(e) => {
        // mousedown instead of onClick to prevent onBlur on tiptap input
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      sx={(theme) =>
        ({
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: theme.spacing.xs,
          paddingBlock: 5,
          border: 'none',
          width: '100%',
          textAlign: 'left',
          cursor: 'pointer',
          backgroundColor: isFocused ? theme.colors.neutral[1] : 'transparent',
          ':hover': {
            backgroundColor:
              colorScheme === 'dark'
                ? theme.colors.neutral[7]
                : theme.colors.neutral[1],
          },
        }) as any
      }
    >
      <Text
        component="span"
        size="sm"
        sx={{
          wordBreak: 'break-word',
          flex: 1,
          lineHeight: description ? 1.2 : undefined,
        }}
      >
        {item.label}
        {description && (
          <>
            <br />
            <Text
              size="xs"
              color={colorScheme === 'dark' ? colors.neutral[4] : 'gray'}
              component="span"
            >
              {description}
            </Text>
          </>
        )}
      </Text>
      {item.group && (
        <Text
          component="span"
          size="sm"
          m={0}
          ml="auto"
          c={badge?.color}
          ta="right"
          sx={{
            flexShrink: 0,
          }}
        >
          {badge?.name}
        </Text>
      )}
    </Box>
  );
};

function useKeyboardNavigation({
  containerRef,
  items,
  handleSelect,
  scrollToIndex,
}: {
  items: SelectItem[];
  containerRef: any;
  handleSelect: (index: number) => void;
  scrollToIndex: (index: number) => void;
}) {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const upHandler = () => {
    const newIndex = (focusedIndex + items.length - 1) % items.length;
    setFocusedIndex(newIndex);
    scrollToIndex(newIndex);
  };

  const downHandler = () => {
    const newIndex = (focusedIndex + 1) % items.length;
    setFocusedIndex(newIndex);
    scrollToIndex(newIndex);
  };

  useEffect(() => setFocusedIndex(0), [items]);

  useImperativeHandle(containerRef as any, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        handleSelect(focusedIndex);
        return true;
      }

      return false;
    },
  }));

  return {
    focusedIndex,
  };
}
