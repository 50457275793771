import {
  QueryKeyUnion,
  useGqtyClient,
  useInvalidateQueries,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import {
  ArrayParam,
  StringParam,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { day, hasValue, waitFor, waitUntil } from '@finalytic/utils';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { getNamespaceAndType } from '../components';

export const runParamKey = 'runAutomationId'; // string
export const resultParamKey = 'workflowIds'; // string[]
export const resultViewParamKey = 'runResultView'; // string

type DrawerType = 'run' | 'preview' | 'workflows';

type RunInput = {
  automationId: string;
  params: any;
};

export const useRunDrawer = (onClose?: () => void) => {
  const { mutate, loading: runTaskLoading } = useRunMutation();

  const [{ automations }] = useTeam();
  const [automationId, setAutomationId] = useQueryParam(
    runParamKey,
    StringParam
  );
  const [workflowIds, setWorkflowIds] = useQueryParam(
    resultParamKey,
    ArrayParam
  );
  const [, setResultView] = useQueryParam(resultViewParamKey, StringParam);
  const [refreshKeys, setRefreshKeys] = useQueryParam('invalidate', ArrayParam);

  const navigate = useNavigate();
  const navigateBack = (v?: number) => navigate(typeof v === 'number' ? v : -1);

  const invalidate = useInvalidateQueries();

  const activeDrawer = useMemo<DrawerType | undefined>(() => {
    if (workflowIds?.length) return 'workflows';
    if (automationId) return 'run';
    return undefined;
  }, [automationId, workflowIds]);

  const client = useGqtyClient();

  const close = (drawer?: DrawerType) => {
    if (drawer === 'run') return setAutomationId(undefined);
    if (drawer === 'workflows') {
      setResultView(undefined);
      return setWorkflowIds(undefined);
    }

    setAutomationId(undefined);
    setWorkflowIds(undefined);
    setResultView(undefined);
    setRefreshKeys(undefined);

    if (onClose) onClose();
  };

  const openDrawer = (automationId: string) => setAutomationId(automationId);

  const awaitRefresh = (ids: string[], queryKeys: QueryKeyUnion[]) =>
    waitUntil(
      async () =>
        await client.query((q) => {
          const plans = q.jobPlans({
            where: {
              workflowId: { _in: ids },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
          });

          return {
            status: plans.map((plan) => plan.status),
          };
        }),
      (res) => {
        return res?.status.every((s) => s === 'completed');
      },
      { retryEvery: '2s', timeoutAfter: '3m' }
    ).then(() => {
      invalidate(queryKeys);
    });

  const runTask = async (
    args: RunInput,
    options?: {
      preventRunDrawer?: boolean;
    }
  ) => {
    const result = await mutate(args);
    if (!options?.preventRunDrawer) {
      setWorkflowIds([result.workflowId]);
    }
    return result;
  };

  const setWorkflows = (
    ids: string[],
    options?: {
      type?: Parameters<typeof setWorkflowIds>[1];
      refreshQueryKeys?: QueryKeyUnion[];
    }
  ) => {
    setWorkflowIds(ids, options?.type);
    setResultView('workflows', 'replaceIn');

    if (options?.refreshQueryKeys) {
      setRefreshKeys(options.refreshQueryKeys);
      awaitRefresh(ids, options.refreshQueryKeys);
    }
  };

  return {
    activeDrawer,
    close,
    setAutomationId,
    automationId,
    navigateBack,
    openDrawer,
    workflowIds,
    refreshKeys,
    setWorkflowIds: setWorkflows,
    runTask,
    runTaskLoading,
    getAutomationInput,
    postAutomations: async ({
      automationIds,
      allPagesSelected,
      filterState,
      getSelectedRowIds,
      where,
      skipCachedActions,
      selectedItemType,
      extraParams,
    }: {
      automationIds: string[];
      getSelectedRowIds: () => string[];
      allPagesSelected: boolean;
      filterState: Record<string, any>;
      where: Record<string, any>;
      skipCachedActions?: boolean;
      selectedItemType: string;
      extraParams?: Record<string, any>;
    }): Promise<any> => {
      const ids = allPagesSelected ? [] : getSelectedRowIds();

      const missingName = selectedItemType.split('.')[1];

      if (!ids?.length && !allPagesSelected) {
        showErrorNotification({
          title: `Missing ${missingName} ids.`,
          message: `Please select at least one ${missingName} to post.`,
        });

        return Promise.reject();
      }

      const filter: Record<string, any> = {};
      Object.entries(filterState || {}).forEach(([key, value]) => {
        const isEmptyObject =
          typeof value === 'object' &&
          !Array.isArray(value) &&
          Object.keys(value).length === 0;

        const isEmptyArray = Array.isArray(value) && value.length === 0;

        if (value && !isEmptyObject && !isEmptyArray) filter[key] = value;
      });

      // Prevent sending all pages when not filtered
      if (allPagesSelected && Object.keys(filter).length < 1) {
        showErrorNotification({
          title: 'Error: Post Automation',
          message:
            'Please set filter when selecting all pages and trying to post.',
          color: 'yellow',
        });

        return Promise.reject();
      }

      const result: { workflowId: string; runId?: string }[] = [];

      // we need to run in sequence and add a waitFor until we moved to inngest, else we risk duplicated data since backend concurrency is not working well
      for (const automationId of automationIds) {
        if (automationId !== automationIds[0]) await waitFor(250);
        const automation = automations.find(
          (i) => i.automationId === automationId
        );
        const input = getAutomationInput(automation?.template);

        const getDate = () => {
          const dateRange: [Date | null, Date | null] | undefined | string =
            filter?.date;

          if (dateRange && typeof dateRange === 'string') return dateRange;

          if (Array.isArray(dateRange))
            return dateRange
              .filter(hasValue)
              .map((i) => day(i).format('YYYY-MM-DD'))
              .join('...');

          return undefined;
        };

        const res = await runTask(
          {
            automationId,
            //input: ids.length === 0 || allPagesSelected ? undefined : ids,
            params: {
              skipCachedActions,
              ...extraParams,
              // Send the filters only when all pages are selected
              ...(allPagesSelected
                ? { ...filter, date: getDate(), where }
                : {}),
              [input.field]:
                ids.length === 0 || allPagesSelected ? undefined : ids,
            },
          },
          {
            preventRunDrawer: true,
          }
        );
        result.push(res);
      }
      setWorkflows(result.map((i) => i.workflowId));
      return result;
    },
  };
};

const useRunMutation = () => {
  const [teamId] = useTeamId();
  const { mutate, loading } = useTrpcMutation('runAutomation', {
    successMessage: {
      title: 'Automation Progress',
      message:
        "Automation is running. Follow the progress and each step's result.",
    },
  });

  const runAutomation = async (args: RunInput) => {
    return await mutate({ ...args, teamId });
  };

  return {
    mutate: runAutomation,
    loading,
  };
};

export const getAutomationInput = (
  template:
    | {
        // title: string | undefined;
        // uniqueRef: string | undefined;
        input: string | undefined;
        params: any;
      }
    | undefined
): {
  field: string;
  namespace: string;
  type: string;
  schema: string;
} => {
  if (!template) return { field: '', namespace: '', type: '', schema: '' };
  if (template.input && !['form', 'report'].includes(template.input)) {
    const [namespace, type] = getNamespaceAndType(template.input);
    return {
      field: 'input',
      namespace,
      type,
      schema: `${namespace}.${type}`,
    };
  }
  const d = Object.entries(template.params?.properties || {})
    .filter(
      ([key, value]: [string, any]) =>
        value.schema &&
        (key.endsWith('Id') || key.endsWith('No') || key === 'input')
    )
    .map(([field, value]: [string, any]) => {
      const [namespace, type] = getNamespaceAndType(value.schema);
      return { field, namespace, type, schema: `${namespace}.${type}` };
    })[0];
  if (d) return d;
  return { field: '', namespace: '', type: '', schema: '' };
};
