import { useInfiniteQuery } from '@finalytic/data';
import { audit_log_bool_exp } from '@finalytic/graphql';
import { formatUserName } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { AuditFilterParams, AuditTableRow } from './_types';

export type AuditRow = NonNullable<
  ReturnType<typeof useAuditTableQuery>['data']
>['pages'][0]['list'][0];

export function useAuditTableQuery({
  objectId,
  table,
  teamId,
}: AuditFilterParams) {
  const obj = objectId?.trim();

  const where = useMemo<audit_log_bool_exp>(
    () => ({
      tenantId: teamId ? { _eq: teamId } : undefined,
      tableName: table ? { _eq: table } : undefined,
      _or: obj
        ? [{ objectId: { _eq: obj } }, { automationId: { _eq: obj } }]
        : undefined,
    }),
    [teamId, table, obj]
  );

  return useInfiniteQuery(
    (q, { where }, { offset, limit }) => {
      const list = q
        .auditLogs({
          where,
          limit,
          offset,
          order_by: [{ createdAt: 'desc' }],
        })
        .map<AuditTableRow>((i) => ({
          id: i.id,
          op: i.op || '',
          when: i.createdAt,
          what: i.deltaJson() as any,
          who: formatUserName({
            firstName: i.actorUserId?.firstName,
            lastName: i.actorUserId?.lastName,
          }),
          table: i.tableName || '',
        }));

      const aggregate = q.auditLogAggregate({ where }).aggregate?.count() || 0;

      return { list, aggregate };
    },
    {
      variables: {
        where,
      },
    }
  );
}
