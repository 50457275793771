import { day } from '@finalytic/utils';
import { Box, Divider, Group, SegmentedControl, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import { CalendarFilterDateType, SelectButton } from '../..';
import { RangeCalendar } from './RangeCalendar';

export type CheckInOutType = 'all' | 'checkIn' | 'checkOut' | 'bookedAt';
export type CheckInOutTypeHandler = (value: CheckInOutType) => void;

const SwitchData = [
  { label: 'Within', value: 'all' },
  { label: 'Check In', value: 'checkIn' },
  { label: 'Check Out', value: 'checkOut' },
  { label: 'Booked', value: 'bookedAt' },
  { label: 'Cancelled', value: 'cancelledAt' },
];

export type ExtendedCalendarProps = {
  dateType?: CheckInOutType;
  setDateType?: CheckInOutTypeHandler;
  filterValue?: CalendarFilterDateType;
  setFilterValue: (value: CalendarFilterDateType | undefined) => void;
};

export const ExtendedCalendar = ({
  filterValue: dateValue,
  setFilterValue: setDateValue,
  dateType,
  setDateType,
}: ExtendedCalendarProps) => {
  const selectOptions = [
    'Past Week',
    'Past Month',
    'Past Quarter',
    'Past Year',
  ] as const;

  const [selectOption, setSelectOption] = useState<
    (typeof selectOptions)[number] | undefined
  >(undefined);

  const setCalendarDatesManually = (
    value: CalendarFilterDateType | undefined
  ) => {
    setDateValue(value);
    if (selectOption) {
      setSelectOption(undefined);
    }
  };

  useEffect(() => {
    if (selectOption) {
      const today = day().toDate();
      let secondDate: Date = day().toDate();

      switch (selectOption) {
        case 'Past Week':
          secondDate = day(today).subtract(7, 'days').toDate();
          break;
        case 'Past Month':
          secondDate = day(today).subtract(1, 'month').toDate();
          break;
        case 'Past Quarter':
          secondDate = day(today).subtract(3, 'months').toDate();
          break;
        case 'Past Year':
          secondDate = day(today).subtract(1, 'year').toDate();
          break;

        default:
          break;
      }

      setDateValue([secondDate, today]);
    }
  }, [selectOption]);

  return (
    <Group align="flex-start" mt={-10} sx={{ minHeight: 280 }} wrap="nowrap">
      <Box sx={{ width: setDateType ? 260 : 180 }}>
        <Stack gap={3}>
          {selectOptions.map((item, index) => (
            <SelectButton
              key={item}
              isActive={selectOption === item}
              label={item}
              onClick={() => setSelectOption(item)}
              sx={
                selectOption === item
                  ? { backgroundColor: '#5C617820' }
                  : undefined
              }
              data-autofocus={index === 0}
            />
          ))}
        </Stack>
        <Divider mb={12} mt={10} />
        {setDateType && (
          <>
            <SegmentedControl
              orientation="vertical"
              fullWidth
              size="xs"
              radius="md"
              data={SwitchData}
              value={dateType}
              onChange={(value) => setDateType(value as CheckInOutType)}
              color="gray"
              styles={(theme) => ({
                root: {
                  backgroundColor: theme.colors.gray[0],
                },
              })}
            />
          </>
        )}
      </Box>
      <RangeCalendar
        values={dateValue || [null, null]}
        onChange={setCalendarDatesManually}
      />
    </Group>
  );
};
