import { LoadingIndicator } from '@finalytic/ui';
import { Center, MantineFontSize, Text, rem } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { forwardRef } from 'react';

type Props = {
  color?: 'red' | 'yellow';
  count: number;
  size?: MantineFontSize;
  sx?: EmotionSx;
  loading?: boolean;
};

export const CountBagde = forwardRef<HTMLDivElement, Props>(
  ({ count, color = 'red', size = '0.75rem', sx, loading }, ref) => {
    return (
      <Center
        ref={ref}
        sx={(theme, u) =>
          ({
            display: 'inline-flex',
            borderRadius: theme.radius.sm,
            backgroundColor: loading
              ? undefined
              : color === 'yellow'
                ? theme.colors.yellow[7]
                : theme.colors.red[9],
            color: theme.white,
            fontWeight: 500,
            paddingInline: rem(4),
            paddingBlock: rem(3),
            marginLeft: theme.spacing.xs,
            ...(typeof sx === 'function' ? sx(theme, u) : sx),
          }) as any
        }
      >
        {loading ? (
          <LoadingIndicator size={'0.75rem'} />
        ) : (
          <Text component="span" size={size}>
            {count}
          </Text>
        )}
      </Center>
    );
  }
);
