import { day, hasValue } from '@finalytic/utils';
import { Box, Popover } from '@mantine/core';
import { DateValue, MonthPicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { FilterPill } from './FilterPill';

type Props = {
  value: [DateValue, DateValue] | undefined | null;
  setValue: (v: [DateValue, DateValue] | undefined | null) => void;
  label: string;
  numberOfColumns?: number;
  columnsToScroll?: number;
};

export const MonthFilter = ({
  label: defaultLabel,
  value,
  setValue,
  columnsToScroll = 1,
  numberOfColumns = 2,
}: Props) => {
  const [input, setInput] = useState(value);

  const [opened, handlers] = useDisclosure(false);

  const isDefault = !value || value.filter(hasValue).length === 0;

  const onClose = () => {
    handlers.close();
    if (input !== value) setValue(input);
  };

  useEffect(() => {
    if (input !== value) setInput(value);
  }, [value]);

  const label = useMemo(() => {
    if (isDefault) return defaultLabel;

    const startDate = input?.[0] && day(input?.[0]);
    const endDate = input?.[1] && day(input?.[1]);

    const showStartDateYear = !endDate || startDate?.year() !== endDate?.year();

    return [
      startDate?.format(showStartDateYear ? 'MMMM YYYY' : 'MMMM'),
      endDate?.format('MMMM YYYY'),
    ]
      .filter(hasValue)
      .join(' - ');
  }, [value, defaultLabel, isDefault]);

  return (
    <Popover
      opened={opened}
      onClose={onClose}
      position="bottom-start"
      radius="md"
      shadow="md"
    >
      <Popover.Target>
        <Box>
          <FilterPill
            opened={opened}
            isDefault={isDefault}
            onClick={handlers.toggle}
            label={label}
            resetFilter={undefined}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <MonthPicker
          type="range"
          numberOfColumns={numberOfColumns}
          columnsToScroll={columnsToScroll}
          value={input || [null, null]}
          onChange={setInput}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
