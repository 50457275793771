import { Logo } from '@finalytic/ui';
import { Maybe, hasValue, toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Flex,
  Group,
  Table as MantineTable,
  Text,
} from '@mantine/core';
import { FormattedStatement } from '@vrplatform/ui-common';
import { ReactNode } from 'react';

export const StatementHeader = ({
  header,
  maxWidth = '700px',
  isSummaryView,
}: {
  header: FormattedStatement['header'];
  maxWidth?: string | number;
  isSummaryView?: boolean;
}) => {
  const hasTeamValues = !!Object.values(header?.team.info || {}).filter(
    hasValue
  ).length;
  const hasOwnerValues = !!Object.values(header?.owner || {}).filter(hasValue)
    .length;

  return (
    <>
      <Flex
        p={15}
        justify="stretch"
        sx={{
          flexDirection: 'column',
          flexGrow: '1 !important' as any,
          position: 'relative',

          maxWidth,
          border: '1px solid #E6E8ED',
          borderRadius: 20,
          '@media (max-width: 400px)': {
            padding: 16,
          },
        }}
      >
        <Box>
          {/* Team Logo and Name */}
          <Group mb="md">
            <Avatar
              size={60}
              src={header?.team.logo}
              sx={{
                border: '2px solid #fff',
                borderRadius: 10,
                boxShadow:
                  '0px 14px 8px rgba(0, 0, 0, 0.01), 0px 6px 6px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)',
              }}
              styles={{
                placeholder: {
                  backgroundColor: '#fff',
                },
                image: {
                  objectFit: 'contain',
                },
              }}
            >
              {!header?.team?.logo && <Logo width={50} />}
            </Avatar>
            <Box>
              <Text size="1.7rem" fw={500}>
                {header?.team.name}
              </Text>
              <Text fw={500} color="#5C6178" mt={-3}>
                {isSummaryView
                  ? 'Owner Statement | Summary'
                  : header?.statement.subline}
              </Text>
            </Box>
          </Group>

          {/* Listing Address */}
          {header?.listing.name && (
            <Text pl="xs" size="xl" fw={500}>
              {header?.listing.name}
            </Text>
          )}
          {header?.listing.address && (
            <Text pl="xs" size="md">
              {header?.listing.address}
            </Text>
          )}

          {/* Team Info */}
          {hasTeamValues && (
            <>
              <TableHeader>Team:</TableHeader>
              <TeamInfo data={header?.team.info || {}} />
            </>
          )}
        </Box>

        {/* Owner Info */}
        {hasOwnerValues && header?.owner && (
          <>
            <TableHeader>Owner:</TableHeader>
            <OwnerInfo owner={header.owner} />
          </>
        )}
      </Flex>
    </>
  );
};

const TableHeader = ({ children }: { children: ReactNode }) => (
  <Text pl="xs" pt="xs" size="lg" fw={500}>
    {children}
  </Text>
);

const SimpleTable = ({ children }: { children: ReactNode }) => {
  return (
    <MantineTable
      sx={{
        flex: 1,
        minWidth: '330px',
        alignSelf: 'center',
        '& tr td:nth-of-type(2)': {
          textAlign: 'right',
        },
        '@media (max-width: 400px)': {
          tr: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            td: {
              paddingLeft: '0 !important',
              width: 'calc(100% - 24px)',
              '&:nth-of-type(2)': {
                textAlign: 'left',
              },
            },
            '&:last-of-type td:nth-of-type(1)': {
              borderBottom: '1px solid rgb(243,244,239)',
            },
          },
        },
      }}
    >
      <tbody>{children}</tbody>
    </MantineTable>
  );
};

const TeamInfo = ({ data }: { data: Record<string, Maybe<string>> }) => {
  return (
    <SimpleTable>
      {Object.entries(data || {})
        .filter(([, value]) => !!value)
        .map(([key, value]) => {
          return <Row key={key} label={toTitleCase(key)!} value={value} />;
        })}
    </SimpleTable>
  );
};

const OwnerInfo = ({
  owner,
}: { owner: NonNullable<FormattedStatement['header']>['owner'] }) => {
  return (
    <>
      <SimpleTable>
        <Row label="Company Name" value={owner?.companyName} />
        <Row label="Name" value={owner?.name} />
        <Row label="Address" value={owner?.address} />
        <Row label="Email" value={owner?.email} />
        <Row label="Phone" value={owner?.phone} />
      </SimpleTable>
    </>
  );
};

const Row = ({
  label,
  value,
  showWhenNull = false,
}: { label: string; value: Maybe<string>; showWhenNull?: boolean }) => {
  if (!value?.trim() && !showWhenNull) return null;

  return (
    <tr>
      <Text component="td" sx={{ color: '#5C6178' }}>
        {label}
      </Text>
      <Text component="td" sx={{ flexShrink: 0 }}>
        {value}
      </Text>
    </tr>
  );
};
