import { InputPercentage, Select } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import {
  HiddenFeatureIndicator,
  OwnerEllipsisMenuItems,
  OwnerEllipsisMenuModals,
} from '@finalytic/data-ui';
import {
  CopyIcon,
  EmailIcon,
  HashtagIcon,
  LoaderIcon,
  PhoneIcon,
  PinIcon,
  PlusIcon,
  ShuffleIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  IconButton,
  SelectItem,
  StringParam,
  UrlUpdateType,
  showSuccessNotification,
  showWarnNotification,
  useQueryParams,
} from '@finalytic/ui';
import { Maybe, ensure, utc } from '@finalytic/utils';
import { Box, Center, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import {
  useClipboard,
  useDebouncedState,
  useDebouncedValue,
} from '@mantine/hooks';
import {
  formatAddress,
  formatUserName,
  getCountryByIsoCode,
  getListingName,
  getOwnerStatus,
  getStateByIsoCode,
  getUserAddress,
  whereListings,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ListingOwnerBadge, OwnerUserStatusBadge } from '../../components';
import { StatementStatusBadge } from '../../views/statements/_components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { OwnerEditForm } from './AddOwnerDrawer';

type View = 'overview' | 'edit';

export function useOwnerDetailDrawer() {
  const [opts, set] = useQueryParams({
    owner: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.owner,
    open: (id: string, view?: 'overview' | 'edit', type?: UrlUpdateType) =>
      set({ owner: id, view }, type),
    close: () => set({ owner: undefined, view: undefined }),
    ownerId: opts.owner,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}

function useOwnerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .user({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((user) => {
            const { status, isArchived, isReinvite, tenantUser } =
              getOwnerStatus(user, args.teamId);

            const address = getUserAddress(user).values;

            const getOwnership = (
              ownership: gqlV2.listing_owner
            ): Ownership => ({
              id: ownership.id,
              listing: {
                id: ownership.listing.id,
                name: getListingName(ownership.listing),
                address: ownership.listing.address,
              },
              split: ownership.split,
              owner: {
                role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                  ownership.owner?.type === 'company'
                    ? 'company'
                    : ownership.role || 'owner'
                ),
                name: formatUserName(ownership.owner || {}),
              },
            });

            const userListings = user
              .ownerships({
                order_by: [
                  {
                    createdAt: 'asc_nulls_last',
                  },
                ],
                where: {
                  role: {
                    _neq: 'ownerMigrated',
                  },
                },
              })
              .map<Ownership>((ownership) => getOwnership(ownership));

            return {
              id: user.id,
              taxId: user.taxId,
              addressId: user.address_id,
              status,
              isArchived,
              isReinvite,
              email: user.email,
              secondaryEmails: user.secondaryEmails(),
              phone: user.phone,
              name: formatUserName(user),
              firstName: user.firstName,
              lastName: user.lastName,
              companyName: user.companyName,
              teamId: tenantUser.tenantId,
              address,
              ownerships: userListings,
              hasUserOwnerships: userListings.length > 0,
              statements: user
                .ownerStatementOwners({
                  order_by: [
                    {
                      statement: {
                        startAt: 'desc_nulls_last',
                      },
                    },
                  ],
                  where: {
                    statement: {
                      tenantId: {
                        _eq: args.teamId,
                      },
                    },
                  },
                })
                .map((statementOwner) => ({
                  id: statementOwner.statementId,
                  startAt: statementOwner.statement.startAt,
                  status: statementOwner.statement.status,
                  listing: {
                    id: statementOwner.statement?.listing?.id,
                    name: statementOwner.statement?.listing
                      ? getListingName(statementOwner.statement?.listing)
                      : '',
                  },
                  statementOwner: {
                    id: statementOwner.id,
                    role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                      statementOwner.owner?.type === 'company'
                        ? 'company'
                        : statementOwner.role || 'owner'
                    ),
                    name: formatUserName(statementOwner.owner),
                  },
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'owners',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Owner = NonNullable<ReturnType<typeof useOwnerQuery>['data']>;

export const OwnerDetailDrawer = () => {
  const { opened, close, ownerId, view, setView } = useOwnerDetailDrawer();

  const { isLoading, data: owner, refetch } = useOwnerQuery(ownerId);

  const [modalOpened, setModalOpened] = useState<
    'delete' | 'invite' | 'revoke-access' | 'archive' | 'copyInvite' | null
  >(null);

  const closeModal = () => setModalOpened(null);

  const { mutate } = useTrpcMutation('updateOwner', {
    invalidateQueryKeys: ['owners'],
  });

  return (
    <>
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={owner?.name || 'Missing name'}
          type="Owner"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            owner && (
              <OwnerEllipsisMenuItems
                handlers={{
                  setArchiveOpen: () => setModalOpened('archive'),
                  setInviteOpen: () => setModalOpened('invite'),
                  setCopyInviteOpen: () => setModalOpened('copyInvite'),
                  setDeleteOpen: () => setModalOpened('delete'),
                  setRevokeAccessOpen: () => setModalOpened('revoke-access'),
                }}
                isArchived={owner.isArchived}
                isReinvite={owner.isReinvite}
                owner={owner}
                refetch={refetch}
              />
            )
          }
        />
        {!owner && !isLoading ? (
          'No owner found'
        ) : view === 'edit' && owner ? (
          <>
            <OwnerEditForm
              isOwnerAddModal={false}
              handleSubmit={async (values, methods) => {
                if (!values.address.stateCode || !values.address.countryCode) {
                  return showWarnNotification({
                    message: 'Please select a country and state',
                  });
                }

                const hasFirstOrLastName =
                  !!values.firstName?.trim() || !!values.lastName?.trim();

                if (
                  hasFirstOrLastName
                    ? !values.firstName?.trim() || !values.lastName?.trim()
                    : !values.companyName?.trim()
                ) {
                  if (hasFirstOrLastName) {
                    if (!values.firstName?.trim()) {
                      methods?.setError('firstName', {
                        message: 'First name is required with last name.',
                      });
                      methods?.setFocus('lastName');
                    } else {
                      methods?.setError('lastName', {
                        message: 'Last name is required with first name.',
                      });
                      methods?.setFocus('lastName');
                    }
                  } else {
                    methods?.setError('firstName', {});
                    methods?.setError('lastName', {});
                    methods?.setError('companyName', {
                      message:
                        'Please enter a first and last name or a company name.',
                    });
                    methods?.setFocus('companyName');
                  }

                  return;
                }

                return mutate({
                  ownerId: owner.id,
                  tenantId: owner.teamId,
                  input: {
                    address: !values.address.line1
                      ? null
                      : {
                          id: owner.addressId,
                          line1: values.address.line1,
                          line2: values.address.line2 || '',
                          city: values.address.city,
                          postcode: values.address.postcode,
                          countryCode: values.address.countryCode,
                          stateCode: values.address.stateCode || '',
                          country:
                            getCountryByIsoCode(values.address.countryCode)
                              ?.name || '',
                          state:
                            getStateByIsoCode(
                              values.address.stateCode,
                              values.address.countryCode
                            )?.name || '',
                        },
                    firstName: values.firstName,
                    lastName: values.lastName,
                    companyName: values.companyName,
                    email: values.email,
                    phone: values.phone,
                    taxId: values.taxId,
                  },
                }).then(() => setView('overview'));
              }}
              initialValues={{
                firstName: owner.firstName || '',
                lastName: owner.lastName || '',
                email: owner.email!,
                phone: owner.phone || '',
                taxId: owner.taxId || '',
                companyName: owner.companyName || '',
                type: 'individual',
                address: owner.address,
                addUserOption: false,
              }}
              onReset={() => setView('overview')}
              submitButtonLabel="Save changes"
              isEmailDisabled={owner.status === 'active'}
            />
          </>
        ) : (
          <OwnerDetail owner={owner} isLoading={isLoading} />
        )}
        <OwnerEllipsisMenuModals
          owner={owner || null}
          archiveModal={{
            closeModal,
            opened: modalOpened === 'archive',
          }}
          inviteModal={{
            closeModal,
            opened: modalOpened === 'invite',
          }}
          copyInviteModal={{
            closeModal,
            opened: modalOpened === 'copyInvite',
          }}
          deleteModal={{
            closeModal,
            opened: modalOpened === 'delete',
          }}
          revokeAccessModal={{
            closeModal,
            opened: modalOpened === 'revoke-access',
          }}
          refetch={refetch}
        />
      </Drawer>
    </>
  );
};

const OwnerDetail = ({
  owner,
  isLoading,
}: { owner: Maybe<Owner>; isLoading: boolean }) => {
  if (!owner) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <OwnerUserStatusBadge status={owner.status} />,
          },
          {
            icon: EmailIcon,
            title: 'Email',
            text: owner.secondaryEmails.length ? (
              <>
                {owner.email}
                <br />
                <Text color="gray" component="span">
                  {owner.secondaryEmails.join(', ')}
                </Text>
              </>
            ) : (
              owner.email
            ),
          },
          {
            icon: HashtagIcon,
            title: 'Tax ID',
            text: owner.taxId,
          },
          {
            icon: PhoneIcon,
            title: 'Phone',
            text: owner.phone,
          },
          {
            icon: PinIcon,
            title: 'Address',
            text: formatAddress(owner.address),
          },
        ]}
      />

      <ListingOwnerships ownerId={owner.id} ownerships={owner.ownerships} />

      <OwnerStatementsTable statements={owner.statements} ownerId={owner.id} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type Ownership = {
  id: any;
  listing: {
    id: any;
    name: string;
    address: string | undefined;
  };
  split: number | undefined;
  owner: {
    role: gqlV2.listing_owner_role_enum | 'company';
    name: string;
  };
};

const ListingOwnerships = ({
  ownerships,
  ownerId,
}: { ownerships: Ownership[]; ownerId: string }) => {
  const columns = useMemo<MRT_ColumnDef<Owner['ownerships'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'listing.name',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.listing.name}
            </Text>
            <Text component="span" display="block" size="xs" color="gray">
              {row.original.listing.address}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Split',
        accessorKey: 'split',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const split = row.original.split;
          const initialValue = typeof split === 'number' ? split : '';
          const [value, setValue] = useDebouncedState<number | string>(
            initialValue,
            500
          );
          const ownershipId = row.original.id;
          const role = row.original.owner.role;

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string; split: number | null }) => {
              return q.updateListingOwner({
                pk_columns: {
                  id: args.ownershipId,
                },
                _set: {
                  split: args.split,
                },
              })?.id;
            }
          );

          if (role === 'company')
            return (
              <ListingOwnerBadge role={role} name={row.original.owner.name} />
            );

          return (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                gap: theme.spacing.xs,
              })}
            >
              <ListingOwnerBadge
                role={role}
                name={row.original.owner.name}
                variant="icon"
              />
              <InputPercentage
                height={20}
                maw={90}
                value={value}
                onChange={setValue}
                loadingMutation={loading}
                placeholder="AUTO"
                decimalScale={0}
                hideControls
                onBlur={(event) => {
                  const newValue = event.target.value
                    ? parseFloat(event.target.value)
                    : '';

                  mutate({
                    args: {
                      ownershipId,
                      split: typeof newValue === 'number' ? newValue : null,
                    },
                  }).then(() => setValue(newValue));
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Listing ownerships"
      rightSection={<AddEntityToOwner ownerId={ownerId} type="listing" />}
      rowData={ownerships}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No listing ownerships
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();

          const ownershipId = row.original.id;
          const role = row.original.owner.role;
          const isCompany = role === 'company';

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string }) => {
              return q.deleteListingOwner({
                id: args.ownershipId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          const { mutate: toggle, loading: loadingToggle } = useMutation(
            (
              q,
              args: { ownershipId: string; role: gqlV2.listing_owner_role_enum }
            ) => {
              return q.updateListingOwner({
                pk_columns: {
                  id: args.ownershipId,
                },
                _set: {
                  role: args.role,
                },
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Ownership ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              {!isCompany && (
                <EllipsisMenuItem
                  customIcon={<ShuffleIcon size={16} />}
                  loading={loadingToggle}
                  onClick={() =>
                    toggle({
                      args: {
                        ownershipId,
                        role: role === 'owner' ? 'spectator' : 'owner',
                      },
                    })
                  }
                >
                  Set as {role === 'owner' ? 'spectator' : 'owner'}
                </EllipsisMenuItem>
              )}
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      ownershipId,
                    },
                  })
                }
              >
                Delete ownership
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(ownershipId)}
                >
                  Copy ownership ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

export const OwnerStatementsTable = ({
  statements,
  ownerId,
  hideAddButton,
}: {
  statements: Owner['statements'];
  ownerId: string;
  hideAddButton?: boolean;
}) => {
  const goto = useNavigate();

  return (
    <DrawerCollapsableTable
      title="Owner statements"
      rightSection={
        !hideAddButton && (
          <AddEntityToOwner ownerId={ownerId} type="statement" />
        )
      }
      rowData={statements}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {utc(statement.startAt).format('MMM YYYY')}
                </Text>
                {statement.listing.name && (
                  <Text component="span" display="block" size="xs" color="gray">
                    {statement.listing.name}
                  </Text>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Group wrap="nowrap">
                <ListingOwnerBadge
                  role={statement.statementOwner.role}
                  name={statement.statementOwner.name}
                  variant="icon"
                />
                <StatementStatusBadge status={statement.status} />
              </Group>
            );
          },
        },
      ]}
      onRowClick={{
        handler: (row) =>
          goto(
            `/statement/${row.original.listing.id}?date=${utc(
              row.original.startAt
            ).yyyymmdd()}&statementOwner=${
              row.original.statementOwner.id
            }&owner=${ownerId}`
          ),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No owner statements
          </Text>
        </Center>
      )}
    />
  );
};

const AddEntityToOwner = ({
  ownerId,
  type,
}: { ownerId: string; type: 'listing' | 'statement' }) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, ownerId, type }, { limit, offset }) => {
      if (type === 'statement') {
        const where: gqlV2.owner_statement_bool_exp = {
          tenantId: { _eq: teamId },
          listing: {
            ownerships: {
              role: {
                _eq: 'spectator',
              },
              ownerId: { _eq: ownerId },
            },
            _or: search
              ? [
                  { title: { _ilike: `%${search}%` } },
                  { name: { _ilike: `%${search}%` } },
                  { address: { _ilike: `%${search}%` } },
                ]
              : undefined,
          },
          _not: {
            owners: {
              ownerId: {
                _eq: ownerId,
              },
            },
          },
        };

        const list = q
          .ownerStatements({
            where,
            limit,
            offset,
            order_by: [{ startAt: 'desc' }],
          })
          .map<SelectItem>((statement) => ({
            label: `${getListingName(statement.listing!)} - ${utc(
              statement.startAt
            ).format('MMM YYYY')}`,
            value: statement.id,
          }));

        const aggregate =
          q.ownerStatementAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where: gqlV2.listing_bool_exp = {
        ...whereListings({
          currentTeamId: teamId,
          dashboard,
          search,
          partnerTeamIds: [],
        }),
        _not: {
          ownerships: {
            ownerId: {
              _eq: ownerId,
            },
          },
        },
      };

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [
            {
              calculated_title: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
        }));
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        ownerId,
        type,
      },
    }
  );

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        entityId: string;
        ownerId: string;
        type: 'listing' | 'statement';
      }
    ) => {
      if (args.type === 'statement') {
        return q.insertOwnerStatementOwner({
          object: {
            statementId: args.entityId,
            ownerId: args.ownerId,
            role: 'spectator',
          },
        })?.id;
      }
      return q.insertListingOwner({
        object: {
          listingId: args.entityId,
          ownerId: args.ownerId,
          role: 'owner',
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const entityId = value.value;
        mutate({ args: { entityId, ownerId, type } });
      }}
      dropdownProps={{
        position: 'bottom-end',
        noOptionsText: 'No owner statements available',
      }}
    >
      {() => {
        return (
          <IconButton loading={loading}>
            <PlusIcon size={18} />
          </IconButton>
        );
      }}
    </Select>
  );
};
