import {
  Maybe,
  ensure,
  formatCurrency,
  groupBy,
  sortBy,
  sum,
  utc,
} from '@finalytic/utils';
import { Statement, StatementSummaryUnion, SummaryRow } from '../_types';

// grouping by makes sense when
// groupedByListing
// groupedByMonth
// groupedByBookingChannel

// grouping by doesn't make sense when
// groupedByReservation
// => then just return one row with statement startBalance, endBalance, netRevenue, expenses, etc.

export const getSummaryRows = ({
  groupedBy,
  statements,
  ownerSplit,
}: {
  statements: Statement[];
  groupedBy: StatementSummaryUnion;

  ownerSplit: number | undefined;
}): SummaryRow[] => {
  let group = {};

  if (groupedBy === 'groupByListing') {
    group = groupBy(statements, (x) => x.listing?.id || 'missingListing');
    return groupedToSummaryRows({ group, groupedBy, ownerSplit });
  } else if (groupedBy === 'groupByMonth') {
    group = groupBy(statements, (x) => utc(x.startAt).format('YYYY-MM-01'));
  } else if (groupedBy === 'groupByReservation') {
    group = groupBy(
      statements,
      (x) => x.lines[0]?.reservation?.id || 'missingReservation'
    );
  }

  return groupedToSummaryRows({ group, groupedBy, ownerSplit });
};

export const getSummaryTotals = ({
  groupedBy,
  rows,
  currency = 'USD',
  statements,
  ownerSplit,
}: {
  rows: SummaryRow[];
  groupedBy: StatementSummaryUnion;
  currency: string | undefined | null;
  statements: Statement[];
  ownerSplit: number | undefined;
}): SummaryRow | null => {
  const statementIds = statements.map((stat) => stat.id);

  type SummaryKey = keyof SummaryRow['amounts'];

  // Overload function to get correct return types
  function getSumOrFirstOrLast(key: SummaryKey, format: 'formatted'): string;
  function getSumOrFirstOrLast(key: SummaryKey, format: 'amounts'): number;
  function getSumOrFirstOrLast(
    key: SummaryKey,
    format: 'amounts' | 'formatted'
  ) {
    let value = 0;

    // Get special values if grouped by month and only for certain keys
    if (
      groupedBy === 'groupByMonth' &&
      ensure<SummaryKey[]>([
        'startingBalance',
        'currentBalance',
        'endingBalance',
      ]).includes(key)
    ) {
      // first month for startingBalance
      // last month for currentBalance and endingBalance
      const t: { [_key in SummaryKey]: number } = {
        startingBalance: rows[0]?.amounts?.startingBalance,
        currentBalance: rows[rows.length - 1]?.amounts?.currentBalance,
        endingBalance: rows[rows.length - 1]?.amounts?.endingBalance,
        netIncome: 0,
        payout: 0,
        split: 0,
      };
      if (t[key]) {
        value = t[key];
      }
    } else {
      // Otherwise just sum up all rows
      const values = rows.map((x) => x.amounts[key]);
      value = sum(values);
    }

    if (format === 'amounts') return value || 0;
    return formatCurrency(value, currency || 'USD');
  }

  const amounts = {
    startingBalance: getSumOrFirstOrLast('startingBalance', 'amounts'),
    netIncome: getSumOrFirstOrLast('netIncome', 'amounts'),
    currentBalance: getSumOrFirstOrLast('currentBalance', 'amounts'),
    payout: getSumOrFirstOrLast('payout', 'amounts'),
    split: getSumOrFirstOrLast('split', 'amounts'),
    endingBalance: getSumOrFirstOrLast('endingBalance', 'amounts'),
  };

  const formatted = {
    startingBalance: getSumOrFirstOrLast('startingBalance', 'formatted'),
    netIncome: getSumOrFirstOrLast('netIncome', 'formatted'),
    currentBalance: getSumOrFirstOrLast('currentBalance', 'formatted'),
    payout: getSumOrFirstOrLast('payout', 'formatted'),
    split: getSumOrFirstOrLast('split', 'formatted'),
    endingBalance: getSumOrFirstOrLast('endingBalance', 'formatted'),
  };

  return {
    id: 'total',
    name: 'Total:',
    amounts,
    formatted,
    statementIds,
    summaryLines: getSummaryLines({
      amounts,
      currency: currency || 'USD',
      formatted,
      statementLines: statements.flatMap((st) => st.lines),
      ownerSplit,
    }),
  };
};

const groupedToSummaryRows = ({
  group,
  groupedBy,
  ownerSplit,
}: {
  group: { [key: string]: Statement[] };
  groupedBy: StatementSummaryUnion;
  ownerSplit: number | undefined;
}) => {
  const rows = Object.entries(group).map<SummaryRow>(([key, statements]) => {
    let rowName = '';
    if (groupedBy === 'groupByListing') {
      rowName = statements[0]?.listing?.name || 'Missing Listing';
    } else if (groupedBy === 'groupByMonth') {
      rowName = statements[0]?.startAt
        ? utc(statements[0]?.startAt).format('MMM YYYY')
        : 'Missing Month';
    }

    const statementIds = statements.map((i) => i.id);

    const sortedByDate = sortBy(statements, (x) => x.startAt);

    const firstStatement = sortedByDate[0];

    const lines = sortedByDate?.flatMap((i) => i.lines);
    const currency = lines[0]?.currency || 'USD';

    const centTotal = sum(statements, (x) =>
      typeof x.centTotal === 'number' ? x.centTotal : 0
    );
    const centPayedOut = sum(statements, (x) =>
      typeof x.centPayedOut === 'number' ? x.centPayedOut : 0
    );

    const status = statements.length > 1 ? 'posted' : statements[0]?.status;

    // only take first statement when groupedBy listing or reservation
    // groupedBy month should sum the centBalanceStart for all statements
    const centBalanceStart =
      groupedBy === 'groupByMonth'
        ? sum(statements, (x: any) => x.centBalanceStart)
        : firstStatement?.centBalanceStart || 0;

    const { amounts, formatted, summaryLines } = getStatementSummary({
      currency,
      centBalanceStart,
      centPayedOut,
      centTotal,
      lines,
      status,
      ownerSplit,
    });

    return {
      id: key,

      statementIds,
      name: rowName,
      amounts,
      formatted,
      summaryLines,
    };
  });

  return sortBy(rows, (x) =>
    groupedBy === 'groupByMonth' ? utc(x.name).unix() : x.name
  );
};

export function getStatementSummary(ownerStatement: {
  currency: string;
  centTotal?: Maybe<number>;
  centBalanceStart?: Maybe<number>;
  centPayedOut?: Maybe<number>;
  status?: Maybe<string>;
  lines?: { role: Maybe<string>; centTotal: Maybe<number> }[];
  ownerSplit: number | undefined;
}): Omit<SummaryRow, 'id' | 'name' | 'statementIds'> {
  const netIncome = (ownerStatement?.centTotal || 0) / 100;
  const startingBalance = (ownerStatement?.centBalanceStart || 0) / 100;

  const currency = ownerStatement.currency;

  const hasPayout = typeof ownerStatement?.centPayedOut === 'number';
  const payout = hasPayout
    ? ownerStatement.centPayedOut! / 100
    : netIncome + startingBalance;

  // Manual calculation
  const currentBalance = startingBalance + netIncome;
  const endingBalance = currentBalance - payout;

  const isPublished = ['published', 'posted'].includes(
    ownerStatement?.status || ''
  );

  const split =
    typeof ownerStatement.ownerSplit === 'number'
      ? (payout * ownerStatement.ownerSplit) / 100
      : payout;

  const amounts = {
    startingBalance,
    netIncome,
    currentBalance,
    payout,
    split,
    endingBalance,
  };

  const formattedPayout = hasPayout
    ? !isPublished
      ? `(draft) ${formatCurrency(payout, currency)}`
      : formatCurrency(payout, currency)
    : '-';

  const formatted = {
    startingBalance: formatCurrency(startingBalance, currency),
    netIncome: formatCurrency(netIncome, currency),
    currentBalance: formatCurrency(currentBalance, currency),
    payout: formattedPayout,
    split: formatCurrency(split, currency),
    endingBalance: hasPayout
      ? !isPublished
        ? `(draft) ${formatCurrency(endingBalance, currency)}`
        : formatCurrency(endingBalance, currency)
      : '-',
  };

  return {
    formatted,
    amounts,
    summaryLines: getSummaryLines({
      amounts,
      formatted,
      statementLines: ownerStatement?.lines || [],
      currency: ownerStatement.currency,
      ownerSplit: ownerStatement.ownerSplit,
    }),
  };
}

const getSummaryLines = ({
  amounts,
  formatted,
  statementLines,
  currency,
  ownerSplit,
}: {
  amounts: SummaryRow['amounts'];
  formatted: SummaryRow['formatted'];
  statementLines: {
    centTotal: Maybe<number>;
    role: Maybe<string>;
  }[];
  currency: string;
  ownerSplit: number | undefined;
}): SummaryRow['summaryLines'] => {
  const startingBalance = amounts.startingBalance;
  const netIncome = amounts.netIncome;
  const currentBalance = amounts.currentBalance;
  const payout = amounts.payout;
  const split = amounts.split;
  const endingBalance = amounts.endingBalance;

  const netIncomeLines = statementLines
    ? sum(
        statementLines?.filter((x) => x?.role === 'bill'),
        'centTotal'
      ) / 100
    : undefined;

  return [
    {
      label: 'Starting Balance',
      formattedAmount: formatted.startingBalance, //sum(ownerStatementBalanceLines, 'centTotal') ,
      formattedAmountVs: undefined,
      amount: startingBalance,
      isPrimary: false,
    },
    {
      label: 'Net Income',
      formattedAmount: formatted.netIncome,
      formattedAmountVs:
        netIncomeLines !== undefined && netIncome !== netIncomeLines
          ? formatCurrency(netIncomeLines, currency)
          : undefined,
      amount: netIncome,
      isPrimary: true,
    },
    {
      label: 'Current Balance',
      formattedAmount: formatted.currentBalance,
      formattedAmountVs: undefined,
      amount: currentBalance,
      isPrimary: false,
    },
    {
      label: 'Owner Payout',
      formattedAmount: formatted.payout,
      formattedAmountVs: undefined,
      amount: payout,
      isPrimary: false,
    },
    ...(amounts.payout !== amounts.split
      ? [
          {
            label: `Owner Split (${ownerSplit || 0}%)`,
            formattedAmount: formatted.split,
            formattedAmountVs: undefined,
            amount: split,
            isPrimary: false,
          },
        ]
      : []),
    {
      label: 'Ending Balance',
      formattedAmount: formatted.endingBalance,
      formattedAmountVs: undefined,
      amount: endingBalance,
      isPrimary: false,
    },
  ];
};
