import { useAuth } from '@finalytic/authentication';
import { Button } from '@finalytic/components';
import { Modal } from '@finalytic/ui';
import { Box, Center, Text, Title, rem, useMantineTheme } from '@mantine/core';
import { useState } from 'react';

export const FinalyticMigrationModal = () => {
  const { primaryColor } = useMantineTheme();
  const clerk = useAuth();
  const [loading, setLoading] = useState(false);

  // const opened =
  //   team.featureFlags.includes('migrate') && PLATFORM === 'finalytic';
  const opened = primaryColor === 'finalytic';

  return (
    <Modal
      opened={opened}
      onClose={() => null}
      size={520}
      withCloseButton={false}
    >
      <Center p={'3rem'} sx={{ flexDirection: 'column' }}>
        <Box mb="lg">
          <Icons />
        </Box>
        <MigrationTextContent
          isModal
          loading={loading}
          onClick={() => {
            clerk.getToken().then((x) => {
              setLoading(true);
              window.location.href = `https://migrate-to-vrp.vrplatform.workers.dev?token=${x}`;
            });
          }}
        />
      </Center>
    </Modal>
  );
};

export const MigrationTextContent = ({
  isModal,
  onClick,
  loading,
}: { isModal?: boolean; onClick: () => void; loading: boolean }) => (
  <>
    <Title
      ta="center"
      size={isModal ? '1.25rem' : undefined}
      mb={rem(isModal ? 30 : 50)}
    >
      Finalytic is now VRPlatform
    </Title>
    <Text ta="center" mb="lg" size="sm">
      We've rebranded from Finalytic to VRPlatform. Same great experience, just
      a fresh new name. All accounts have been seamlessly migrated.
    </Text>
    <Text ta="center" size="sm" mb={rem(50)}>
      When you visit us at Finalytic, you'll be automatically redirected to our
      new VRPlatform home. Thank you for your continued support as we evolve.
      Feel free to reach out if you have any questions.
    </Text>
    <Box
      sx={{
        '&, & > a ': {
          width: '100%',
          maxWidth: '25rem',
          display: 'block',
        },
      }}
    >
      <Button
        variant="primary"
        size="xs"
        sx={(theme) => ({ marginBottom: theme.spacing.lg })}
        component="a"
        onClick={onClick}
        loading={loading}
        href="javascript:void(0)"
      >
        Take me there
      </Button>
      <Button
        variant="transparent"
        size="xs"
        color="neutral"
        component="a"
        href="mailto:help@vrplatform.app"
      >
        Contact support
      </Button>
    </Box>
  </>
);

const Icons = () => (
  <svg
    width="128"
    height="40"
    viewBox="0 0 128 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#121826" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5484 28.0285L17.7602 21.3406L20.0229 19.1113L26.8111 25.7992L24.5484 28.0285Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7828 25.7545L15.5201 27.9838L13.2573 25.7545L15.5201 23.5252L17.7828 25.7545Z"
      fill="white"
    />
    <path
      d="M10.8799 23.7948L8.61719 21.5654L17.6682 12.6483L19.9199 10.4637L31.1764 21.5878L28.9137 23.8172L19.9309 14.8776L10.8799 23.7948Z"
      fill="white"
    />
    <path
      d="M54.9999 19.9999H72.9999M72.9999 19.9999L65.9999 13M72.9999 19.9999L65.9999 26.9999"
      stroke="#D4D4D4"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="88"
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_3183_61135)"
    />
    <g opacity="0.95">
      <path
        d="M112.876 14.8294C112.876 12.3067 114.922 10.2616 117.444 10.2616C119.967 10.2616 122.012 12.3067 122.012 14.8294C122.012 17.3522 119.967 19.3973 117.444 19.3973C114.922 19.3973 112.876 17.3522 112.876 14.8294Z"
        fill="url(#paint1_linear_3183_61135)"
      />
      <path
        d="M100.178 15.0584C100.178 12.4363 102.518 10.2714 105.14 10.2714C107.762 10.2714 108.783 11.9854 110.396 14.0527C111.075 14.924 114.929 20.282 114.929 20.282C114.929 20.282 115.758 21.723 115.758 22.905C115.758 25.8399 111.079 30.4571 111.079 30.4571L100.868 17.4305C100.868 17.4305 100.178 16.7558 100.178 15.0584Z"
        fill="url(#paint2_linear_3183_61135)"
        fillOpacity="0.8"
      />
      <path
        d="M93.9878 15.017C93.9878 12.3949 96.328 10.23 98.95 10.23C101.572 10.23 102.593 11.9441 104.206 14.0113C104.886 14.8826 108.299 19.369 110.678 22.6732C112.322 24.9557 112.445 24.9579 112.445 27.7852C112.445 30.6126 108.894 31.8502 107.81 31.9076C105.53 32.0282 103.946 30.0612 103.946 30.0612L94.6778 17.3891C94.6778 17.3891 93.9878 16.7144 93.9878 15.017Z"
        fill="url(#paint3_linear_3183_61135)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3183_61135"
        x1="88"
        y1="0"
        x2="128"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3F3F3" />
        <stop offset="1" stopColor="#E6E6E6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3183_61135"
        x1="125.585"
        y1="21.5149"
        x2="111.784"
        y2="5.38688"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3183_61135"
        x1="121.851"
        y1="35.1359"
        x2="91.1492"
        y2="7.44384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3183_61135"
        x1="119.663"
        y1="36.9388"
        x2="86.5094"
        y2="3.9585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
      </linearGradient>
    </defs>
  </svg>
);
