import {
  DefaultMantineColor,
  MantineThemeOverride,
  MantineProvider as Provider,
  createTheme,
} from '@mantine/core';
import { MantineEmotionProvider, emotionTransform } from '@mantine/emotion';
import { Notifications } from '@mantine/notifications';
import { ReactNode, useMemo } from 'react';
import { useV2UI } from '../hooks';
import { theme as defaultTheme } from './colors';

import '@mantine/notifications/styles.css';

export type CustomThemes = 'finalytic' | 'vrplatform';
export type TailwindColors = 'neutral';
export type DashboardThemes = CustomThemes | PortalTheme;
export type ExtendedCustomColors =
  | CustomThemes
  | DefaultMantineColor
  | TailwindColors
  | PortalTheme;
//
type PortalTheme =
  | 'portal.orange'
  | 'portal.teal'
  | 'portal.indigo'
  | 'portal.brown'
  | 'portal.green'
  | 'portal.blue'
  | 'portal.gray'
  | 'portalblack'
  | 'portal.red'
  | 'portal.violett'
  | 'portal.deepPurple'
  | 'portal.purple'
  | 'portal.hostingBlue';

export const useAppName = () => {
  const name = import.meta.env.VITE_PLATFORM_NAME as CustomThemes;

  const formats: Record<CustomThemes, string> = {
    finalytic: 'Finalytic',
    vrplatform: 'VRPlatform',
  };

  const appName = formats[name];

  if (!name) throw new Error('VITE_PLATFORM_NAME is not defined in .env file');
  if (!appName) throw new Error('VITE_PLATFORM_NAME is not valid');

  return {
    themeName: name,
    appName: formats[name],
  };
};

export const MantineProvider = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  const { isDarkTheme } = useV2UI();

  const theme = useMemo<MantineThemeOverride>(() => {
    return createTheme({
      ...defaultTheme,
      primaryColor: themePrimaryColor || defaultTheme.primaryColor,
    });
  }, [themePrimaryColor]);

  return (
    <Provider
      theme={theme}
      forceColorScheme={isDarkTheme ? 'dark' : 'light'}
      stylesTransform={emotionTransform}
      withGlobalClasses
    >
      <MantineEmotionProvider>
        <Notifications />
        {children}
      </MantineEmotionProvider>
    </Provider>
  );
};
