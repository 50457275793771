import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { InputSearch, InputSearchProps } from './InputSearch';

type Props = {
  placeholder?: string;
  searchInput?: string;
  setSearchInput: (input: string) => void;
  debounce?: number;
};

export const DelayedInputSearch = ({
  setSearchInput,
  placeholder,
  searchInput,
  debounce = 500,
  ...inputProps
}: Props & InputSearchProps) => {
  const [input, setInput] = useState(searchInput);

  const [delayed] = useDebouncedValue(input, debounce);

  useEffect(() => {
    if (typeof delayed === 'string') {
      setSearchInput(delayed);
    }
  }, [delayed]);

  useEffect(() => {
    setInput(searchInput);
  }, [searchInput]);

  return (
    <InputSearch
      {...(inputProps as any)}
      value={input}
      onChange={(event) => setInput(event.target.value)}
      reset={() => setInput('')}
      placeholder={placeholder}
    />
  );
};
