import { Select, SelectItem } from '@finalytic/ui';
import { ICellEditorParams } from '@finalytic/ui-grid';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ListingOwnerRow } from '../../useListingDetailData';

export const SelectVendor = forwardRef(
  (params: ICellEditorParams<ListingOwnerRow, string | undefined>, ref) => {
    const value = params?.value;

    const [newValue, setNewValue] = useState(value);
    const vendors: SelectItem[] = params.context?.vendors || [];

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (value !== newValue) {
            return newValue;
          } else {
            return value;
          }
        },
      };
    });

    const setValue = (item: SelectItem) => {
      setNewValue(item.value);
    };

    const removeValue = () => {};

    // Only for selects
    useEffect(() => {
      if (newValue !== value) {
        params.stopEditing();
        // forceCellRefresh();
      }
    }, [newValue, value]);

    const selectValue = useMemo(() => {
      return vendors.find((v) => v.value === value);
    }, [vendors, value]);

    return (
      <Select
        data={vendors} // label: source.description ; value: source.id
        value={selectValue} // label: setting.value ; value: setting.id
        removeValue={removeValue}
        withSearch
        setValue={setValue}
        popoverWidth={400}
        defaultOpen
        preventClose
        onClose={params.stopEditing}
        searchPlaceholder="Search vendors..."
      />
    );
  }
);
