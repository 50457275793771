import { Box } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

export const WhiteTagCell = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: EmotionSx;
}) => {
  return (
    <Box
      component="span"
      mr={5}
      sx={
        {
          border: '1px solid #EEEFF1',
          borderRadius: 5,
          padding: '2px 7px',
          backgroundColor: '#fff',
          fontSize: 13,
          ...sx,
        } as any
      }
    >
      {children}
    </Box>
  );
};
