import { Logo, useAppName, useColors } from '@finalytic/ui';
import { Avatar, Stack } from '@mantine/core';
import { EmotionSx as Sx } from '@mantine/emotion';
import { ReactNode } from 'react';

const iconStyling: Sx = {
  border: '6px solid #fff',
};

type ConnectingContainerProps = {
  children: ReactNode;
  showSingleIcon?: boolean;
  icon?: string | ReactNode;
  text?: ReactNode;
  iconBackgroundColor?: string;
  hideIcons?: boolean;
};

export const ConnectingContainer = ({
  children,
  text,
  icon,
  iconBackgroundColor,
  showSingleIcon = false,
  hideIcons = false,
}: ConnectingContainerProps) => {
  const { black } = useColors();
  const { appName } = useAppName();

  const iconUrl = typeof icon === 'string' ? icon : undefined;

  const iconBg: Sx | undefined = iconBackgroundColor
    ? {
        '.mantine-Avatar-placeholder': { backgroundColor: iconBackgroundColor },
      }
    : undefined;

  const dropShadow =
    'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))';

  return (
    <Stack
      pb="lg"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        position: 'relative',

        ':after': {
          position: 'absolute',
          content: '""',
          left: 0,
          right: 0,
          top: '-50px',
          height: '500px',
          zIndex: -1,

          backgroundImage: `url("data:image/svg+xml,%3Csvg width='680' height='548' viewBox='0 0 680 548' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' fill-rule='evenodd' clip-rule='evenodd' d='M340 548C527.777 548 680 395.777 680 208C680 20.2232 527.777 -132 340 -132C152.223 -132 0 20.2232 0 208C0 395.777 152.223 548 340 548ZM678 208C678 394.672 526.672 546 340 546C153.328 546 2 394.672 2 208C2 21.3278 153.328 -130 340 -130C526.672 -130 678 21.3278 678 208ZM562 208C562 330.607 462.607 430 340 430C217.393 430 118 330.607 118 208C118 85.3928 217.393 -14 340 -14C462.607 -14 562 85.3928 562 208ZM340 428C461.503 428 560 329.503 560 208C560 86.4973 461.503 -12 340 -12C218.497 -12 120 86.4973 120 208C120 329.503 218.497 428 340 428ZM340 345C415.663 345 477 283.663 477 208C477 132.337 415.663 71 340 71C264.337 71 203 132.337 203 208C203 283.663 264.337 345 340 345ZM475 208C475 282.558 414.558 343 340 343C265.442 343 205 282.558 205 208C205 133.442 265.442 73 340 73C414.558 73 475 133.442 475 208ZM340 284C381.974 284 416 249.974 416 208C416 166.026 381.974 132 340 132C298.026 132 264 166.026 264 208C264 249.974 298.026 284 340 284ZM414 208C414 248.869 380.869 282 340 282C299.131 282 266 248.869 266 208C266 167.131 299.131 134 340 134C380.869 134 414 167.131 414 208ZM340 254C365.405 254 386 233.405 386 208C386 182.595 365.405 162 340 162C314.595 162 294 182.595 294 208C294 233.405 314.595 254 340 254ZM384 208C384 232.301 364.301 252 340 252C315.699 252 296 232.301 296 208C296 183.699 315.699 164 340 164C364.301 164 384 183.699 384 208Z' fill='url(%23paint0_linear_692_27856)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_692_27856' x1='340' y1='29.5' x2='329' y2='328' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237A8893'/%3E%3Cstop offset='1' stop-color='%2370808B' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      }}
    >
      {/* Logos */}
      {!hideIcons && (
        <Avatar.Group
          spacing={30}
          mb={10}
          sx={{
            borderRadius: 100,
            filter: dropShadow,
            msFilter: dropShadow,
            MozFilter: dropShadow,
            WebkitFilter: dropShadow,
          }}
        >
          {!showSingleIcon && (
            <Avatar
              size={100}
              radius={100}
              sx={
                {
                  ...iconStyling,
                  '.mantine-Avatar-placeholder': {
                    backgroundColor:
                      appName === 'Finalytic' ? black.base : 'white',
                  },
                } as any
              }
            >
              <Logo width={100} color="#fff" />
            </Avatar>
          )}
          <Avatar
            src={iconUrl}
            size={100}
            radius={100}
            // ml={-10}
            sx={{
              ...iconStyling,
              ...iconBg,
            }}
          >
            {typeof icon !== 'string' && icon}
          </Avatar>
        </Avatar.Group>
      )}

      {text && (
        <Stack gap={2} sx={{ textAlign: 'center' }}>
          {text}
        </Stack>
      )}

      {children}
    </Stack>
  );
};
