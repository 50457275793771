import { DatePicker as MantineCalendar } from '@mantine/dates';
import { CalendarFilterDateType } from './';
import { defaultStyles } from './_styles';

type RangeCalendarProps = {
  onChange: (value: CalendarFilterDateType | undefined) => void;
  values: CalendarFilterDateType;
};

export const RangeCalendar = ({ values, onChange }: RangeCalendarProps) => {
  return (
    <MantineCalendar
      value={values || [null, null]}
      type="range"
      numberOfColumns={2}
      // initialMonth={day().subtract(1, 'month').toDate()} // this puts the current month on the right hand side
      onChange={onChange}
      color="dark"
      styles={defaultStyles}
      // paginateBy={1}
    />
  );
};
