import { SelectItem, SingleSelect } from '@finalytic/ui';
import { ICellEditorParams } from '@finalytic/ui-grid';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AccountMappingTableContext } from '../_types';

export const SelectAccountBucketEditorCell = forwardRef(
  (params: ICellEditorParams, ref) => {
    const { buckets }: AccountMappingTableContext = params.context;
    const value: SelectItem | undefined = buckets.find(
      (i) => i.value === params?.value
    );

    const [newValue, setNewValue] = useState(value);

    const setValue = (newValue: SelectItem) => {
      // Deselect
      if (newValue?.value === value?.value) {
        setNewValue(undefined);
      } else {
        setNewValue(newValue);
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return newValue?.value;
        },
      };
    });

    useEffect(() => {
      if (newValue?.value !== value?.value) {
        params.stopEditing();
      }
    }, [newValue]);

    return (
      <SingleSelect
        data={buckets} // label: source.description ; value: source.id
        value={value} // label: setting.value ; value: setting.id
        withSearch
        setValue={setValue}
        popoverWidth="target"
        onClose={() => params.api.stopEditing()}
        preventClose
        defaultOpen
      />
    );
  }
);
