import { Maybe } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Group,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ComponentProps, memo } from 'react';

type Props = {
  name: Maybe<string>;
  address: Maybe<string>;
  connections: ComponentProps<typeof ConnectionIcons>['connections'];
  variant?: 'default' | 'compact';
};

export const ListingNameCell = memo(function Cell({
  address,
  connections,
  name,
  variant = 'default',
}: Props) {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Group wrap="nowrap">
      {((variant === 'compact' && !!connections?.length) ||
        variant === 'default') && (
        <Box miw={variant === 'compact' ? 25 : 50}>
          <ConnectionIcons connections={connections} />
        </Box>
      )}
      <Box>
        <Text lineClamp={1} size="sm" sx={{ display: 'block' }}>
          {name}
        </Text>
        {address && (
          <Text
            c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
            size="xs"
            lineClamp={1}
            sx={{ display: 'block' }}
          >
            {address}
          </Text>
        )}
      </Box>
    </Group>
  );
});

type ConnectionIconsProps = {
  connections: Maybe<
    { id: string; iconRound: Maybe<string>; name: Maybe<string> }[]
  >;
};

export const ConnectionIcons = ({
  connections: value,
}: ConnectionIconsProps) => {
  const max = 2;
  const connections = value || [];
  const isTooMuch = connections.length > max;
  const slicedConnections = connections.slice(0, max);
  const restConnections = connections.slice(max);
  return (
    <Avatar.Group sx={{ background: 'transparent' }}>
      {slicedConnections.map((i) => (
        <Tooltip
          label={i?.name}
          key={i?.id}
          withinPortal
          withArrow
          position="top"
        >
          <Avatar
            src={i?.iconRound}
            size="sm"
            sx={{
              background: 'transparent',
              border: 'transparent',
            }}
          />
        </Tooltip>
      ))}
      {isTooMuch && (
        <Tooltip
          withArrow
          withinPortal
          radius="sm"
          label={
            <>
              {restConnections.map((i) => (
                <Group gap="xs" key={i.id} wrap="nowrap" px="xs">
                  <Avatar
                    src={i?.iconRound}
                    size={'xs'}
                    sx={{
                      background: 'transparent',
                      border: 'transparent',
                    }}
                  />
                  <Text>{i.name}</Text>
                </Group>
              ))}
            </>
          }
        >
          <Avatar radius="xl" size="sm">
            +{connections.length - max}
          </Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  );
};
