import { ensure, formatCurrency, groupBy, sum } from '@finalytic/utils';
import { executeStatementExpression } from '../../expressions';
import { formatPercentage } from '../../utils';
import {
  NetRevenueColumn,
  NetRevenueColumnType,
  NetRevenueRow,
  NetRevenueTotals,
  StatementLine,
  StatementSummaryUnion,
} from '../_types';

export const getNetRevenueTotalRow = ({
  columns,
  rows,
  lines: netRevenueLines,
}: {
  groupedBy: StatementSummaryUnion;
  rows: NetRevenueRow[];
  columns: NetRevenueColumn[];
  lines: StatementLine[];
}): NetRevenueTotals => {
  // return exactly the same object from rows[number] but with totals
  const totals: NetRevenueRow = {
    id: 'total',
    group: undefined,
    name: 'Total:',
    statementId: undefined,
    columnValues: {},
    reservation: undefined,
  };

  if (rows.length < 1) return null;

  const currency = netRevenueLines[0]?.currency || 'USD';

  // Run through all rows and set totals
  Object.keys(rows[0].columnValues).forEach((key: string) => {
    const column = columns.find((i) => i.id === key);

    if (!column) return;

    if (
      // total columns
      ensure<NetRevenueColumnType[]>([
        'sumAccounts',
        'sumColumns',
        'subtractColumns',
      ]).includes(column.type)
    ) {
      const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: formatCurrency(value, currency),
        value,
      };
    } else if (column.type === 'metadata') {
      const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: value,
        value,
      };
    } else if (column.type === 'field') {
      // total nights/total
      if (['reservation.nights', 'total'].includes(column.value)) {
        const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));
        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: formatCurrency(value, currency),
          value,
        };
      } else if (column.value === 'reservation.checkIn') {
        const byReservation = groupBy(
          netRevenueLines,
          (x) => x.reservation?.id || 'missing'
        );

        const nights = Object.entries(byReservation).map(([key, values]) => {
          const reservation = values?.[0]?.reservation;

          const isRow = rows.find((row) => row.id === key);
          if (!reservation || !isRow) return 0;

          return reservation?.nights || 0;
        });

        const value = sum(nights);

        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: `${value} night${value === 1 ? '' : 's'}`,
          value,
        };
      } else {
        const value = ' ';
        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: value,
          value,
        };
      }
    } else if (
      ensure<NetRevenueColumnType[]>([
        'formula',
        'formula.percentage',
        'formula.number',
        'formula.currency',
      ]).includes(column.type)
    ) {
      let value: number | string | undefined;

      try {
        if (!Array.isArray(column.value)) {
          const executed = executeStatementExpression(column.value, {
            columns,
            statementLines: netRevenueLines,
          });

          if (executed === Infinity) {
            value = '-';
          } else {
            value = executed || 0;
          }
        }
      } catch (error: any) {
        console.log(error);
      }

      const getFormattedValue = (value: number) => {
        // TODO: Remove percentage fix
        const hasPercentageFix =
          typeof column.value === 'string' &&
          column.value.split(' ').join('').includes('*100');

        const formattedValue: Record<string, string> = {
          formula: formatCurrency(value, currency),
          'formula.currency': formatCurrency(value, currency),
          'formula.percentage': formatPercentage(
            hasPercentageFix ? value / 100 : value
          ),
          'formula.number': value.toString(),
        };

        return formattedValue[column.type];
      };

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue:
          typeof value === 'number' ? getFormattedValue(value) : value || ' ',
        value: typeof value !== 'undefined' ? value : ' ',
      };
    } else {
      const value = ' ';
      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: value,
        value,
      };
    }
  });

  return totals;
};
