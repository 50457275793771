import { Maybe, sortBy, toTitleCase } from '@finalytic/utils';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Divider, Group, Stack, Text } from '@mantine/core';
import { useMergedRef, useScrollIntoView } from '@mantine/hooks';
import {
  ElementRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLayoutEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { DelayedInputSearch, LoadingIndicator, SelectButton } from '../..';
import { useColors } from '../../../styles';
import { createUUID } from '../../../utils';
import { SelectItem } from '../_types';
import {
  PopoverContentBaseProps,
  PopoverContentProps,
} from './_popover-content-types';

export function normalize(text: string | undefined) {
  return (text || '')
    .normalize('NFD')
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '');
}
export const PopoverContent = ({
  withSearch,
  setValue,
  activeValue,
  data,
  onAddCustomValue,
  onSearchInput,
  searchPlaceholder,
  addOptionText,
  noOptionsText = 'No options available...',
  popoverHeight = 230,
  sort = 'asc',
  loading,
}: PopoverContentProps & PopoverContentBaseProps) => {
  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRefs = useRef<Record<string, HTMLButtonElement>>({});

  // State
  const [filterInput, setFilterInput] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    undefined
  );

  const { sorted, hasGroupedValues, ungroupedKey } = useMemo(() => {
    const hasGroupedValues = data.some((v) => !!v.group);
    const ungroupedKey = 'ungrouped';

    if (sort) {
      const s = sortBy(data, (x) =>
        hasGroupedValues
          ? `${data.findIndex((i) => i.group === x.group) || data.length}.${
              x.label
            }`
          : x.label
      );
      const sortedByDirection = sort === 'asc' ? s : s.reverse();

      return {
        sorted: sortBy(sortedByDirection, (x) => (x?.pinned ? 0 : 1)),
        hasGroupedValues,
        ungroupedKey,
      };
    } else {
      return {
        sorted: sortBy(data, (x) => (x?.pinned ? 0 : 1)),
        hasGroupedValues,
        ungroupedKey,
      };
    }
  }, [data, sort]);

  const { filteredValues, hasPinnedValues } = useMemo(() => {
    const normalizedSearchInput = normalize(filterInput);
    const isInSearch = (value: SelectItem): boolean =>
      normalize(value.label).includes(normalizedSearchInput) ||
      normalize(value.value).includes(normalizedSearchInput);

    const filteredValues = filterInput
      ? sorted.filter((i) => isInSearch(i))
      : sorted;

    const hasPinnedValues = filteredValues.some((i) => !!i?.pinned);

    return {
      filteredValues,
      hasPinnedValues,
    };
  }, [filterInput, sorted]);

  // Scroll & Keyboard Logic
  const { targetRef, scrollableRef } = useScrollIntoView<HTMLButtonElement>({
    duration: 0,
    offset: 40,
    cancelable: false,
    isList: true,
  });

  const [windowWidth] = useWindowResize();

  const listRef =
    useRef<ElementRef<typeof VariableSizeList<ListItemProps>>>(null);
  const sizeMap = useRef<{ [t: number]: number }>({});
  const mergedRef = useMergedRef(listRef, scrollableRef);

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index: number) => {
    return sizeMap.current[index] || 33;
  };

  const addCustomValue = (input: string | undefined) => {
    if (onAddCustomValue) {
      onAddCustomValue({
        label: input || '',
        value: createUUID(),
      });
    }
  };

  const scrollSelectedItemIntoView = (_scrollDown: boolean) => {
    window.setTimeout(() => {
      targetRef.current =
        itemsRefs.current[
          filteredValues[
            highlightedIndex === undefined ? 0 : highlightedIndex
          ]?.value
        ];
      console.log(targetRef);
      targetRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // if (e.target != containerRef.current) return;
      switch (e.code) {
        case 'Enter':
        case 'Space':
          if (highlightedIndex !== undefined) {
            if (highlightedIndex === -1) {
              addCustomValue(filterInput);
            } else {
              setValue(filteredValues[highlightedIndex]);
            }
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown': {
          if (highlightedIndex === undefined) {
            setHighlightedIndex(onAddCustomValue ? -1 : 0);
            break;
          }
          const isDown = e.code === 'ArrowDown';
          const newValue = (highlightedIndex || 0) + (isDown ? 1 : -1);
          if (
            newValue >= (onAddCustomValue ? -1 : 0) &&
            newValue < filteredValues.length
          ) {
            setHighlightedIndex(newValue);
          }
          scrollSelectedItemIntoView(isDown);
          break;
        }
      }
    };
    containerRef.current?.addEventListener('keydown', handler);

    return () => {
      containerRef.current?.removeEventListener('keydown', handler);
    };
  }, [highlightedIndex, filteredValues]);

  // Booleans
  const showAddButton = useMemo<boolean>(
    () => !!onAddCustomValue,
    [onAddCustomValue]
  );

  const onTextChange = (value: string) => {
    if (highlightedIndex !== undefined) {
      setHighlightedIndex(undefined);
    }
    setFilterInput(value);
  };

  // Side effects
  useEffect(() => {
    if (onSearchInput) onSearchInput(filterInput);
  }, [onSearchInput, filterInput]);

  const itemData: ListItemProps = {
    activeValue,
    hasGroupedValues,
    highlightedIndex,
    itemsRefs,
    setValue,
    ungroupedKey,
    values: filteredValues,
    withSearch: !!withSearch,
  };

  return (
    <Stack
      gap={5}
      ref={containerRef}
      sx={{
        height: '100%',
      }}
    >
      {withSearch && (
        <Box>
          <DelayedInputSearch
            mb={!hasPinnedValues && !showAddButton ? 12 : 3}
            searchInput={filterInput}
            setSearchInput={onTextChange}
            reset={() => setFilterInput('')}
            placeholder={searchPlaceholder}
            autoFocus
          />
          {!hasPinnedValues && !showAddButton && <Divider />}
        </Box>
      )}

      {loading ? (
        <Center sx={{ minHeight: 60 }}>
          <LoadingIndicator size="sm" />
        </Center>
      ) : (
        <div>
          {showAddButton && (
            <>
              <SelectButton
                onClick={() => addCustomValue(filterInput)}
                label={
                  addOptionText
                    ? addOptionText(filterInput)
                    : `Add "${filterInput}"`
                }
                leftSection={
                  <FontAwesomeIcon icon={faPlus} color={'#5C6178'} />
                }
                isFocused={-1 === highlightedIndex}
                wrapText
              />
              <Divider />
            </>
          )}
          <div
            style={{
              display: 'block',
              flex: '1 1 0',
              minHeight:
                filteredValues.length * 33 < popoverHeight
                  ? filteredValues.length * 33
                  : popoverHeight,
            }}
          >
            <AutoSizer disableWidth>
              {({ height }) => {
                return (
                  <>
                    {filteredValues.length > 0 ? (
                      <VariableSizeList
                        ref={mergedRef}
                        height={height}
                        width={'100%'}
                        itemCount={filteredValues.length}
                        itemData={itemData}
                        itemSize={getSize}
                      >
                        {/* {ListItem} */}
                        {({ data, index, style }) => (
                          <div style={style}>
                            <ListItem
                              data={data}
                              index={index}
                              setSize={setSize}
                              windowWidth={windowWidth}
                              style={style}
                            />
                          </div>
                        )}
                      </VariableSizeList>
                    ) : (
                      <Text
                        my={6}
                        ta="center"
                        size={'0.75rem'}
                        c="gray"
                        fw={500}
                      >
                        {noOptionsText}
                      </Text>
                    )}
                  </>
                );
              }}
            </AutoSizer>
          </div>
        </div>
      )}
    </Stack>
  );
};

type ListItemProps = {
  hasGroupedValues: boolean;
  values: SelectItem[];
  ungroupedKey: string;
  withSearch: boolean;
  activeValue: Maybe<SelectItem>;
  setValue: (value: SelectItem) => void;
  highlightedIndex: number | undefined;
  itemsRefs: MutableRefObject<Record<string, HTMLButtonElement>>;
};

const ListItem = ({
  data,
  index,
  // style,
  windowWidth,
  setSize,
}: ListChildComponentProps<ListItemProps> & {
  setSize: any;
  windowWidth: number;
}) => {
  const {
    hasGroupedValues,
    values: arr,
    ungroupedKey,
    withSearch,
    setValue,
    activeValue,
    highlightedIndex,
    itemsRefs,
  } = data;
  const item = arr[index];

  const { gray } = useColors();

  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize(index, rowRef.current?.getBoundingClientRect?.().height);
  }, [setSize, index, windowWidth]);

  const isPinned = item?.pinned && !arr[index + 1]?.pinned;

  return (
    <div ref={rowRef}>
      {hasGroupedValues && item.group !== arr[index - 1]?.group && (
        <Group
          w="100%"
          gap="xs"
          wrap="nowrap"
          justify="space-between"
          sx={{ position: 'sticky', top: 0 }}
        >
          <Text size="xs" color={gray.dark + 80} fw={500}>
            {item.group || toTitleCase(ungroupedKey)}
          </Text>
          <Divider w="100%" mt={2} />
        </Group>
      )}
      <SelectButton
        key={item.value}
        autoFocus={!withSearch && index === 0}
        onClick={() => setValue(item)}
        label={item.label}
        isActive={activeValue?.value === item.value}
        // onMouseEnter={() => setHighlightedIndex(index)}
        isFocused={index === highlightedIndex}
        ref={(node: HTMLButtonElement) => {
          if (itemsRefs?.current) {
            itemsRefs.current[item.value] = node;
          }
        }}
        wrapText
      />
      {isPinned && <Divider mt="xs" />}
    </div>
  );
};

const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
