import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(
  (_theme, { v2Icon }: { v2Icon: boolean }) => ({
    navLink: {
      '> div': {
        height: v2Icon ? 35 : undefined,
      },
      '& path, & rect': {
        fill: v2Icon ? undefined : '#ffffff80 !important',
        stroke: v2Icon ? '#dadada' : undefined,
      },
      ':hover > div, &.active > div': {
        backgroundColor: '#00000060',
        borderRadius: v2Icon ? _theme.radius.md : 10,
        cursor: 'pointer',

        span: {
          opacity: 1,
        },

        '& path, & rect': {
          fill: v2Icon ? undefined : '#f3f3f3 !important',
          stroke: v2Icon ? 'white' : undefined,
        },
      },
    },
    subNavLink: {
      '& div:hover, &.active > div': {
        backgroundColor: '#00000060',

        span: {
          opacity: 1,
        },
      },
    },
  })
);
