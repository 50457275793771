import {
  formatCurrency,
  groupBy,
  hasValue,
  sum,
  toTitleCase,
  utc,
} from '@finalytic/utils';
import { executeStatementExpression } from '../../expressions';
import { formatPercentage } from '../../utils';
import { groupStatementLinesBy } from '../_helpers';
import {
  NetRevenueColumn,
  NetRevenueRow,
  StatementLine,
  StatementSummaryUnion,
} from '../_types';

export const getNetRevenueRows = ({
  groupedBy,
  columns,
  lines,
}: {
  groupedBy: StatementSummaryUnion;
  columns: NetRevenueColumn[];
  lines: StatementLine[];
}): NetRevenueRow[] => {
  const grouped = groupStatementLinesBy({
    groupedBy,
    lines,
    groupedListingAndMonth: true,
  });

  return formatNetRevRows({
    grouped,
    columns,
    groupedBy,
  });
};

const formatNetRevRows = ({
  columns: netRevColumns,
  grouped,
  groupedBy,
}: {
  columns: NetRevenueColumn[];
  grouped: { [key: string]: StatementLine[] };
  groupedBy: StatementSummaryUnion;
}): NetRevenueRow[] => {
  // Return formatted rows with colIds and values
  return Object.entries(grouped)
    .map(([key, lines]) => {
      const currency = lines[0]?.currency || 'USD';

      const listingName =
        lines[0]?.reservation?.listing?.name || 'Missing Listing';

      const row: NetRevenueRow = {
        id: key,
        name: undefined,
        statementId: lines[0].statementId,
        group: undefined,
        columnValues: {},
        reservation:
          groupedBy === 'groupByReservation' ? lines[0].reservation : undefined,
      };

      // Set row name and group
      if (groupedBy === 'groupByListing') {
        const month = key.split('.')[1]
          ? utc(key.split('.')[1]).format('MMM YYYY')
          : '';

        row.name = `${listingName} (${month || 'Missing Month'})`;
        row.group = {
          id: key.split('.')[0],
          name: listingName,
        };
      } else if (groupedBy === 'groupByMonth') {
        row.name = utc(key).format('MMM YYYY');
      } else if (groupedBy === 'groupByBookingChannel') {
        row.name = toTitleCase(key);
      } else if (groupedBy === 'groupByReservation') {
        const reservationDate = lines[0]?.reservation?.checkIn;
        row.name = reservationDate || 'Missing reservation';
      }

      // Map through template
      netRevColumns.forEach((col) => {
        const columnType = col.type;

        switch (columnType) {
          case 'sumAccounts': {
            const l = lines.filter((i) =>
              (col.value as string[]).includes(i.group?.remoteId || '')
            );

            const amount = (sum(l, 'centTotal') || 0) / 100;

            row.columnValues[col.id] = {
              columnId: col.id,
              columnType,
              value: amount,
              formattedValue: formatCurrency(amount, currency),
            };
            break;
          }
          case 'sumColumns': {
            const columnAccounts = netRevColumns
              .filter((i) => (col.value as string[]).includes(i.id))
              .flatMap((i) => i.value);

            const l = lines.filter((i) =>
              columnAccounts.includes(i.group?.remoteId || '')
            );

            const total = (sum(l, 'centTotal') || 0) / 100;

            row.columnValues[col.id] = {
              columnId: col.id,
              columnType,
              value: total,
              formattedValue: formatCurrency(total, currency),
            };

            break;
          }
          case 'subtractColumns': {
            const colAmounts = netRevColumns
              .filter((i) => (col.value as string[]).includes(i.id))
              .map(
                (col) =>
                  sum(
                    lines.filter((i) =>
                      (col.value as string[]).includes(i.group?.remoteId || '')
                    ),
                    'centTotal'
                  ) || 0
              );

            const total = colAmounts.reduce((prev, curr, index) => {
              if (index < 1) return curr;

              return prev - curr;
            }, 0);

            row.columnValues[col.id] = {
              columnId: col.id,
              columnType,
              value: total,
              formattedValue: formatCurrency(total, currency),
            };
            break;
          }
          case 'metadata': {
            const g = groupBy(
              lines,
              (x) => x.reservation?.id || 'missing_reservation'
            );

            const value = sum(
              Object.values(g).map((val) => {
                return (
                  val?.filter(
                    (line) => line?.metadata?.[col.value as string]
                  )[0]?.metadata![col.value] || 0
                );
              })
            );

            row.columnValues[col.id] = {
              columnId: col.id,
              columnType,
              value,
              formattedValue: value,
            };
            break;
          }
          case 'field': {
            if (groupedBy !== 'groupByReservation') break;

            // Field Options
            // { value: 'reservation.guestName', label: 'Guest name' },
            // { value: 'reservation.confirmationCode', label: 'Confirmation code' },
            // { value: 'reservation.checkIn', label: 'Reservation Dates' },
            // { value: 'reservation.bookingChannel', label: 'Booking Channel' },
            // { value: 'total', label: 'Total' },

            if (col.value === 'reservation.checkIn') {
              const formatDate = (v: string, format = 'DD. MMM. YYYY') =>
                v ? utc(v).format(format) : '';

              const checkOut = lines[0].reservation?.checkOut;
              const checkIn = lines[0].reservation?.checkIn;
              const nights = lines[0].reservation?.nights || 0;

              const value = checkIn || '';

              let formattedValue = ' ';

              if (checkOut || checkIn || nights) {
                formattedValue = `${formatDate(checkIn || '', 'DD. MMM')}${
                  checkOut ? ` - ${formatDate(checkOut)}` : ''
                }\n${nights} nights`;
              }

              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue,
              };
            } else if (col.value === 'reservation.guestName') {
              const guestName = lines[0].reservation?.guestName || '';
              const value = toTitleCase(guestName) || '';
              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: value,
              };
            } else if (col.value === 'reservation.bookingChannel') {
              const bookingChannel = lines[0].reservation?.bookingChannel || '';
              const value = toTitleCase(bookingChannel) || '';
              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: value,
              };
            } else if (col.value === 'reservation.bookingChannelIcon') {
              const value = lines[0]?.reservation?.connectionIcon || '';

              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: value,
              };
            } else if (col.value === 'reservation.confirmationCode') {
              const confirmationCode =
                lines[0].reservation?.confirmationCode || '';
              const pmsReferenceCode = lines[0]?.reservation?.pmsReferenceCode;

              let value = '';

              if (confirmationCode || pmsReferenceCode) {
                if (pmsReferenceCode === confirmationCode) {
                  value = confirmationCode;
                } else {
                  value = [
                    confirmationCode,
                    pmsReferenceCode && `PMS: ${pmsReferenceCode}`,
                  ]
                    .filter(Boolean)
                    .join('\n');
                }
              }

              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: value,
              };
            } else if (col.value === 'total') {
              const value = (sum(lines, 'centTotal') || 0) / 100;

              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: formatCurrency(value, currency),
              };
            } else if (col.value === 'reservation.status') {
              const value = lines[0]?.reservation?.status || '';

              row.columnValues[col.id] = {
                columnId: col.id,
                columnType,
                value,
                formattedValue: toTitleCase(value),
              };
            }
            break;
          }

          case 'formula':
          case 'formula.percentage':
          case 'formula.currency':
          case 'formula.number': {
            let value: string | number = '';

            try {
              const executed = executeStatementExpression(col.value, {
                columns: netRevColumns,
                statementLines: lines,
              });

              if (executed === Infinity || Number.isNaN(executed)) {
                value = 'Failed to parse.';
              } else {
                value = executed;
              }
            } catch (error: any) {
              console.log(error);
              value = error?.message || 'Failed to parse formula.';
            }

            // TODO: Remove percentage fix
            const hasPercentageFix =
              typeof value === 'number' &&
              col.value.split(' ').join('').includes('*100');

            const formattedValue: Record<string, string> = {
              formula: formatCurrency(value, currency),
              'formula.currency': formatCurrency(value, currency),
              'formula.percentage': formatPercentage(
                hasPercentageFix ? (value as number) / 100 : value
              ),
              'formula.number': value.toString(),
            };

            row.columnValues[col.id] = {
              columnId: col.id,
              columnType,
              value,
              formattedValue: formattedValue[columnType],
            };
            break;
          }

          default:
            break;
        }
      });

      // If every column with amount === 0 => then hide this row
      const ignore = Object.values(row.columnValues)
        .filter(({ value }) => typeof value === 'number')
        .every(({ value }) => value === 0);
      if (ignore) return undefined;

      return row;
    })
    .filter(hasValue);
};
