import { create } from 'zustand';

type State = {
  unreadCount: number;
};

type Actions = {
  setUnreadCount: (count: number) => void;
};

const initialState: State = {
  unreadCount: 1,
};

export const useIntercomStore = create<State & Actions>()((set, _get) => ({
  ...initialState,
  setUnreadCount: (count) => set({ unreadCount: count }),
}));
