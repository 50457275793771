import { listing_bool_exp } from '@finalytic/graphql';

export function whereListingIsExcluded(
  automationId: string | string[]
): listing_bool_exp {
  return {
    _not: {
      _or: [
        {
          status: {
            // TODO: VRP-4868 remove enabled/disabled
            _in: ['inactive', 'disabled'],
            _is_null: false,
          },
        },
        {
          // TODO: VRP-4868 remove enabled/disabled
          pmsStatus: { _in: ['disabled', 'inactive'] },
          status: { _is_null: true },
        },
        {
          settingsRight: {
            key: {
              _eq: 'exclude',
            },
            automationId: Array.isArray(automationId)
              ? { _in: automationId }
              : {
                  _eq: automationId,
                },
            value: {
              _eq: 'true',
            },
          },
        },
      ],
    },
  };
}
