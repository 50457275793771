import {
  GqlClientV2,
  Query,
  listing,
  owner_statement_bool_exp,
  owner_statement_line_bool_exp,
  reservation,
} from '@finalytic/graphql';
import { Maybe, sortBy } from '@finalytic/utils';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';
import { whereListingIsExcludedSetting } from '../queries';
import {
  getAccountDescription,
  getListingAddress,
  getListingStatus,
  getOwnerAddress,
  getTenantAddress,
  getUserAddress,
} from '../utils';
import { Statement } from './_types';

export const statementExportWorkerInput = {
  statementIds: z.array(z.string()).optional(),
  ownerId: z.string().optional(),
  vendorId: z.string().optional(),
  startAt: z.string().nullable().optional(),
  endAt: z.string().nullable().optional(),
  teamId: z.string(),
  status: z.array(z.string()).optional(),
  listingId: z.string().nullable().optional(),
  groupedBy: z.enum([
    'groupByReservation',
    'groupByMonth',
    'groupByListing',
    'groupByBookingChannel',
  ]),
  dashboard: z.enum(['propertyManager', 'owner']),
  v2Owners: z.boolean(),
};

const paymentsFilter = z.object(statementExportWorkerInput);

export type StatementInput = z.infer<typeof paymentsFilter>;

export const useStatementExportData = async (
  client: GqlClientV2,
  args: StatementInput
) => {
  const statements = await client.query((query) => {
    const where: owner_statement_bool_exp = args.statementIds
      ? { id: { _in: args.statementIds } }
      : {
          listing: args.listingId
            ? {
                id: args.listingId
                  ? {
                      _eq: args.listingId,
                    }
                  : undefined,
              }
            : undefined,
          startAt: {
            _gte: args.startAt,
            _lte: args.endAt,
          },
          owners: args.ownerId
            ? {
                ownerId: args.v2Owners ? undefined : { _eq: args.ownerId },
                newOwnerId: args.v2Owners ? { _eq: args.ownerId } : undefined,
                vendorSourceId: args.vendorId
                  ? { _eq: args.vendorId }
                  : undefined,
              }
            : {
                role: { _eq: 'owner' },
                vendorSourceId: args.vendorId
                  ? { _eq: args.vendorId }
                  : undefined,
              },
          tenantId: {
            _eq: args.teamId,
          },
          status: args.status ? { _in: args.status as any } : undefined,
        };

    return getStatementQuery(query, where, undefined, {
      v2Owners: args.v2Owners,
    });
  });

  const template = sortBy(
    statements?.map((st) => st.template),
    (x) => x?.version || 0
  ).reverse()[0];

  const statementLines = statements.flatMap((statement) => statement.lines);

  return {
    statements,
    template,
    statementLines,
  };
};

export const getStatementQuery = (
  q: Query,
  where: owner_statement_bool_exp,
  filter:
    | {
        lines?: owner_statement_line_bool_exp;
      }
    | undefined,
  args: {
    statementAutomationId?: string;
    v2Owners: boolean;
  }
): Statement[] => {
  const ownerStatements = q.ownerStatements({
    where,
    order_by: [{ startAt: 'asc' }],
  });

  return ownerStatements.map((statement) => {
    const disabledAutomation = statement.listing?.settingsRight({
      where: whereListingIsExcludedSetting({
        automationIds: args?.statementAutomationId
          ? [args.statementAutomationId]
          : [],
        listingId: undefined,
      }),
    })[0]?.id;

    const getTemplate = (): Statement['template'] => {
      const basicTemplate = {
        id: statement.template?.id,
        data: statement.template?.data(),
        // data: statement.templateJson() || statement.template?.data(),
        billingAccountId: statement.template?.billingAccountId,
        balanceStartAt: statement.template?.balanceStartAt,
        version: statement.template?.version,
      };

      return basicTemplate;
    };

    const listingStatus = getListingStatus(statement.listing);

    const whereLines = filter?.lines || {};

    const formatListing = (listing: Maybe<listing>) => ({
      id: listing?.id,
      address: getListingAddress(listing, { format: 'owner_statement' }).full,
      imageUri: listing?.imageUri,
      name: listing?.title || listing?.name,
      ownerships: undefined,
      // TODO: VRP-4868 remove enabled/disabled
      disabled:
        listing?.status === 'inactive' ||
        listing?.status === 'disabled' ||
        !!disabledAutomation,
    });

    const listingConnections = q
      .listingConnections({
        where: {
          reservations: {
            ownerStatementLines: {
              ownerStatement: where,
            },
          },
        },
      })
      .map((lc) => ({
        id: lc.id,
        listing: formatListing(lc.listing),
      }));

    const appIcons = q
      .connection({
        where: {
          listingConnections: {
            reservations: {
              ownerStatementLines: {
                ownerStatement: where,
              },
            },
          },
        },
      })
      .map((c) => ({
        id: c.id,
        iconRound: c.app?.iconRound,
      }));

    const getUniqueReservations = () => {
      const getReservation = (reservation: reservation | undefined) => ({
        id: reservation?.id,
        currency: reservation?.currency,
        checkIn: reservation?.checkIn,
        checkOut: reservation?.checkOut,
        nights: reservation?.nights,
        status: reservation?.status,
        pmsReferenceCode: reservation?.pmsReferenceCode,
        confirmationCode: reservation?.confirmationCode,
        connectionIcon: appIcons.find((a) => a.id === reservation?.connectionId)
          ?.iconRound,
        guestName: reservation?.guestName,
        guests: reservation?.guests,
        listing: listingConnections.find(
          (lc) => lc.id === reservation?.listingConnectionId
        )?.listing,
        bookingChannel: reservation?.channel?.uniqueRef,
      });

      return statement
        .lines({
          where: whereLines,
          order_by: [
            {
              reservationId: 'asc_nulls_last',
            },
          ],
          distinct_on: ['reservationId'],
        })
        .map((line) => getReservation(line.reservation));
    };

    const uniqueReservations = getUniqueReservations();

    const getLines = () => {
      return statement
        .lines({
          where: whereLines,
          order_by: [{ reservation: { checkIn: 'asc_nulls_last' } }],
        })
        .map<Statement['lines'][0]>((line) => {
          return {
            id: line.id,
            statementId: statement.id,
            centTotal: line.centTotal || 0,
            description: line.customDescription || line.description, // customDescription => are users able to overwrite journal entry description?
            currency: line.currency,
            date: line.date,
            isOwnerPayout: line.isOwnerPayout, // always gets filtered out => should UI just not query it???
            externalLink: line.externalLink, // are we going to have this?
            role: line.role || undefined, // needed for distinguishing bill => what goes into summary
            metadata: line.metadata(), // needed for possible "metadata" column in net rev table
            listing: formatListing(statement?.listing),
            group: {
              // this is the account
              id: line.group?.id,
              remoteId: line.group?.remoteId,
              type: line.group?.type,
              description: line.group?.description,
              details: getAccountDescription(line.group),
            },
            reservation: uniqueReservations.find(
              (res) => res.id === line.reservation?.id
            ),
          };
        });
    };

    const getOwners = () => {
      return statement
        .owners({
          where: {
            // role: args?.v2Owners ? undefined : { _in: ['owner', 'company'] },
            newOwnerId: args?.v2Owners ? { _is_null: false } : undefined,
          },
          order_by: args?.v2Owners
            ? [
                {
                  newOwner: {
                    name: 'asc_nulls_last',
                  },
                },
              ]
            : [
                {
                  owner: {
                    type: 'asc_nulls_last',
                  },
                },
                {
                  owner: {
                    lastName: 'asc_nulls_last',
                  },
                },
              ],
        })
        .map((ship) => {
          return {
            id: ship.id,
            split: ship.split,
            owner: args?.v2Owners
              ? {
                  id: ship.newOwnerId,
                  email: ship.newOwner?.email,
                  firstName: ship.newOwner?.firstName,
                  lastName: ship.newOwner?.name,
                  companyName: ship.newOwner?.name,
                  address: getOwnerAddress(ship.newOwner!, {
                    format: 'owner_statement',
                  }).full,
                  phone: ship.newOwner?.phone,
                }
              : {
                  id: ship.ownerId,
                  email: ship.owner?.email,
                  firstName: ship.owner?.firstName,
                  lastName: ship.owner?.lastName,
                  companyName: ship.owner?.companyName,
                  address: getUserAddress(ship.owner).full,
                  phone: ship.owner?.phone,
                },
          };
        })
        .filter((x) => x.owner.id);
    };

    return ensure<Statement>({
      id: statement.id,
      statementId: statement.id,
      periodId: statement.listingOwnershipPeriodId,
      startAt: statement.startAt,
      status: statement.status,
      centBalanceEnd: statement.centBalanceEnd || 0,
      centBalanceStart: statement.centBalanceStart || 0,
      centPayedOut: statement.centPayedOut || 0,
      centTotal: statement.centTotal || 0,
      currency: statement.currency,
      template: getTemplate(),
      tenant: {
        id: statement.tenantId,
        name: statement.tenant?.name,
        companyName: statement.tenant?.companyName,
        logo: statement.tenant?.logo,
        taxId: statement?.tenant?.companyTaxCode,
        address: getTenantAddress(statement.tenant, {
          format: 'owner_statement',
        }).full,
        phone: statement?.tenant?.supportPhone,
        email: statement.tenant?.supportEmail,
      },
      statementOwners: getOwners(),
      listing: {
        id: statement.listing?.id,
        name: statement.listing?.title || statement.listing?.name,
        address: getListingAddress(statement.listing, {
          format: 'owner_statement',
        }).full,
        imageUri: statement.listing?.imageUri,
        disabled:
          !!disabledAutomation ||
          listingStatus === 'disabled' ||
          listingStatus === 'inactive' ||
          listingStatus === 'pmsDisabled',
        ownerships:
          statement.listing
            ?.ownerships({
              where: {
                newOwnerId: args?.v2Owners ? { _is_null: false } : undefined,
              },
            })
            .map((ship) => {
              if (args?.v2Owners) {
                return {
                  id: ship.id,
                  owner: {
                    id: ship.newOwner?.id,
                    firstName: ship.newOwner?.firstName,
                    name: ship.newOwner?.name,
                    companyName: ship.newOwner?.name,
                    lastName: ship.newOwner?.name,
                    email: ship.newOwner?.email,
                    phone: ship.newOwner?.phone,
                    address: getOwnerAddress(ship.newOwner, {
                      format: 'owner_statement',
                    }).full,
                    taxId: ship.newOwner?.taxId,
                  },
                };
              }

              return {
                id: ship.id,
                owner: {
                  name: ship.owner?.name,
                  companyName: ship.owner?.companyName,
                  firstName: ship.owner?.firstName,
                  lastName: ship.owner?.lastName,
                  id: ship.owner?.id,
                  email: ship.owner?.email,
                  phone: ship.owner?.phone,
                  address: getUserAddress(ship.owner).full,
                },
              };
            }) || [],
      },

      lines: getLines(),
    });
  });
};
