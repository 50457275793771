import { MantineFontSize, Text } from '@mantine/core';
import { ReactNode } from 'react';

export const ActivityDeltaComparison = ({
  size = 'sm',
  type,
  value,
  oldValue,
}: {
  size?: MantineFontSize;
  type: string | ReactNode;
  oldValue?: string;
  value: string;
}) => (
  <Text size={size} color="gray">
    <Text component="span" color="black" inherit>
      {type}
    </Text>{' '}
    changed{' '}
    {!!oldValue && (
      <>
        from{' '}
        <Text component="span" color="black" inherit td="line-through">
          {oldValue}
        </Text>{' '}
      </>
    )}{' '}
    to{' '}
    <Text component="span" color="black" inherit>
      {value}
    </Text>
  </Text>
);
