import { LoadingIndicator } from '@finalytic/ui';
import { Box, Popover, Tooltip } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useDebouncedValue } from '@mantine/hooks';
import { FormulaField } from '@vrplatform/ui-common';
import prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { ComponentProps, useEffect, useRef, useState } from 'react';
import CodeEditor from 'react-simple-code-editor';
import { FormulaDropdown } from './FormulaDropdown';

type DropdownProps = Omit<ComponentProps<typeof FormulaDropdown>, 'baseProps'>;

type Mode = 'onBlur' | 'debounced';

type BaseProps = {
  disabled?: boolean;
  loadingValue: boolean;
  placeholder?: string;
  initialValue: string;
  onChange: (
    newValue: string | null,
    withNotification: boolean
  ) => Promise<any>;
  error: boolean | string;
  mode: Mode;
};

type InputFormulaProps = BaseProps & DropdownProps;

export const InputFormula = (props: InputFormulaProps) => {
  const editorRef = useRef<CodeEditor | null>(null);

  const [opened, setDropdownOpened] = useState(false);
  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const [selectedValue, setSelectedValue] = useState<FormulaField | null>(null);
  const onSelectValue = (value: FormulaField) => {
    setSelectedValue(value);
    setDropdownOpened(false);
  };

  const disabled =
    props.disabled || (props.mode === 'debounced' ? false : props.loadingValue);

  return (
    <Popover
      width={props.dropdownProps?.width || 'target'}
      position={props.dropdownProps?.position || 'bottom'}
      disabled={disabled}
      withinPortal={props.dropdownProps?.withinPortal}
      opened={opened}
      onClose={() => setDropdownOpened(false)}
      shadow="md"
    >
      <Popover.Target>
        <Tooltip
          label={props.error}
          disabled={typeof props.error !== 'string'}
          withArrow
          withinPortal
        >
          <Box sx={{ position: 'relative' }}>
            <Editor
              {...props}
              editorRef={editorRef}
              dropdownOpened={opened}
              setDropdownOpened={setDropdownOpened}
              search={search}
              setSearch={setSearch}
              selectedValue={selectedValue}
              resetSelectedValue={() => setSelectedValue(null)}
              disabled={disabled}
            />
            {props.loadingValue && (
              <Box sx={{ position: 'absolute', right: 10, top: 13 }}>
                <LoadingIndicator size="xs" />
              </Box>
            )}
          </Box>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          padding: 0,
        }}
      >
        <FormulaDropdown
          baseProps={{
            editorRef,
            onSelectValue,
            searchValue: debouncedSearch || '',
          }}
          {...props}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

interface EditorProps extends BaseProps {
  dropdownOpened: boolean;
  setDropdownOpened: (open: boolean) => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  editorRef: React.MutableRefObject<CodeEditor | null>;
  selectedValue: FormulaField | null;
  resetSelectedValue: () => void;
  error: boolean | string;
  mode: Mode;
}

const Editor = ({
  disabled,
  placeholder,
  setSearch,
  setDropdownOpened,
  search,
  dropdownOpened,
  editorRef,
  initialValue,
  onChange,
  selectedValue,
  resetSelectedValue,
  error,
  mode,
}: EditorProps) => {
  const [value, setValue] = useState(initialValue);

  const { classes } = useEditorStyles({ disabled: !!disabled, error: !!error });

  const [debounced] = useDebouncedValue(value, 1000);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const target = editorRef.current as any;
    const isFocusedRef = target === document.activeElement;

    if (debounced !== initialValue && isFocusedRef && mode === 'debounced') {
      console.log('isFocusedRef', isFocusedRef);
      onChange(debounced, false);
    }
  }, [debounced, mode]);

  useEffect(() => {
    const target = editorRef.current as any;
    const selectionStart = target?.selectionStart || 0;
    const selectionEnd = target?.selectionStart || 0;

    if (selectedValue) {
      const newValue = `${value.slice(0, selectionStart - search.length)}${
        selectedValue.displayValue
      }${value.slice(selectionEnd)}`;

      setValue(newValue);
      setSearch('');
      resetSelectedValue();
      setDropdownOpened(false);
    }
  }, [selectedValue]);

  return (
    <CodeEditor
      disabled={disabled}
      value={value}
      onValueChange={(code) => setValue(code)}
      highlight={(code) =>
        prism.highlight(code || '', prism.languages.sql, 'sql')
      }
      onFocus={(event) => {
        const target = event.target as EventTarget & HTMLTextAreaElement;

        if (target) {
          target.selectionStart = target.value?.length || 0;
          target.selectionEnd = target.value?.length || 0;
        }
        setDropdownOpened(true);
      }}
      padding={10}
      placeholder={placeholder}
      className={classes.input}
      onEmptied={() => setSearch('')}
      onBlur={(event) => {
        setDropdownOpened(false);
        const value: string | null = (event.target as any).value || null;
        onChange(value, true);
      }}
      onKeyDown={(
        event:
          | React.KeyboardEvent<HTMLDivElement>
          | React.KeyboardEvent<HTMLTextAreaElement>
      ) => {
        const key = event.key;
        const code = event.code;
        const target = event.target as any;
        const currentInputValue: string | undefined = target?.value;
        const cursorPosition = target?.selectionStart || 0;

        editorRef.current = target;

        // Managing search and popover open state
        if (
          key.includes('Meta') ||
          ['-', '/', '*', '+', '(', ')'].includes(key)
        ) {
          setSearch('');
          setDropdownOpened(false);
        } else if (key === 'Backspace') {
          const hasSelected = target.selectionStart !== target.selectionEnd;

          const deletedCharacter = currentInputValue?.slice(
            cursorPosition - 1,
            cursorPosition
          );

          if (deletedCharacter === `"` && !hasSelected) {
            // find character at cursor position and delete everying until the same character at the next previous position
            const previousPosition = cursorPosition - 1 || 0;

            if (
              !previousPosition ||
              previousPosition === 1 ||
              !currentInputValue
            ) {
              setValue(currentInputValue?.slice(previousPosition) || '');
            } else {
              let pos = previousPosition - 1;
              while (currentInputValue[pos] !== `"` && pos > -1) {
                pos--;
              }

              if (pos === -1) {
                const newValue =
                  currentInputValue.slice(0, previousPosition + 1) +
                  currentInputValue.slice(cursorPosition);
                setValue(newValue);
              } else {
                const newValue =
                  currentInputValue.slice(0, pos + 1) +
                  currentInputValue.slice(cursorPosition);
                setValue(newValue);
              }
            }
          }

          if (search) {
            setSearch(hasSelected ? '' : (e) => e.slice(0, -1));
          }
        } else if (/^[a-zA-Z0-9\s]{1}$/.test(key)) {
          setSearch((e) => {
            const newValue = `${e}${key}`;
            return newValue.trim() ? newValue : '';
          });
          if (!dropdownOpened) {
            setDropdownOpened(true);
          }
        }

        // Managing popover current focus
        switch (code) {
          case 'Enter':
            event.preventDefault();
            break;
        }
      }}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
      }}
    />
  );
};

const useEditorStyles = createStyles(
  (theme, args: { disabled: boolean; error: boolean }) => {
    const errorColor = theme.colors.red[6];
    const themeColor = theme.colors[theme.primaryColor][4];

    return {
      input: {
        // lineHeight: '2 !important',
        overflow: 'visible!important',
        border: '1px solid',
        borderColor: args.error ? errorColor : theme.colors.gray[4],
        borderRadius: theme.radius.md,
        backgroundColor: args.disabled
          ? `${theme.colors.neutral[1]} !important`
          : theme.white,
        pre: {
          paddingBlock: '8px!important',
          'span.string': {
            backgroundColor: theme.colors.gray[1],
            borderRadius: theme.radius.sm,
            color: theme.colors.gray[8],
          },
        },
        textarea: {
          wordBreak: 'normal',
          lineBreak: 'normal',
          '&:focus': {
            outline: 'none',
            border: '1px solid !important',
            borderColor: args.error
              ? `${errorColor}!important`
              : `${themeColor}!important`,
            borderRadius: theme.radius.sm,
            boxShadow: `0px 0px 0px 2px ${
              (args.error ? errorColor : themeColor) + 40
            }`,
          },
        },
      },
    };
  }
);
