import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group } from '@mantine/core';
import { Button as MantineButton } from '@mantine/core';
import { RefObject, forwardRef } from 'react';
import { useColors } from '../../../styles';
import { LoadingIndicator } from '../../loading-indicator';
import { BaseButtonProps } from './_button-types';

type Props = { label: string } & BaseButtonProps;

export const SingleSelectButton = forwardRef(
  (
    {
      onClick,
      label,
      opened,
      withBorder,
      placeholder,
      disabled,
      loading,
    }: Props,
    ref
  ) => {
    const { gray } = useColors();

    const focusedStyles = {
      border: '1px solid #BEC0C9!important',
      boxShadow: '0px 0px 0px 1px #BEC0C9!important',
    };

    const defaultRootStyles = {
      marginTop: '3px',
      height: '2.25rem',
      borderWidth: '1px',
      borderColor: withBorder ? '#CED0D780!important' : 'transparent!important',
      borderRadius: 8,
      width: '100%',
      cursor: disabled ? 'default' : 'pointer',
      backgroundColor: disabled ? gray.light + 30 : undefined,
      ...(opened && withBorder && focusedStyles),
    };

    if (loading) {
      return (
        <Group
          justify="space-between"
          px={10}
          sx={{
            ...defaultRootStyles,
            fontSize: 13,
            lineHeight: 'normal',
            fontWeight: 500,
          }}
        >
          <LoadingIndicator size={'1.125rem'} pl={3} />
          <FontAwesomeIcon
            icon={faCaretDown}
            color="#CED0D7"
            width={40}
            style={{ flexShrink: 0 }}
          />
        </Group>
      );
    }

    return (
      <MantineButton
        ref={ref as RefObject<HTMLButtonElement>}
        variant="white"
        type="button"
        onClick={onClick}
        px={10}
        sx={{
          ...defaultRootStyles,
          '.mantine-Button-label': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            fontWeight: 500,
            color: '#5C6178',
            fontSize: 13,
            lineHeight: 'normal',
          },
          '&, *': {
            backgroundColor: disabled ? undefined : 'transparent',
            transform: 'none !important',
          },
        }}
        styles={{
          root: {
            display: 'block',
          },
        }}
      >
        <Box
          component="span"
          sx={{
            maxWidth: '95%',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: loading ? 'visible' : 'hidden',
            color: !label ? '#BEC0C9' : undefined,
          }}
        >
          {label || placeholder}
        </Box>

        <FontAwesomeIcon
          icon={faCaretDown}
          color="#CED0D7"
          width={40}
          style={{ flexShrink: 0 }}
        />
      </MantineButton>
    );
  }
);

SingleSelectButton.displayName = 'SingleSelectButton';
