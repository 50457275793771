import {
  gqlV2,
  useMutation,
  useQuery,
  useSubscription,
  useTeamId,
} from '@finalytic/data';
import { showErrorNotification } from '@finalytic/ui';
import { ScrollArea } from '@mantine/core';
import { getSourceDescription, whereSources } from '@vrplatform/ui-common';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { StatementTemplateType } from '..';

export function useStatementSettingsMutations(_canUpdate: boolean) {
  const [teamId] = useTeamId();

  // TODO: move insert and update together
  const { mutate: insertOwnerStatementTemplate, loading: insertLoading } =
    useMutation(
      (q, args: gqlV2.owner_statement_template_insert_input) => {
        const res = q.insertOwnerStatementTemplate({ object: args });

        return {
          id: res?.id,
          billingAccountId: res?.billingAccountId,
          balanceStartAt: res?.balanceStartAt,
          data: res?.data(),
        };
      },
      {
        successMessage: {
          title: 'Statement Settings',
          message: 'Your statements settings were succesfully saved.',
        },
      }
    );
  const { mutate: updateOwnerStatementTemplate, loading: updateLoading } =
    useMutation(
      (
        q,
        {
          id,
          input,
        }: { id: string; input: gqlV2.owner_statement_template_insert_input }
      ) => {
        const res = q.updateOwnerStatementTemplate({
          pk_columns: { id },
          _set: input,
          _inc: { version: 1 },
        });

        return {
          id: res?.id,
          billingAccountId: res?.billingAccountId,
          balanceStartAt: res?.balanceStartAt,
          data: res?.data(),
        };
      },
      {
        successMessage: {
          title: 'Statement Settings',
          message: 'Your statements settings were succesfully saved.',
        },
      }
    );

  const insert = ({
    input,
  }: {
    input: gqlV2.owner_statement_template_insert_input;
  }) => insertOwnerStatementTemplate({ args: { ...input, tenantId: teamId } });

  const update = ({
    id,
    input,
  }: {
    id: string;
    input: gqlV2.owner_statement_template_insert_input;
  }) => updateOwnerStatementTemplate({ args: { id, input } });

  return {
    loading: updateLoading || insertLoading,
    update,
    insert,
  };
}

export function useStatementSettings() {
  const [teamId] = useTeamId();

  const {
    data,
    refetch: refetchTemplate,
    isLoading: loading,
    error,
  } = useQuery(
    (q, args) => {
      const ownerStatementTemplates =
        q
          .ownerStatementTemplates({
            where: { tenantId: { _eq: args.teamId } },
            order_by: [{ createdAt: 'desc' }],
            limit: 1,
          })
          .map((temp) => ({
            id: temp.id,
            billingAccountId: temp.billingAccountId,
            balanceStartAt: temp.balanceStartAt,
            data: temp.data(),
            version: temp.version,
            ownerStatements: temp
              .ownerStatements({ limit: 1 })
              .map((statement) => ({ id: statement.id })),
          })) || [];

      return {
        ownerStatementTemplates,
      };
    },
    {
      queryKey: 'ownerStatementTemplates',
      variables: {
        teamId,
      },
    }
  );

  useEffect(() => {
    if (error) {
      showErrorNotification({
        title: 'Error fetching template.',
        message:
          'There was an error loading your template. Please try refreshing your browser.',
        autoClose: false,
        withCloseButton: false,
      });
    }
  }, [error]);

  const ownerStatementTemplate: StatementTemplateType =
    data?.ownerStatementTemplates[0]?.data || undefined;

  const templateId: string | undefined =
    data?.ownerStatementTemplates[0]?.id || undefined;

  const templateVersion = data?.ownerStatementTemplates[0]?.version || 0;

  return {
    canUpdate: !data?.ownerStatementTemplates[0]?.ownerStatements?.length,
    refetchTemplate,
    ownerStatementTemplate,
    templateId,
    templateVersion,
    loading,
    error,
  };
}
export function useStatementAccounts(search?: string, limit?: number) {
  const [teamId] = useTeamId();

  const trimmedSearch = search?.trim();

  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      const accounts = q
        .source({
          where: args.where,
          order_by: [{ description: 'asc_nulls_last' }],
          limit: args.limit || undefined,
        })
        .map((source) => ({
          id: source.id || '',
          value: source.remoteId || '',
          type: source.json({ path: 'AccountType' }),
          label: getSourceDescription(source),
          status: source.status === 'active' ? 'active' : 'archived',
        }));

      return accounts;
    },
    {
      queryKey: 'sources',
      skip: !teamId,
      variables: {
        where: whereSources({
          connectionId: undefined,
          tenantId: teamId,
          type: 'account',
          search: trimmedSearch,
          status: ['active', 'archived'],
        }),
        limit,
      },
    }
  );

  return {
    accounts: data || [],
    loading,
  };
}

export function useUnmappedStatementAccountsSubscription() {
  const [teamId] = useTeamId();

  const { accounts } = useStatementAccounts();

  const { data: template } = useSubscription(
    (q) =>
      q
        .ownerStatementTemplates({
          limit: 1,
          where: { tenantId: { _eq: teamId } },
          order_by: [{ createdAt: 'desc' }],
        })?.[0]
        ?.data() as StatementTemplateType
  );

  const { unmappedAccounts } = useUnmapped({
    accounts,
    template,
  });

  return {
    unmappedAccounts: !template ? [] : unmappedAccounts,
  };
}

export function useUnmappedStatementAccounts() {
  const { accounts } = useStatementAccounts();
  const { ownerStatementTemplate: template } = useStatementSettings();

  const { unmappedAccounts } = useUnmapped({ accounts, template });

  return {
    unmappedAccounts,
  };
}

export function useUnmapped({
  accounts,
  template,
  // loading,
}: {
  accounts: ReturnType<typeof useStatementAccounts>['accounts'];
  template: StatementTemplateType | undefined;
  // loading?: boolean;
}) {
  const { unmappedAccounts, mappedAccounts } = useMemo(() => {
    const netRev = (template?.netRevenueSections[0]?.columns || [])
      .filter((i) => i?.type === 'sumAccounts')
      .flatMap((i) => i?.value as string[]);

    const other = (template?.otherSections || []).flatMap(
      (i) => i?.value as string[]
    );

    const excluded = template?.excludedAccounts || [];

    const mappedAccounts: string[] = [...netRev, ...other, ...excluded];

    const unmappedAccounts = (accounts || []).filter(
      (i) => !mappedAccounts.includes(i?.value || '')
    );

    return { mappedAccounts, unmappedAccounts };
  }, [template, accounts]);

  return {
    unmappedAccounts,
    mappedAccounts,
  };
}

export function useAccountBucketCheckById(accountId: string) {
  const { watch } = useFormContext<StatementTemplateType>();

  const excluded = watch('excludedAccounts')?.filter(
    (i) => i === accountId
  ).length;
  const [netRev] = watch('netRevenueSections').map(
    (i) =>
      i.columns
        .map(
          (i) =>
            Array.isArray(i.value) && (i.value as string[]).includes(accountId)
        )
        .filter((i) => !!i).length
  );

  const accounts = watch('otherSections')?.filter((i) =>
    (i.value as string[]).includes(accountId)
  ).length;

  console.log({ excluded, netRev, accounts });

  return {
    excluded,
    netRev,
    accounts,
  };
}

type Duplicate = {
  [accountId: string]: { [bucket: string]: number };
};

export function useAccountBucketCheck() {
  const { accounts } = useStatementAccounts();
  const { watch } = useFormContext<StatementTemplateType>();

  const excludedAccounts = watch('excludedAccounts');
  const netRevenueSections = watch('netRevenueSections.0');
  const otherSections = watch('otherSections');

  const duplicate = useMemo(() => {
    const temp: Duplicate = {};

    (accounts || [])
      .map((i) => i.value)
      .forEach((accountId) => {
        temp[accountId] = {};

        temp[accountId].exluded = (excludedAccounts || [])?.filter(
          (i) => i === accountId
        ).length;

        temp[accountId].account =
          otherSections?.filter((i) =>
            (i.value as string[]).includes(accountId)
          ).length || 0;

        temp[accountId].netRev = netRevenueSections.columns
          .map(
            (i) =>
              Array.isArray(i.value) &&
              (i.value as string[]).includes(accountId)
          )
          .filter((i) => !!i).length;

        temp;
      });

    return Object.entries(temp)
      .map(([key, value]) => [accounts.find((i) => i.value === key), value])
      .filter(
        (i: any) =>
          Object.values<any>(i[1]).reduce((prev: any, v: any) => prev + v, 0) >
          1
      );
  }, [excludedAccounts, otherSections, netRevenueSections, accounts]);

  useEffect(() => {
    if (duplicate.length > 0) {
      const t = duplicate.map((i) => `"${i[0]?.label}"`);

      console.log(duplicate);

      showErrorNotification({
        id: 'duplicate-account-mappings',
        title: 'Duplicate Account Mappings',
        message: (
          <ScrollArea sx={{ height: 150 }}>
            The accounts below are mapped to more than one collection. Please
            re-assign them to the same collection and save the template to fix
            this issue:
            <br />
            <ul>
              {t.map((c, index) => (
                <li key={index}>{c}</li>
              ))}
            </ul>
          </ScrollArea>
        ),
        autoClose: false,
        color: 'yellow',
      });
    }
  }, [duplicate]);

  return duplicate;
}
