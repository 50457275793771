import { useMe, useTeamId } from '@finalytic/data';
import { usePostHog } from 'posthog-js/react';
import { useIntercom } from 'react-use-intercom';

type AuthEvent = 'account_signed_out';

type TeamEvent = 'team_added' | 'team_info_updated' | 'team_deleted';

type OwnerEvent = 'owner_created' | 'owner_updated' | 'owner_deleted';

type OwnershipEvent =
  | 'ownership_created'
  | 'ownership_updated'
  | 'ownership_deleted';

type ConnectionEvent =
  | 'connection_created'
  | 'connection_reconnected'
  | 'connection_deleted'
  | 'connection_requested';

type AutomationEvent =
  | 'automation_created'
  | 'automation_updated'
  | 'automation_deleted';

type TaxStatementEvent =
  | 'tax_statement_preview_opened'
  | 'tax_statement_downloaded';

type Event =
  | AuthEvent
  | TeamEvent
  | OwnerEvent
  | OwnershipEvent
  | ConnectionEvent
  | AutomationEvent
  | TaxStatementEvent;

export function usePosthogCapture() {
  const posthog = usePostHog();
  const intercom = useIntercom();
  const { id: user_id, email: user_email } = useMe();
  const [tenant_id] = useTeamId();

  const capture = <
    TData extends { tenant_id?: string; user_id?: string } & Record<
      string,
      any
    >,
  >(
    event: Event,
    data: TData
  ) => {
    const meta = { user_id, tenant_id, user_email, ...data };
    intercom.trackEvent(event, meta);
    posthog?.capture(event, meta);
  };

  return capture;
}
