import {
  BedIcon,
  CalculatorIcon,
  CircleDollarIcon,
  FileText2Icon,
  HomeIcon,
  UserPlusIcon,
  UsersIcon,
} from '@finalytic/icons';
import { Anchor, Box, Card, Group, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const iconSize = 140;

export const ConnectPmsCard = () => {
  return (
    <SetupCard
      buttonLabel="Connect"
      buttonLink="/connections?add-connection=1&type=pms"
      title="Connect Property Management System (PMS)"
      description="Connect your PMS to access important property data."
      icon={<HomeIcon size={140} strokeWidth={0.7} />}
      iconPosition={{ bottom: -40, right: 10 }}
    />
  );
};

export const ConnectAccountingCard = () => {
  return (
    <SetupCard
      buttonLabel="Connect"
      buttonLink="/connections?add-connection=1&type=accounting"
      title="Connect Accounting System"
      description="Sync your accounting system to enable automatic data transfer."
      icon={<CalculatorIcon size={iconSize} />}
    />
  );
};
export const ConnectOtaCard = () => {
  return (
    <SetupCard
      buttonLabel="Connect"
      buttonLink="/connections?add-connection=1&type=bookingChannel"
      title="Connect Online Travel Agency (OTA)"
      description="Link your OTA to access booking data for owner statements"
      icon={<BedIcon size={120} strokeWidth={0.7} />}
      iconPosition={{ bottom: -10, right: 0 }}
    />
  );
};

export const ConnectMerchantProcessorCard = () => {
  return (
    <SetupCard
      buttonLabel="Connect"
      buttonLink="/connections?add-connection=1&type=paymentGateway"
      title="Connect Merchant Processor (ex. Stripe)"
      description="Integrate your merchant processor to sync online payments."
      icon={<CircleDollarIcon size={130} strokeWidth={0.7} />}
      iconPosition={{ bottom: -10, right: 0 }}
    />
  );
};

export const InviteMembersCard = () => {
  return (
    <SetupCard
      buttonLabel="Add members"
      buttonLink="/settings/team/members"
      title="Add team members"
      description="Add team members to ensure everyone has access to necessary information."
      icon={<UserPlusIcon strokeWidth={0.7} size={iconSize} />}
      iconPosition={{ bottom: -40, right: 0 }}
    />
  );
};

export const SetupStatementCard = () => {
  return (
    <SetupCard
      buttonLabel="Setup"
      buttonLink="/automations?addAutomation=1"
      title="Setup owner statement"
      description="Configure owner statements to provide accurate financial data to owners."
      icon={<FileText2Icon size={iconSize} />}
    />
  );
};

export const UpdateTeamDetails = () => {
  return (
    <SetupCard
      buttonLabel="Update details"
      buttonLink="/settings/team/general"
      title="Update team details"
      description="Set up team details to improve team management and productivity."
      icon={<UsersIcon size={iconSize} strokeWidth={0.7} />}
      iconPosition={{ bottom: -40, right: -20 }}
    />
  );
};

export const UpdateStatementTemplateCard = () => {
  return (
    <SetupCard
      buttonLabel="Update branding"
      buttonLink="/settings/features/owner-portal"
      title="Update color scheme and company logo"
      description="Update your color scheme and company logo to reflect your brand identity."
      icon={<FileText2Icon size={iconSize} />}
    />
  );
};

export const AddOwnersCard = () => {
  return (
    <SetupCard
      buttonLabel="Add owners"
      buttonLink="/owners"
      title="Add owners"
      description="Add property owners to keep track of ownership and management."
      icon={<UserPlusIcon strokeWidth={0.7} size={iconSize} />}
      iconPosition={{ bottom: -40, right: 0 }}
    />
  );
};

export const InviteOwnersCard = () => {
  return (
    <SetupCard
      buttonLabel="Invite owners"
      buttonLink="/owners"
      title="Invite owners"
      description="Invite property owners to streamline reporting and payments."
      icon={<UserPlusIcon strokeWidth={0.7} size={iconSize} />}
      iconPosition={{ bottom: -40, right: 0 }}
    />
  );
};

type SetupCardProps = {
  title: string;
  description: ReactNode;
  buttonLabel: string;
  buttonLink: string;
  icon: ReactNode;
  iconPosition?: {
    bottom: number;
    right: number;
  };
};

const SetupCard = ({
  buttonLabel,
  buttonLink,
  description,
  icon,
  title,
  iconPosition = {
    bottom: -10,
    right: -10,
  },
}: SetupCardProps) => {
  return (
    <Card
      mih={210}
      maw={450}
      radius="md"
      sx={{
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text component="h4" m={0}>
        {title}
      </Text>
      <Text component="p" m={0} sx={{ position: 'relative', zIndex: 1 }}>
        {description}
      </Text>
      <Card.Section
        bottom={0}
        mt="auto"
        px="md"
        mr="sm"
        sx={{ containerType: 'inline-size' }}
      >
        <Group
          justify="space-between"
          align="flex-end"
          sx={{
            position: 'relative',
            '@container (max-width: 200px)': {
              marginTop: 20,
            },
          }}
          gap={0}
        >
          <Anchor
            component={Link}
            to={buttonLink}
            mb="md"
            sx={{ position: 'relative', zIndex: 1 }}
          >
            {buttonLabel} &rarr;
          </Anchor>
          <Box
            sx={{
              position: 'absolute',
              right: iconPosition.right,
              bottom: iconPosition.bottom,
              zIndex: 0,
              '@container (max-width: 200px)': {
                position: 'relative',
              },
            }}
          >
            {icon}
          </Box>
        </Group>
      </Card.Section>
    </Card>
  );
};
