import { InputDate } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { Input as MantineInput } from '@mantine/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

export const DateInput = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  // placeholder,
  onValueChange,
}: FormInputBaseType) => {
  const { control } = useFormContext();

  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          const extendedOnChange = (value: string | undefined) => {
            onValueChange?.(formKey, value);
            onChange(value);
          };

          const onInputChange = (value: Date | null) => {
            extendedOnChange(
              !value ? undefined : day(value).format('YYYY-MM-DD')
            );
          };

          console.log(value);
          return (
            <InputDate
              // TODO: @LarsBehrenberg - fix this
              // placeholder={placeholder || label || ''}
              value={value ? new Date(value) : undefined}
              onChange={onInputChange}
              name={formKey}
              disabled={disabled}
              clearable
              sx={{ input: { borderColor: '#CED0D780' } }}
            />
          );
        }}
      />
    </MantineInput.Wrapper>
  );
};
