import { useCubeQuery } from '@cubejs-client/react';
import {
  AreaChart,
  Card,
  CardTitle,
  Legend,
  PercentageBadge,
  getPercentageDifference,
} from '@finalytic/ui';
import { day, formatCurrency, sum, utc } from '@finalytic/utils';
import {
  Anchor,
  Box,
  Group,
  Card as MantineCard,
  MantineSize,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  isCurrencyAmount?: boolean;
  total: 'sum' | 'average';
  href: string;
  borderRadius?: MantineSize;
  query: {
    measures: string[];
    order: {
      [key: string]: 'asc' | 'desc';
    };
    timeDimensions: {
      dimension: string;
      granularity: 'day';
      dateRange: [string, string];
    }[];
  };
};

export const AreaChartByDate = ({
  query,
  title,
  isCurrencyAmount,
  total: totalType,
  href,
  borderRadius = 'lg',
}: Props) => {
  const { resultSet, isLoading, error } = useCubeQuery({
    // limit: 100,
    ...query,
  });

  const { data, first, last, total, previousTotal, diffPercentage } =
    useMemo(() => {
      const series = resultSet?.series()?.[0];

      const data = series?.series || [];

      const primaryData = data.slice(data.length / 2, data.length);
      const secondaryData = data.slice(0, data.length / 2);

      const sums = sum(primaryData, 'value');
      const previousSum = sum(secondaryData, 'value');

      const total: Record<typeof totalType, number> = {
        sum: sums,
        average: sums / primaryData.length,
      };

      const previousTotal: Record<typeof totalType, number> = {
        sum: previousSum,
        average: previousSum / secondaryData.length,
      };

      return {
        // title: series?.title,
        data: primaryData.map((item, index) => {
          const previousItem = secondaryData[index];

          return {
            ...item,
            previous: previousItem?.value,
            currentItem: item,
            previousItem,
          };
        }),
        first: primaryData[0],
        last: primaryData[primaryData.length - 1],
        total: isCurrencyAmount
          ? formatCurrency(total[totalType], 'usd')
          : total[totalType],
        previousTotal: isCurrencyAmount
          ? formatCurrency(previousTotal[totalType], 'usd')
          : previousTotal[totalType],
        diffPercentage: getPercentageDifference(
          total[totalType],
          previousTotal[totalType]
        ),
      };
    }, [resultSet, isCurrencyAmount, totalType]);

  return (
    <Card error={error} loading={isLoading} radius={borderRadius}>
      <Group justify="space-between" align="flex-start">
        <CardTitle
          title={title}
          total={total}
          previousTotal={previousTotal}
          diffPercentage={diffPercentage}
        />

        <Anchor pt={4} component={Link} to={href}>
          View all
        </Anchor>
      </Group>
      <Box sx={{ flexGrow: 1, display: 'flex', height: 270 }} w="100%">
        {data.length > 0 ? (
          <AreaChart
            data={data}
            xAxisKey="x"
            yAxisKey="value"
            xAxisProps={{
              interval: 4,
              tickSize: 0,
              ticks: [],
              tickFormatter: () => '',
            }}
            xAxisLabels={[
              {
                children: day(first.x).format('DD MMM'),
                position: 'left',
                offset: -30,
              },
              {
                children: day(last.x).format('DD MMM'),
                position: 'right',
                offset: -30,
              },
            ]}
            tooltipProps={{
              content: ({ payload }: any) => {
                const theme = useMantineTheme();
                const legendColor = theme.colors[theme.primaryColor][6];

                const prev = payload?.[0]?.payload?.previousItem;
                const current = payload?.[0]?.payload?.currentItem;

                const formatLabel = (d: string) => {
                  if (!d) return '';

                  const labelDate = utc(d);
                  return labelDate.format('MMM DD, YYYY');
                };
                const formatValue = (v: any) =>
                  isCurrencyAmount ? formatCurrency(v, 'usd') : v;

                const diff = getPercentageDifference(
                  current?.value,
                  prev?.value
                );
                return (
                  <MantineCard p="xs" shadow="md" withBorder miw={250}>
                    <Group justify="space-between" mb="xs">
                      <Text color="#525252">{title}</Text>
                      <PercentageBadge value={diff} precision={0} />
                    </Group>

                    <Group justify="space-between">
                      <Legend
                        legendColor={legendColor}
                        label={formatLabel(current?.x)}
                      />
                      <Text ta="right"> {formatValue(current?.value)}</Text>
                    </Group>
                    <Group justify="space-between">
                      <Legend label={formatLabel(prev?.x)} />
                      <Text ta="right">{formatValue(prev?.value)}</Text>
                    </Group>
                  </MantineCard>
                );
              },
            }}
          />
        ) : (
          <Text
            size="sm"
            component="p"
            ta="center"
            c="gray"
            sx={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}
          >
            No data available for this period
          </Text>
        )}
      </Box>
    </Card>
  );
};
