import { InputDay } from '@finalytic/components';
import { InputRangeValueType } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { Input as MantineInput } from '@mantine/core';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

export const DateInputRange = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  placeholder,
  onValueChange,
}: FormInputBaseType) => {
  const { control } = useFormContext();

  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value: formValue } }) => {
          const extendedOnChange = (value: string) => {
            onValueChange?.(formKey, value);
            onChange(value);
          };

          // value format: YYYY-MM-DD || YYYY-MM-DD...YYYY-MM-DD
          const value = useMemo(() => {
            if (!(formValue as string).includes('...'))
              return [
                formValue ? day(formValue).toDate() : null,
                null,
              ] as InputRangeValueType;

            const v = (formValue as string).split('...');

            return v
              ? (v.map((i) => {
                  return i ? day(i).toDate() : null;
                }) as InputRangeValueType)
              : undefined;
          }, [formValue]);

          const onInputChange = (newValue: InputRangeValueType) => {
            const filtered = newValue.filter(Boolean);

            if (filtered.length > 1) {
              const isSame =
                day(filtered[0])?.yyyymmdd() === day(filtered[1])?.yyyymmdd();

              if (isSame) return extendedOnChange('');

              extendedOnChange(
                newValue
                  .map((i: InputRangeValueType[number]) =>
                    day(i).format('YYYY-MM-DD')
                  )
                  .join('...')
              );
            } else if (filtered.length === 1) {
              extendedOnChange(day(filtered[0]).yyyymmdd());
            }
          };

          const isRange = (value || []).filter(Boolean).length > 1;

          return (
            <InputDay
              type="range"
              labelSeparator={isRange ? '-' : ''}
              placeholder={placeholder || label || ''}
              value={value || [null, null]}
              onChange={onInputChange}
              name={formKey}
              disabled={disabled}
              sx={{ input: { borderColor: '#CED0D780' } }}
            />
          );
        }}
      />
    </MantineInput.Wrapper>
  );
};
