import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useColors } from '../../../styles';

type Props = {
  component?: any;
  href?: string;
  target?: string;
  to?: string;
  opacity?: number;
} & ButtonProps &
  ComponentPropsWithoutRef<'button'>;

export const TransparentButton = forwardRef<HTMLButtonElement | null, Props>(
  ({ children, color, opacity = 0.7, sx, ...props }, ref) => {
    const { gray } = useColors();

    return (
      <Button
        variant="outline"
        ref={ref}
        sx={
          {
            border: 'none',
            borderRadius: 10,
            padding: 5,
            color: color || '#fff',
            '& .mantine-Button-label': {
              opacity,
            },
            ':hover': {
              backgroundColor: `${gray.dark}30`,
            },
            ...sx,
          } as any
        }
        {...props}
      >
        {children}
      </Button>
    );
  }
);

TransparentButton.displayName = 'Transparent Button';
