import { user_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';

const ownersFilterInput = {
  search: z.string().optional().nullable(),
  type: z.enum(['individual', 'company']).optional().nullable(),
  status: z
    .enum(['inactive', 'active', 'unconfirmed', 'archived'])
    .optional()
    .nullable(),
};

const ownersFilter = z.object(ownersFilterInput);

type OwnersFilterParams = z.infer<typeof ownersFilter>;

type BaseWhere = {
  teamId: string;
};

export function whereOwnerUsers({
  search: s,
  teamId,
  type,
  status,
}: BaseWhere & OwnersFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<user_bool_exp[]>([
        { name: { _ilike: `%${search}%` } },
        { firstName: { _ilike: `%${search}%` } },
        { lastName: { _ilike: `%${search}%` } },
        { address: { _ilike: `%${search}%` } },
        { companyName: { _ilike: `%${search}%` } },
        { email: { _ilike: `%${search}%` } },
        { phone: { _ilike: `%${search}%` } },
        {
          ownerships: {
            listing: {
              name: { _ilike: `%${search}%` },
            },
          },
        },
        {
          ownerships: {
            listing: {
              address: { _ilike: `%${search}%` },
            },
          },
        },
      ])
    : undefined;

  return ensure<user_bool_exp>({
    memberships: {
      tenantId: { _eq: teamId },
      role: { _eq: 'owner' },
      status: status
        ? {
            _eq: status,
          }
        : undefined,
    },
    ownerAccesses: type
      ? {
          owner: {
            type: { _eq: type },
            tenantId: { _eq: teamId },
          },
        }
      : undefined,
    _or: searchQuery,
  });
}
