import {
  Box,
  Divider,
  Group,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActionButton, Button, TransparentButton } from '../../../components';
import { showErrorNotification, showSuccessNotification } from '../../../hooks';
import { useColors } from '../../../styles';
import { Modal } from '../Modal';
import { ModalFooter } from '../ModalFooter';
import {
  CriticalPriorityIcon,
  HighPriorityIcon,
  MediumPriorityIcon,
} from './_icons';
import { FeedbackFormInput, ImportanceTypes } from './_types';

type FeedbackModalProps = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    data: FeedbackFormInput
  ) => Promise<{ ok: boolean; message: string }>;
};

export const FeedbackModal = ({
  opened,
  onClose,
  onSubmit,
}: FeedbackModalProps) => {
  const { gray } = useColors();
  const dividerOpacity = 0.4;

  const theme = useMantineTheme();

  const [loading, setLoading] = useState(false);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FeedbackFormInput>({
    mode: 'onChange',
  });

  const importance = watch('importance');
  const setImportance = (value: ImportanceTypes) =>
    setValue('importance', value === importance ? undefined : value);

  const submit = async (data: FeedbackFormInput) => {
    setLoading(true);
    const res = await onSubmit(data);

    if (res.ok) {
      showSuccessNotification({
        message: res.message,
      });
    } else {
      showErrorNotification({
        message: res.message,
      });
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} size={700} title={'Send Feedback'}>
      <Divider mb={15} sx={{ opacity: dividerOpacity }} />
      <Controller
        control={control}
        name="userInput"
        rules={{ required: true }}
        render={({ field: { name, onBlur, onChange, value } }) => (
          <Textarea
            value={value}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={`How can me make ${theme.primaryColor} better for you?`}
            styles={{
              input: {
                minHeight: 200,
                borderColor: 'transparent',
                padding: 0,
                ':focus': {
                  borderColor: 'transparent',
                },
                '::placeholder': {
                  fontWeight: 500,
                },
              },
            }}
          />
        )}
      />

      <Divider mb={20} sx={{ opacity: dividerOpacity }} />
      <Group mb={25} justify="space-between">
        <Text fw={500}>Importance</Text>
        <Group wrap="nowrap" gap={5}>
          <CriticalButton
            leftIcon={<MediumPriorityIcon />}
            isActive={importance === 'MEDIUM'}
            onClick={() => setImportance('MEDIUM')}
          >
            Medium
          </CriticalButton>
          <CriticalButton
            leftIcon={<HighPriorityIcon />}
            isActive={importance === 'HIGH'}
            onClick={() => setImportance('HIGH')}
          >
            High
          </CriticalButton>
          <CriticalButton
            leftIcon={<CriticalPriorityIcon />}
            isActive={importance === 'CRITICAL'}
            onClick={() => setImportance('CRITICAL')}
          >
            Critical
          </CriticalButton>
        </Group>
      </Group>
      <Box>
        <ModalFooter>
          <Group justify="right" my={5}>
            <TransparentButton onClick={onClose} color={gray.dark}>
              Cancel
            </TransparentButton>
            <ActionButton
              loading={loading}
              onClick={handleSubmit(submit)}
              disabled={!isValid}
            >
              Send
            </ActionButton>
          </Group>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

const CriticalButton = ({
  children,
  leftIcon,
  onClick,
  isActive,
}: {
  children: ReactNode;
  leftIcon: ReactNode;
  onClick: () => void;
  isActive: boolean;
}) => (
  <Button
    leftSection={leftIcon}
    radius={5}
    onClick={onClick as any}
    styles={(theme) => ({
      root: {
        height: 30,
        backgroundColor: isActive ? 'rgba(193, 194, 197, 0.35)' : undefined,
      },
      inner: {
        padding: 0,
      },
      label: {
        fontSize: theme.fontSizes.sm,
      },
      icon: {
        marginRight: 0,
      },
    })}
  >
    {children}
  </Button>
);
