import { StatementsIcon } from '@finalytic/ui';
import { Center } from '@mantine/core';

export const TableStatementIcon = () => (
  <Center
    sx={(theme) => ({
      height: 23,
      width: 23,
      svg: {
        height: '100%',
        width: '100%',
        '& path, & rect': {
          fill: theme.colors.gray[5],
        },
      },
    })}
  >
    <StatementsIcon />
  </Center>
);
