import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { Maybe, ensure, isUUID, toTitleCase } from '@finalytic/utils';
import { Badge, Group, Text } from '@mantine/core';
import {
  MappingLeftSchemaUnion,
  formatOwnerName,
  formatUserName,
} from '@vrplatform/ui-common';
import { MappingRow } from '../_tables';

export function useExceptionName(
  id: string,
  leftSchema: Maybe<MappingLeftSchemaUnion>
) {
  const [teamId] = useTeamId();
  const { NEW_OWNERS } = useEnabledFeatures();

  const { data: name, isLoading: loading } = useQuery(
    (q, { id, leftSchema, NEW_OWNERS }) => {
      if (leftSchema === 'finalytic.owner') {
        if (NEW_OWNERS) {
          return (
            q
              .owners({
                where: {
                  id: { _eq: id },
                },
                limit: 1,
              })
              .map((x) => formatOwnerName(x))[0] || 'Missing owner'
          );
        }

        const user = q.userById({ id });

        return (
          formatUserName({
            companyName: user?.companyName,
            firstName: user?.firstName,
            lastName: user?.lastName,
          }) || 'Missing owner'
        );
      }

      if (leftSchema === 'finalytic.listingCollection') {
        const listingCollection = q.listingCollection({ id });

        return listingCollection?.name || 'Missing listing collection';
      }

      if (leftSchema === 'finalytic.app') {
        const app = q.app({
          where: {
            id: { _eq: id },
          },
          limit: 1,
        });

        return app ? app[0]?.name || '' : 'Missing app';
      }

      if (leftSchema === 'finalytic.connection') {
        const connection = q.connectionById({ id });

        return connection?.name || 'Missing connection';
      }

      if (leftSchema === 'finalytic.bankAccountDigits') {
        return id || 'Missing bank account digits';
      }

      if (leftSchema === 'finalytic.listing') {
        const listing = q.listing({ id });

        return listing?.name || 'Missing listing';
      }

      if (leftSchema === 'finalytic.lineType') {
        const lineType = q.paymentLineClassification({ name: id });

        return lineType?.name || 'Missing line type';
      }

      if (leftSchema === 'finalytic.listingConnection') {
        const listingConnection = q.listingConnection({ id });

        return listingConnection?.name || 'Missing unit';
      }

      if (leftSchema === 'finalytic.bookingChannel') {
        const bookingChannel = q.bookingChannel({ id });

        return (
          toTitleCase(bookingChannel?.uniqueRef) || 'Missing booking channel'
        );
      }

      if (
        !`${leftSchema}`?.startsWith('finalytic.') &&
        `${leftSchema}`.includes('.')
      ) {
        const source = q.sourceById({ id });
        const item = { id: source?.remoteId, description: source?.description };

        return item.description || item.id || 'No Description';
      }

      return 'Missing schema';
    },
    {
      skip:
        !teamId ||
        !id ||
        !leftSchema ||
        !ensure<MappingLeftSchemaUnion[]>([
          'finalytic.app',
          'finalytic.lineType',
          'finalytic.bankAccountDigits',
          'finalytic.bankAccount',
        ]).includes(leftSchema)
          ? !isUUID(id)
          : false,
      variables: {
        id,
        leftSchema,
        NEW_OWNERS,
      },
    }
  );

  return {
    name,
    loading,
  };
}

export const LeftSideNameCell = (params: {
  value: MappingRow['target'];
  data: MappingRow;
}) => {
  const value = params.value || '';
  const key: MappingLeftSchemaUnion | undefined = params.data
    ?.leftType as MappingLeftSchemaUnion;

  const { loading, name } = useExceptionName(value, key);

  if (loading)
    return <LoadingIndicator mt={9} size={'sm'} isFullPageLoading={false} />;

  return (
    <Group sx={{ height: '100%' }} gap={10} wrap="nowrap">
      <Text component="span" lineClamp={1} sx={{ maxWidth: 400 }}>
        {value === '*' ? 'Set Default:' : name}
      </Text>
      <Badge variant="light" size="sm" sx={{ flexShrink: 0 }}>
        {toTitleCase(key?.split('.').reverse()[0])?.replace(
          'Listing Connection',
          'Unit'
        )}
      </Badge>
    </Group>
  );
};
