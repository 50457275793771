import { listing as Listing } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';
import { FormatAddressOptions, formatAddress } from './format-address';

export const getListingAddress = (
  listing: Maybe<Listing>,
  options?: FormatAddressOptions
) => {
  const values = {
    line1: listing?.addressRelation?.line || '',
    line2: listing?.addressRelation?.lineDetails || '',
    city: listing?.addressRelation?.city || '',
    postcode: listing?.addressRelation?.postalCode || '',
    state: listing?.addressRelation?.state || '',
    stateCode: listing?.addressRelation?.stateCode || '',
    country: listing?.addressRelation?.country || '',
    countryCode: listing?.addressRelation?.countryCode || '',
  };

  const full =
    formatAddress(values, options) ||
    listing?.addressRelation?.full ||
    listing?.address;

  return {
    values,
    full,
  };
};
