import {
  automation_bool_exp,
  automation_template_bool_exp,
} from '@finalytic/graphql';
import { Maybe, hasValue } from '@finalytic/utils';

export const whereAutomations = ({
  tenantId,
  isPartnerAdmin,
  isSuperAdmin,
  search,
  status,
  accountingConnectionId,
  templateType,
  isVrpAdmin,
}: {
  search?: Maybe<string>;
  status?: Maybe<string>;
  tenantId: string;
  accountingConnectionId?: string;
  templateType?: (
    | 'ownerStatements.ownerStatements'
    | 'ownerStatements.ownerStatementBills'
    | 'commission.managementCommission'
  )[];
} & WhereAutomationTemplates): automation_bool_exp => ({
  tenantId: { _eq: tenantId },
  status: status ? { _eq: status } : undefined,
  ttemplate: {
    visibility: whereAutomationTemplateVisibility({
      isPartnerAdmin,
      isSuperAdmin,
      isVrpAdmin,
    }),
    type: templateType?.length ? { _in: templateType } : undefined,
  },
  _or: search
    ? [
        { title: { _ilike: `%${search}%` } },
        { ttemplate: { title: { _ilike: `%${search}%` } } },
      ]
    : undefined,
  _and: accountingConnectionId
    ? [
        {
          _or: [
            {
              rightConnection: {
                app: { category: { _neq: 'accountingPlatform' } },
              },
              leftConnection: {
                app: { category: { _neq: 'accountingPlatform' } },
              },
            },
            {
              leftConnectionId: {
                _eq: accountingConnectionId,
              },
            },
            {
              rightConnectionId: {
                _eq: accountingConnectionId,
              },
            },
          ],
        },
      ]
    : undefined,
});

type WhereAutomationTemplates = {
  isSuperAdmin: boolean;
  isPartnerAdmin: boolean;
  isVrpAdmin: boolean;
};

export const whereAutomationTemplateVisibility = ({
  isPartnerAdmin,
  isSuperAdmin,
  isVrpAdmin,
}: WhereAutomationTemplates): automation_template_bool_exp['visibility'] => ({
  _in: [
    isPartnerAdmin ? 'partnerAdmin' : undefined,
    isSuperAdmin || isVrpAdmin ? 'admin' : undefined,
    'public',
  ].filter(hasValue),
});
