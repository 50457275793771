import {
  gqlV2,
  useEnabledFeatures,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  Drawer,
  DrawerHeader,
  DrawerReservationDetails,
  LoadingIndicator,
  useQueryParam,
} from '@finalytic/ui';
import { StringParam, useQueryParams } from '@finalytic/ui';
import { emptyUUID, ensure } from '@finalytic/utils';
import { Group, Text } from '@mantine/core';
import {
  getFormattedStatement,
  getStatementQuery,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { StatementSummaryBox } from '../../StatementSummaryBox';
import { StatementHeader } from '../../headers';
import { StatementLinesTable } from './_tables';

function useOwnerStatement({
  statementId,
  reservationId,
}: {
  statementId?: string | null;
  reservationId?: string | null;
}) {
  const [teamId] = useTeamId();
  const { NEW_OWNERS } = useEnabledFeatures();

  const [ownerId] = useQueryParam('statementOwnership', StringParam);

  const { data: ownerStatements, isLoading: loading } = useQuery(
    (q, args) => {
      return getStatementQuery(
        q,
        args.where || { id: { _eq: emptyUUID } },
        { lines: args.whereLines },
        {
          v2Owners: args.NEW_OWNERS,
        }
      );
    },
    {
      skip:
        (!reservationId && !statementId) ||
        reservationId === 'missingReservation',
      variables: {
        NEW_OWNERS,
        where: statementId
          ? ensure<gqlV2.owner_statement_bool_exp>({
              id: { _eq: statementId },
              tenantId: { _eq: teamId },
            })
          : undefined,
        whereLines: reservationId
          ? reservationId === 'none'
            ? ensure<gqlV2.owner_statement_line_bool_exp>({
                reservationId: { _is_null: true },
              })
            : ensure<gqlV2.owner_statement_line_bool_exp>({
                reservationId: { _eq: reservationId || emptyUUID },
              })
          : undefined,
        teamId,
      },
    }
  );

  const statement = ownerStatements?.[0];
  // const statementTemplate = statement?.template;

  const formattedStatement = useMemo(() => {
    return getFormattedStatement({
      groupedBy: 'groupByReservation',
      ownerId: ownerId || statement?.statementOwners?.[0]?.owner.id,
      statements: statement ? [statement] : [],
      type: 'propertyManager',
      v2Owners: NEW_OWNERS,
    });
  }, [statement, ownerId, NEW_OWNERS]);

  // const accounts = useMemo(() => {
  //   const a: string[] = [];
  //   if (statementTemplate?.data) {
  //     const netRevColumns =
  //       statementTemplate?.data?.netRevenueSections[0]?.columns;

  //     // const columns = statementTemplate?.data?.otherSections[0]?.columns;
  //     if (netRevColumns) {
  //       netRevColumns.forEach((c: any) => {
  //         if (c.type === 'sumAccounts') a.push(...c.value);
  //       });
  //       return a;
  //     }
  //   }
  //   return a;
  // }, [statementTemplate]);

  const lines = useMemo(() => statement?.lines, [statement?.lines]);
  const reservation = lines ? lines[0]?.reservation : undefined;

  const currency = statement?.currency || 'USD';

  return {
    loading,
    lines,
    reservation,
    currency,
    statements: ownerStatements || [],
    formattedStatement,
  };
}

export function useStatementLinesDetailDrawer() {
  const [
    { ownerstatement: statementId, statementreservation: reservationId },
    setQuery,
  ] = useQueryParams({
    ownerstatement: StringParam,
    statementreservation: StringParam,
  });

  return {
    opened: !!statementId,
    values: {
      statementId,
      reservationId,
    },
    handlers: {
      close: () =>
        setQuery({
          ownerstatement: undefined,
          statementreservation: undefined,
        }),
      open: (value: { statementId: string; reservationId?: string }) =>
        setQuery({
          ownerstatement: value.statementId,
          statementreservation: value.reservationId,
        }),
    },
  };
}

export const StatementLinesDetailDrawer = () => {
  const {
    values: { reservationId, statementId },
    handlers,
  } = useStatementLinesDetailDrawer();

  const closeDrawer = handlers.close;

  const { loading, lines, reservation, currency, formattedStatement } =
    useOwnerStatement({
      statementId,
      reservationId,
    });

  return (
    <Drawer opened={!!statementId} onClose={closeDrawer} size={1200}>
      <DrawerHeader title="Statement Lines" onClose={closeDrawer} />

      {/* Modal Header */}
      {loading ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <>
          {/* Payment Details */}

          {reservationId && (
            <DrawerReservationDetails
              guestName={reservation?.guestName || 'No reservation'}
              status={reservation?.status}
              checkIn={reservation?.checkIn}
              checkOut={reservation?.checkOut}
              confirmationCode={reservation?.confirmationCode}
            />
          )}

          {!reservationId && (
            <Group justify="space-between">
              <StatementHeader header={formattedStatement?.header} />
              <StatementSummaryBox
                data={formattedStatement?.summary?.totals?.summaryLines || []}
              />
            </Group>
          )}

          {/* Payment Lines */}
          <Text component="h3" size="lg" mb={5} px={5}>
            Statement Lines{' '}
            {reservationId && (
              <>
                for reservation:{' '}
                <Text component="span" ml={8} fw={400}>
                  {reservation?.confirmationCode}
                </Text>
                {reservation?.pmsReferenceCode &&
                reservation?.pmsReferenceCode !==
                  reservation?.confirmationCode ? (
                  <Text component="span" ml={8} fw={400}>
                    / {reservation?.pmsReferenceCode}
                  </Text>
                ) : null}
              </>
            )}
          </Text>
          <StatementLinesTable rowData={lines} currency={currency} />
          {/* <PaymentWorkflowsTable rowData={workflows} /> */}
        </>
      )}
    </Drawer>
  );
};
