import { useRunDrawer } from '@finalytic/data-ui';
import { Drawer } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { RunResultContent } from './RunResultContent';

const previewMaxWidth = 1100;

export const RunResultDrawer = () => {
  const { close, navigateBack, workflowIds, activeDrawer } = useRunDrawer();

  const opened = !!activeDrawer && activeDrawer === 'workflows';

  return (
    <Drawer
      opened={opened}
      zIndex={101}
      onClose={close}
      size={previewMaxWidth}
      containerSx={{
        marginRight: 20,
        borderRadius: 20,
        padding: 0,
      }}
    >
      <RunResultContent
        workflowIds={workflowIds?.filter(hasValue) || []}
        closeDrawer={navigateBack}
      />
    </Drawer>
  );
};
