import { useAuth, useUser } from '@finalytic/authentication';
import {
  STATUS_PAGE,
  captureSentryError,
  useHasEnabledFeatures,
  useTeamRole,
} from '@finalytic/data';
import { Icon, MoreHorizontalIcon } from '@finalytic/icons';
import { FeedbackFormInput, FeedbackModal, useColors } from '@finalytic/ui';
import {
  Avatar,
  Box,
  Group,
  Menu,
  Stack,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { usePosthogCapture } from '../../hooks';

type SettingsPopoverMenuProps = {
  isNavbarExpanded: boolean;
  isOwnerDashboard?: boolean;
};

export const SettingsPopoverMenu = ({
  isNavbarExpanded,
  isOwnerDashboard = false,
}: SettingsPopoverMenuProps) => {
  const { gray } = useColors();
  const { colorScheme } = useMantineColorScheme();
  const hasEnabledFeatures = useHasEnabledFeatures();
  const { isVrpAdmin } = useTeamRole();
  const capture = usePosthogCapture();
  const { user } = useUser();
  const { signOut: sOut, actor } = useAuth();

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const email = user?.primaryEmailAddress?.emailAddress;

  const isUserPreview = !!actor?.sub;

  const signOut = () => {
    capture('account_signed_out', {
      user_email: email,
      last_name: lastName,
      first_name: firstName,
    });
    sOut();
  };

  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  const submitFeedback = async (data: FeedbackFormInput) => {
    try {
      class FeedbackRequestError extends Error {
        constructor(readonly feedback: string) {
          super(`User Feedback: ${feedback}`);
          this.name = 'FeedbackRequestError';
        }
      }

      captureSentryError(new FeedbackRequestError(data.userInput), {
        level: 'info',
        tags: {
          userInput: data.userInput,
          priority: data.importance,
          sendIntegrationNotification: 'true',
        },
      });

      return {
        ok: true,
        message:
          'Your feedback was submitted! Thank you! We will get back to you soon.',
      };
    } catch (error: any) {
      return {
        ok: false,
        message:
          error?.message ||
          "We couldn't process your feedback. Please try again.",
      };
    }
  };

  return (
    <>
      <Menu
        radius={10}
        width={isNavbarExpanded ? 'target' : 200}
        withinPortal
        position="top-start"
        shadow="lg"
        styles={{
          dropdown: { padding: '0px !important' },
          item: {
            ':hover': {
              backgroundColor: gray.dark + 20,
            },
            margin: '5px 4px',
            width: 'calc(100% - 8px)',
            lineHeight: 'normal',
          },
          itemSection: {
            width: 12,
          },
        }}
      >
        <Menu.Target>
          <Group
            gap={0}
            px="xs"
            py="sm"
            justify="space-between"
            data-testid="user-setting-popover-menu"
            wrap={isNavbarExpanded ? 'nowrap' : undefined}
            sx={(theme) => ({
              cursor: 'pointer',
              borderRadius: theme.radius.md,
              ':hover': {
                backgroundColor: '#5C617840',
              },
            })}
          >
            <Group gap={10} wrap="nowrap" sx={{ overflowX: 'hidden' }}>
              <Avatar
                src={user?.imageUrl || 'https://www.gravatar.com/avatar?d=mp'}
                radius={100}
              />
              {isNavbarExpanded && (
                <Stack
                  gap={0}
                  sx={{
                    color: '#fff',
                    '*': {
                      maxWidth: 165,
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  <Text>
                    {firstName} {lastName}
                  </Text>
                  <Text
                    sx={{
                      opacity: 0.8,
                    }}
                    size="xs"
                  >
                    {email}
                  </Text>
                </Stack>
              )}
            </Group>

            {isNavbarExpanded && <MoreHorizontalIcon size={18} />}
          </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <Box
            component="a"
            href={STATUS_PAGE}
            target="_blank"
            rel="noreferrer"
            sx={{
              textDecoration: 'none',
              '@keyframes pulse': {
                '0%, 100%': {},
                '50%': {
                  transform: 'scale(1.3)',
                },
              },
              '&:hover ': {
                textDecoration: 'underline',
                textDecorationColor: gray.dark,
                '.status': {
                  animation: 'pulse 1.5s ease-in-out infinite',
                },
              },
            }}
          >
            <Group
              gap={12}
              sx={(theme) => ({
                backgroundColor:
                  colorScheme === 'dark' ? undefined : gray.light + 40,
                borderBottom:
                  colorScheme === 'dark'
                    ? `1px solid ${theme.colors.gray[7]}`
                    : undefined,
                margin: '-1px -1px 0',
                borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
                height: 38,
                paddingLeft: '15px',
              })}
            >
              <Box
                className="status"
                sx={(theme) => ({
                  borderRadius: '100%',
                  backgroundColor: theme.colors.teal[4],
                  width: 8,
                  height: 8,
                })}
              />
              <Text
                component="span"
                fw={500}
                size="xs"
                c={colorScheme === 'dark' ? gray.light : gray.dark}
              >
                All systems are online
              </Text>
            </Group>
          </Box>

          {window.location.hostname === 'staging.portal.vrplatform.app' &&
            isVrpAdmin && (
              <Menu.Item
                component={'a'}
                href="https://portal.vrplatform.app?vrp_staging_overwrite=true"
                leftSection={
                  <Icon
                    icon="ArrowLeftIcon"
                    size={16}
                    color={(theme) => theme.black}
                  />
                }
              >
                Go to production
              </Menu.Item>
            )}

          {hasEnabledFeatures && (
            <>
              <Menu.Item
                component={Link}
                to="/features"
                leftSection={
                  <Icon
                    icon="LightningIcon"
                    size={16}
                    color={(theme) => theme.black}
                  />
                }
              >
                Upcoming features
              </Menu.Item>
              <Menu.Divider />
            </>
          )}

          {isUserPreview && isOwnerDashboard ? null : (
            <Menu.Item
              component={Link}
              data-testid="settings"
              to="/settings"
              leftSection={
                <Icon
                  icon="GearIcon"
                  size={16}
                  color={(theme) => theme.black}
                />
              }
            >
              Settings
            </Menu.Item>
          )}

          <Menu.Item
            data-testid="sign-out"
            onClick={() => signOut()}
            leftSection={
              <Icon
                icon="LogOut2Icon"
                size={16}
                color={(theme) => theme.black}
              />
            }
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <FeedbackModal
        opened={open}
        onClose={onClose}
        onSubmit={submitFeedback}
      />
    </>
  );
};
