import { InputFormSchema } from '@vrplatform/ui-common';
import {
  ConfirmModal,
  ConfirmModalProps,
  ConfirmResultType,
} from '../confirm-modal';

export type TaskConfirmData = {
  ok: boolean;
  data?: { workflowId: string; runId?: string; taskId?: string };
  task?: { workflowId: string; runId?: string; taskId?: string };
} & Partial<ConfirmResultType>;
export function TaskConfirmModal<T extends InputFormSchema>({
  onSuccess: _,
  ...props
}: Omit<ConfirmModalProps<T>, 'onSubmit'> & {
  onSubmit: (args: any) => Promise<TaskConfirmData | undefined>;
}) {
  //const [, addTask] = useTasksContext();
  // const [runId, setRunId] = useState('');
  const onSubmit: ConfirmModalProps<T>['onSubmit'] = async (args: any) => {
    try {
      const result = await props.onSubmit(args);
      //const task = result?.data || result?.task;
      //if (task?.taskId) addTask(task.taskId, onSuccess as any);
      //else if (task?.runId) addTask(task.runId, onSuccess as any);
      return {
        message: 'Task was created, closing this window ...',
        isSuccess: true,
        ...result,
      };
    } catch (error: any) {
      if (error?.message) {
        return {
          message: error?.message,
          isSuccess: false,
        };
      }
      return {
        message: 'An error occured',
        isSuccess: false,
      };
    }
  };
  return (
    <ConfirmModal {...props} autoCloseOnSuccess="2s" onSubmit={onSubmit} />
  );
}
