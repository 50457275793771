import { MantineRadius, NumberInput, Text } from '@mantine/core';

export const InputPercentage = ({
  setValue,
  value,
  placeholder,
  radius = 0,
  inputRef,
  precision = 2,
}: {
  value: number | undefined;
  setValue: (val: number) => void;
  placeholder?: string;
  radius?: MantineRadius;
  inputRef?: any;
  precision?: number;
}) => {
  return (
    <NumberInput
      ref={inputRef}
      value={value}
      onChange={(val) => setValue(typeof val !== 'number' ? 0 : val)}
      sx={{ height: '100%' }}
      radius={radius}
      placeholder={placeholder}
      decimalScale={precision}
      max={100}
      leftSection={<Text>%</Text>}
      styles={{
        root: {
          height: '100%',
        },
        input: {
          height: '100%',
        },
        wrapper: {
          height: '100%',
        },
      }}
    />
  );
};
