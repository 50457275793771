import { gqlV2 } from '@finalytic/data';
import { ExtendedCustomColors } from '@finalytic/ui';
import { MantineColorsTuple, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

export function useStatementStatusColor(
  status: gqlV2.owner_statement_status_enum
) {
  const theme = useMantineTheme();

  const colors = useMemo<[MantineColorsTuple, ExtendedCustomColors]>(() => {
    const themeColor: {
      [_key in gqlV2.owner_statement_status_enum]: ExtendedCustomColors;
    } = {
      draft: 'yellow',
      inReview: 'purple',
      posted: 'green',
      published: 'green',
      void: 'gray',
    };

    return [theme.colors[themeColor[status]], themeColor[status]];
  }, [status]);

  return colors;
}
