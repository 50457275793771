import { NoStatementsTableOverlay, Table } from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { utc } from '@finalytic/utils';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { TableStatementIcon } from './TableStatementIcon';
import { TableTitle } from './TableTitle';

export type ListingStatementRow = {
  id: string;
  month: string;
  ownerName: string;
  listingId: string;
};

type Props = {
  rowData: ListingStatementRow[];
};

export const ListingStatementsTable = ({ rowData }: Props) => {
  const { classes } = useTableStyles();

  const goto = useNavigate();

  const columnDefs = useMemo<ColDef<ListingStatementRow>[]>(
    () => [
      {
        maxWidth: 55,
        // cellClass: 'no-padding',
        cellStyle: { display: 'grid', placeContent: 'center' },
        cellRenderer: TableStatementIcon,
      },
      {
        field: 'month',
        sort: 'desc',
        maxWidth: 150,
        minWidth: 100,
        valueFormatter: ({ value }) => utc(value).format('MMM YYYY'),
      },
      { field: 'ownerName' },
      {
        cellClass: 'arrow',
        cellStyle: { display: 'grid', placeContent: 'center' },
        maxWidth: 50,
        cellRenderer: (_p: ICellRendererParams<ListingStatementRow>) => (
          <FontAwesomeIcon icon={faChevronRight} />
        ),
      },
    ],
    []
  );

  if (!rowData.length) return null;

  return (
    <>
      <Box mt="lg" mb="xs">
        <TableTitle>Statements</TableTitle>
      </Box>
      <Box>
        <Table
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ flex: 1 }}
          pagination={false}
          suppressCellFocus={true}
          headerHeight={0}
          rowClass={classes.row}
          className={classes.table}
          domLayout="autoHeight"
          animateRows
          getRowId={({ data }) => data?.id}
          noRowsOverlayComponent={() => (
            <NoStatementsTableOverlay text="No Statements" />
          )}
          onRowClicked={({ data }) => {
            goto(
              `/statement/${data?.listingId}?date=${utc(data?.month).format(
                'YYYY-MM-01'
              )}`
            );
          }}
        />
      </Box>
    </>
  );
};

const useTableStyles = createStyles(() => ({
  row: {
    '&.no-padding': { padding: 0 },
    cursor: 'pointer',
    '&:hover .arrow': {
      opacity: 1,
    },

    '.arrow': {
      opacity: 0.5,
      transition: 'opacity 0.1s ease-in-out',
    },
  },
  table: {
    '.ag-header': {
      borderBottom: 'none',
    },
  },
}));
