import { Collapse } from '@finalytic/components';
import { LazyTable, MRT_ColumnDef, useTablePagination } from '@finalytic/table';
import { LoadingIndicator } from '@finalytic/ui';
import {
  Box,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ComponentProps, useMemo } from 'react';

export const DrawerCollapsableTable = <TRow extends Record<string, any>>({
  rowMenu,
  columns,
  rightSection,
  rowData,
  title,
  emptyRowsFallback: Empty,
  onRowClick,
  subRows,
  customRowCount,
  paginationSize = 10,
  loading,
  defaultOpened,
  hideHeader = true,
}: {
  title: string;
  rowData: TRow[];
  customRowCount?: number;
  columns: MRT_ColumnDef<TRow>[];
  rightSection: React.ReactNode;
  emptyRowsFallback: ComponentProps<
    typeof LazyTable<TRow>
  >['table']['emptyRowsFallback'];
  subRows?: ComponentProps<typeof LazyTable<TRow>>['subRows'];
  rowMenu?: ComponentProps<typeof LazyTable<TRow>>['rowMenu'];
  onRowClick?: ComponentProps<typeof LazyTable<TRow>>['table']['onRowClick'];
  paginationSize?: number;
  loading?: boolean;
  defaultOpened?: boolean;
  hideHeader?: boolean;
}) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const pagination = useTablePagination(paginationSize);

  const withPagination = rowData.length > paginationSize;

  const data = useMemo(() => {
    if (!withPagination) return rowData;

    const index = pagination.pagination.pageIndex;
    const pageSize = pagination.pagination.pageSize;
    const start = index * pageSize;
    const end = start + pageSize;
    return rowData.slice(start, end);
  }, [rowData, pagination.pagination, withPagination]);

  return (
    <Box>
      <Collapse
        title={
          <>
            <Text component="span" size="sm">
              {title}
              {!loading && (
                <Text
                  color={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                  ml="xs"
                  size="xs"
                  component="span"
                >
                  {customRowCount ?? rowData.length}
                </Text>
              )}
            </Text>
            {loading && <LoadingIndicator size={'0.75rem'} />}
          </>
        }
        rightSection={rightSection}
        minHeight={30}
        defaultOpened={defaultOpened ?? !!rowData.length}
      >
        <Box>
          <LazyTable
            data={{
              rows: data,
              error: null,
              loading: loading ?? false,
              rowCount: rowData.length,
            }}
            table={{
              columns,
              hideHeader,
              hideTopBar: true,
              emptyRowsFallback: Empty,
              onRowClick,
            }}
            pagination={withPagination ? pagination : undefined}
            rowMenu={rowMenu}
            subRows={subRows}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
