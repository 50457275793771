import { day } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ExtendedCalendar, ExtendedCalendarProps } from '../../calendar';
import { FilterPopover, PopoverButton, PopoverHeader } from '../_components';
import { DateIcon } from '../_icons';

type DateFilterProps = {
  defaultButtonLabel: string;
  customButton?: (props: {
    buttonLabel: string;
    togglePopover: () => void;
    opened: boolean;
  }) => JSX.Element;
  type?: 'onBlur' | 'onChange';
};

export const DateFilter = ({
  defaultButtonLabel,
  filterValue: dateValue,
  setFilterValue: setDateValue,
  dateType,
  setDateType,
  customButton: CustomButton,
  type = 'onChange',
}: DateFilterProps & ExtendedCalendarProps) => {
  const [opened, handlers] = useDisclosure(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [temporaryValue, setTemporaryValue] =
    useState<ExtendedCalendarProps['filterValue']>(dateValue);
  const [temporaryType, setTemporaryType] =
    useState<ExtendedCalendarProps['dateType']>(dateType);

  const filterIsActive = !!(Array.isArray(dateValue) && dateValue[0]);

  useEffect(() => {
    if (filterIsActive) {
      const first = day(dateValue[0]);
      const second = dateValue[1] && day(dateValue[1]);
      const firstDateFormat =
        first.year() === second?.year() ? 'MMM DD' : 'MMM DD YYYY';
      const firstDate = first.format(firstDateFormat);
      const secondDate = second?.format('MMM DD YYYY');
      setButtonLabel(
        `${firstDate}${[secondDate && ' - ', secondDate]
          .filter(Boolean)
          .join('')}`
      );
    } else {
      setButtonLabel('');
    }
  }, [dateValue]);

  const onClosePopover = () => {
    handlers.close();

    if (type === 'onBlur') {
      setDateValue(temporaryValue);
      if (temporaryType) setDateType?.(temporaryType);
    }
  };

  const resetFilter = () => {
    handlers.close();
    setDateValue(undefined);
    if (setDateType) {
      setDateType('all');
    }
  };

  return (
    <FilterPopover
      opened={opened}
      onClose={onClosePopover}
      target={
        CustomButton ? (
          <Box>
            <CustomButton
              buttonLabel={buttonLabel || defaultButtonLabel}
              togglePopover={handlers.toggle}
              opened={opened}
            />
          </Box>
        ) : (
          <PopoverButton
            onClick={handlers.toggle}
            title={buttonLabel || defaultButtonLabel}
            Icon={DateIcon}
            isActive={filterIsActive}
          />
        )
      }
    >
      <PopoverHeader
        title={buttonLabel || defaultButtonLabel}
        handleFilterReset={resetFilter}
      />

      <ExtendedCalendar
        setFilterValue={type === 'onChange' ? setDateValue : setTemporaryValue}
        dateType={type === 'onChange' ? dateType : temporaryType}
        filterValue={type === 'onChange' ? dateValue : temporaryValue}
        setDateType={
          type === 'onChange'
            ? setDateType
            : setDateType
              ? setTemporaryType
              : undefined
        }
      />
    </FilterPopover>
  );
};
