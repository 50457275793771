import { useQuery, useTeamId } from '@finalytic/data';
import { Select, SelectItem } from '@finalytic/ui';
import { Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

type Props = FormInputBaseType;

function useAutomations() {
  const [teamId] = useTeamId();
  const [_search, setSearch] = useState('');

  const { data, isLoading: loading } = useQuery(
    (q, { teamId }) => {
      return q
        .automations({
          where: {
            tenantId: { _eq: teamId },
            ttemplate: {
              visibility: {
                _eq: 'public',
              },
            },
          },
          order_by: [{ title: 'asc' }],
          limit: 50,
        })
        .map((source) => {
          const template = source?.ttemplate?.title;
          const description = source?.title;
          return {
            id: source.id,
            description: description || template,
          };
        });
    },
    {
      variables: {
        teamId,
      },
      skip: !teamId,
      queryKey: ['automations'],
    }
  );

  const sources = useMemo<SelectItem[]>(
    () =>
      (data || []).map((source) => ({
        value: source.id || '',
        label: source.description || '',
      })),
    [data]
  );

  console.log(data, sources);

  return {
    sources,
    loading,
    setSearch,
  };
}

function useSingleAutomation(sourceId: string | undefined) {
  const { data, isLoading: loading } = useQuery(
    (q, { sourceId }) => {
      const source = q.automation({
        id: sourceId,
      });
      const template = source?.ttemplate?.title;
      const description = source?.title;
      return {
        id: source?.id,
        description: description || template,
      };
    },
    {
      variables: {
        sourceId,
      },
      skip: !sourceId,
      queryKey: 'sources',
    }
  );

  return {
    source: data,
    loading,
  };
}

export const SelectAutomation = ({
  label,
  defaultValue,
  formKey,
  placeholder,
  required,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  const { loading, setSearch, sources } = useAutomations();

  return (
    <>
      {label && (
        <Text size="sm" fw={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          const { source, loading: loadingSingleAutomation } =
            useSingleAutomation(value);

          const selectValue = useMemo(
            () =>
              source?.id
                ? {
                    label: source.description as string,
                    value: source.id as string,
                  }
                : undefined,
            [source?.id]
          );

          const extendedOnChange = (val: string | undefined) => {
            onValueChange?.(formKey, val);
            onChange(val);
          };

          return (
            <Select
              data={sources}
              withSearch
              loading={loading}
              value={selectValue}
              setValue={(item) => extendedOnChange(item?.value)}
              removeValue={() => extendedOnChange('')}
              popoverWidth="target"
              onSearchInput={setSearch}
              withBorder
              placeholder={placeholder}
              searchPlaceholder={placeholder}
              inputLoading={loadingSingleAutomation}
            />
          );
        }}
      />
    </>
  );
};
