import { InputSelect, InputWrapper } from '@finalytic/components';
import { day } from '@finalytic/utils';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

export const MonthSelect = ({
  label,
  formKey,
  defaultValue,
  onValueChange,
}: FormInputBaseType) => {
  const { control, setValue } = useFormContext();

  const previousYearMonths = 15 - day().get('month') + 1;

  const dates =
    Array(12 + previousYearMonths)
      .fill(new Date())
      .map((i: Date, index) => {
        const d = day(i).add(6 - index, 'months');
        return {
          label: d.format('MMMM YYYY'),
          value: `${d.format('YYYY-MM')}-01`,
          group: `${d.format('YYYY')}`, // Add space at the front to prevent incorrect group sorting
        };
      }) || [];

  useEffect(() => {
    setValue(formKey, defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={formKey}
      defaultValue={defaultValue || day().format('YYYY-MM-01')}
      rules={{ required: true }}
      render={({ field }) => {
        const value = field.value ? formatValue(field.value) : null;

        return (
          <InputWrapper label={label}>
            <InputSelect
              data={{
                options: dates,
                sort: null,
              }}
              value={value}
              type="single"
              setValue={(newValue) => {
                if (!newValue?.value) return;
                onValueChange?.(formKey, newValue.value);
                field.onChange(newValue.value);
              }}
              dropdownProps={{
                withinPortal: true,
                width: 'target',
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const formatValue = (v: string) => ({
  label: day(v).format('MMMM YYYY'),
  value: `${day(v).format('YYYY-MM')}-01`,
  group: `${day(v).format('YYYY')}`, // Add space at the front to prevent incorrect group sorting
});
