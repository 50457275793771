import {
  useEnabledFeatures,
  useInvalidateQueries,
  useTrpcMutation,
} from '@finalytic/data';
import { StringParam, useColors, useQueryParamSet } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import {
  IconDefinition,
  faArchive,
  faPaperPlane,
  faUserMinus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@mantine/core';
import { useClipboard, useDebouncedValue } from '@mantine/hooks';
import { formatUserName } from '@vrplatform/ui-common';
import { PropsWithChildren } from 'react';
import { ConfirmModal, SubmitConfirmModalType } from '../../../modal';
import { InviteOwnerModal } from '../../../modal';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  ownerName: string;
  ownerId: string;
  teamId: string;
  refetch: () => void;
}

interface ArchiveOwnerModalProps extends ModalProps {
  isArchived: boolean;
}

export const OwnerEllipsisMenuModals = ({
  deleteModal,
  owner: initial,
  archiveModal,
  inviteModal,
  revokeAccessModal,
  copyInviteModal,
  refetch: re,
}: {
  owner: {
    id: Maybe<string>;
    email: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    teamId: Maybe<string>;
    isReinvite: boolean;
    isArchived: boolean;
  } | null;
  refetch: () => void;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  archiveModal: {
    opened: boolean;
    closeModal: () => void;
  };
  revokeAccessModal: {
    opened: boolean;
    closeModal: () => void;
  };
  inviteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  copyInviteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const invalidate = useInvalidateQueries(['owners']);
  const owner = initial || debounced;

  if (!owner) return null;

  const formattedName = formatUserName(owner);
  const { isReinvite, isArchived, id: ownerId, teamId, email } = owner;
  if (!ownerId || !teamId || !email) return null;

  const refetch = () => {
    re();
    invalidate();
  };

  return (
    <>
      <InviteOwnerModal
        email={email}
        name={formattedName}
        isReinvite={isReinvite}
        refetchTable={refetch}
        opened={inviteModal.opened}
        closeModal={inviteModal.closeModal}
      />

      <ArchiveOwnerModal
        closeModal={archiveModal.closeModal}
        opened={archiveModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
        isArchived={isArchived}
      />

      <RevokeOwnerAccessModal
        closeModal={revokeAccessModal.closeModal}
        opened={revokeAccessModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />

      <DeleteOwnerModal
        closeModal={deleteModal.closeModal}
        opened={deleteModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />
      <CopyOwnerInvitationModal
        closeModal={copyInviteModal.closeModal}
        opened={copyInviteModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />
    </>
  );
};

const ArchiveOwnerModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
  refetch,
  isArchived,
}: ArchiveOwnerModalProps) => {
  const { mutate: archiveOwner } = useTrpcMutation('archiveOwner', {
    successMessage: {
      message: 'Owner archived successfully',
      title: 'Success!',
    },
  });

  const { mutate: enableOwner } = useTrpcMutation('enableOwner', {
    successMessage: {
      message: 'Owner unarchived successfully',
      title: 'Success!',
    },
  });

  const submitArchiveOwner: SubmitConfirmModalType = async () => {
    try {
      if (isArchived) {
        await enableOwner({ ownerId, tenantId: teamId });
        refetch();
        return {
          isSuccess: true,
        };
      } else {
        const result = await archiveOwner({ ownerId, tenantId: teamId });
        refetch();
        return {
          isSuccess: !!result.updatedTenantUserStatus,
        };
      }
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          `We couldn't ${
            isArchived ? 'unarchive' : 'archive'
          } this owner. Please try again later.`,
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={submitArchiveOwner}
      title={isArchived ? 'Unarchive owner' : 'Archive owner'}
      submitButtonTitle={isArchived ? 'Unarchive owner' : 'Archive owner'}
      icon={faArchive}
    >
      {isArchived ? (
        <>
          <Text component="p" fw={500}>
            Are you sure you want to unarchive {ownerName || 'this owner'}?
          </Text>
          <Text component="p">
            You will be able to assign listings to this owner again.
          </Text>
        </>
      ) : (
        <>
          <Text component="p" fw={500}>
            Are you sure you want to archive {ownerName || 'this owner'}?
          </Text>
          <Text component="p">
            This will hide the owner behind an "archived" filter and not remove
            the owner from the team. The current listing ownerships will be
            removed, but the owner will retain access to the owner portal to
            view statements.
          </Text>
        </>
      )}
    </BaseModal>
  );
};

const CopyOwnerInvitationModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
}: ModalProps) => {
  const { copy } = useClipboard();
  const { mutate: mutateInvitationUrl } = useTrpcMutation('copyInvitationUrl', {
    successMessage: {
      title: 'Copied to clipboard!',
      message: 'Successfully received invitation url.',
    },
  });

  if (!ownerId || !teamId) return null;

  const receiveInvitationUrl = async () => {
    try {
      const res = await mutateInvitationUrl({
        teamId,
        userId: ownerId,
      });

      if (res.signedInvitationUrl) {
        copy(res.signedInvitationUrl);
      }
      return {
        isSuccess: !!res.signedInvitationUrl,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage: error?.message || 'Failed to copy invite link.',
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={receiveInvitationUrl}
      title={'Copy invitation link'}
      submitButtonTitle="Submit"
      icon={faPaperPlane}
    >
      <Text component="p" fw={500}>
        Would you like to copy the invite link for {ownerName || 'this owner'}?
      </Text>
      <Text component="p">
        Invitations are valid once and expire after 7 days.
      </Text>
    </BaseModal>
  );
};

const RevokeOwnerAccessModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
  refetch,
}: ModalProps) => {
  const { mutate } = useTrpcMutation('revokeOwnerAccess', {
    successMessage: {
      message: 'Owner portal access was revoked successfully.',
      title: 'Success!',
    },
  });

  const revokeOwnerAccess: SubmitConfirmModalType = async () => {
    try {
      const result = await mutate({ ownerId, tenantId: teamId });
      refetch();

      return {
        isSuccess: !!result?.ok,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          "We couldn't archive this owner. Please try again later.",
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={revokeOwnerAccess}
      title="Revoke access"
      submitButtonTitle="Revoke access"
      icon={faUserMinus}
    >
      <Text component="p" fw={500}>
        Are you sure you want to revoke access for {ownerName || 'this owner'}?
      </Text>
      <Text component="p">
        The owner will continue to show in your dashboard, but they will no
        longer have access the owner portal.
      </Text>
    </BaseModal>
  );
};

const DeleteOwnerModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
  refetch,
}: ModalProps) => {
  const { NEW_OWNERS } = useEnabledFeatures();

  const setOwner = useQueryParamSet('owner', StringParam);
  const setOwnerUser = useQueryParamSet('owner-user', StringParam);

  const { mutate } = useTrpcMutation('deleteOwner', {
    successMessage: {
      title: 'Success!',
      message: (data) => {
        if (data.type === 'access-removed') {
          return `${toTitleCase(
            type
          )} was deleted from this team. But remains active in other teams.`;
        }

        return `${toTitleCase(type)} was deleted successfully.`;
      },
    },
  });

  const type = NEW_OWNERS ? 'user' : 'owner';

  const deleteOwner: SubmitConfirmModalType = async () => {
    try {
      const result = await mutate({
        ownerId,
        tenantId: teamId,
        v2Owners: NEW_OWNERS,
      });
      refetch();
      setOwner(undefined);
      setOwnerUser(undefined);

      return {
        isSuccess: !!result?.type,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          `We couldn't delete this ${type}. Please try again later.`,
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={deleteOwner}
      title={`Delete ${type}`}
      submitButtonTitle="Delete"
      icon={faArchive}
    >
      <Text component="p" fw={500}>
        Are you sure you want to delete {ownerName || `this ${type}`}?
      </Text>
      <Text component="p">
        The {type} will be entirely removed from your team. If the {type} is
        still active in other teams, they will keep their account and only lose
        access to your team. Otherwise, their account will be deleted.
      </Text>
    </BaseModal>
  );
};

type BaseProps = {
  opened: boolean;
  closeModal: () => void;
};

interface BaseModalProps extends BaseProps {
  icon: IconDefinition;
  onSubmit: SubmitConfirmModalType;
  title: string;
  submitButtonTitle: string;
}

const BaseModal = ({
  closeModal,
  icon,
  onSubmit,
  opened,
  children,
  submitButtonTitle,
  title,
}: PropsWithChildren<BaseModalProps>) => {
  const { gray } = useColors();

  return (
    <ConfirmModal
      opened={opened}
      size="md"
      onClose={closeModal}
      onSubmit={onSubmit}
      title={title}
      withCloseButton
      submitButtonTitle={submitButtonTitle}
      autoCloseOnSuccess
      icon={<FontAwesomeIcon icon={icon} size="3x" color={gray.dark} />}
      subtitle={children}
    />
  );
};
