import { ReactNode, createContext, useContext, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Automation, AutomationTemplate } from '../../hooks';

// ** Automation Setting
// 1. id
// 2. target => listing.id | owner.id etc.
// 3. group (non-nullable) => listing | owner | connection etc.
// 4. key (non-nullable) => SchemaKey
// 5. value (non-nullable) => undefined
// 6. sourceId => source.id
// 7. childSettings => [setting, setting, setting, etc.]

type Context = {
  automation: Automation;
  template: AutomationTemplate;
  refetchAutomation: () => void;
  hideMappingSettingModal?: boolean;
  forceGlobalMapping?: boolean; // overwrite for global mapping view
};

const EditAutomationContext = createContext<Context | null>(null);

export const useEditAutomationContext = () =>
  useContext(EditAutomationContext) as Context;

export const EditAutomationContextProvider = ({
  children,
  template,
  automation,
  refetchAutomation,
  hideMappingSettingModal,
  forceGlobalMapping,
}: {
  children: ReactNode;
  template: AutomationTemplate;
  automation: Automation;
  refetchAutomation: () => void;
  hideMappingSettingModal?: boolean;
  forceGlobalMapping?: boolean;
}) => {
  const methods = useForm({
    mode: 'onChange',
  });

  const val = useMemo<Context>(
    () => ({
      template,
      automation,
      refetchAutomation,
      hideMappingSettingModal,
      forceGlobalMapping,
    }),

    [
      template,
      automation,
      refetchAutomation,
      hideMappingSettingModal,
      forceGlobalMapping,
    ]
  );

  return (
    <FormProvider {...methods}>
      <EditAutomationContext.Provider value={val}>
        {children}
      </EditAutomationContext.Provider>
    </FormProvider>
  );
};
