import { useTeam } from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import {
  StringParam,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { Maybe, day } from '@finalytic/utils';
import { useMemo } from 'react';

export function useStatementRunDrawer() {
  const [queryDate, setQueryDate] = useQueryParam('date', StringParam);

  const { openDrawer } = useRunDrawer();
  const [{ automations }] = useTeam();

  const { statementAutomations, statementBillAutomations } = useMemo(() => {
    const statementAutomations = (automations || [])
      ?.filter((x) =>
        x.template.uniqueRef?.toLowerCase().endsWith('ownerstatements')
      )
      .map((a) => ({ automationId: a.automationId, status: a.status }));

    const statementBillAutomations = (automations || [])
      ?.filter((x) =>
        x.template.uniqueRef?.toLowerCase().endsWith('ownerstatementbills')
      )
      .map((a) => ({ automationId: a.automationId, status: a.status }));

    return {
      statementAutomations,
      statementBillAutomations,
    };
  }, [automations]);

  return {
    statementAutomations,
    statementBillAutomations,
    open: (date?: string) => {
      if (!queryDate) {
        setQueryDate(date || day().format('YYYY-MM-01'));
      }

      const automationId = getStatementAutomationId(statementAutomations);

      // Error message is thrown in helper function
      if (!automationId) return;

      openDrawer(automationId);
    },
  };
}

export function getStatementAutomationId(
  statementAutomations: {
    automationId: string;
    status: Maybe<string>;
  }[]
) {
  if (!statementAutomations?.length)
    return showErrorNotification({
      title: 'Missing owner statement automation',
      message:
        'Please create an owner statement automation to generate statements.',
    });

  const activeStatementAutomations = statementAutomations.filter(
    ({ status }) => status === 'active'
  );

  if (activeStatementAutomations.length > 1) {
    return showErrorNotification({
      title: 'Multiple active owner statement automations',
      message:
        'Please deactivate all but one owner statement automation to generate statements.',
    });
  }

  return (
    activeStatementAutomations[0]?.automationId ||
    statementAutomations[0]?.automationId
  );
}
