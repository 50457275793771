import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithRef, forwardRef } from 'react';
import { useAppName } from '../../../styles';

type Props = {
  component?: any;
  href?: string;
  target?: string;
  to?: string;
} & ButtonProps &
  ComponentPropsWithRef<'button'>;

export const ActionButton = forwardRef<HTMLButtonElement | null, Props>(
  ({ children, onClick, color, ...props }, ref) => {
    const { themeName } = useAppName();

    const buttonThemColor =
      themeName === 'vrplatform' ? color || 'vrplatform' : color || 'purple';
    return (
      <Button
        ref={ref}
        onClick={onClick}
        radius={10}
        px={10}
        color={buttonThemColor}
        variant="filled"
        {...props}
      >
        {children}
      </Button>
    );
  }
);

ActionButton.displayName = 'Action Button';
