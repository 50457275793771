import { Button } from '@finalytic/components';
import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import { HomeIcon, ShareIcon, UserIcon } from '@finalytic/icons';
import { Card, useColors } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Anchor,
  Center,
  Group,
  List,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import {
  formatOwnerName,
  formatUserName,
  whereConnectionStatusDefault,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type CardType = 'listings' | 'owners' | 'connections';

type Props = {
  type: CardType;
};

export const ListCard = ({ type }: Props) => {
  const [teamId] = useTeamId();
  const { NEW_OWNERS } = useEnabledFeatures();

  const { gray, themeColors } = useColors();
  const { colorScheme } = useMantineColorScheme();

  const {
    data,
    error,
    isLoading: loading,
  } = useQuery(
    (q, args) => {
      const limit = 10;

      if (args.type === 'listings') {
        return q
          .listings({
            where: { tenantId: { _eq: args.teamId } },
            order_by: [{ createdAt: 'asc_nulls_last' }],
            limit,
          })
          .map((listing) => ({
            id: listing.id,
            name: listing.title || listing.name,
          }));
      } else if (args.type === 'owners') {
        if (args.NEW_OWNERS) {
          return q
            .owners({
              where: {
                tenantId: { _eq: args.teamId },
              },
              order_by: [
                {
                  name: 'asc_nulls_last',
                },
              ],
              limit,
            })
            .map((owner) => ({
              id: owner.id,
              name: formatOwnerName(owner, { lastNameFirst: true }),
            }));
        }

        return q
          .tenantUser({
            where: { tenantId: { _eq: args.teamId }, role: { _eq: 'owner' } },
            order_by: [
              {
                user: {
                  createdAt: 'asc_nulls_last',
                },
              },
            ],
            limit,
          })
          .map((tenantUser) => ({
            id: tenantUser.user.id,
            name: formatUserName(tenantUser.user, { lastNameFirst: true }),
          }));
      } else if (args.type === 'connections') {
        return q
          .connection({
            where: {
              tenantId: { _eq: args.teamId },
              appId: { _neq: 'finalytic' },
              status: whereConnectionStatusDefault,
            },
            order_by: [
              {
                createdAt: 'asc_nulls_last',
              },
            ],
            limit,
          })
          .map((connection) => ({
            id: connection.id,
            name: connection.name,
          }));
      } else return [];
    },
    {
      variables: {
        type,
        teamId,
        NEW_OWNERS,
      },
    }
  );

  const emptyViewTexts = useMemo(() => {
    const texts: Record<
      CardType,
      { title: string; subtitle: string; href: string; buttonText: string }
    > = {
      connections: {
        href: '/connections?add-connection=1',
        title: 'No connections yet',
        subtitle: 'Add a connection to get started.',
        buttonText: 'Add connection',
      },
      listings: {
        href: '/listings',
        title: 'No listings yet',
        subtitle: 'Fetch your PMS from connections view to get started.',
        buttonText: 'Fetch from PMS',
      },
      owners: {
        href: '/owners',
        title: 'No owners yet',
        subtitle: 'Add owners to assign them listings...',
        buttonText: 'Add owner',
      },
    };

    return texts[type];
  }, [type]);

  return (
    <Card
      error={error}
      loading={loading}
      p={0}
      bg={!loading && !data?.length ? themeColors.gray[0] : undefined}
      h={350}
    >
      {(data?.length || 0) > 0 ? (
        <>
          <Group justify="space-between" px="md" pt="md" mb="md">
            <Text
              size="lg"
              component="span"
              color={colorScheme === 'dark' ? gray.light : gray.dark}
            >
              {toTitleCase(type)}
            </Text>
            <Anchor component={Link} to={`/${type.toLowerCase()}`}>
              View all
            </Anchor>
          </Group>

          <List
            spacing={7}
            styles={(theme) => {
              return {
                root: {
                  overflowY: 'scroll',
                  paddingBottom: 9,
                },
                item: {
                  borderTop: '1px solid',
                  borderTopColor: theme.colors.gray[1],
                  paddingTop: 10,
                  paddingInline: theme.spacing.sm,
                  color: colorScheme === 'dark' ? gray.light : gray.dark,
                },
              };
            }}
          >
            {data?.map((item) => (
              <List.Item
                key={item.id}
                icon={
                  <Center h={25} w={25}>
                    <ItemIcon type={type} />
                  </Center>
                }
              >
                {item.name}
              </List.Item>
            ))}
          </List>
        </>
      ) : (
        <Center sx={{ flexDirection: 'column', height: '100%' }}>
          <ItemIcon type={type} size={30} />
          <Text
            c={gray.dark}
            fw="bold"
            mt="sm"
            mb="xs"
            size="md"
            ta="center"
            px="xs"
          >
            {emptyViewTexts.title}
          </Text>
          <Text color={gray.dark} size="sm" mb="sm" ta="center" px="xs">
            {emptyViewTexts.subtitle}
          </Text>
          <Button
            component={Link}
            to={emptyViewTexts.href}
            variant="light"
            leftIcon={'PlusIcon'}
          >
            {emptyViewTexts.buttonText}
          </Button>
        </Center>
      )}
    </Card>
  );
};

const ItemIcon = ({ type, size = 18 }: { type: CardType; size?: number }) => {
  const { gray } = useColors();

  switch (type) {
    case 'connections':
      return <ShareIcon size={size} color={gray.dark} />;
    case 'listings':
      return <HomeIcon size={size} color={gray.dark} />;

    case 'owners':
      return <UserIcon size={size} color={gray.dark} />;
    default:
      return null;
  }
};
