import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { OfficeIcon, UserIcon } from '@finalytic/icons';
import {
  ArrayParam,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { hasValue, toTitleCase } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import {
  formatOwnerName,
  formatUserName,
  orderByOwner,
  whereOwnersV2,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { AutomationFilter } from '../../../components';
import { useGenericTableStore } from '../../../stores';

export const useListingFilter = () => {
  const [queryFilter, setQueryFilter] = useQueryParams({
    search: StringParam,
    status: ArrayParam,
    collectionId: StringParam,
    ownerIds: ArrayParam,
    automationIds: ArrayParam,
  });

  const filterType = useGenericTableStore((s) => s.filterType);
  const stateFilter = useGenericTableStore((s) => s.filter);
  const resetSelection = useGenericTableStore((s) => s.resetSelection);
  const setStateFilter = useGenericTableStore((s) => s.setFilter);

  const filter = useMemo(() => {
    if (filterType === 'query-params') {
      return queryFilter;
    }
    return stateFilter as typeof queryFilter;
  }, [filterType, stateFilter, queryFilter]);

  return {
    filter,
    setFilter: (filter: Partial<typeof stateFilter>) => {
      if (filterType === 'query-params') {
        setQueryFilter(filter);
      } else {
        setStateFilter(filter);
      }
      resetSelection();
    },
    reset: () => {
      const initial = {
        search: undefined,
        status: undefined,
        ownerIds: undefined,
        automationIds: undefined,
        collectionId: undefined,
      };

      setStateFilter(initial);
      setQueryFilter(initial);
      resetSelection();
    },
  };
};

export const ListingFilter = ({
  hide,
}: {
  hide?: ('status' | 'owner' | 'automation' | 'collection')[];
}) => {
  const [dashboard] = useDashboard();

  return (
    <Group>
      <SearchFilter />
      {dashboard !== 'owner' && (
        <>
          {!hide?.includes('status') && <StatusFilter />}
          {!hide?.includes('collection') && <CollectionFilter />}
          {!hide?.includes('owner') && <OwnerFilter />}
          {!hide?.includes('automation') && <AFilter />}
        </>
      )}
    </Group>
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = useListingFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const StatusFilter = () => {
  const { filter, setFilter } = useListingFilter();

  const value: SelectItem[] =
    filter?.status?.filter(hasValue).map((x) => ({
      label: x === 'inactive' ? 'Disabled' : toTitleCase(x)!,
      value: x,
    })) || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ status: v.map((i) => i.value) })}
      label="Status"
      type="multiple"
      data={{
        options: [
          {
            label: 'Disabled',
            value: 'inactive',
            icon: (
              <Box
                w={10}
                h={10}
                sx={(theme) => ({
                  alignSelf: 'center',
                  borderRadius: theme.radius.xl,
                  backgroundColor: theme.colors.yellow[6],
                })}
              />
            ),
          },
        ],
      }}
    />
  );
};

const OwnerFilter = () => {
  const [teamId] = useTeamId();
  const { NEW_OWNERS } = useEnabledFeatures();
  const { filter, setFilter } = useListingFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search, NEW_OWNERS }, { limit, offset }) => {
      if (NEW_OWNERS) {
        const where = whereOwnersV2({
          teamId,
          search,
        });

        const list = q
          .owners({
            limit,
            offset,
            order_by: [{ type: 'asc' }, orderByOwner],
            where,
          })
          .map<SelectItem>((owner) => ({
            label: formatOwnerName(owner),
            value: owner.id,
            group: owner.type === 'company' ? 'Company' : 'Individual',
            icon:
              owner.type === 'company' ? (
                <OfficeIcon size={16} />
              ) : (
                <UserIcon size={16} />
              ),
          }));

        const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where: gqlV2.user_bool_exp = {
        memberships: {
          tenantId: { _eq: teamId },
          role: { _eq: 'owner' },
        },
        ownerships: {
          listing: {
            tenantId: { _eq: teamId },
          },
        },
        _or: search
          ? [
              {
                firstName: { _ilike: `%${search}%` },
              },

              {
                lastName: { _ilike: `%${search}%` },
              },

              {
                companyName: { _ilike: `%${search}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .user({
          where,
          limit,
          offset,
          order_by: [
            {
              firstName: 'asc',
            },
          ],
        })
        .map<SelectItem>((user) => ({
          label: formatUserName(user),
          value: user.id,
        }));

      const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        NEW_OWNERS,
      },
    }
  );

  const { data } = useQuery(
    (q, { ownerIds, NEW_OWNERS }) => {
      if (NEW_OWNERS) {
        const value = q
          .owners({
            where: {
              id: { _in: ownerIds },
            },
          })
          .map<SelectItem>((owner) => ({
            label: formatOwnerName(owner),
            value: owner.id,
          }));

        return {
          value,
        };
      }

      const value = q
        .user({ where: { id: { _in: ownerIds } } })
        .map<SelectItem>((user) => ({
          label: formatUserName(user),
          value: user.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        ownerIds: filter.ownerIds || [],
        NEW_OWNERS,
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ ownerIds: v.map((i) => i.value) })}
      label="Owner"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const CollectionFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useListingFilter();

  const queryData = useQuery(
    (q, { teamId }) => {
      const where: gqlV2.listing_collection_bool_exp = {
        tenantId: { _eq: teamId },
        listings: {},
      };

      const list = q
        .listingCollections({
          where,
          order_by: [
            {
              name: 'asc',
            },
          ],
        })
        .map<SelectItem>((collection) => ({
          label: collection.name || 'No name',
          value: collection.id,
        }));

      return {
        list,
      };
    },
    {
      skip: !teamId,
      queryKey: 'listings',
      variables: {
        teamId,
      },
    }
  );

  const unmappedItem: SelectItem = {
    label: 'Ungrouped',
    value: 'null',
  };

  const value = useMemo(() => {
    if (filter.collectionId === 'null') return unmappedItem;

    return (
      queryData.data?.list.find((x) => x.value === filter.collectionId) || null
    );
  }, [queryData.data?.list, filter.collectionId]);

  return (
    <Filter.Select
      type="single"
      value={value}
      setValue={(v) => setFilter({ collectionId: v?.value })}
      label="Listing Group"
      withinPortal
      data={{
        options: queryData.data?.list || [],
        error: queryData.error,
        loading: queryData.isLoading,
      }}
      pinnedItems={[unmappedItem]}
    />
  );
};

const AFilter = () => {
  const { filter, setFilter } = useListingFilter();
  return (
    <AutomationFilter
      automationIds={filter.automationIds}
      setAutomationIds={(ids) => setFilter({ automationIds: ids })}
    />
  );
};
