import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  usePartnerTeams,
  useTeamId,
} from '@finalytic/data';
import { hasValue } from '@finalytic/utils';
import { whereListings } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useListingFilter } from './ListingFilter';

export function useWhereListings(opts: {
  filterByAutomationId: string | undefined;
}) {
  const { id: meId } = useMe();
  const { NEW_OWNERS } = useEnabledFeatures();

  const filter = useFormattedListingFilter();

  const filterByAutomationId = opts?.filterByAutomationId;

  return useMemo(() => {
    return whereListings({
      ...filter,
      v2Owners: NEW_OWNERS,
      ownerUserId: meId,
      disabledByAutomationId:
        filterByAutomationId || filter.disabledByAutomationId,
    });
  }, [filter, NEW_OWNERS, meId, filterByAutomationId]);
}

export function useFormattedListingFilter() {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const [teams] = usePartnerTeams();

  const { filter } = useListingFilter();

  return useMemo(
    () => ({
      currentTeamId: teamId,
      dashboard,
      partnerTeamIds: teams.map((i) => i.id),
      search: filter.search?.trim(),
      tenantId: undefined,
      status: filter.status?.[0] as any,
      ownerIds: filter.ownerIds,
      disabledByAutomationId: filter.automationIds?.filter(hasValue),
      collectionId: filter.collectionId,
    }),
    [filter, teams, teamId, dashboard]
  );
}
