import { Button, useColors } from '@finalytic/ui';
import { Stack, Text } from '@mantine/core';
import { useConnectionModalContext } from '../_context';
import { ConnectionProgressBar } from '../components';
import { ConnectingContainer } from '../components/ConnectingContainer';

export const ConnectionLoadingModalScreen = ({ text }: { text?: string }) => {
  const { app, resetView } = useConnectionModalContext();
  const { gray } = useColors();

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        <>
          <Text size="md">Connecting to {app?.name}</Text>
          <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            {text || 'Authenticating ...'}
          </Text>
          <Text size="sm" color={gray.dark}>
            May take a few minutes
          </Text>
        </>
      }
    >
      <Stack
        gap={35}
        mt={20}
        sx={{
          width: '100%',
          maxWidth: '380px',
        }}
      >
        {/* Loading Bar */}
        <ConnectionProgressBar />

        <Stack sx={{ width: 144, margin: '0 auto' }}>
          <Button onClick={resetView}>Cancel</Button>
        </Stack>
      </Stack>
    </ConnectingContainer>
  );
};
