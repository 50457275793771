import { useEnabledFeatures, useQuery, useTrpcMutation } from '@finalytic/data';
import {
  ArchiveIcon,
  CopyIcon,
  CrossIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  Link2Icon,
  RefreshCwIcon,
} from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showSuccessNotification,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  faArchive,
  faCabinetFiling,
  faUserMinus,
} from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mantine/core';
import { deleteCheckOwner } from '@vrplatform/ui-common';
import { HiddenFeatureIndicator } from '../../../indicators';
import { useOwnerHasPortalAccess } from './useOwnerHasPortalAccess';

type Props = {
  owner: {
    id: Maybe<string>;
    teamId: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    email: Maybe<string>;
  };
  isReinvite: boolean;
  isArchived: boolean;
  refetch: () => void;
};

export const OwnerEllipsisMenuItems = ({
  isArchived,
  isReinvite,
  owner: { id: ownerId, teamId },
  handlers: {
    setArchiveOpen,
    setDeleteOpen,
    setInviteOpen,
    setRevokeAccessOpen,
    setCopyInviteOpen,
  },
}: Props & {
  handlers: {
    setInviteOpen: () => void;
    setCopyInviteOpen: () => void;
    setDeleteOpen: () => void;
    setArchiveOpen: null | (() => void);
    setRevokeAccessOpen: () => void;
  };
}) => {
  const { NEW_OWNERS } = useEnabledFeatures();
  const setView = useQueryParamsSet({
    view: StringParam,
    owner: StringParam,
  });

  if (!ownerId || !teamId) return null;

  return (
    <>
      <EllipsisMenuItem
        onClick={() =>
          NEW_OWNERS
            ? setView({
                view: 'edit',
              })
            : setView({
                view: 'edit',
                owner: ownerId,
              })
        }
        customIcon={<Edit3Icon size={18} />}
      >
        Edit {NEW_OWNERS ? 'user' : 'owner'}
      </EllipsisMenuItem>
      <EllipsisMenuDivider />

      <OwnerImpersonationEllipsisItem ownerId={ownerId} teamId={teamId} />

      <HiddenFeatureIndicator permission="partner-admin">
        <OwnerRevokeAccessMenuButton
          openModal={() => setRevokeAccessOpen()}
          ownerId={ownerId}
          teamId={teamId}
        />
      </HiddenFeatureIndicator>

      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={() => setInviteOpen()}
        customIcon={
          isReinvite ? <RefreshCwIcon size={18} /> : <EmailIcon size={18} />
        }
      >
        {isReinvite ? 'Resend email invitation' : 'Email invite to app'}
      </EllipsisMenuItem>

      {isReinvite && (
        <EllipsisMenuItem
          onClick={setCopyInviteOpen}
          customIcon={<Link2Icon size={18} />}
        >
          Copy invitation url
        </EllipsisMenuItem>
      )}

      <EllipsisMenuDivider />

      {setArchiveOpen && (
        <EllipsisMenuItem
          icon={isArchived ? faCabinetFiling : faArchive}
          customIcon={
            isArchived ? <CrossIcon size={18} /> : <ArchiveIcon size={16} />
          }
          onClick={() => setArchiveOpen()}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </EllipsisMenuItem>
      )}

      <OwnerDeleteMenuButton
        openDeleteModal={() => setDeleteOpen()}
        ownerId={ownerId}
        teamId={teamId}
      />
      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuItem
          customIcon={<CopyIcon size={18} />}
          disabled={!ownerId}
          onClick={() =>
            navigator.clipboard.writeText(`${ownerId}`).then(() =>
              showSuccessNotification({
                message: 'The ID was added to your clipboard.',
              })
            )
          }
        >
          Copy user ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

type BaseMenuButtonProps = { ownerId: string; teamId: string };

interface OwnerRevokeAccessMenuButtonProps extends BaseMenuButtonProps {
  openModal: () => void;
}
const OwnerRevokeAccessMenuButton = ({
  openModal,
  ownerId,
  teamId,
}: OwnerRevokeAccessMenuButtonProps) => {
  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    ownerId,
    teamId,
  });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label="Owner does not currently have access to your team."
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          onClick={openModal}
          icon={faUserMinus}
          loading={loadingQuery}
          disabled={!hasAccess}
        >
          Revoke Portal Access
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};

const OwnerDeleteMenuButton = ({
  ownerId,
  openDeleteModal,
  teamId,
}: BaseMenuButtonProps & { openDeleteModal: () => void }) => {
  const { NEW_OWNERS } = useEnabledFeatures();

  const { data, isLoading: loading } = useQuery(
    (q, { ownerId, tenantId }) => deleteCheckOwner(q, { ownerId, tenantId }),
    {
      queryKey: 'owners',
      skip: NEW_OWNERS,
      variables: {
        ownerId,
        tenantId: teamId,
      },
    }
  );

  const canBeDeleted = NEW_OWNERS ? true : data?.canDelete;

  return (
    <Tooltip
      disabled={canBeDeleted || loading}
      label="This owner cannot be deleted with existing owner statements."
      withArrow
    >
      <Box>
        <EllipsisMenuDangerItem
          onClick={openDeleteModal}
          loading={loading}
          disabled={!canBeDeleted}
        >
          Delete
        </EllipsisMenuDangerItem>
      </Box>
    </Tooltip>
  );
};

const OwnerImpersonationEllipsisItem = ({
  ownerId,
  teamId,
}: BaseMenuButtonProps) => {
  const { NEW_OWNERS } = useEnabledFeatures();

  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    ownerId,
    teamId,
  });

  const { mutate, loading } = useTrpcMutation('impersonateUser', {
    successMessage: {
      title: 'Owner Preview',
      message: 'Successfully created owner preview.',
    },
  });

  const createPreviewV2 = () =>
    mutate({ userId: ownerId, tenantId: teamId }).then((res) => {
      console.log(res);
      window.open(res.url);
    });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label="Owner has not been invited to the owner portal."
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          customIcon={<EyeIcon size={18} />}
          onClick={createPreviewV2}
          loading={loading || loadingQuery}
          disabled={!hasAccess}
        >
          {NEW_OWNERS ? 'User' : 'Owner'} Impersonation
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};
