import { useAuth } from '@finalytic/authentication';
import { Button } from '@finalytic/components';
import { useDashboard } from '@finalytic/data';
import { Logo, Modal, StringParam, useQueryParam } from '@finalytic/ui';
import { Box, Center, Text, Title, rem } from '@mantine/core';

function useIsOwnerInviteLoggedInModalOpen() {
  const { actor } = useAuth();
  const [dashboard] = useDashboard();

  const [invite, setInvite] = useQueryParam('invite', StringParam);

  const opened = !actor?.sub && dashboard === 'owner' && !!invite;

  return {
    opened,
    close: () => setInvite(undefined, 'replaceIn'),
  };
}

export const OwnerInviteLoggedInModal = () => {
  const { opened, close } = useIsOwnerInviteLoggedInModalOpen();

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      size={500}
      styles={{
        body: {
          paddingBlock: rem(40),
          paddingInline: rem(40),
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(48),
        }}
      >
        <Center>
          <Logo width={40} />
        </Center>
        <Box>
          <Title ta="center" size={rem(20)} mb={rem(24)}>
            You are already logged in
          </Title>
          <Text c="neutral" ta="center" size="sm">
            Were you looking to accept an invitation to join a team? Please sign
            out first to accept the invitation.
            <br />
            <br />
            If you are trying to reset your password, please go into your
            settings or use the "Forgot password" link on the sign-in page.
          </Text>
        </Box>

        <Button variant="primary" onClick={close}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
