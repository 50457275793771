import { Text, TextProps } from '@mantine/core';
import { StatusPillType } from './_types';

type StatusPillProps = {
  type: StatusPillType;
  label: string;
  textSize?: TextProps['size'];
};

export const StatusPill = ({
  type,
  label,
  textSize = 'xs',
}: StatusPillProps) => {
  if (!type) return null;

  let styles: { backgroundColor: string; color: string } | undefined;

  if (type === 'green')
    styles = { backgroundColor: '#D4F7DC', color: '#15692A' };

  if (type === 'red') styles = { backgroundColor: '#FFE0ED', color: '#9F4167' };

  if (type === 'yellow')
    styles = { backgroundColor: '#FFEBCC', color: '#804C00' };

  return (
    <Text
      component="span"
      sx={{ ...styles, borderRadius: 6, transform: 'capitalize' }}
      px={6}
      py={2}
      size={textSize}
      fw={500}
    >
      {label}
    </Text>
  );
};
