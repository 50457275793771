import { audit_log } from '@finalytic/graphql';
import { ActivityRow } from './_types';

export const ACTIVITY_START_DATE = '2024-05-01';

export const getActivityActor = (log: audit_log): ActivityRow['actor'] => {
  const actor: ActivityRow['actor'] = {
    id: log.actorUserId,
    email: log.actorUser?.email || '',
    firstName: log.actorUser?.firstName || '',
    lastName: log.actorUser?.lastName || '',
    avatar: undefined,
  };

  if (actor.id) return actor;

  return null;
};
