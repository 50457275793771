import {
  useBilling,
  useDashboard,
  useEnabledFeatures,
  useMe,
  useQuery,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  ApiTokensIcon,
  AutomationsIcon,
  BillingIcon,
  BracketsIcon,
  DashboardIcon,
  FileTextIcon,
  FingerprintIcon,
  MembersIcon,
  NotificationIcon,
  OwnerPortalIcon,
  OwnerStatementsIcon,
  RouteIcon,
  UserIcon,
} from '@finalytic/icons';
import { Center, Stack } from '@mantine/core';
import { useNavbarExpanded } from '../hooks';
import { NavbarRoute } from '../layout/navbar';
import { NavigationSection } from './NavigationSection';

export const SettingsNavigation = () => {
  const { isDesktopExpanded: navbarIsExpanded } = useNavbarExpanded();

  const [dashboard] = useDashboard();
  const { isTeamAdmin } = useTeamRole();
  const { showBilling } = useBilling();
  const { BILLING, GL } = useEnabledFeatures();

  const isPmDashboard = dashboard === 'propertyManager';
  const isOwnerPortal = dashboard === 'owner';

  return (
    <Stack gap="xl">
      <NavigationSection title={isOwnerPortal ? '' : 'User Account'}>
        <NavbarRoute
          title="Profile"
          link="/settings/profile"
          icon={<UserIcon size={20} />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        />

        <NavbarRoute
          title="Security"
          link="/settings/security"
          icon={<FingerprintIcon size={20} />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        />

        {isOwnerPortal && (
          <OwnerSettingsNavigation navbarIsExpanded={navbarIsExpanded} />
        )}
      </NavigationSection>

      {!isOwnerPortal && dashboard !== 'overview' && (
        <>
          <NavigationSection title="Team">
            <NavbarRoute
              title="General"
              link="/settings/team/general"
              icon={<DashboardIcon size={20} />}
              navbarIsExpanded={navbarIsExpanded}
              v2Icon
            />

            {isTeamAdmin && (
              <NavbarRoute
                title="Security"
                link="/settings/team/security"
                icon={<FingerprintIcon size={20} />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
            )}

            {isPmDashboard && (
              <NavbarRoute
                title="Members"
                link="/settings/team/members"
                icon={<MembersIcon />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
            )}
            {BILLING && showBilling && isTeamAdmin && (
              <BillingNavItems navbarIsExpanded={navbarIsExpanded} />
            )}
            <HiddenFeatureIndicator permission="super-admin">
              <NavbarRoute
                title="Automations"
                link="/settings/team/automations"
                icon={<AutomationsIcon size={20} />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
            </HiddenFeatureIndicator>
          </NavigationSection>

          {isPmDashboard && (
            <NavigationSection title="Integrations">
              <NavbarRoute
                title="Api tokens"
                link="/settings/integrations/api-tokens"
                icon={<ApiTokensIcon />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
              <NavbarRoute
                title="Webhooks"
                link="/settings/integrations/webhooks"
                icon={<BracketsIcon size={18} />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
              {GL && (
                <NavbarRoute
                  title="Accounts"
                  link="/settings/integrations/accounts"
                  icon={<RouteIcon size={18} />}
                  navbarIsExpanded={navbarIsExpanded}
                  v2Icon
                />
              )}
            </NavigationSection>
          )}

          {isPmDashboard && (
            <NavigationSection title="Features">
              <NavbarRoute
                title="Owner Portal"
                link="/settings/features/owner-portal"
                icon={<OwnerPortalIcon />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />

              <HiddenFeatureIndicator permission="super-admin">
                <NavbarRoute
                  title="Owner Statements"
                  link="/settings/features/owner-statements"
                  icon={<OwnerStatementsIcon />}
                  navbarIsExpanded={navbarIsExpanded}
                  v2Icon
                />
              </HiddenFeatureIndicator>
            </NavigationSection>
          )}
        </>
      )}
    </Stack>
  );
};

const OwnerSettingsNavigation = ({
  navbarIsExpanded,
}: { navbarIsExpanded: boolean }) => {
  const { NEW_OWNERS } = useEnabledFeatures();
  const { id: meId } = useMe();

  const { data } = useQuery(
    (q, args) => {
      return !!q
        .ownerUserAccessAggregate({
          where: {
            userId: { _eq: args.meId },
            role: { _eq: 'admin' },
          },
        })
        .aggregate?.count();
    },
    {
      keepPreviousData: true,
      variables: {
        meId,
      },
    }
  );

  return (
    <>
      <NavbarRoute
        title="Notifications"
        link="/settings/notifications"
        icon={
          <Center w={20}>
            <NotificationIcon size={16} />
          </Center>
        }
        navbarIsExpanded={navbarIsExpanded}
        v2Icon
      />

      {NEW_OWNERS && data && (
        <NavbarRoute
          title="Statement Details"
          link="/settings/details"
          icon={<FileTextIcon size={20} strokeWidth={1.2} />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        />
      )}
    </>
  );
};

const BillingNavItems = ({
  navbarIsExpanded,
}: { navbarIsExpanded: boolean }) => {
  // const [teamId] = useTeamId();

  // const { isTeamAdmin } = useTeamRole();
  // const { showBilling } = useBilling();

  // const data = useTrpcQuery(
  //   'getHyperlinePortal',
  //   {
  //     tenantId: teamId,
  //   },
  //   {
  //     skip: !showBilling || !isTeamAdmin,
  //   }
  // );

  // if (!data.data?.url) return null;

  // return (
  //   <NavbarRoute
  //     title="Billing"
  //     link={data.data.url}
  //     icon={<BillingIcon />}
  //     navbarIsExpanded={navbarIsExpanded}
  //     v2Icon
  //   />
  // );

  return (
    <NavbarRoute
      title="Billing"
      link={'settings/team/billing'}
      icon={<BillingIcon />}
      navbarIsExpanded={navbarIsExpanded}
      v2Icon
    />
  );
};
