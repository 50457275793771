import { Button, Filter } from '@finalytic/components';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  ArrowCornerCcwRbIcon,
  ArrowRightIcon,
  CopyIcon,
  PlusIcon,
} from '@finalytic/icons';
import {
  InfiniteTable,
  MRT_ColumnDef,
  MRT_DensityState,
  MRT_Row,
  MRT_SortingState,
} from '@finalytic/table';
import {
  EllipsisMenuItem,
  showSuccessNotification,
  showWarnNotification,
} from '@finalytic/ui';
import {
  Box,
  Center,
  Group,
  MantineFontSize,
  Stack,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useMemo, useState } from 'react';
import { AutomationMappingEditor } from '../../../../../../components';
import { AutomationOverrideModal } from '../../../../../../components/automation-mappings/AutomationOverrideModal';
import { LeftSideNameCell } from '../../_cells';
import { useEditAutomationContext } from '../../_context';
import { useAutomationChangeStore, useMapping } from '../../_hooks';
import { MappingRow } from '../_table-types';
import { ChildExceptionsManagerMenu } from './ChildExceptionsManagerModal';
import { MappingDatasourceParams, Setting } from './get-mapping-row-data';
import { useMappingTableQuery } from './useMappingTableQuery';

type MappingTableProps = MappingDatasourceParams;

type QueryMappingRow = NonNullable<
  ReturnType<typeof useMappingTableQuery>['data']
>['pages'][number]['list'][number];

export const MappingTable = memo(
  ({
    settingKey,
    mih = '90vh',
  }: MappingTableProps & {
    mih?: MantineFontSize;
  }) => {
    const { colorScheme } = useMantineColorScheme();

    const { automation } = useEditAutomationContext();

    const [sorting, setSorting] = useState<MRT_SortingState>([
      { id: 'name', desc: false },
    ]);

    const { mapping, leftLabel, rightLabel } = useMapping(settingKey);

    const [activeRowData, setActiveRowData] = useState<MappingRow | undefined>(
      undefined
    );

    const search = useAutomationChangeStore((state) => {
      const filter = state.filter;
      return (
        (filter[automation.id] && filter[automation.id][settingKey]?.search) ||
        ''
      );
    });
    const mappingFilter = useAutomationChangeStore((state) => {
      const filter = state.filter;
      return (
        filter[automation.id] && filter[automation.id][settingKey]?.mapping
      );
    });

    const resetFilter = useAutomationChangeStore((store) => store.resetFilter);

    const [opened, handlers] = useDisclosure(false);

    const queryData = useMappingTableQuery(settingKey, {
      sorting,
      filter: {
        search,
        mapping: mappingFilter,
      },
    });
    const { refetch } = queryData;

    const openLineExceptionModal = (data: MappingRow) => {
      setActiveRowData(data);
      handlers.open();
    };

    const columns = useMemo<MRT_ColumnDef<QueryMappingRow>[]>(
      () => [
        {
          header: leftLabel.label || '',
          accessorKey: 'name',
        },
        {
          header: rightLabel.label || '',
          accessorKey: 'settings',
          Cell: ({ row }) => {
            const { automation } = useEditAutomationContext();
            const { mappingScope, left, right, rightLabel } =
              useMapping(settingKey);

            const data = row.original;
            const setting = data.settings?.[0];

            return (
              <Box w="100%">
                <AutomationMappingEditor
                  automation={{
                    automationId: automation.id,
                    leftConnectionId: automation.leftConnectionId,
                    rightConnectionId: automation.rightConnectionId,
                  }}
                  targetId={row.original.id}
                  mapping={{
                    leftType: setting?.leftType || left,
                    rightType: setting?.rightType || right,
                    settingKey,
                    isLocal: mappingScope === 'local',
                    label: rightLabel.label,
                    altLeftTypes: mapping.left.alt,
                    exceptionFilters: mapping.params?.exceptionFilters,
                  }}
                  parentSetting={
                    setting?.parentSettingId
                      ? {
                          settingId: setting.parentSettingId,
                          value: '',
                          target: data.id,
                        }
                      : null
                  }
                  invalidateKeys={['settings']}
                />
              </Box>
            );
          },
        },
      ],
      [leftLabel, rightLabel, settingKey]
    );

    const { mappingScope, left, right, mappingTitleTo } =
      useMapping(settingKey);

    const handleModalOpen = (data: QueryMappingRow) => {
      const setting = data.settings?.[0];
      const disabled = !setting?.settingId;

      if (disabled)
        return showWarnNotification({
          icon: null,
          message: 'Setting ID not found',
        });

      const mappingRow: MappingRow = {
        leftType: setting?.leftType,
        rightType: setting?.rightType,
        childSettings: setting?.childSettings || [],
        name: data.name || '',
        target: data.id,
        parentSettingId: undefined,
        settingId: setting.settingId,
        value: setting.value,
      };

      openLineExceptionModal(mappingRow);
    };

    return (
      <Box
        mih={mih}
        sx={(theme) => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '.mantine-Table-tr-detail-panel': {
            backgroundColor:
              theme.colors.neutral[colorScheme === 'dark' ? 9 : 0],
            width: '100%',
            height: '100%',
            borderBottom: 'none!important',
            '& td': {
              borderBottom: 'none!important',
              paddingBlock: '0px!important',
              padding: 0,
              width: '100%',
              minWidth: '100%',
              display: 'grid',
            },
          },
        })}
      >
        <InfiniteTable
          queryData={queryData}
          table={{}}
          columns={columns}
          sorting={{
            sorting,
            setSorting,
          }}
          resetFilter={() => resetFilter(automation.id, settingKey)}
          rowMenu={{
            hideMenu: ({ row }) => {
              const setting = row.original.settings?.[0];
              return !setting?.settingId;
            },
            menuItems: ({ row }) => {
              const { black } = useMantineTheme();
              const data = row.original;

              const setting = data.settings?.[0];
              const disabled = !setting?.settingId;

              const copyId = () =>
                navigator.clipboard.writeText(setting.settingId).then(() =>
                  showSuccessNotification({
                    icon: null,
                    message: 'Setting ID copied to clipboard',
                  })
                );

              return (
                <>
                  {!!mapping.left.alt?.length && (
                    <EllipsisMenuItem
                      disabled={disabled}
                      onClick={() => handleModalOpen(data)}
                      customIcon={<PlusIcon color={black} />}
                    >
                      Add exception
                    </EllipsisMenuItem>
                  )}

                  <HiddenFeatureIndicator permission="super-admin">
                    <EllipsisMenuItem
                      disabled={!setting?.settingId}
                      onClick={copyId}
                      customIcon={<CopyIcon size={16} color={black} />}
                    >
                      Copy setting ID
                    </EllipsisMenuItem>
                  </HiddenFeatureIndicator>
                </>
              );
            },
          }}
          subRows={{
            defaultExpanded: true,
            getRowCanExpand: (row) =>
              !!row?.original?.settings?.[0]?.childSettings?.length,
            renderDetailPanel: ({ row, table }) => {
              const tableDensity = table.getState().density;

              return (
                <ChildRow
                  row={row as any}
                  settingKey={settingKey}
                  refetch={refetch}
                  tableDensity={tableDensity}
                  openExceptionModal={handleModalOpen}
                />
              );
            },
          }}
        >
          <Group justify="space-between" w="100%">
            <MappingFilter settingKey={settingKey} />
            <ChildExceptionsManagerMenu
              settingKey={settingKey}
              automation={automation}
              mapping={mapping}
            />
          </Group>
        </InfiniteTable>

        <AutomationOverrideModal
          opened={opened}
          closeModal={handlers.close}
          automation={{
            automationId: automation.id,
            leftConnectionId: automation.leftConnectionId,
            rightConnectionId: automation.rightConnectionId,
          }}
          parentMapping={{
            isLocal: mappingScope === 'local',
            leftType: left,
            rightType: right,
            settingKey: settingKey,
            label: leftLabel.label,
            altLeftTypes: mapping.left.alt || [],
            exceptionFilters: mapping.params?.exceptionFilters,
          }}
          modalTitle={`${mappingTitleTo}`}
          parentSetting={
            activeRowData?.settingId
              ? {
                  settingId: activeRowData?.settingId,
                  target: activeRowData.target,
                  value: activeRowData?.value || '',
                }
              : null
          }
          isDefaultExceptions={false}
          hideParentMapping
        />
      </Box>
    );
  }
);

const ChildRow = ({
  row,
  settingKey,
  refetch,
  tableDensity,
  openExceptionModal,
}: {
  row: MRT_Row<{
    id: any;
    name: string | undefined;
    settings: Setting[];
  }>;
  settingKey: string;
  refetch: () => void;
  tableDensity: MRT_DensityState;
  openExceptionModal: (data: QueryMappingRow) => void;
}) => {
  const childSettings = useMemo(
    () => row.original.settings?.[0]?.childSettings,
    [row.original.settings?.[0]?.childSettings]
  );

  if (!childSettings?.length) return null;

  const limit = 5;
  const additional = childSettings.length - limit;
  const hasMore = additional > 0;

  return (
    <Box
      p={tableDensity}
      sx={() => ({
        width: '100%',
        minWidth: '100%',
      })}
    >
      <Stack>
        {(hasMore ? childSettings.slice(0, 5) : childSettings).map(
          (setting) => {
            const { automation } = useEditAutomationContext();

            const { left, right, mappingScope, rightLabel, mapping } =
              useMapping(settingKey);

            return (
              <Group wrap="nowrap" key={setting.settingId}>
                <Box ml={8} sx={{ flexShrink: 0 }}>
                  <ArrowCornerCcwRbIcon />
                </Box>

                <Group justify="space-between" w="100%" ml="xl">
                  <LeftSideNameCell value={setting.target} data={setting} />
                  <ArrowRightIcon />
                </Group>
                <Box w="100%">
                  <AutomationMappingEditor
                    targetId={setting.target}
                    automation={{
                      automationId: automation.id,
                      leftConnectionId: automation.leftConnectionId,
                      rightConnectionId: automation.rightConnectionId,
                    }}
                    mapping={{
                      leftType: setting?.leftType || left,
                      rightType: setting?.rightType || right,
                      settingKey,
                      isLocal: mappingScope === 'local',
                      label: rightLabel.label,
                      altLeftTypes: mapping.left.alt,
                      exceptionFilters: mapping.params?.exceptionFilters,
                    }}
                    parentSetting={
                      setting.parentSettingId
                        ? {
                            settingId: setting.parentSettingId,
                            value: '',
                            target: row.original.id,
                          }
                        : null
                    }
                    onCompleted={refetch}
                  />
                </Box>
              </Group>
            );
          }
        )}

        {hasMore && (
          <Center py="sm">
            <Button
              onClick={() => openExceptionModal(row.original)}
              disabled={!row.original.settings?.[0]?.settingId}
              size="xs"
            >
              {`+${additional} more exception${additional > 1 ? 's' : ''}`}
            </Button>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

const MappingFilter = ({ settingKey }: { settingKey: string }) => {
  return (
    <Group>
      <SearchFilter settingKey={settingKey} />
      <MappedFilter settingKey={settingKey} />
    </Group>
  );
};

const SearchFilter = ({ settingKey }: { settingKey: string }) => {
  const { automation } = useEditAutomationContext();

  const search = useAutomationChangeStore((state) => {
    const filter = state.filter;
    return (
      (filter[automation.id] && filter[automation.id][settingKey]?.search) || ''
    );
  });
  const setFilter = useAutomationChangeStore((state) => state.setFilter);

  const setSearch = (val: string) =>
    setFilter(automation.id, settingKey, { search: val });

  return <Filter.Search value={search} setValue={setSearch} width={200} />;
};

const MappedFilter = ({ settingKey }: { settingKey: string }) => {
  const { automation } = useEditAutomationContext();

  const mapping = useAutomationChangeStore((state) => {
    const filter = state.filter;
    return filter[automation.id] && filter[automation.id][settingKey]?.mapping;
  });
  const setFilter = useAutomationChangeStore((state) => state.setFilter);

  const setMapping = (val: string | undefined) =>
    setFilter(automation.id, settingKey, {
      mapping: (val as any) || undefined,
    });

  return (
    <Filter.Select
      value={
        mapping
          ? {
              label: mapping === 'mapped' ? 'Mapped' : 'Unmapped',
              value: mapping,
            }
          : null
      }
      label="Mapping"
      type="single"
      setValue={(value) => setMapping(value?.value)}
      data={{
        options: [
          {
            label: 'Mapped',
            value: 'mapped',
          },
          {
            label: 'Unmapped',
            value: 'unmapped',
          },
        ],
      }}
    />
  );
};
