import { user_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';

export const ownersFilterInput = {
  teamId: z.string().optional().nullable(),
  search: z.string().optional().nullable(),
  status: z
    .array(z.enum(['inactive', 'active', 'unconfirmed', 'archived']))
    .optional()
    .nullable(),
};

const ownersFilter = z.object(ownersFilterInput);

export type OwnersFilterParams = z.infer<typeof ownersFilter>;
export type OwnerStatus = NonNullable<OwnersFilterParams['status']>[number];

type BaseWhere = {
  partnerTeamIds: string[];
  dashboard: Dashboard;
  currentTeamId: string;
  type?: 'owners' | 'companies' | null;
};

export function whereOwners({
  search: s,
  currentTeamId,
  dashboard,
  partnerTeamIds,
  teamId,
  status,
  type,
}: BaseWhere & OwnersFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<user_bool_exp[]>([
        { name: { _ilike: `%${search}%` } },
        { firstName: { _ilike: `%${search}%` } },
        { lastName: { _ilike: `%${search}%` } },
        { address: { _ilike: `%${search}%` } },
        { companyName: { _ilike: `%${search}%` } },
        { email: { _ilike: `%${search}%` } },
        { phone: { _ilike: `%${search}%` } },
        {
          ownerships: {
            listing: {
              name: { _ilike: `%${search}%` },
            },
          },
        },
        {
          ownerships: {
            listing: {
              address: { _ilike: `%${search}%` },
            },
          },
        },
      ])
    : undefined;

  const teamIdsFilter: string[] = teamId
    ? [teamId]
    : dashboard === 'partner'
      ? partnerTeamIds
      : [currentTeamId];

  return ensure<user_bool_exp>({
    _and: status?.length
      ? status.includes('archived')
        ? [
            {
              // archived
              _or: [
                { status: { _eq: 'archived' } },
                {
                  memberships: {
                    status: { _in: status },
                    tenantId: { _in: teamIdsFilter },
                  },
                },
              ],
            },
          ]
        : [
            // inactive OR unconfirmed OR active
            {
              status: { _neq: 'archived' },
              memberships: {
                status: { _in: status },
                tenantId: { _in: teamIdsFilter },
              },
            },
          ]
      : // no status filter
        [
          {
            status: { _neq: 'archived' },
            memberships: {
              status: { _neq: 'archived' },
              tenantId: { _in: teamIdsFilter },
            },
          },
        ],
    type: type
      ? { _eq: type === 'owners' ? 'owner' : 'company' }
      : { _in: ['owner', 'company'] },
    memberships: {
      tenantId: { _in: teamIdsFilter },
    },

    _or: searchQuery,
  });
}
