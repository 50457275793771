import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import { Query, listing_bool_exp } from '@finalytic/graphql';
import { Maybe, ensure, sortBy } from '@finalytic/utils';
import {
  getActionMessage,
  getListingAddress,
  getListingName,
  whereConnections,
  whereListings,
  whereSettingAcrossAutomations,
} from '@vrplatform/ui-common';
import { ReactNode } from 'react';
import { useListingClassMappingInfo } from '../hooks';
import { ConnectionNameCell } from '../views/connections/ConnnectionNameCell';
import { ListingNameCell } from '../views/listings/_components';

export type IssueFilter = {
  search: Maybe<string>;
  level?: Maybe<'error' | 'warning'>;
};

export type IssueRow = {
  id: string;
  date: string;
  type:
    | 'connection-error'
    | 'owner-statement-reservation'
    | 'listing-class-missing'
    | 'listing-owner-missing';
  table: 'connection' | 'owner-statement' | 'listing';
  level: 'error' | 'warning';
  message: string;
  item: ReactNode;
  itemName: string;
};

export function usePmIssuesAggregateQuery() {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const listingClassMapping = useListingClassMappingInfo();

  return useQuery((q, args) => getIssues(q, args, { limit: 0, offset: 0 }), {
    keepPreviousData: true,
    queryKey: ['issues', 'issueMessageOverwrites'],
    refetchOnWindowFocus: true,
    skip: !teamId,
    variables: ensure<Parameters<typeof getIssues>[1]>({
      teamId,
      GL,
      onlyAggregate: true,
      search: undefined,
      listingClassMapping,
    }),
  });
}

export function getIssues(
  q: Query,
  args: {
    teamId: string;
    GL: boolean;
    onlyAggregate: boolean;
    listingClassMapping: ReturnType<typeof useListingClassMappingInfo>;
  } & IssueFilter,
  opts: {
    limit: number;
    offset: number;
  }
): {
  aggregate: number;
  list: IssueRow[];
} {
  const limit = opts.limit;
  const offset = opts.offset;

  const overwrites = q
    .issueMessageOverwrites({
      order_by: [{ pattern: 'asc_nulls_last' }],
    })
    .map((o) => ({
      pattern: o.pattern || '',
      message: o.message || '',
    }));

  // things to check
  // 1. connections
  // 2. owner stateemtn => wrong reservation
  // 3. listings
  // 1. no owner assigned
  // 2. no class assigned (setting)

  const whereCon = whereConnections({
    teamId: args.teamId,
    status: 'error',
    currentTeamId: args.teamId,
    partnerTeamIds: [],
    dashboard: 'propertyManager',
  });

  const connections =
    args.onlyAggregate || args.level === 'warning'
      ? []
      : q
          .connection({
            where: whereCon,
            limit,
            offset,
            order_by: [{ updatedAt: 'desc' }],
          })
          .map<IssueRow>((connection) => {
            const lastFetch =
              connection
                .jobPlans({
                  where: {
                    jobs: {
                      kind: { _in: ['extract', 'extractLegacy'] },
                    },
                    isCurrentOnConnection: { _eq: true },
                  },
                  order_by: [{ createdAt: 'desc_nulls_last' }],
                })
                .map((plan) => ({
                  status: plan.status,
                  title: getActionMessage(plan.title || '', overwrites),
                  createdAt: plan.createdAt,
                }))[0] || null;

            return {
              id: connection.id,
              type: 'connection-error',
              table: 'connection',
              level: 'error',
              message: lastFetch.title || 'Missing error',
              date: lastFetch.createdAt,
              itemName: connection.name!,
              item: (
                <ConnectionNameCell
                  icon={connection?.app.iconRound}
                  name={connection.name}
                />
              ),
            };
          });

  const connectionAggrgate =
    args.level === 'warning'
      ? 0
      : q
          .connectionAggregate({
            where: whereCon,
          })
          .aggregate?.count() || 0;

  const whereMissingOwner: listing_bool_exp = {
    ...whereListings({
      currentTeamId: args.teamId,
      dashboard: 'propertyManager',
      partnerTeamIds: [],
      search: args.search,
      status: 'active',
    }),
    _not: args.GL
      ? {
          ownershipPeriods: {},
        }
      : {
          ownerships: {},
        },
  };

  const missingOwnerAggregate =
    args.level === 'error'
      ? 0
      : q
          .listingAggregate({
            where: whereMissingOwner,
          })
          .aggregate?.count() || 0;

  const missingOwners =
    args.onlyAggregate || args.level === 'error'
      ? []
      : q
          .listings({
            where: whereMissingOwner,
            limit,
            offset,
            order_by: [
              {
                createdAt: 'desc',
              },
            ],
          })
          .map<IssueRow>((listing) => {
            const lc = listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                iconRound: conn.connection.app.iconRound,
              }));

            const listingName = getListingName(listing);

            return {
              id: listing.id,
              type: 'listing-owner-missing',
              table: 'listing',
              level: 'warning',
              message: 'No ownership assigned',
              itemName: listingName,
              date: listing.createdAt,
              item: (
                <ListingNameCell
                  address={getListingAddress(listing).full}
                  connections={lc}
                  name={listingName}
                  variant="compact"
                />
              ),
            };
          });

  const whereMissingClass: listing_bool_exp = {
    ...whereListings({
      currentTeamId: args.teamId,
      dashboard: 'propertyManager',
      partnerTeamIds: [],
      search: args.search,
      status: 'active',
    }),
    _not: {
      settingsRight: {
        key: { _eq: args.listingClassMapping.mapping.key },
        _or: whereSettingAcrossAutomations({
          leftConnectionId:
            args.listingClassMapping.automation.finalyticConnectionId,
          leftSchema: args.listingClassMapping.mapping.leftType,
          rightSchema: args.listingClassMapping.mapping.rightType,
          rightConnectionId:
            args.listingClassMapping.automation.accountingConnectionId || '',
          automationId:
            args.listingClassMapping.automation.automation?.automationId,
        }),
      },
    },
  };

  const disableListingClassMapping =
    args.level === 'error' ||
    args.GL ||
    !args.listingClassMapping.automation.automation ||
    !args.listingClassMapping.accounting;

  const missingClassAggregate = disableListingClassMapping
    ? 0
    : q
        .listingAggregate({
          where: whereMissingClass,
        })
        .aggregate?.count() || 0;

  const missingClasses =
    args.onlyAggregate || disableListingClassMapping
      ? []
      : q
          .listings({
            where: whereMissingClass,
            limit,
            offset,
            order_by: [
              {
                createdAt: 'desc',
              },
            ],
          })
          .map<IssueRow>((listing) => {
            const lc = listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                iconRound: conn.connection.app.iconRound,
              }));

            const listingName = getListingName(listing);

            return {
              id: listing.id,
              type: 'listing-class-missing',
              table: 'listing',
              level: 'warning',
              message: 'No class assigned',
              itemName: listingName,
              date: listing.createdAt,
              item: (
                <ListingNameCell
                  address={getListingAddress(listing).full}
                  connections={lc}
                  name={listingName}
                  variant="compact"
                />
              ),
            };
          });

  const aggregate =
    connectionAggrgate + missingOwnerAggregate + missingClassAggregate;
  const list = sortBy(
    [...connections, ...missingOwners, ...missingClasses],
    'date',
    'desc'
  );

  return {
    aggregate,
    list,
  };
}
