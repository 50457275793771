import { Maybe } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { IconProps } from '../_types';

type TemplateType =
  | 'ownerStatements.ownerStatements'
  | 'ownerStatements.ownerStatementBills'
  | 'commission.managementCommission'
  | 'accounting.paymentJournalEntries'
  | 'accounting.reservationInvoices'
  | 'accounting.reservationPayments'
  | 'xero.passTheKeys';

export const AutomationIcon = ({
  templateType,
  ...props
}: { templateType: Maybe<TemplateType | (string & {})> } & IconProps) => {
  switch (templateType) {
    case 'ownerStatements.ownerStatements':
    case 'ownerStatements.ownerStatementBills':
      return <AutomationOwnerStatementsIcon {...props} />;
    case 'commission.managementCommission':
      return <AutomationManagementCommissionsIcon {...props} />;
    case 'accounting.paymentJournalEntries':
      return <AutomationPaymentJournalIcon {...props} />;
    case 'accounting.reservationInvoices':
      return <AutomationReservationInvoiceIcon {...props} />;
    case 'accounting.reservationPayments':
      return <AutomationReservationPaymentsIcon {...props} />;
    case 'xero.passTheKeys':
      return <AutomationReservationPaymentsIcon {...props} />;
    default:
      return null;
  }
};

const defaultColor = '#737373';

export const AutomationReservationInvoiceIcon = ({
  size = 20,
  color = defaultColor,
  strokeWidth = 1,
  ...props
}: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.33334 1.66683V2.13354C9.33334 3.25365 9.33334 3.8137 9.55132 4.24152C9.74307 4.61785 10.049 4.92381 10.4254 5.11556C10.8532 5.33354 11.4132 5.33354 12.5333 5.33354H13M9.33334 7.33354H7.66666C7.11437 7.33354 6.66665 7.78125 6.66665 8.33354C6.66665 8.88583 7.11437 9.33355 7.66666 9.33355H8.33333C8.88562 9.33355 9.33334 9.78127 9.33334 10.3336C9.33334 10.8858 8.88562 11.3336 8.33333 11.3336H6.66665M8 7.33354V6.66686M8 11.3336V12.0002M13.3334 5.99234V11.4669C13.3334 12.587 13.3334 13.1471 13.1154 13.5749C12.9236 13.9512 12.6177 14.2572 12.2413 14.4489C11.8135 14.6669 11.2535 14.6669 10.1334 14.6669H5.86662C4.74652 14.6669 4.18647 14.6669 3.75864 14.4489C3.38232 14.2572 3.07636 13.9512 2.88461 13.5749C2.66663 13.1471 2.66663 12.587 2.66663 11.4669V4.53349C2.66663 3.41339 2.66663 2.85334 2.88461 2.42552C3.07636 2.04919 3.38232 1.74323 3.75864 1.55148C4.18647 1.3335 4.74652 1.3335 5.86663 1.3335H8.67453C9.16371 1.3335 9.4083 1.3335 9.63847 1.38876C9.84254 1.43775 10.0376 1.51856 10.2166 1.62822C10.4184 1.7519 10.5914 1.92485 10.9373 2.27075L12.3961 3.72959C12.742 4.0755 12.915 4.24845 13.0386 4.45028C13.1483 4.62923 13.2291 4.82432 13.2781 5.02839C13.3334 5.25856 13.3334 5.50315 13.3334 5.99234Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

export const AutomationReservationPaymentsIcon = ({
  size = 24,
  color = defaultColor,
  strokeWidth = 1,
  ...props
}: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.00007 9.33355C6.00007 10.0699 6.59703 10.6669 7.33341 10.6669H8.83781C9.47973 10.6669 10.0001 10.1465 10.0001 9.50461C10.0001 9.00432 9.67997 8.56017 9.20536 8.40197L6.79481 7.59845C6.3202 7.44025 6.00007 6.99609 6.00007 6.49581C6.00007 5.8539 6.52044 5.33352 7.16236 5.33352H8.66676C9.40314 5.33352 10.0001 5.93048 10.0001 6.66686M8.00008 4.33352V11.6669M14.6668 8.00021V8.00021C14.6668 4.31828 11.682 1.3335 8.00008 1.3335V1.3335C4.31816 1.3335 1.33337 4.31828 1.33337 8.00021V8.00021C1.33337 11.6821 4.31816 14.6669 8.00008 14.6669V14.6669C11.682 14.6669 14.6668 11.6821 14.6668 8.00021Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

export const AutomationOwnerStatementsIcon = ({
  size = 24,
  color = defaultColor,
  strokeWidth = 1,
  ...props
}: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6667 2.00033V2.00033C11.2867 2.00033 11.5967 2.00033 11.851 2.06849C12.5412 2.25342 13.0803 2.79251 13.2652 3.48268C13.3334 3.73702 13.3334 4.04702 13.3334 4.66702V11.4671C13.3334 12.5872 13.3334 13.1472 13.1154 13.5751C12.9236 13.9514 12.6177 14.2574 12.2413 14.4491C11.8135 14.6671 11.2535 14.6671 10.1334 14.6671H5.86662C4.74652 14.6671 4.18647 14.6671 3.75864 14.4491C3.38232 14.2574 3.07636 13.9514 2.88461 13.5751C2.66663 13.1472 2.66663 12.5872 2.66663 11.4671V4.66702C2.66663 4.04702 2.66663 3.73702 2.73478 3.48268C2.91971 2.79251 3.4588 2.25342 4.14897 2.06849C4.40331 2.00033 4.71331 2.00033 5.33331 2.00033V2.00033M10.6667 2.00033V2.00033C10.6667 1.26395 10.0697 0.666992 9.33334 0.666992H6.66665C5.93027 0.666992 5.33331 1.26395 5.33331 2.00033V2.00033M10.6667 2.00033V2.00033C10.6667 2.73672 10.0697 3.33368 9.33334 3.33368H6.66665C5.93027 3.33368 5.33331 2.73672 5.33331 2.00033V2.00033M5.99998 7.3337H10M5.99998 10.0004H8.66667"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

export const AutomationPaymentJournalIcon = ({
  size = 24,
  color = defaultColor,
  strokeWidth = 1,
  ...props
}: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00008 12.0005C9.26013 11.1904 10.282 10.8397 11.4709 10.6879C12.7281 10.5273 13.3567 10.447 13.7295 10.2132C14.0873 9.98875 14.3048 9.74162 14.4821 9.35826C14.6668 8.95887 14.6668 8.42988 14.6668 7.3719V4.53374C14.6668 3.37293 14.6668 2.79253 14.4129 2.33796C14.207 1.96922 13.8048 1.61514 13.4129 1.45757C12.9298 1.26334 12.4435 1.32546 11.4709 1.4497C10.282 1.60156 9.26013 1.95229 8.00008 2.76232M8.00008 12.0005C6.74004 11.1904 5.71815 10.8397 4.5293 10.6879C3.27209 10.5273 2.64348 10.447 2.27071 10.2132C1.9129 9.98875 1.69532 9.74162 1.51806 9.35826C1.33337 8.95887 1.33337 8.42988 1.33337 7.37189V4.53374C1.33337 3.37293 1.33337 2.79253 1.58725 2.33796C1.7932 1.96922 2.19539 1.61514 2.58725 1.45757C3.07033 1.26334 3.55665 1.32546 4.52931 1.4497C5.71815 1.60156 6.74004 1.95229 8.00008 2.76232M8.00008 12.0005V2.76232M14.0001 13.0005C12.2287 13.0005 11.0456 13.1476 9.86464 13.6372C8.98634 14.0013 8.54719 14.1834 8.3132 14.2205C8.00203 14.2698 7.99814 14.2698 7.68697 14.2205C7.45298 14.1834 7.01383 14.0013 6.13553 13.6372C4.95456 13.1476 3.77149 13.0005 2.00005 13.0005"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

export const AutomationManagementCommissionsIcon = ({
  size = 24,
  color = defaultColor,
  strokeWidth = 1,
  ...props
}: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 4.6671L5.99999 11.3338M14.6668 8.00045C14.6668 11.6824 11.682 14.6672 8.00008 14.6672C4.31816 14.6672 1.33337 11.6824 1.33337 8.00045C1.33337 4.31853 4.31816 1.33374 8.00008 1.33374C11.682 1.33374 14.6668 4.31853 14.6668 8.00045ZM6.66666 6.33377C6.66666 6.88606 6.21894 7.33378 5.66665 7.33378C5.11437 7.33378 4.66665 6.88606 4.66665 6.33377C4.66665 5.78149 5.11437 5.33377 5.66665 5.33377C6.21894 5.33377 6.66666 5.78149 6.66666 6.33377ZM11.3334 9.66713C11.3334 10.2194 10.8856 10.6671 10.3334 10.6671C9.78106 10.6671 9.33335 10.2194 9.33335 9.66713C9.33335 9.11484 9.78106 8.66712 10.3334 8.66712C10.8856 8.66712 11.3334 9.11484 11.3334 9.66713Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);
