import { createUUID } from '@finalytic/data-ui';
import { ReactNode } from 'react';

import { ensure } from '@finalytic/utils';
import { NetRevenueColumn } from '@vrplatform/ui-common';
import { FormProvider, useForm } from 'react-hook-form';
import { StatementTemplateType } from './_types';

export const StatementSettingsContextProvider = ({
  children,
  template,
}: {
  children: ReactNode;
  template: StatementTemplateType;
}) => {
  const methods = useForm<StatementTemplateType>({
    defaultValues: template,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export const defaultNetRevenueTemplate = ensure<{
  title: string;
  columns: NetRevenueColumn[];
}>({
  title: 'Net Revenue Section',
  columns: [
    {
      id: createUUID(),
      name: 'Guest Name',
      type: 'field',
      value: 'reservation.guestName',
      visible: true,
      locked: true,
    },
    {
      id: createUUID(),
      name: 'Confirmation Code',
      type: 'field',
      value: 'reservation.confirmationCode',
      visible: true,
      locked: true,
    },
    {
      id: createUUID(),
      name: 'Reservation Dates',
      type: 'field',
      value: 'reservation.checkIn',
      visible: true,
    },

    {
      id: createUUID(),
      name: 'Total',
      type: 'field',
      value: 'total',
      visible: true,
    },
  ],
});
