import { CountBagde } from '@finalytic/components';
import { useDashboard, useQuery, useTeamId } from '@finalytic/data';
import { wherePartnerConnectionErrors } from './useWherePartnerConnectionErrors';

const usePartnerConnectionErrorsAggregateQuery = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  return useQuery(
    (q, { teamId, dashboard }) => {
      const where = wherePartnerConnectionErrors({
        appId: undefined,
        partnerId: teamId,
        search: undefined,
        tenantId: undefined,
        dashboard,
      });

      return q.connectionAggregate({ where }).aggregate?.count() || 0;
    },
    {
      queryKey: 'connections',
      variables: {
        teamId,
        dashboard,
      },
    }
  );
};

export const PartnerConnectionErrorAggregateBadge = () => {
  const { data, isInitialLoading } = usePartnerConnectionErrorsAggregateQuery();

  return <CountBagde count={data || 0} loading={isInitialLoading} />;
};
