import { Filter, UserAvatar } from '@finalytic/components';
import { SearchFilter } from '@finalytic/components/src/filter/SearchFilter';
import { gqlV2, useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { IconButton, SelectItem } from '@finalytic/ui';
import { Maybe, day } from '@finalytic/utils';
import { Box, Center, Divider, Group, Text, Tooltip } from '@mantine/core';
import { formatUserName } from '@vrplatform/ui-common';
import { ComponentProps, ReactNode, useMemo, useState } from 'react';
import { ActivityIcon } from './ActivityIcon';
import { ActivityDetailPanel } from './_components';
import { ACTIVITY_START_DATE } from './_queries';
import { ActivityOperation, ActivityPanelProps, ActivityRow } from './_types';

type Props = {
  data: ComponentProps<typeof InfiniteTable<ActivityRow>>['queryData'];
  setFilter: (
    filter: Partial<{
      search: string;
      actorUserId: Maybe<string>;
      op: Maybe<ActivityOperation>;
    }>
  ) => void;
  children?: ReactNode;
  renderDetailPanel?: (props: ActivityPanelProps) => {
    rows: { label: ReactNode; date: string }[];
    loading: boolean;
    error: Error | null;
  };
};

export const ActivityTable = ({
  data,
  children,
  setFilter,
  renderDetailPanel,
}: Props) => {
  const columns = useMemo<MRT_ColumnDef<ActivityRow>[]>(
    () => [
      {
        header: 'Icon',
        maxSize: 15,
        Cell: ({ row }) => {
          return (
            <Box pos="relative">
              <UserAvatar size={33} user={row.original.actor} v2VrpIcon />
              <ActivityIcon icon={row.original.icon} />
            </Box>
          );
        },
      },
      {
        header: 'Message',
        Cell: ({ row }) => {
          const data = row.original;
          const isSystem = !row.original.actor?.id;

          const name = isSystem ? 'System' : formatUserName(data.actor);
          const diff = (day(data.date) as any)?.fromNow?.();

          const canExpand = !!row.original.detailIds?.length;
          const isExpanded = row.getIsExpanded();

          const disableDateTooltip = (data.detailIds?.length || 1) > 1;

          return (
            <Group justify="space-between" wrap="nowrap" w="100%">
              <Box>
                <Text component="span" display="block" color="gray" size="xs">
                  {name}・
                  <Tooltip
                    label={day(data.date).format('MMM D, YYYY - h:mm A')}
                    withArrow
                    withinPortal
                    disabled={disableDateTooltip}
                  >
                    <Text component="span">{diff}</Text>
                  </Tooltip>
                </Text>
                {data.label}
              </Box>
              {canExpand && (
                <IconButton
                  sx={{
                    transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                    transition: 'transform 0.2s',
                  }}
                >
                  <Icon icon="ChevronIcon" size={16} />
                </IconButton>
              )}
            </Group>
          );
        },
      },
    ],
    []
  );

  const pageData = useMemo(
    () =>
      data?.data?.pages.map((page) => {
        page.list = page.list.filter((x) => x.label);
        return page;
      }),
    [data.data?.pages]
  );

  return (
    <InfiniteTable
      queryData={{ ...data, data: { pages: pageData || [] } }}
      columns={columns}
      table={{
        hideHeader: true,
        hidePagination: true,
        hideSettings: true,
        hideExpandColumn: true,
        onRowClick: {
          disabled: (row) => !row.getCanExpand(),
          handler: (row) => row.toggleExpanded(),
        },
        emptyRowsFallback: () => (
          <Center
            mt="lg"
            sx={{
              flexDirection: 'column',
            }}
          >
            <Icon
              icon="HistoryIcon"
              size={20}
              color={({ colors }) => colors.gray[5]}
            />

            <Text c="neutral" mt="sm" fw={500}>
              No activity
            </Text>
            <Text c="gray" mt="xs" size="xs">
              We couldn't find any activity since{' '}
              {day(ACTIVITY_START_DATE).format('MMM D, YYYY')}.
            </Text>
          </Center>
        ),
      }}
      resetFilter={() => {}}
      subRows={{
        defaultExpanded: false,
        getRowCanExpand: (row) => !!row.original.detailIds?.length,
        renderDetailPanel: ({ row }) => {
          if (!renderDetailPanel) return null;

          return (
            <ActivityDetailPanel
              renderDetailPanel={() =>
                renderDetailPanel({
                  isExpanded: row.getIsExpanded(),
                  row: row.original,
                })
              }
            />
          );
        },
      }}
    >
      <Group>
        <SearchFilter
          value=""
          setValue={(search) =>
            setFilter({
              search,
            })
          }
        />
        <ActorUserFilter
          setActorUserId={(v) =>
            setFilter({
              actorUserId: v,
            })
          }
        />
        <OperationFilter setOperation={(o) => setFilter({ op: o })} />
        {children && <Divider orientation="vertical" />}
        {children}
      </Group>
    </InfiniteTable>
  );
};

const ActorUserFilter = ({
  setActorUserId,
}: { setActorUserId: (id: Maybe<string>) => void }) => {
  const [teamId] = useTeamId();
  const [actorUserId, setTemp] = useState<Maybe<string>>(undefined);

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.user_bool_exp = {
        memberships: {
          tenantId: { _eq: teamId },
          role: { _neq: 'owner' },
          status: { _eq: 'active' },
        },
        _or: search
          ? [
              { firstName: { _ilike: `%${search}%` } },
              { lastName: { _ilike: `%${search}%` } },
              { email: { _ilike: `%${search}%` } },
            ]
          : undefined,
      };

      const list = q
        .user({
          where,
          order_by: [{ lastName: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: formatUserName(res),
        }));

      const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { actorUserId }) => {
      if (!actorUserId) return null;

      const value =
        q
          .user({ where: { id: { _eq: actorUserId } } })
          .map<SelectItem>((item) => ({
            label: formatUserName(item),
            value: item.id,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        actorUserId,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => {
        setTemp(v?.value);
        setActorUserId(v?.value);
      }}
      label="User"
      type="single"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const OperationFilter = ({
  setOperation,
}: { setOperation: (op: Maybe<ActivityOperation>) => void }) => {
  const [v, setTemp] = useState<Maybe<string>>(null);

  const options = useMemo<SelectItem<ActivityOperation>[]>(
    () => [
      {
        value: 'insert',
        label: 'Created',
      },
      {
        value: 'update',
        label: 'Updated',
      },
      {
        value: 'delete',
        label: 'Deleted',
      },
    ],
    []
  );

  const value = options.find((o) => o.value === v) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => {
        setTemp(v?.value);
        setOperation(v?.value);
      }}
      label="Action"
      type="single"
      withinPortal
      data={{
        options,
      }}
    />
  );
};
