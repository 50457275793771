import { useTrpcQuery } from '@finalytic/data';

export function useOwnerHasPortalAccess({
  ownerId,
  teamId,
}: { ownerId: string; teamId: string }) {
  const { data, loading: loadingQuery } = useTrpcQuery(
    'fetchClerkUser',
    {
      teamId,
      userId: ownerId,
    },
    {
      skip: !ownerId || !teamId,
    }
  );

  const hasAccess = !!data?.publicMetadata?.allowed_ids?.includes(teamId);

  return {
    hasAccess,
    loadingQuery,
  };
}
