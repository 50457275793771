import { ArrayParam, useQueryParam } from '@finalytic/ui';
import {
  Drawer,
  DrawerHeader,
  LoadingIndicator,
  TableContainer,
} from '@finalytic/ui';
import { useStatementsList } from '../../../hooks';
import { StatementListTable } from '../../tables';

export const StatementSummaryDetailDrawer = () => {
  const [summaryStatements, setSummaryStatements] = useQueryParam(
    'summaryStatements',
    ArrayParam
  );

  const ids = (summaryStatements || []).filter((i) => !!i) as string[];

  const { ownerStatements: statements, refetch } = useStatementsList({
    filter: { statementIds: ids },
    billingLimit: undefined,
  });

  const closeDrawer = () => setSummaryStatements([]);

  return (
    <Drawer
      opened={!!summaryStatements?.length}
      onClose={closeDrawer}
      size={1100}
    >
      <DrawerHeader
        title="Statements included in summary"
        onClose={closeDrawer}
      />

      {/* Modal Header */}
      {!statements?.length ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <TableContainer pb="sm">
          <StatementListTable
            rowData={statements}
            refetch={refetch}
            selectable={false}
          />
        </TableContainer>
      )}
    </Drawer>
  );
};
