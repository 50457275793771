import {
  useEnabledFeatures,
  useInfiniteQuery,
  useTeamRole,
} from '@finalytic/data';
import { MRT_SortingState } from '@finalytic/table';
import { hasValue } from '@finalytic/utils';
import { useEditAutomationContext } from '../../_context';
import { useMapping } from '../../_hooks';
import { getMappingRowData } from './get-mapping-row-data';

export function useMappingTableQuery(
  settingKey: string,
  {
    // pagination,
    sorting,
    filter: { search, mapping: mappingFilter },
    isModalQuery,
  }: {
    sorting: MRT_SortingState;
    filter: {
      search: string;
      mapping: 'mapped' | 'unmapped' | undefined;
    };
    isModalQuery?: boolean;
  }
) {
  const { NEW_OWNERS } = useEnabledFeatures();
  const { automation } = useEditAutomationContext();
  const { isVrpAdmin } = useTeamRole();

  const { mappingScope, defaultLeftType, leftTypeOverwrite, mapping } =
    useMapping(settingKey);
  const isLocal = mappingScope === 'local';

  const params = mapping.params;
  const leftSchema = leftTypeOverwrite || defaultLeftType;
  const leftParams = leftTypeOverwrite ? {} : mapping.left.params || params;
  const rightSchema = mapping.right.schema;

  return useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const rows = getMappingRowData(q, {
        search: args.filter.search,
        mapping: args.filter.mapping,
        ...args.args,
        limit,
        offset,
        v2Owners: args.args.NEW_OWNERS,
        isVrpAdmin: args.args.isVrpAdmin,
        connections: automation.connections,
      });

      return {
        list: rows.list,
        aggregate: rows.aggregate || 0,
      };
    },
    {
      queryKey: ['settings', isModalQuery ? 'modal' : undefined].filter(
        hasValue
      ),
      variables: {
        sorting,
        filter: {
          search: search?.trim(),
          mapping: mappingFilter,
        },
        args: {
          automationId: automation.id,
          isLocal,
          leftParams,
          leftSchema,
          rightSchema,
          partnerId: automation.partnerId,
          teamId: automation.tenantId,
          settingKey,
          leftConnectionId: automation.leftConnectionId,
          rightConnectionId: automation.rightConnectionId,
          NEW_OWNERS,
          isVrpAdmin,
        },
      },
    }
  );
}
