import { AlertTriangleIcon } from '@finalytic/icons';
import {
  Card,
  Center,
  Group,
  Text,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  rows: LineProps[];
};

export const DrawerInfoCard = ({ rows }: Props) => {
  const { colorScheme } = useMantineColorScheme();

  if (rows.every((row) => !row.text)) return null;

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.neutral[1],
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
        flexShrink: 0,
      })}
      radius="md"
      mb="sm"
    >
      {rows.map((row, index) => (
        <Line key={index} {...row} />
      ))}
    </Card>
  );
};

type LineProps = {
  icon: typeof AlertTriangleIcon;
  title: string;
  text: ReactNode;
};

const Line = ({ text, icon: Icon, title }: LineProps) => {
  const { colorScheme } = useMantineColorScheme();

  if (!text) return null;

  return (
    <Group wrap="nowrap" gap="xs">
      <Center pb={2} sx={{ flexShrink: 0, width: 20 }}>
        <Icon size={18} />
      </Center>
      <Text
        component="span"
        sx={{ flex: 0.3 }}
        w={80}
        size="sm"
        color={colorScheme === 'dark' ? 'dimmed' : 'neutral'}
      >
        {title}
      </Text>
      <Text size="sm" component="span" sx={{ flex: 1 }}>
        {text}
      </Text>
    </Group>
  );
};
