import {
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  gqlV2,
  useInfiniteQuery,
  useInvalidateQueries,
  useQuery,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import {
  Edit3Icon,
  EyeIcon,
  Icon,
  TrashIcon,
  UserIcon,
} from '@finalytic/icons';
import {
  AvatarGroup,
  BooleanParam,
  Drawer,
  IconButton,
  Logo,
  SelectItem,
  showSuccessNotification,
  useAppName,
  useQueryParam,
} from '@finalytic/ui';
import { ensure, hasValue } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { formatOwnerName, whereOwnersV2 } from '@vrplatform/ui-common';
import { useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { DrawerHeader, DrawerSectionTitle } from '../_components';

export function useInviteOnwerUserDrawer() {
  const [opened, setOpened] = useQueryParam('invite-owner-user', BooleanParam);

  return {
    opened: !!opened,
    open: () => setOpened(true),
    close: () => setOpened(false),
  };
}

export const InviteOwnerUserDrawer = () => {
  const [{ id: teamId, partnerId }] = useTeam();
  const { opened, close } = useInviteOnwerUserDrawer();
  const { themeName } = useAppName();

  const { mutate, loading } = useTrpcMutation('addOwner');
  const invalidate = useInvalidateQueries(['owners']);

  const [view, setView] = useState<
    | { key: 'form'; data: null }
    | { key: 'owner-exists'; data: FormInputs }
    | { key: 'pm-exists'; data: null }
  >({ data: null, key: 'form' });

  const submit = async (data: FormInputs, force?: boolean) => {
    const result = await mutate({
      force: !!force,
      teamId,
      owner: {
        email: data.email.toLowerCase().trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        role: 'owner',
        ownerAccesses: data.ownerAccess
          .filter((x) => x.ownerId)
          .map(({ ownerId, role }) => ({
            ownerId: ownerId!,
            role,
          })),
        teamId,
        sendInvite: true,
        partnerId,
      },
    });

    if (result.status === 'owner-exists') {
      setView({
        data,
        key: 'owner-exists',
      });
    } else if (result.status === 'pm-exists') {
      setView({
        data: null,
        key: 'pm-exists',
      });
    } else {
      // all went well
      close();
      invalidate();
      showSuccessNotification({
        title: 'Success!',
        message: 'User was successfully added.',
      });
      // if (result.owner?.id) setOwner(result.owner?.id);
    }
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      containerSx={{
        position: 'relative',
      }}
    >
      {view.key === 'form' && (
        <>
          <DrawerHeader title={'Invite user'} closeDrawer={close} />
          <OwnerUserEditForm
            onReset={close}
            submitButtonLabel={'Invite user'}
            handleSubmit={submit}
            initialValues={undefined}
          />
        </>
      )}

      {view.key === 'owner-exists' && (
        <Center
          py="xl"
          px="md"
          sx={{ flexDirection: 'column', minHeight: '95%' }}
        >
          <AvatarGroup
            leftIcon={<Logo width={80} />}
            leftBgColor={themeName === 'vrplatform' ? 'white' : undefined}
            rightIcon={<UserIcon size={48} strokeWidth={1} />}
            rightBgColor="white"
          />
          <Text component="h4" size="xl" my="lg" ta="center" fw={500}>
            User already exists
          </Text>
          <Text component="p" mt={0} mb={32} c={'neutral'} ta="center">
            This user already exists. Adding the user will not update their
            current details like fist name etc., but will give them access to
            the owners you have selected. Would you like to continue?
          </Text>

          <Button
            variant="primary"
            onClick={() => submit(view.data, true)}
            sx={{ width: '100%', marginBottom: rem(15) }}
          >
            Invite person
          </Button>

          <Button
            variant="light"
            leftIcon={'ArrowLeftIcon'}
            onClick={() =>
              setView({
                data: null,
                key: 'form',
              })
            }
            sx={{ width: '100%' }}
          >
            Cancel
          </Button>
        </Center>
      )}
      {view.key === 'pm-exists' && (
        <Center mih="100%" py="xl" px="md" sx={{ flexDirection: 'column' }}>
          <AvatarGroup
            leftIcon={<Logo width={80} />}
            leftBgColor={themeName === 'vrplatform' ? 'white' : undefined}
            rightIcon={<UserIcon size={48} strokeWidth={1} />}
            rightBgColor="white"
          />
          <Text component="h4" size="xl" my="lg" fw={500} ta="center">
            PM user already exists
          </Text>
          <Text component="p" mt={0} mb={32} ta="center" c="neutral">
            We weren't able to add this email address with owner access. The
            user already exists as a property manager team member.
          </Text>

          <Button
            leftIcon={'ArrowLeftIcon'}
            onClick={() =>
              setView({
                data: null,
                key: 'form',
              })
            }
          >
            Cancel
          </Button>
        </Center>
      )}

      <LoadingOverlay visible={loading} />
    </Drawer>
  );
};

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  ownerAccess: {
    ownerId: string | null;
    role: gqlV2.owner_user_access_role_enum;
  }[];
  // address: Parameters<typeof formatAddress>[0];
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

export const OwnerUserEditForm = ({
  handleSubmit,
  initialValues,
  onReset,
  submitButtonLabel,
  isEmailDisabled,
  isEdit,
}: {
  handleSubmit: (values: FormInputs) => Promise<void>;
  onReset: () => void;
  initialValues: FormInputs | undefined;
  submitButtonLabel: string;
  isEmailDisabled?: boolean;
  isEdit?: boolean;
}) => {
  const methods = useForm<FormInputs>({
    values: initialValues || {
      email: '',
      firstName: '',
      lastName: '',
      ownerAccess: [
        {
          ownerId: null,
          role: 'admin',
        },
      ],
    },
  });

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
        onSubmit={methods.handleSubmit(handleSubmit)}
        onReset={onReset}
      >
        <Box>
          <DrawerSectionTitle mt={0}>User Details</DrawerSectionTitle>
          <Stack>
            <Box
              sx={(theme) => ({
                alignItems: 'flex-start',
                justifyContent: 'stretch',
                display: 'flex',
                gap: theme.spacing.md,
                '> div': {
                  flex: 1,
                },
              })}
            >
              <Controller
                control={methods.control}
                name="firstName"
                defaultValue=""
                rules={{
                  required: 'First name is required',
                  validate: trapSpacesForRequiredFields,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper
                    label="First name"
                    error={error?.message}
                    required
                  >
                    <Input
                      {...field}
                      placeholder="First name"
                      autoComplete="off"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                control={methods.control}
                name="lastName"
                defaultValue=""
                rules={{
                  required: 'Last name is required',
                  validate: trapSpacesForRequiredFields,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper
                    label="Last name"
                    error={error?.message}
                    required
                  >
                    <Input
                      {...field}
                      placeholder="Last name"
                      autoComplete="off"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />
            </Box>

            <Controller
              control={methods.control}
              name="email"
              defaultValue=""
              rules={{
                required: 'Email is required',
                validate: trapSpacesForRequiredFields,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <InputWrapper label="Email" error={error?.message} required>
                  <Input
                    {...field}
                    placeholder="Email"
                    autoComplete="off"
                    type="email"
                    error={!!error}
                    disabled={isEmailDisabled}
                  />
                </InputWrapper>
              )}
            />
          </Stack>

          {!isEdit && <OwnerSelectSection />}
        </Box>

        <Group
          mt="md"
          sx={(theme) => ({
            position: 'sticky',
            bottom: 0,
            height: 60,
            left: 0,
            right: 0,
            paddingBottom: theme.spacing.md,
            backgroundColor: theme.white,
            borderTop: `1px solid ${theme.colors.gray[2]}`,
            paddingTop: theme.spacing.sm,
            paddingInline: theme.spacing.xs,
            marginRight: `-${theme.spacing.xs}`,
            marginLeft: `-${theme.spacing.xs}`,
          })}
        >
          <Button
            type="reset"
            onClick={close}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            sx={{ flexGrow: '1!important' as any }}
            type="submit"
            disabled={!methods.formState.isDirty}
            loading={methods.formState.isSubmitting}
            variant="primary"
          >
            {submitButtonLabel}
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};

const OwnerSelectSection = () => {
  const { control, watch } = useFormContext<FormInputs>();
  const { primaryColor } = useMantineTheme();
  const [teamId] = useTeamId();

  const aggregate = useQuery(
    (q, { teamId }) => {
      const where = whereOwnersV2({
        teamId,
        search: undefined,
      });

      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

      return {
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );

  const rows = watch('ownerAccess');

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'ownerAccess',
    rules: {
      minLength: 1,
      required: true,
    },
  });

  const hasNoOwners = aggregate.data?.aggregate === 0;

  return (
    <Box>
      <DrawerSectionTitle>Owner Access</DrawerSectionTitle>
      <Stack gap="xs">
        {fields.map((access, index) => {
          return (
            <Group key={access.id} wrap="nowrap" gap="xs" align="flex-start">
              <Controller
                control={control}
                name={`ownerAccess.${index}.ownerId`}
                rules={{
                  required:
                    index === 0 ? 'Select at least one owner' : undefined,
                }}
                render={({ field, fieldState: { error } }) => {
                  const [search, setSearch] = useState('');

                  const alreadySelectedOwnerIds = rows
                    .filter((x) => x.ownerId !== field.value)
                    .map((x) => x.ownerId)
                    .filter(hasValue);

                  const queryData = useInfiniteQuery(
                    (
                      q,
                      { teamId, search, alreadySelectedOwnerIds },
                      { limit, offset }
                    ) => {
                      const where: gqlV2.owner_bool_exp = {
                        ...whereOwnersV2({
                          teamId,
                          search,
                        }),
                        id: { _nin: alreadySelectedOwnerIds },
                      };

                      const list = q
                        .owners({
                          where,
                          limit,
                          offset,
                          order_by: [
                            {
                              type: 'asc_nulls_first',
                            },
                            {
                              name: 'asc_nulls_last',
                              firstName: 'asc_nulls_last',
                            },
                          ],
                        })
                        .map<SelectItem>((owner) => ({
                          label: formatOwnerName(owner) || '',
                          value: owner.id,
                          icon: (
                            <Icon
                              icon={
                                owner.type === 'company'
                                  ? 'OfficeIcon'
                                  : 'UserIcon'
                              }
                              size={owner.type === 'company' ? 16 : 14}
                            />
                          ),
                        }));

                      const aggregate =
                        q.ownerAggregate({ where }).aggregate?.count() || 0;

                      return {
                        list,
                        aggregate,
                      };
                    },
                    {
                      skip: !teamId,
                      queryKey: 'owners',
                      variables: {
                        teamId,
                        alreadySelectedOwnerIds,
                        search: search?.trim(),
                      },
                    }
                  );

                  const options =
                    queryData.data?.pages.flatMap((x) => x.list) || [];
                  const value =
                    options.find((x) => x.value === field.value) || null;

                  return (
                    <InputWrapper required error={error?.message}>
                      <InputSelect
                        infiniteData={{ ...queryData, setSearch }}
                        type="single"
                        value={value}
                        setValue={(newValue) => {
                          field.onChange(newValue?.value || null);
                        }}
                        dropdownProps={{
                          withinPortal: true,
                        }}
                        inputProps={{
                          placeholder: 'Owner',
                          width: 220,
                          error: !!error,
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
              <Controller
                control={control}
                name={`ownerAccess.${index}.role`}
                rules={{
                  required: 'Role is required',
                }}
                render={({ field, fieldState: { error } }) => {
                  const options = ensure<
                    SelectItem<gqlV2.owner_user_access_role_enum>[]
                  >([
                    {
                      label: 'Owner',
                      value: 'admin',
                      icon: <Edit3Icon size={16} />,
                    },
                    {
                      label: 'Viewer',
                      value: 'viewer',
                      icon: <EyeIcon size={16} />,
                    },
                  ]);

                  const value = field.value;
                  const formatted =
                    options.find((o) => o.value === value) || null;

                  return (
                    <InputWrapper required error={error?.message}>
                      <InputSelect
                        data={{
                          options,
                        }}
                        type="single"
                        setValue={(value) => {
                          if (!value?.value) return;

                          field.onChange(value.value);
                        }}
                        value={formatted || null}
                        inputProps={{
                          placeholder: 'Role',
                          error: !!error,
                        }}
                        dropdownProps={{
                          withinPortal: true,
                          position: 'bottom-end',
                          width: 200,
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
              {!!index && (
                <IconButton onClick={() => remove(index)} pt={5}>
                  <TrashIcon color="gray" size={16} />
                </IconButton>
              )}
            </Group>
          );
        })}

        <Button
          sx={{
            marginLeft: 'auto',
          }}
          variant="light"
          color={primaryColor}
          leftIcon={'PlusIcon'}
          disabled={hasNoOwners}
          onClick={() =>
            append({
              ownerId: null,
              role: 'admin',
            })
          }
        >
          Add owner access
        </Button>
      </Stack>
    </Box>
  );
};
