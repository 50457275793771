import { captureSentryError } from '@finalytic/data';
import {
  StringParam,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { ConnectApp, IntegrationType, viewsUnion } from './_types';
import { ConnectFn, IssueType } from './hooks';

type context = {
  view: viewsUnion;
  setView: (view: viewsUnion) => void;
  resetView: () => void;

  apps: IntegrationType[];
  app?: IntegrationType;
  presetApp?: IntegrationType;
  setApp: (integration?: IntegrationType) => void;

  connectApp: ConnectApp;

  errorMessage?: string;
  issue?: IssueType;
};

const ConnectionModalContext = createContext<context | null>(null);

export type ConnectionModalContextProviderProps = {
  children?: ReactNode;
  refetchConnections: () => void;
  apps: IntegrationType[];
  presetApp?: IntegrationType;
  addConnection: ConnectFn;
};

export const useConnectionModalContext = () =>
  useContext(ConnectionModalContext) as context;

export const ConnectionModalContextProvider = ({
  children,
  refetchConnections,
  apps,
  presetApp,
  addConnection,
}: ConnectionModalContextProviderProps) => {
  const [view, setView] = useState<viewsUnion>('list-view');
  const [appState, setApp] = useState<IntegrationType | undefined>(undefined);

  const app = presetApp || appState;

  const [issue, setIssue] = useState<IssueType | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [connectionId, setSearchParams] = useQueryParam(
    'connectionId',
    StringParam
  );

  const updateView = (view: viewsUnion) => setView(view);

  const resetView = () => {
    setIssue(undefined);
    setErrorMessage(undefined);
    setSearchParams(undefined);
    setApp(undefined);
    setView('list-view');
  };

  const connectApp: ConnectApp = async ({ input, sessionKey }) => {
    if (!app?.id) {
      showErrorNotification({ message: 'Missing connection.' });
      return;
    }
    setView('loading-view');

    if (input) input.sessionKey = sessionKey;
    await addConnection({
      appId: app.id,
      sessionKey,
      connectionId: connectionId || undefined,
      params: input,
    }).then(({ type, error, issue }) => {
      if (type === 'success') {
        setView('success-view');
        refetchConnections?.();
      }

      if (type === 'issue') {
        setIssue(issue);
        setView('enter-parameter-view');
      }

      if (type === 'error') {
        const errorMessage =
          error?.message ||
          error?.title ||
          'Missing adding connection error message.';

        captureSentryError(errorMessage);

        setErrorMessage(errorMessage);
        setView('error-view');
        showErrorNotification({
          title: error?.title,
          message: error?.message,
        });
        refetchConnections?.();
      }
    });
  };

  const value = useMemo(
    () => ({
      apps,
      view,
      setView: updateView,
      resetView,
      app,
      setApp,
      connectApp,
      errorMessage,
      issue,
      presetApp,
    }),
    [apps, view, app, errorMessage, issue, presetApp]
  );

  return (
    <ConnectionModalContext.Provider value={value}>
      {children}
    </ConnectionModalContext.Provider>
  );
};
