import { Icon } from '@finalytic/icons';
import { IconButton, Logo, useAppName, useColors } from '@finalytic/ui';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Group,
  MantineSize,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PropsWithChildren } from 'react';
import { useIntercom } from 'react-use-intercom';

export type LayoutDataProps = {
  handleLogout: (() => void) | undefined;
  teamSelectComponent?: React.ReactNode;
  showHelpCenter?: boolean;
};

export type LayoutProps = {
  title: string;
  sidebar: React.ReactNode;
  sidebarPaddingTop?: string | number;
};

export const Layout = ({
  title,
  children,
  sidebar,
  sidebarPaddingTop = '28vh',
  handleLogout,
  showHelpCenter,
  teamSelectComponent,
}: PropsWithChildren<LayoutProps & LayoutDataProps>) => {
  const windowPadding: MantineSize = 'lg';

  const colors = useColors();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const { showArticle } = useIntercom();

  return (
    <Flex
      p={windowPadding}
      direction={isMobile ? 'column' : 'row'}
      align="stretch"
      sx={{
        minHeight: isMobile ? undefined : '100vh',
      }}
    >
      <Box
        sx={{ flex: 1 }}
        pb={isMobile ? '10vh' : undefined}
        px={isMobile ? undefined : 'sm'}
      >
        <Group justify="space-between" mb="3vh" px="xs">
          <Group>
            {handleLogout && (
              <Tooltip label="Sign out" withArrow>
                <Box sx={{ transform: 'rotate(180deg)' }}>
                  <IconButton onClick={handleLogout}>
                    <Icon
                      icon="LogOut2Icon"
                      size={16}
                      color={(theme) => theme.colors.neutral[6]}
                    />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {teamSelectComponent && (
              <Box
                miw="230px"
                sx={() => ({ '*': { color: '#000000!important' } })}
              >
                {teamSelectComponent}
              </Box>
            )}
          </Group>
          {showHelpCenter && (
            <Button
              variant="white"
              onClick={() => showArticle(8592961)}
              // component={'a'}
              // href="https://intercom.help/vrt-5c47e36cc061/en/articles/8592961-onboarding-checklist"
              // target="_blank"
            >
              Help center
            </Button>
          )}
        </Group>
        <Stack mx="auto" align="center" gap={0}>
          <RoundLogo />
          <Text
            mt="sm"
            component="h1"
            size={'1.5rem'}
            mb="lg"
            fw={500}
            test-id="onboarding-title"
          >
            {title}
          </Text>
        </Stack>
        {children}
      </Box>
      <Box
        sx={(theme) => {
          const hueColor =
            theme.primaryColor === 'Finalytic'
              ? colors.purple.base
              : colors.blue.light;

          return {
            flex: 1,
            zIndex: -2,
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors.dark[6],
            minHeight: '100%',
            position: 'relative',
            '&::after': {
              position: 'absolute',
              content: '""',
              zIndex: -1,
              top: '20%',
              width: '50%',
              left: '25%',
              height: '20%',
              backgroundColor: hueColor,
              transition: 'background-color 0.3s ease-in-out',
              opacity: 0.4,
              filter: 'blur(150px)',
            },
          };
        }}
      >
        <Box
          sx={{
            paddingTop: sidebarPaddingTop,
            paddingBottom: isMobile ? '10vh' : undefined,
            paddingInline: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // overflow: 'scroll',
          }}
        >
          {sidebar}
        </Box>
      </Box>
    </Flex>
  );
};

const RoundLogo = () => {
  const { themeName } = useAppName();
  const { black } = useColors();

  return (
    <Avatar
      size={45}
      radius={45}
      sx={{
        '.mantine-Avatar-placeholder': {
          backgroundColor: themeName === 'finalytic' ? black.base : 'white',
        },
      }}
    >
      <Logo width={40} color="#fff" />
    </Avatar>
  );
};
