import { useTeamRole } from '@finalytic/data';
import { FormulaField, InputFormula } from '@finalytic/ui';
import { Box } from '@mantine/core';
import {
  NetRevenueColumn,
  defaultStatementFormulaFields,
  formatColumnsToFormulaFields,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useStatementAccounts } from '../../views/statement-settings/_hooks';

type Props = {
  collectionColumns: NetRevenueColumn[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  cellWidth?: number;
  onEnter: () => void;
};

export const StatementFormulaEditor = ({
  collectionColumns,
  setValue,
  value,
  cellWidth,
  onEnter,
}: Props) => {
  const [search, setSearch] = useState('');

  const { isPartnerAdmin } = useTeamRole();

  const { accounts, loading } = useStatementAccounts(search, 50);

  const fields = useMemo<FormulaField[]>(() => {
    const collections = formatColumnsToFormulaFields(
      collectionColumns.filter((c) => c.type === 'sumAccounts')
    );

    const sumColumns = formatColumnsToFormulaFields(
      collectionColumns.filter((c) => c.type === 'sumColumns')
    );

    const metadataColumns = formatColumnsToFormulaFields(
      collectionColumns.filter((i) => i.type === 'metadata')
    );

    const accs: FormulaField[] = accounts.map((acc) => ({
      displayValue: `"acc.${acc.value}"`, // source.remoteId
      id: acc.id, // source.id
      label: acc.label, //source.description
      type: 'account',
    }));

    return [
      ...defaultStatementFormulaFields,
      ...collections,
      ...sumColumns,
      ...metadataColumns,
      ...accs,
    ];
  }, [collectionColumns, defaultStatementFormulaFields, accounts]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        width: cellWidth || 350,
      }}
    >
      <InputFormula
        fields={fields}
        value={value}
        setValue={setValue}
        placeholder="Please enter a formula"
        autoFocus
        rounded={false}
        loading={loading}
        onSearchInput={(val) => setSearch(val)}
        onEnter={onEnter}
        debugMode={isPartnerAdmin}
      />
    </Box>
  );
};
