import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useAuditDrawer,
  useInfiniteQuery,
  useQuery,
} from '@finalytic/data';
import { SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { useState } from 'react';

export const AuditFilter = () => {
  const { filter, setFilter } = useAuditDrawer();

  const setTable = (value: Maybe<string>) => setFilter({ table: value });
  const setTeam = (value: Maybe<string>) => setFilter({ teamId: value });

  return (
    <Group gap="xs">
      <Filter.Search
        setValue={(v) => setFilter({ objectId: v })}
        value={filter.objectId || ''}
      />
      {/* Team ID */}
      <Teams teamId={filter.teamId} setTeamId={setTeam} />

      {/* Table */}
      <Tables table={filter.table} setTable={setTable} />
    </Group>
  );
};

const Teams = ({
  setTeamId,
  teamId,
}: {
  teamId: Maybe<string>;
  setTeamId: (val: Maybe<string>) => void;
}) => {
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = {
        name: args.search
          ? {
              _ilike: `%${args.search}%`,
            }
          : undefined,
      };

      const list = q
        .tenant({
          order_by: [{ name: 'asc_nulls_last' }],
          limit,
          offset,
          where,
        })
        .map<SelectItem>((tenant) => ({
          label: tenant.name || '',
          value: tenant.id || '',
        }));

      const aggregate = q.tenantAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'connections',
      variables: {
        search: search.trim(),
      },
    }
  );

  const { data: value } = useQuery(
    (q, args) => {
      if (!args.teamId) return null;

      return (
        q.tenant({ where: { id: { _eq: args.teamId } } }).map((i) => ({
          label: i.name || '',
          value: i.id,
        }))[0] || null
      );
    },
    {
      variables: {
        teamId,
      },
    }
  );

  return (
    <Filter.Select
      type="single"
      value={value || null}
      infiniteData={{ ...queryData, setSearch }}
      label="Team"
      setValue={(v) => {
        setTeamId(v?.value);
      }}
    />
  );
};

const Tables = ({
  setTable,
  table,
}: {
  table: Maybe<string>;
  setTable: (val: Maybe<string>) => void;
}) => {
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.audit_log_bool_exp = {
        tableName: args.search
          ? {
              _ilike: `%${args.search}%`,
            }
          : undefined,
      };

      const list = q
        .auditLogs({
          distinct_on: ['tableName'],
          order_by: [{ tableName: 'asc_nulls_last' }],
          limit,
          offset,
          where,
        })
        .map<SelectItem>((tenant) => ({
          label: tenant.tableName || '',
          value: tenant.tableName || '',
        }));

      const aggregate =
        q
          .auditLogAggregate({ where, distinct_on: ['tableName'] })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'connections',
      variables: {
        search: search.trim(),
      },
    }
  );

  const value = table ? { label: table, value: table } : null;

  return (
    <Filter.Select
      type="single"
      value={value || null}
      infiniteData={{ ...queryData, setSearch }}
      label="Table"
      setValue={(v) => {
        setTable(v?.value);
      }}
    />
  );
};
