import {
  AddressFormInputs,
  Button,
  Divider,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  useEnabledFeatures,
  useInvalidateQueries,
  useMutation,
  useTeam,
  useTrpcMutation,
} from '@finalytic/data';
import { AlertTriangleIcon, OfficeIcon, UserIcon } from '@finalytic/icons';
import {
  AvatarGroup,
  BooleanParam,
  Drawer,
  Logo,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  showWarnNotification,
  useAppName,
  useQueryParam,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  Box,
  Center,
  Checkbox,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import {
  OWNER_COMPANY_TYPES,
  OwnerCompanyTypeValue,
  formatAddress,
  getCountryByIsoCode,
  getStateByIsoCode,
  validateTin,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { usePosthogCapture } from '../../hooks';
import { DrawerHeader, DrawerSectionTitle } from '../_components';

export function useAddOwnerDrawer() {
  const [opened, setOpened] = useQueryParam('add-owner', BooleanParam);

  return {
    opened: !!opened,
    open: () => setOpened(true),
    close: () => setOpened(false),
  };
}

export const AddOwnerDrawer = () => {
  const [{ id: teamId, partnerId }] = useTeam();
  const { themeName } = useAppName();
  const { opened, close } = useAddOwnerDrawer();
  const setOwner = useQueryParamSet('owner', StringParam);
  const { NEW_OWNERS } = useEnabledFeatures();

  const [view, setView] = useState<
    | { key: 'form'; data: null }
    | { key: 'owner-exists'; data: FormInputs & { ownerIds?: string[] } }
    | { key: 'pm-exists'; data: null }
  >({ data: null, key: 'form' });

  const capture = usePosthogCapture();
  const { mutate, loading } = useTrpcMutation('addOwner');
  const invalidate = useInvalidateQueries(['owners']);

  const inviteNewOwnerUser = async (
    data: FormInputs & { ownerIds?: string[] },
    methods: UseFormReturn<FormInputs, any> | undefined,
    opts: {
      force: boolean;
      sendInvite: boolean;
    }
  ): Promise<{ ok: boolean }> => {
    const city = data.address.city?.trim();
    const line1 = data.address.line1?.trim();
    const postcode = data.address.postcode?.trim();

    const hasAddress = !!line1;

    if (hasAddress && (!data.address.stateCode || !data.address.countryCode)) {
      showErrorNotification({
        title: 'Validation error',
        message: 'Please select a country and state.',
        icon: <AlertTriangleIcon size={20} />,
      });

      return {
        ok: false,
      };
    }

    // Legacy validation
    if (!NEW_OWNERS) {
      const hasFirstOrLastName =
        !!data.firstName?.trim() || !!data.lastName?.trim();
      if (
        hasFirstOrLastName
          ? !data.firstName?.trim() || !data.lastName?.trim()
          : !data.companyName?.trim()
      ) {
        if (hasFirstOrLastName) {
          if (!data.firstName?.trim()) {
            methods?.setError('firstName', {
              message: 'First name is required with last name.',
            });
            methods?.setFocus('lastName');
          } else {
            methods?.setError('lastName', {
              message: 'Last name is required with first name.',
            });
            methods?.setFocus('lastName');
          }
        } else {
          methods?.setError('firstName', {});
          methods?.setError('lastName', {});
          methods?.setError('companyName', {
            message: 'Please enter a first and last name or a company name.',
          });
          methods?.setFocus('companyName');
        }

        return {
          ok: false,
        };
      }
    }
    const country =
      (data.address.countryCode &&
        getCountryByIsoCode(data.address.countryCode)?.name) ||
      '';
    const state =
      (data.address.countryCode &&
        data.address.stateCode &&
        getStateByIsoCode(data.address.stateCode, data.address.countryCode)
          ?.name) ||
      '';

    const result = await mutate({
      force: opts.force,
      teamId,
      owner: {
        email: data.email.toLowerCase().trim(),
        firstName: data.firstName?.trim(),
        companyName: data.companyName?.trim(),
        role: 'owner',
        lastName: data.lastName?.trim(),
        teamId,
        partnerId,
        addressCity: !hasAddress ? undefined : city,
        addressCountry: !hasAddress ? undefined : country,
        addressLine1: !hasAddress ? undefined : line1,
        addressPostcode: !hasAddress ? undefined : postcode,
        phone: data.phone?.trim(),
        sendInvite: opts.sendInvite,
        addressV2: !hasAddress
          ? undefined
          : {
              city,
              line1,
              postcode,
              countryCode: data.address.countryCode || '',
              stateCode: data.address.stateCode || '',
              country,
              state,
            },
      },
    });

    if (result.status === 'owner-exists') {
      setView({
        data,
        key: 'owner-exists',
      });
      return {
        ok: false,
      };
    } else if (result.status === 'pm-exists') {
      setView({
        data: null,
        key: 'pm-exists',
      });
      return {
        ok: false,
      };
    } else {
      // all went well
      close();
      invalidate();
      showSuccessNotification({
        title: 'Success!',
        message: 'Person was successfully added.',
      });
      if (result.owner?.id && !NEW_OWNERS) setOwner(result.owner?.id);

      return {
        ok: true,
      };
    }
  };

  const { mutate: addNewOwner } = useMutation(
    (q, args: FormInputs & { teamId: string }) => {
      if (!args.address.countryCode) throw new Error('Country is required');
      if (!args.address.stateCode) throw new Error('State is required');

      const country = getCountryByIsoCode(args.address.countryCode)?.name || '';
      const state =
        getStateByIsoCode(args.address.stateCode, args.address.countryCode)
          ?.name || '';

      const isIndividual = args.type === 'individual';

      const hasAddress = !!args.address.line1;

      return q.insertOwner({
        object: {
          type: isIndividual ? 'individual' : 'company',
          firstName:
            args.type === 'individual' ? args.firstName.trim() : undefined,
          name: args.lastName.trim(),
          tenantId: args.teamId,
          email: args.email?.toLowerCase().trim(),
          phone: args.phone?.trim(),
          taxId: args.taxId?.trim(),
          companyType: isIndividual ? undefined : args.type,
          address: !hasAddress
            ? undefined
            : {
                data: {
                  line: args.address.line1.trim(),
                  lineDetails: args.address.line2?.trim(),
                  city: args.address.city.trim(),
                  postalCode: args.address.postcode.trim(),
                  country,
                  countryCode: args.address.countryCode?.trim(),
                  state,
                  stateCode: args.address.stateCode?.trim(),
                  tenant_id: args.teamId,
                },
              },
        },
      })?.id;
    }
  );

  const handleNewOwnerSubmit = async (values: FormInputs) => {
    if (values.addUserOption && !values.email?.trim()) {
      showWarnNotification({
        message:
          'Email is required when trying to invite owner with user access to VRP',
      });

      throw new Error(
        'Email is required when trying to invite owner with user access to VRP'
      );
    }

    const newOwnerId = await addNewOwner({
      args: {
        ...values,
        teamId,
      },
    });

    capture('owner_created', {
      tenant_id: teamId,
      owner_id: newOwnerId,
      type: values.type,
      with_user_invite: values.addUserOption,
    });

    if (values.addUserOption && values.type === 'individual') {
      const res = await inviteNewOwnerUser(
        {
          ...values,
          ownerIds: [newOwnerId],
        },
        undefined,
        {
          force: false,
          sendInvite: true,
        }
      );

      if (!res.ok) {
        return;
      }
    }

    if (newOwnerId) setOwner(newOwnerId);

    invalidate();
    close();
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      containerSx={{
        position: 'relative',
      }}
    >
      {view.key === 'form' && (
        <>
          <DrawerHeader
            title={NEW_OWNERS ? 'Add owner' : 'Add owner/spectator'}
            closeDrawer={close}
            containerSx={{ marginBottom: 0 }}
          />
          <OwnerEditForm
            onReset={close}
            submitButtonLabel={NEW_OWNERS ? 'Add owner' : 'Add owner/spectator'}
            handleSubmit={async (values, methods) =>
              NEW_OWNERS
                ? handleNewOwnerSubmit(values)
                : inviteNewOwnerUser(values, methods, {
                    force: false,
                    sendInvite: false,
                  })
            }
            isOwnerAddModal
            initialValues={undefined}
          />
        </>
      )}
      {view.key === 'owner-exists' && (
        <Center
          py="xl"
          px="md"
          sx={{ flexDirection: 'column', minHeight: '95%' }}
        >
          <AvatarGroup
            leftIcon={<Logo width={80} />}
            leftBgColor={themeName === 'vrplatform' ? 'white' : undefined}
            rightIcon={<UserIcon size={48} strokeWidth={1} />}
            rightBgColor="white"
          />
          <Text component="h4" size="xl" mb="lg" ta="center" fw={500} mt="lg">
            {NEW_OWNERS ? 'Owner user already exists' : 'Person already exists'}
          </Text>
          <Text component="p" mt={0} mb={32} ta="center" c="neutral">
            {NEW_OWNERS
              ? "We've found an owner user with this email address. Confirming will re-send a team invitation to the user and add this owner to their view permissions. Are you sure you want to continue?"
              : 'This person already exists. Adding the person will not change their current name, company or listing ownership information. However, you can add listing ownerships after the person has been added to your team. Would you like to continue?'}
          </Text>

          <Button
            variant="primary"
            onClick={() =>
              inviteNewOwnerUser(view.data, undefined, {
                force: true,
                sendInvite: !!NEW_OWNERS,
              })
            }
            sx={{ width: '100%', marginBottom: rem(15) }}
          >
            Invite person
          </Button>

          <Button
            variant="light"
            onClick={() => {
              if (NEW_OWNERS) {
                invalidate();
                close();
              } else {
                setView({
                  data: null,
                  key: 'form',
                });
              }
            }}
            sx={{ width: '100%' }}
          >
            Cancel
          </Button>
        </Center>
      )}
      {view.key === 'pm-exists' && (
        <Center mih="100%" py="xl" px="md" sx={{ flexDirection: 'column' }}>
          <AvatarGroup
            leftIcon={<Logo width={80} />}
            leftBgColor={themeName === 'vrplatform' ? 'white' : undefined}
            rightIcon={<UserIcon size={48} strokeWidth={1} />}
            rightBgColor="white"
          />
          <Text component="h4" size="xl" my="lg" ta="center" fw={500}>
            PM user already exists
          </Text>
          <Text component="p" mt={0} mb={32} ta="center" c="neutral">
            We couldn't add this email address as person. The user already
            exists as a PM team member.
          </Text>

          <Button
            onClick={() => {
              if (NEW_OWNERS) {
                invalidate();
                close();
              } else {
                setView({
                  data: null,
                  key: 'form',
                });
              }
            }}
          >
            Cancel
          </Button>
        </Center>
      )}
      <LoadingOverlay visible={loading} />
    </Drawer>
  );
};

type FormInputs = {
  firstName: string;
  lastName: string;
  companyName: string; // legacy
  email: string;
  phone: string;
  taxId: string;
  type: 'individual' | 'company' | OwnerCompanyTypeValue;
  address: Parameters<typeof formatAddress>[0];
  addUserOption: boolean;
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

export const OwnerEditForm = ({
  handleSubmit,
  initialValues,
  onReset,
  submitButtonLabel,
  isEmailDisabled,
  isOwnerAddModal,
}: {
  handleSubmit: (
    values: FormInputs,
    methods: UseFormReturn<FormInputs, any>
  ) => Promise<any>;
  onReset: () => void;
  initialValues: FormInputs | undefined;
  submitButtonLabel: string;
  isEmailDisabled?: boolean;
  isOwnerAddModal: boolean;
}) => {
  const { NEW_OWNERS } = useEnabledFeatures();

  const [taxDisabled, setTaxDisabled] = useState(false);

  const methods = useForm<FormInputs>({
    values: initialValues,
  });

  const ownerType = methods.watch('type') || 'individual';
  const isInvite = methods.watch('addUserOption');

  const isIndividual = ownerType === 'individual';

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'hidden',
          overflowX: 'unset',
        })}
        onSubmit={methods.handleSubmit((values) =>
          handleSubmit(values, methods)
        )}
        onReset={onReset}
      >
        {NEW_OWNERS ? (
          <Box>
            <Controller
              control={methods.control}
              defaultValue="individual"
              name="type"
              rules={{
                required: 'Owner type is required',
              }}
              render={({ field, fieldState: { error } }) => {
                const individualOption = {
                  label: 'Individual',
                  value: 'individual',
                  icon: <UserIcon size={16} />,
                };

                const options = [
                  individualOption,
                  ...OWNER_COMPANY_TYPES.map((x) => ({
                    ...x,
                    icon: <OfficeIcon size={16} />,
                  })),
                ];

                return (
                  <Box>
                    <InputWrapper
                      label="Owner type"
                      required
                      error={error?.message}
                    >
                      <InputSelect
                        data={{
                          options,
                          sort: null,
                        }}
                        type="single"
                        value={
                          options.find((x) => x.value === field.value) || null
                        }
                        inputProps={{
                          placeholder: 'Company type',
                          error: !!error,
                        }}
                        dropdownProps={{ width: 'target' }}
                        setValue={(e) => {
                          if (e?.value) {
                            if (
                              (e.value === 'individual' &&
                                field.value !== 'individual') ||
                              (e.value !== 'individual' &&
                                field.value === 'individual')
                            ) {
                              methods.setValue('firstName', '');
                              methods.setValue('lastName', '');
                              methods.clearErrors('firstName');
                              methods.clearErrors('lastName');
                            }
                            field.onChange(e.value);
                          }
                        }}
                      />
                    </InputWrapper>
                  </Box>
                );
              }}
            />
            <Divider mt="xs" />
          </Box>
        ) : (
          <Divider
            mt="xs"
            sx={(theme) => ({
              marginInline: `-${theme.spacing.xs}`,
            })}
          />
        )}

        <ScrollArea
          sx={{
            flex: 1,
          }}
        >
          <Stack mt="sm">
            {NEW_OWNERS && !isIndividual ? (
              <Controller
                control={methods.control}
                name="lastName"
                defaultValue=""
                rules={{
                  required: 'Company name is required',
                  validate: trapSpacesForRequiredFields,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper
                    label="Company name"
                    error={error?.message}
                    required
                  >
                    <Input
                      {...field}
                      placeholder="Company name"
                      autoComplete="off"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />
            ) : (
              <>
                {!NEW_OWNERS && (
                  <Controller
                    control={methods.control}
                    name="companyName"
                    rules={
                      NEW_OWNERS
                        ? {
                            required: 'Company name is required',
                            validate: trapSpacesForRequiredFields,
                          }
                        : undefined
                    }
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <InputWrapper
                        label="Company name"
                        error={error?.message}
                        required={NEW_OWNERS}
                      >
                        <Input
                          {...field}
                          placeholder="Company name"
                          autoComplete="off"
                          error={!!error}
                        />
                      </InputWrapper>
                    )}
                  />
                )}
                <Box
                  sx={(theme) => ({
                    alignItems: 'flex-start',
                    justifyContent: 'stretch',
                    display: 'flex',
                    gap: theme.spacing.md,
                    '> div': {
                      flex: 1,
                    },
                  })}
                >
                  <Controller
                    control={methods.control}
                    name="firstName"
                    defaultValue=""
                    rules={
                      NEW_OWNERS
                        ? {
                            required: 'First name is required',
                            validate: trapSpacesForRequiredFields,
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { error } }) => (
                      <InputWrapper
                        label="First name"
                        error={error?.message}
                        required={NEW_OWNERS}
                      >
                        <Input
                          {...field}
                          placeholder="First name"
                          autoComplete="off"
                          error={!!error}
                        />
                      </InputWrapper>
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="lastName"
                    defaultValue=""
                    rules={
                      NEW_OWNERS
                        ? {
                            required: 'Last name is required',
                            validate: trapSpacesForRequiredFields,
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { error } }) => (
                      <InputWrapper
                        label="Last name"
                        error={error?.message}
                        required={NEW_OWNERS}
                      >
                        <Input
                          {...field}
                          placeholder="Last name"
                          autoComplete="off"
                          error={!!error}
                        />
                      </InputWrapper>
                    )}
                  />
                </Box>
              </>
            )}

            <Controller
              control={methods.control}
              name="email"
              rules={{
                required: NEW_OWNERS
                  ? isInvite
                    ? 'Email is required when trying to invite owner with user access to VRP'
                    : false
                  : 'Email is required',
                validate: NEW_OWNERS
                  ? isInvite
                    ? trapSpacesForRequiredFields
                    : undefined
                  : trapSpacesForRequiredFields,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label="Email"
                  error={error?.message}
                  required={!NEW_OWNERS ? true : isInvite}
                >
                  <Input
                    {...field}
                    placeholder="Email"
                    autoComplete="off"
                    type="email"
                    error={!!error}
                    disabled={isEmailDisabled}
                  />
                </InputWrapper>
              )}
            />

            <Controller
              control={methods.control}
              name="phone"
              render={({ field, fieldState: { error } }) => (
                <InputWrapper label="Phone" error={error?.message}>
                  <Input
                    {...field}
                    placeholder="Phone"
                    type="tel"
                    autoComplete="off"
                    error={!!error}
                  />
                </InputWrapper>
              )}
            />
          </Stack>

          <DrawerSectionTitle>Address</DrawerSectionTitle>

          <AddressFormInputs />

          <DrawerSectionTitle>Tax Information</DrawerSectionTitle>

          <Controller
            control={methods.control}
            name="taxId"
            rules={{
              required: taxDisabled
                ? undefined
                : 'Tax Identification Number is required',
              validate: taxDisabled ? undefined : (v) => validateTin(v),
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                label="TIN (US only)"
                error={error?.message}
                mb="sm"
                required={!taxDisabled}
              >
                <Input
                  {...field}
                  placeholder="Tax Identification Number"
                  autoComplete="off"
                  error={!!error}
                  disabled={taxDisabled}
                />
              </InputWrapper>
            )}
          />

          <Checkbox
            label="I do not have a TIN"
            size="xs"
            mb="sm"
            data-testid="no-tin-checkbox"
            checked={taxDisabled}
            onChange={() => {
              setTaxDisabled((e) => !e);
              methods.clearErrors('taxId');
              methods.setValue('taxId', '');
            }}
          />
        </ScrollArea>

        <Box
          sx={(theme) => ({
            paddingBottom: theme.spacing.sm,
          })}
        >
          {NEW_OWNERS && isIndividual && isOwnerAddModal ? (
            <>
              <Divider mb={5} />

              <InputWrapper label="User Access">
                <Controller
                  control={methods.control}
                  name="addUserOption"
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      label="Invite owner with user access to VRP"
                      size="xs"
                      data-testid="invite-user-checkbox"
                      checked={field.value}
                      onChange={() => {
                        field.onChange(!field.value);
                        methods.clearErrors('email');
                      }}
                      mb="lg"
                      mt={8}
                    />
                  )}
                />
              </InputWrapper>
            </>
          ) : (
            <Divider
              mb={'xs'}
              sx={(theme) => ({
                marginInline: `-${theme.spacing.xs}`,
              })}
            />
          )}
          <Group>
            <Button
              type="reset"
              onClick={close}
              disabled={methods.formState.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              sx={{ flexGrow: '1!important' as any }}
              type="submit"
              data-testid="submit"
              disabled={!methods.formState.isDirty}
              variant="primary"
              loading={methods.formState.isSubmitting}
            >
              {submitButtonLabel}
            </Button>
          </Group>
        </Box>
      </Box>
    </FormProvider>
  );
};
