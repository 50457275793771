import { Box } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

type ModalFooterProps = {
  children: ReactNode;
  sx?: EmotionSx;
};

export const ModalFooter = ({ children, sx }: ModalFooterProps) => (
  <Box
    py={10}
    px={20}
    sx={(theme, u) =>
      ({
        position: 'sticky',
        bottom: 0,
        marginLeft: '-1rem',
        marginRight: '-1rem',
        backgroundColor: '#F4F4F7',
        borderRadius: '0 0 10px 10px',
        ...(typeof sx === 'function' ? sx(theme, u) : sx),
      }) as any
    }
  >
    {children}
  </Box>
);
