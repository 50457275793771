import { VRP_TENANT_ID } from '@vrplatform/ui-common';
import React from 'react';
import { teamContext } from './team';

export function useBilling() {
  const { team } = React.useContext(teamContext);

  const isChildTeam =
    team.partnerId === VRP_TENANT_ID && team.id !== VRP_TENANT_ID;
  const isPartnerTeam = team.type === 'partner' && team.id !== VRP_TENANT_ID;

  return React.useMemo(() => {
    // show billing for
    // 1. child teams of VRP partner team
    // 2. All partner teams that or not VRP
    const showBilling =
      (isChildTeam || isPartnerTeam) && !!team?.billingSubscriptionStatus;

    return {
      showBilling,
      billingStatus: team.billingSubscriptionStatus,
    };
  }, [
    isChildTeam,
    VRP_TENANT_ID,
    isPartnerTeam,
    team.billingSubscriptionStatus,
  ]);
}
