import {
  gqlV2,
  useAccountingConnection,
  useEnabledFeatures,
  useQuery,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { Maybe, ensure, sortBy } from '@finalytic/utils';
import {
  AutomationMapping,
  AutomationMappings,
  AutomationViewSettings,
  whereAutomations,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';

export type GlobalMappingTabProps = {
  settingKey: string;
  mapping: AutomationMapping;
  automations: {
    local: {
      id: string;
      type: Maybe<string>;
      title: Maybe<string>;
    }[];
    global: {
      id: string;
      type: Maybe<string>;
      title: Maybe<string>;
    }[];
  };
  automation: {
    id: string;
    leftConnection: {
      id: string;
      appId: Maybe<string>;
      appName: Maybe<string>;
      icon: Maybe<string>;
    };
    rightConnection: {
      id: string;
      appId: Maybe<string>;
      appName: Maybe<string>;
      icon: Maybe<string>;
    };
    viewSettings: AutomationViewSettings;
  };
};

export function useGlobalMappingTabsQuery() {
  const { NEW_OWNERS } = useEnabledFeatures();
  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();
  const { accounting } = useAccountingConnection();
  const [{ id: teamId, finalyticConnectionId }] = useTeam();

  const { data, isLoading: loading } = useQuery(
    (
      q,
      {
        isPartnerAdmin,
        isSuperAdmin,
        teamId,
        accountingConnectionId,
        finalyticConnectionId,
        isVrpAdmin,
      }
    ) => {
      const where: gqlV2.automation_bool_exp = {
        ...whereAutomations({
          isPartnerAdmin,
          isSuperAdmin,
          tenantId: teamId,
          accountingConnectionId,
          isVrpAdmin,
        }),
        _or: [
          {
            leftConnectionId: { _eq: finalyticConnectionId },
          },
          {
            rightConnectionId: { _eq: finalyticConnectionId },
          },
        ],
      };

      const uniqueByTemplate = q
        .automations({
          where,
          distinct_on: ['templateId'],
          order_by: [{ templateId: 'desc' }],
        })
        .map((automation) => {
          const template = automation.ttemplate;

          const mappings = (template?.mappings() || {}) as AutomationMappings;

          return {
            mappings,
            automation: ensure<GlobalMappingTabProps['automation']>({
              id: automation.id,
              leftConnection: {
                appId: automation.leftConnection?.appId,
                icon: automation.leftConnection?.app?.iconRound,
                appName: automation.leftConnection?.app?.name,
                id: automation.leftConnection?.id,
              },
              rightConnection: {
                appId: automation.rightConnection?.appId,
                icon: automation.rightConnection?.app?.iconRound,
                appName: automation.rightConnection?.app?.name,
                id: automation.rightConnection?.id,
              },
              viewSettings: (automation.viewSettings() ||
                {}) as AutomationViewSettings,
            }),
          };
        });

      const automations = q
        ?.automations({
          where,
        })
        ?.map((automation) => {
          const automationMappings = (automation.ttemplate?.mappings() ||
            {}) as AutomationMappings;

          const viewSettings = (automation.viewSettings() ||
            {}) as AutomationViewSettings;

          const globalMappings = Object.entries(automationMappings)
            .filter(([key, mapping]) => {
              if (mapping.scope === 'local') return false;
              if (viewSettings[key]?.isLocal) return false;
              // if (viewSettings[key]?.leftType) return false;

              return true;
            })
            .map(([mappingKey]) => mappingKey);

          const localMappings = Object.entries(automationMappings)
            .filter(([mappingKey]) => !globalMappings.includes(mappingKey))
            .map(([mappingKey]) => mappingKey);

          return {
            id: automation.id,
            type: automation.ttemplate?.type,
            title:
              automation.title ||
              automation.ttemplate?.title ||
              automation.ttemplate?.name,
            globalMappings,
            localMappings,
          };
        });

      return {
        uniqueByTemplate,
        automations,
      };
    },
    {
      queryKey: ['automations', 'automationTemplates'],
      variables: {
        teamId,
        isPartnerAdmin,
        isVrpAdmin,
        isSuperAdmin,
        accountingConnectionId: accounting?.id,
        finalyticConnectionId,
      },
      skip: !teamId,
    }
  );

  const mappings = useMemo<GlobalMappingTabProps[]>(() => {
    if (!data?.uniqueByTemplate.length) return [];

    const globals = data.uniqueByTemplate.reduce<GlobalMappingTabProps[]>(
      (acc, template) => {
        Object.entries(template.mappings)
          .filter(([key, mapping]) => {
            if (!NEW_OWNERS) return true;

            if (
              key === 'vendor' &&
              mapping.left.schema === 'finalytic.listingOwner'
            )
              return false;

            return true;
          })
          .forEach(([settingKey, mapping]) => {
            // const viewSettings = template.automation.viewSettings?.[settingKey];

            if (
              mapping.scope !== 'local' &&
              !acc.find((x) => x.settingKey === settingKey)
              // &&
              // !viewSettings?.leftType &&
              // !viewSettings?.isLocal
            ) {
              acc.push({
                settingKey,
                mapping,
                automation: template.automation,
                automations: {
                  local: sortBy(
                    data?.automations.filter((x) =>
                      x.localMappings.includes(settingKey)
                    ) || [],
                    (x) => x.title || ''
                  ),
                  global: sortBy(
                    data?.automations.filter((x) =>
                      x.globalMappings.includes(settingKey)
                    ) || [],
                    (x) => x.title || ''
                  ).sort((a, b) => {
                    if (settingKey !== 'location') return 0;

                    if (a.type === 'accounting.reservationInvoices') return -1;
                    if (b.type === 'accounting.reservationInvoices') return 1;

                    return 0;
                  }),
                },
              });
            }
          });
        return acc;
      },
      []
    );

    return sortBy(globals, (x) => x.mapping.label);
  }, [data, NEW_OWNERS]);

  return {
    loading,
    mappings,
  };
}
