import { registerSW } from 'virtual:pwa-register';
import { ErrorBoundary } from '@finalytic/data-ui';
import {
  ExtendedCustomColors,
  MantineProvider,
  V2Provider,
} from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { useIntercomStore } from '../stores';
import { ReloadPrompt } from './ReloadPrompt';

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');

if (!isLocalhost) {
  // POSTHOG
  posthog.init('phc_xIipUnhPJJfmkSZGObf95hIkwOcfT4bU5msyu7jZy8H', {
    api_host: 'https://pineapple.vrplatform.app',
  });

  // SENTRY
  Sentry.init({
    dsn: 'https://4ba9b6d2701d4a889e6f323c050a4a46@o515442.ingest.sentry.io/5624987',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.DEV ? 'development' : 'production',
    tracesSampleRate: 0.1,
    tunnel: 'https://pomegranate.vrplatform.app',
    integrations: [
      new posthog.SentryIntegration(posthog, 'finalytic', 5624987),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          'https://finalytic.hasura.app',
          'https://graphql.vrp.rocks',
          'https://clerk.vrplatform.app/v1/client/.*',
          'https://cdn.segment.com/v1/projects.*',
        ],
        networkCaptureBodies: true,
        networkResponseHeaders: ['X-Request-Id'],
        networkRequestHeaders: ['Finalytic-Platform'],
      }),
      window.location.hostname === 'staging.portal.vrplatform.app'
        ? Sentry.feedbackIntegration({
            colorScheme: 'light',
            showEmail: false,
            showName: false,
            showBranding: false,
            themeLight: {
              submitBackground: '#025FCA',
              submitBackgroundHover: '#024797',
              submitBorder: '#025FCA',
              submitOutlineFocus: '#025FCA',
            },
          })
        : undefined,
    ].filter(hasValue),
  });
}

if ('serviceWorker' in navigator) {
  registerSW({ immediate: true });
}

const INTERCOM_APP_ID = 'ec8spyh1';

export const RootWrapper = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  const setUnread = useIntercomStore((state) => state.setUnreadCount);

  return (
    <PostHogProvider client={posthog}>
      <IntercomProvider appId={INTERCOM_APP_ID} onUnreadCountChange={setUnread}>
        <ErrorBoundary>
          <V2Provider>
            <MantineProvider themePrimaryColor={themePrimaryColor}>
              {children}
              <ReloadPrompt />
            </MantineProvider>
          </V2Provider>
        </ErrorBoundary>
      </IntercomProvider>
    </PostHogProvider>
  );
};
