import { Button, Filter } from '@finalytic/components';
import { gqlV2, useInfiniteQuery, useTeamId } from '@finalytic/data';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { day, ensure, hasValue, toTitleCase } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import {
  getAutomationName,
  getListingAddress,
  getListingName,
  whereConnectionStatusDefault,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ListingNameCell } from '../../views/listings/_components/ListingNameCell';

type IssueFilter = {
  search: string | undefined;
  status: string[] | undefined;
  automationIds?: string[];
};

type IssueRow = NonNullable<
  ReturnType<typeof useIssueQuery>['data']
>['pages'][number]['list'][number];

export type GetIssueWhere = (
  baseWhere: gqlV2.issue_bool_exp,
  filter: IssueFilter
) => gqlV2.issue_bool_exp;

type Props = {
  where: GetIssueWhere;
};

export const LegacyIssuesTable = ({ where }: Props) => {
  const [filter, setFilter] = useSetState<IssueFilter>({
    search: undefined,
    status: undefined,
    automationIds: undefined,
  });

  const queryData = useIssueQuery(filter, where);

  const columns = useMemo(
    () =>
      ensure<(MRT_ColumnDef<IssueRow> | undefined)[]>([
        {
          header: 'Item',
          Cell: ({ row }) => {
            const refType = row.original.refType;

            if (refType === 'listing') {
              return <ListingNameCell {...row.original.refListing} />;
            }

            return null;
          },
        },
        {
          accessorKey: 'refType',
          header: 'Section',
          Cell: ({ cell }) => toTitleCase(cell.getValue<string>()),
        },
        {
          accessorKey: 'message',
          header: 'Issue',
        },
        {
          accessorKey: 'createdAt',
          header: 'Date',
        },

        {
          accessorKey: 'kind',
          header: 'kind',
        },
        // {
        //   accessorKey: 'subType',
        //   header: 'subType',
        // },
        {
          accessorKey: 'type',
          header: 'Button',
          Header: '',
          mantineTableBodyCellProps: { align: 'right' },
          Cell: ({ row }) => {
            return <IssueFixButton {...row.original} />;
          },
        },
      ]).filter(hasValue),
    []
  );

  return (
    <>
      <InfiniteTable
        queryData={queryData}
        columns={columns}
        table={{
          emptyRowsFallback: 'No issues found',
        }}
        resetFilter={() => setFilter({ search: undefined })}
      >
        <Group>
          <Filter.Search
            value={filter.search || ''}
            setValue={(search) => setFilter({ search })}
          />
        </Group>
      </InfiniteTable>
    </>
  );
};

function useIssueQuery(filter: IssueFilter, getWhere: GetIssueWhere) {
  const [teamId] = useTeamId();
  const trimmed = filter.search?.trim();
  const where = useMemo<gqlV2.issue_bool_exp>(() => {
    const searchWhere: gqlV2.issue_bool_exp[] | undefined = !trimmed
      ? undefined
      : [
          {
            message: { _ilike: `%${trimmed}%` },
          },
        ];

    const baseWhere: gqlV2.issue_bool_exp = {
      tenantId: { _eq: teamId },
      status: { _eq: 'open', _is_null: false },
      automationId: { _is_null: false },
      uniqueRef: { _is_null: false },
      _or: searchWhere,
    };

    return getWhere(baseWhere, filter);
  }, [teamId, trimmed, filter.status, filter.automationIds, getWhere]);

  return useInfiniteQuery(
    (query, { where }, { limit, offset }) => {
      const distinct_on: gqlV2.issue_select_column[] = ['uniqueRef'];

      const aggregate =
        query.aggregateIssues({ where, distinct_on }).aggregate?.count() || 0;

      const list = query
        .issues({
          where,
          limit,
          offset,
          order_by: [{ uniqueRef: 'desc_nulls_last' }, { createdAt: 'desc' }],
          distinct_on,
        })
        .map((issue) => {
          return {
            id: issue.id,
            message: issue.message,
            createdAt: day(issue.createdAt).format('D MMM, YYYY'),
            kind: issue.kind,
            type: issue.type,
            // subType: issue.subType,
            status: issue.status,
            refType: issue.refType,
            automation: {
              id: issue.automation?.id,
              name: getAutomationName(issue.automation),
              templateType: issue.automation?.ttemplate?.type,
            },
            refListing: {
              id: issue.refListing?.id,
              name: getListingName(issue.refListing),
              address: getListingAddress(issue.refListing).full,
              connections: issue.refListing
                ?.connections({
                  where: {
                    status: whereConnectionStatusDefault,
                  },
                })
                .map((con) => ({
                  id: con.id,
                  iconRound: con?.connection.app?.iconRound,
                  name: con?.connection.name,
                })),
            },
            refValue: issue.refValue,
            refId: issue.refId,
            code: issue.code,
            metadata: issue.metadata(),
          };
        });
      return { list, aggregate };
    },
    {
      variables: {
        where,
      },
      refetchOnWindowFocus: true,
      queryKey: 'issues',
    }
  );
}

const IssueFixButton = ({ type, metadata, automation }: IssueRow) => {
  if (type === 'mapping') {
    const automationId = automation?.id;
    const data = metadata as {
      mappingKey: string;
      isLocal: boolean;
    };

    const disabled = !data?.mappingKey || !automationId;
    const getHref = () => {
      if (data.isLocal) {
        return `/automations/${automationId}/mapping/${data.mappingKey}`;
      } else {
        return `/mappings/${data.mappingKey}`;
      }
    };

    return (
      <Button disabled={disabled} component={Link} to={getHref()}>
        Fix mapping
      </Button>
    );
  }

  return null;
};
