import { useEnabledFeatures, useHasEnabledFeatures } from '@finalytic/data';
import { VerifyTokenView } from '@finalytic/data-ui';
import { LoadingIndicator } from '@finalytic/ui';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PmDashboard } from '../../views/dashboard/PmDashboard';

const EditAutomationView = lazy(
  () => import('../../views/automations/views/edit-automation')
);
const AutomationsOverviewView = lazy(
  () => import('../../views/automations/views/overview-automations')
);
const FeaturesView = lazy(() => import('../../views/features'));
const ConnectionsViewV2 = lazy(() => import('../../views/connections'));
const StatementListViewV2 = lazy(
  () => import('../../views/statements/statement-list/StatementListView')
);

const StatementSettingsView = lazy(
  () => import('../../views-legacy/StatementSettings')
);
const StatementView = lazy(() => import('../../views/statements/statement'));
const LedgerStatementView = lazy(
  () => import('../../views/statements/statement/LedgerStatementView')
);
const TaxStatementSettingsView = lazy(
  () => import('../../views/statements/1099')
);
const StatementSummaryView = lazy(
  () => import('../../views-legacy/StatementSummary')
);

const StatementSummaryViewV2 = lazy(
  () => import('../../views/statements/summary')
);
const ReservationsViewV2 = lazy(() => import('../../views/reservations'));
const ItemsView = lazy(() => import('../../views/items'));
const GlobalMappingsView = lazy(() => import('../../views/global-mappings'));
const FilesView = lazy(() => import('../../views/files'));

const ListingListView = lazy(() => import('../../views/listings/list'));
const ListingView = lazy(
  () => import('../../views/listings/detail/ListingDetailView')
);

const GettingStartedView = lazy(
  () => import('../../views/getting-started/GettingStartedView')
);
const PaymentsViewV2 = lazy(() => import('../../views/payments'));
const SettingsView = lazy(() => import('../../views/settings'));

const ReportsView = lazy(() => import('../../views-legacy/ReportsView'));
const OwnersViewV2 = lazy(() => import('../../views/owners'));
const OwnersViewV3 = lazy(() => import('../../views/owners-v2'));

const TestingView = lazy(() => import('../../views/testing/TestingView'));

const TrpcTestingView = lazy(
  () => import('../../views-legacy/TrpcTestingView')
);

const StatementTemplatesView = lazy(
  () => import('../../views/statements/templates')
);

const CustomFeesView = lazy(
  () => import('../../views/custom-fees/CustomFeesView')
);

const RecurringFeesView = lazy(() => import('../../views/recurring-fees'));

const GeneralLedgerView = lazy(
  () => import('../../views/general-ledger/GeneralLedgerView')
);

const ExpensesView = lazy(() => import('../../views/expenses'));

export const PmRoutes = () => {
  const { search } = useLocation();

  const { SETUP_GUIDE_UI, NEW_OWNERS, GL, ISSUE_UI } = useEnabledFeatures();
  const hasEnabledFeatures = useHasEnabledFeatures();

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route
          path="token-verify"
          element={<VerifyTokenView isAuthenticated />}
        />

        <Route index element={<PmDashboard />} />

        {ISSUE_UI && (
          <Route path="dashboard">
            <Route
              index
              element={<Navigate to="/dashboard/overview" replace />}
            />
            <Route path=":tabValue" element={<PmDashboard />} />
          </Route>
        )}

        {SETUP_GUIDE_UI && (
          <Route path="getting-started" element={<GettingStartedView />} />
        )}

        <Route
          path="reservations"
          element={<ReservationsViewV2 limit={undefined} />}
        />
        <Route path="payments" element={<PaymentsViewV2 limit={undefined} />} />
        <Route path="items" element={<ItemsView />} />
        <Route path="mappings" element={<GlobalMappingsView />}>
          <Route index element={<GlobalMappingsView />} />
          <Route path=":tabValue" element={<GlobalMappingsView />} />
        </Route>
        <Route path="listings" element={<ListingListView />} />
        {GL && (
          <>
            <Route path="listing/:listingId" element={<ListingView />} />
            <Route
              path="listing/:listingId/:tabValue"
              element={<ListingView />}
            />
          </>
        )}
        <Route
          path="owners"
          element={NEW_OWNERS ? <OwnersViewV3 /> : <OwnersViewV2 />}
        />
        <Route path="connections" element={<ConnectionsViewV2 />} />
        <Route path="files" index element={<FilesView />} />

        <Route path="testing" element={<TestingView />} />
        <Route path="testing2" element={<TrpcTestingView />} />

        <Route path="automations" element={<AutomationsOverviewView />} />
        <Route path="automations/:id" element={<EditAutomationView />} />

        <Route path="reports">
          <Route index element={<ReportsView />} />
          <Route path=":id" element={<ReportsView />} />
        </Route>

        <Route
          path="owner-statements"
          element={<Navigate to={`/statements${search}`} replace />}
        />

        <Route path="statement">
          {GL ? (
            <Route path=":periodId" element={<LedgerStatementView />} />
          ) : (
            <Route path=":listingId" element={<StatementView />} />
          )}
        </Route>
        <Route path="statements">
          <Route index element={<Navigate to="owner-statements" replace />} />

          <Route
            path="summary"
            element={
              NEW_OWNERS ? <StatementSummaryViewV2 /> : <StatementSummaryView />
            }
          />
          {NEW_OWNERS && (
            <>
              <Route
                index
                path="tax-statements/tax-settings"
                element={<Navigate to="accounts" replace />}
              />
              <Route
                path="tax-statements/tax-settings/:tabValue"
                element={<TaxStatementSettingsView />}
              />
            </>
          )}
          <Route
            path="templates"
            element={<Navigate to="/statements/templates/default" replace />}
          />
          <Route
            path="templates/:tabValue"
            element={<StatementTemplatesView />}
          />
          <Route path="settings/*" element={<StatementSettingsView />} />

          <Route
            path=":tabValue"
            element={<StatementListViewV2 limit={undefined} />}
          />
        </Route>

        {GL ? (
          <>
            <Route path="expenses" element={<ExpensesView />} />
            <Route path="general-ledger" element={<GeneralLedgerView />} />
            <Route path="fees-commissions/*" element={<RecurringFeesView />} />
          </>
        ) : (
          <>
            <Route path="custom-fees" element={<CustomFeesView />} />
            <Route path="custom-fees/:tabValue" element={<CustomFeesView />} />
            <Route
              path="custom-fees/default/:commission"
              element={<CustomFeesView />}
            />
          </>
        )}

        <Route path="settings/*" element={<SettingsView />} />

        {hasEnabledFeatures && (
          <>
            <Route path="features">
              <Route path=":tabValue" element={<FeaturesView />} />
              <Route index element={<Navigate to="features" replace />} />
            </Route>
          </>
        )}
        <Route
          path="callback"
          element={
            <Navigate
              to={`/connections${window.location.search || ''}&callback=true`}
            />
          }
        />

        <Route
          path="connect-accounting"
          element={<Navigate to={`/${window.location.search || ''}`} />}
        />

        <Route path="checkout-redirect" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};
