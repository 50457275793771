import { ChevronIcon, CrossIcon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import {
  Center,
  Group,
  Text,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { forwardRef } from 'react';

type Props = {
  opened: boolean;
  isDefault: boolean;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  resetFilter?: () => void;
};

export const FilterPill = forwardRef<HTMLDivElement, Props>(
  ({ isDefault, opened, onClick, label, resetFilter, icon }, ref) => {
    const { colors, primaryColor } = useMantineTheme();
    const { colorScheme } = useMantineColorScheme();

    const isDarkTheme = colorScheme === 'dark';

    return (
      <Group
        ref={ref}
        wrap="nowrap"
        onClick={onClick}
        justify="space-between"
        sx={(theme) => ({
          cursor: 'pointer',
          borderStyle: isDefault && !opened ? 'dashed' : 'solid',
          borderRadius: theme.radius.xl,
          borderWidth: '1px',
          height: '2rem',
          padding: '0.25rem 0.5rem',
          width: '100%',
          boxShadow: opened
            ? `0px 0px 0px 2px ${theme.colors[theme.primaryColor][4]}40`
            : undefined,
          borderColor:
            opened || !isDefault
              ? theme.colors[theme.primaryColor][isDarkTheme ? 4 : 6]
              : theme.colors.gray[colorScheme === 'dark' ? 8 : 3],
          backgroundColor: !isDefault
            ? isDarkTheme
              ? theme.colors[theme.primaryColor][8] + 40
              : theme.colors[theme.primaryColor][0] + 70
            : undefined,
        })}
      >
        {icon ? (
          <Group gap={rem(8)} pl={rem(3)} wrap="nowrap">
            <Center
              pb={rem(1)}
              sx={(theme) => ({
                flexShrink: 0,
                'svg, path': {
                  stroke: theme.colors[theme.primaryColor][7],
                },
              })}
            >
              {icon}
            </Center>
            <Text
              size="xs"
              component="span"
              sx={{
                color: isDefault
                  ? colors.neutral[5]
                  : colors[primaryColor][isDarkTheme ? 4 : 7],
              }}
              lineClamp={1}
            >
              {label}
            </Text>
          </Group>
        ) : (
          <Text
            size="xs"
            component="span"
            sx={{
              color: isDefault
                ? colors.neutral[5]
                : colors[primaryColor][isDarkTheme ? 4 : 7],
            }}
            lineClamp={1}
          >
            {label}
          </Text>
        )}

        {isDefault || !resetFilter ? (
          <ChevronIcon
            size={16}
            color={isDefault ? colors.neutral[4] : colors[primaryColor][3]}
            sx={{
              flexShrink: 0,
            }}
          />
        ) : (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              resetFilter();
            }}
            p={0}
            m={0}
            sx={{ width: 'auto', minWidth: 0, flexShrink: 0 }}
          >
            <CrossIcon size={16} color={colors[primaryColor][3]} />
          </IconButton>
        )}
      </Group>
    );
  }
);
