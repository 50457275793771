import { Button } from '@finalytic/components';
import { useColors } from '@finalytic/ui';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text } from '@mantine/core';
import { ConfirmModalResultIcon } from '../_components';

type ErrorScreenProps = {
  resultMessage: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const ErrorScreen = ({
  resultMessage,
  onClose,
  onSubmit,
}: ErrorScreenProps) => {
  const { gray } = useColors();

  return (
    <>
      <Stack gap={0} mb="lg" align="center" justify="center" sx={{ flex: 1 }}>
        <ConfirmModalResultIcon color="#FFEEEE">
          <FontAwesomeIcon icon={faExclamation} color="#FF5656" size="lg" />
        </ConfirmModalResultIcon>
        <Text fw={500} mb={10} sx={{ textAlign: 'center' }}>
          {resultMessage}
        </Text>
      </Stack>

      <Button
        variant="primary"
        onClick={onSubmit}
        sx={{ width: '100%', height: 40, marginBottom: 10 }}
      >
        Retry
      </Button>
      <Button onClick={onClose} sx={{ width: '100%', color: gray.dark }}>
        Cancel
      </Button>
    </>
  );
};
