import { LoadingIndicator } from '@finalytic/ui';
import { NumberInput, NumberInputProps } from '@mantine/core';
import { ComponentPropsWithRef, forwardRef } from 'react';

type Props = {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  setValue: (v: number | string) => void;
  value: (number | string) | undefined;
} & Omit<NumberInputProps, 'onChange' | 'value'> &
  Omit<ComponentPropsWithRef<'input'>, 'onChange' | 'value'>;

export const InputAmount = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      setValue,
      placeholder,
      loadingMutation,
      loadingQuery,
      leftSection,
      ...props
    },
    ref
  ) => {
    return (
      <NumberInput
        ref={ref}
        value={value}
        onChange={setValue}
        sx={(theme) => ({
          height: '100%',
          'input:focus': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
          },
        })}
        placeholder={placeholder}
        leftSection={
          loadingQuery ? <LoadingIndicator size="xs" /> : leftSection
        }
        rightSection={loadingMutation && <LoadingIndicator size="xs" />}
        {...props}
      />
    );
  }
);
