import { Maybe, listingOwnershipPeriod_bool_exp } from '@finalytic/graphql';

export const whereListingPeriod = ({
  listingId,
  ownerId,
  date,
}: {
  listingId?: Maybe<string>;
  ownerId?: Maybe<string>;
  date?: Maybe<string>;
}): listingOwnershipPeriod_bool_exp => {
  return {
    listingId: listingId ? { _eq: listingId } : undefined,
    members: ownerId ? { ownerId: { _eq: ownerId } } : undefined,
    _or: date
      ? [
          {
            startAt: {
              _lte: date,
            },
            endAt: {
              _gte: date,
            },
          },
          {
            startAt: {
              _lte: date,
            },
            endAt: {
              _is_null: true,
            },
          },
        ]
      : undefined,
  };
};
