import { Button, Input } from '@finalytic/components';
import { captureSentryError } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { Box } from '@mantine/core';
import { ChangeEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IntegrationList,
  Layout,
  LayoutProps,
  OnboardingIntegration,
  SidebarConnect,
} from '../components';
import { useIntegrationHover, useOnboardingDevMode } from '../hooks';

type ConnectViewProps = {
  onIntegrationSubmit: (integration: OnboardingIntegration) => void;
  onSearch: (searchInput: string) => void;
  onMissingIntegrationSubmit: () => void;
  integrations: OnboardingIntegration[];
  loading: boolean;
} & (
  | {
      type: 'Accounting';
      handleSkipOnboarding: () => Promise<void>;
    }
  | {
      type: 'PMS';
    }
);

export const ConnectView = ({
  onIntegrationSubmit,
  integrations,
  onMissingIntegrationSubmit,
  loading,
  ...props
}: ConnectViewProps) => {
  const [search, setSearch] = useState('');
  const [loadingSkip, setLoadingSkip] = useState(false);

  const isDevMode = useOnboardingDevMode();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const s = event.target.value;

    setSearch(s);
    props.onSearch(s);
  };

  const { setAccounting, setPms } = useIntegrationHover();

  const setIntegration = (integration: OnboardingIntegration | undefined) => {
    if (props.type === 'Accounting') {
      setAccounting(integration);
    } else {
      setPms(integration);
    }
  };

  return (
    <Box maw={550} mx="auto">
      {/* Input */}
      <Input
        value={search}
        onChange={onInputChange}
        name="integrationSearch"
        placeholder={`Search for your ${props.type}...`}
        leftSection={<Icon icon="SearchIcon" size={18} />}
        type="text"
        mb="xl"
      />
      {/* Applist */}
      <IntegrationList
        integrations={integrations}
        notListedTitle={
          props.type === 'Accounting'
            ? 'My Accounting is not listed'
            : 'My PMS is not listed'
        }
        loading={loading}
        setIntegration={setIntegration}
        handleIntegrationSubmit={onIntegrationSubmit}
        handleMissingIntegration={onMissingIntegrationSubmit}
      />

      {props.type === 'PMS' && isDevMode && (
        <Button
          component={Link}
          data-testid="skip-pms"
          to={`/connect-accounting${location.search}`}
          sx={{
            width: '100%',
            marginTop: 70,
          }}
        >
          Skip this step
        </Button>
      )}

      {props.type === 'Accounting' && (
        <Button
          id="skip-onboarding"
          data-testid="skip-onboarding"
          variant="primary"
          sx={{
            width: '100%',
            marginTop: 70,
          }}
          loading={loadingSkip}
          onClick={async () => {
            try {
              setLoadingSkip(true);
              await props.handleSkipOnboarding?.();
            } catch (error: any) {
              console.error(error);
              captureSentryError(error);
            } finally {
              setLoadingSkip(false);
            }
          }}
        >
          Skip and finish onboarding
        </Button>
      )}
    </Box>
  );
};

export const ConnectStorybookView = ({
  title,
  ...props
}: ConnectViewProps & LayoutProps) => {
  const { pms, accounting } = useIntegrationHover();

  return (
    <Layout
      title={title}
      handleLogout={undefined}
      // setTeam={() => null}
      // team={undefined}
      sidebar={
        <SidebarConnect
          pms={
            props.type === 'PMS'
              ? pms
              : {
                  id: 'apaleo',
                  icon: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/b39826af-5759-4b72-7946-d09e22bb7000/smol2',
                  name: 'Apaleo',
                }
          }
          accounting={accounting}
          title={
            props.type === 'PMS' ? 'Choose your PMS' : 'Choose your Accounting'
          }
          subtitle="We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings."
        />
      }
      // teams={[]}
    >
      <ConnectView {...props} />
    </Layout>
  );
};
