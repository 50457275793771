import { z } from 'zod';

// Zod parsing
// Payout ID,Payout Date,Payout Amount,Currency,Type,Description,Confirmation Code,Amount
// 123,2023-01-01,1000,EUR,payout,,
// 123,2023-01-01,1000,EUR,reservation,,ABC-123,300
// 123,2023-01-01,1000,EUR,reservation,,ABC-234,800
// 123,2023-01-01,1000,EUR,resolution,"Some Explanation",ABC-234,-100

export const deposits_csv_import_schema = z.object({
  'Payout ID': z.coerce.string({ required_error: 'Payout ID is required' }),
  'Payout Date': z.string().refine(
    (date) => {
      return date.match(/^\d{4}-\d{2}-\d{2}$/);
    },
    {
      message: 'Please provide a date in the format YYYY-MM-DD',
    }
  ),
  'Payout Amount': z.number({
    required_error: 'Payout Amount is required',
    invalid_type_error: 'Payout Amount must be a number',
  }),
  Currency: z.string({
    required_error: 'Currency is required',
    invalid_type_error: 'Currency must be a string',
  }),
  Type: z.enum(['payout', 'reservation'], {
    invalid_type_error: 'Type must be either "payout" or "reservation"',
    required_error: 'Type is required',
  }),
  Description: z.string().nullable(),
  'Confirmation Code': z.string().nullable(),
  Amount: z.number().nullable(),
});
