import { utc } from '@finalytic/utils';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Center, Group, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';
import { ReservationStatusPill, useColors } from '../../../';
import { CheckInIcon, CheckOutIcon } from './_icons';

type ReservationDetailsProps = {
  guestName?: string | null;
  status?: string | null;
  checkIn?: string | null;
  checkOut?: string | null;
  bookingAt?: string | null;
  confirmationCode?: string | null;
  listingName?: string | null;
  appIcon?: string | null;
  appName?: string | null;
};

function useNoWrap() {
  return useMediaQuery('(min-width: 900px)');
}

export const DrawerReservationDetails = ({
  checkIn,
  checkOut,
  guestName,
  status,
  bookingAt,
  confirmationCode,
  listingName,
  appIcon,
  appName,
}: ReservationDetailsProps) => {
  const { gray, border } = useColors();

  const noWrap = useNoWrap();

  return (
    <Group justify="space-between" wrap={noWrap ? 'nowrap' : undefined}>
      <Box mx={20} sx={{ flexGrow: 0 }}>
        <Group wrap={'nowrap'} mb={10}>
          {appIcon && (
            <Avatar src={appIcon} alt={appName || 'App Connection'} size="md" />
          )}
          <Box>
            <Text size="sm" mb={0}>
              {confirmationCode}
            </Text>
            <Text mb={0} sx={{ fontSize: 28, fontWeight: 500, lineHeight: 1 }}>
              {guestName}
            </Text>
          </Box>
        </Group>
        <ReservationStatusPill status={status} bookedAt={bookingAt} />
      </Box>
      <Group
        wrap="nowrap"
        sx={{
          flex: 1,
          border: '1px solid',
          borderColor: border.gray,
          borderRadius: 20,
          maxWidth: 600,
        }}
      >
        <CheckInOutContainer
          text={listingName || ''}
          title="Listing"
          icon={
            <Box sx={{ width: 24 }}>
              <FontAwesomeIcon icon={faHouse} color={gray.dark} />
            </Box>
          }
          withBorderRight
        />

        <CheckInOutContainer
          text={checkIn ? utc(checkIn).format('MMMM D, YYYY') : ''}
          title="Check In"
          icon={<CheckInIcon />}
          withBorderRight={true}
        />

        <CheckInOutContainer
          text={checkOut ? utc(checkOut).format('MMMM D, YYYY') : ''}
          title="Check Out"
          icon={<CheckOutIcon />}
          withBorderRight={false}
        />
      </Group>
    </Group>
  );
};

const CheckInOutContainer = ({
  title,
  text,
  icon,
  withBorderRight,
}: {
  title: string;
  text?: string;
  icon: ReactNode;
  withBorderRight: boolean;
}) => {
  const noWrap = useNoWrap();

  const { border, gray } = useColors();

  return (
    <Center
      sx={{
        flex: 1,
        height: 110,
        minWidth: noWrap ? 150 : undefined,
        borderRight: withBorderRight ? '1px solid' : undefined,
        borderColor: border.gray,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {icon}
        <Text size="sm" c={gray.dark} mb={4} fw={500}>
          {title}
        </Text>
        <Text
          size="sm"
          sx={{ height: 16, lineHeight: '16px' }}
          fw={500}
          lineClamp={1}
        >
          {text}
        </Text>
      </Box>
    </Center>
  );
};
