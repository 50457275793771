import { Select } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeam,
  useTeamId,
  useTeamRole,
} from '@finalytic/data';
import { ChevronIcon, Icon, PlusIcon } from '@finalytic/icons';
import {
  BooleanParam,
  IconButton,
  Logo,
  SelectItem,
  StringParam,
  VrpDarkLogo,
  useQueryParams,
} from '@finalytic/ui';
import { hasValue, isUUID } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  Stack,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { openSpotlight } from '@mantine/spotlight';
import { forwardRef, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useNavbarExpanded } from '../../hooks';

export const NavbarTeamSwitch = () => {
  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Group
      wrap="nowrap"
      justify={isDesktopExpanded ? 'flex-start' : 'center'}
      sx={{
        flex: 1,
        flexDirection: isDesktopExpanded ? undefined : 'column',
      }}
    >
      <DashboardLogo />
      <TeamSwitch />
    </Group>
  );
};

const DashboardLogo = () => {
  const { isDesktopExpanded } = useNavbarExpanded();
  const { isPartnerAdmin } = useTeamRole();

  const [team] = useTeam();
  const [dashboard] = useDashboard();
  const logo = dashboard === 'owner' ? team.logo : undefined;

  const { white } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Group
      wrap="nowrap"
      sx={{
        flexShrink: 0,
        justifyContent: isDesktopExpanded ? 'flex-start' : 'center',
        flexDirection: isDesktopExpanded ? undefined : 'column',
      }}
      w={40}
    >
      <Box
        onClick={() => isPartnerAdmin && openSpotlight()}
        sx={{
          backgroundColor: colorScheme === 'dark' ? undefined : white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 5,
          borderRadius: 10,
          cursor: isPartnerAdmin ? 'pointer' : undefined,
        }}
      >
        {colorScheme === 'dark' && dashboard !== 'owner' ? (
          <VrpDarkLogo />
        ) : (
          <Logo img={logo} />
        )}
      </Box>
    </Group>
  );
};

function useTeams({ search: s }: { search: string }) {
  const { id: meId } = useMe();
  const search = s.trim();
  const { OVERVIEW_UI } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { search }, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = {
        status: { _neq: 'deleted' },
        type: { _nin: ['partner', 'admin'] },
      };
      if (search) {
        where._or = [
          {
            name: { _ilike: `%${search}%` },
          },
        ];
        if (isUUID(search))
          where._or?.push({
            id: {
              _eq: search,
            },
          });
      }

      const aggregate = q.tenantAggregate({ where })?.aggregate?.count() || 0;

      const list = q
        .tenant({
          order_by: [{ type: 'asc_nulls_last' }, { name: 'asc' }],
          where,
          limit: limit,
          offset,
        })
        .map<SelectItem>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'teams',
      variables: {
        search,
      },
    }
  );

  const { data, isLoading: loading2 } = useQuery(
    (q, args) => {
      const canSwitch =
        (q
          .tenantAggregate({ where: { status: { _neq: 'deleted' } } })
          ?.aggregate?.count() || 0) > 1;

      const pinnedTeams = q
        .tenant({
          where: {
            status: { _neq: 'deleted' },
            type: { _in: ['admin', 'partner'] },
          },
          order_by: [{ name: 'asc' }],
        })
        .map<SelectItem>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      const isMultiplePartnerAdmin =
        (q
          .tenantUserAggregate({
            where: {
              tenant: { type: { _eq: 'partner' } },
              role: { _eq: 'admin' },
              userId: { _eq: args.meId },
            },
          })
          ?.aggregate?.count() || 0) > 1;

      const isInMultipleTeams =
        (q
          .tenantUserAggregate({
            where: {
              tenant: { type: { _neq: 'partner' } },
              userId: { _eq: args.meId },
            },
          })
          .aggregate?.count() || 0) > 1;

      return {
        canSwitch,
        pinnedTeams,
        showTeamOverview: isMultiplePartnerAdmin || isInMultipleTeams,
      };
    },
    {
      queryKey: 'teams',
      variables: {
        meId,
      },
    }
  );

  return {
    queryData,
    loadingValue: loading2,
    canSwitch: !!data?.canSwitch,
    pinnedTeams: data?.pinnedTeams || [],
    showTeamOverview: !!data?.showTeamOverview && OVERVIEW_UI,
  };
}

const TeamSwitch = () => {
  const [searchParams] = useSearchParams();
  const [, setDrawer] = useQueryParams({
    'add-team-drawer': BooleanParam,
    'add-team-name': StringParam,
  });

  const openAddTeamModal = (teamName: string | undefined) => {
    setDrawer({
      'add-team-drawer': true,
      'add-team-name': teamName,
    });
  };

  const [team] = useTeam();
  const { isPartnerAdmin, isVrpAdmin } = useTeamRole();
  const [, setTeamId] = useTeamId();
  const goto = useNavigate();

  const { isDesktopExpanded } = useNavbarExpanded();
  const [dashboard, toggleTeamOverview] = useDashboard();

  const [search, setSearch] = useState('');

  const { queryData, loadingValue, canSwitch, pinnedTeams, showTeamOverview } =
    useTeams({
      search,
    });

  const value = useMemo(() => {
    if (dashboard === 'overview') return null;

    if (!team.id) return null;

    return {
      label: team.name || '',
      value: team.id || '',
    };
  }, [team.id, dashboard]);

  const customActionTop = useMemo(
    () => ({
      label: 'Overview',
      icon: <Icon icon="DashboardIcon" size={16} />,
      onSubmit: () => {
        toggleTeamOverview(true);
        goto('/');
      },
    }),
    []
  );

  return (
    <>
      <Stack
        gap={0}
        sx={{
          color: '#fff',
          flex: isDesktopExpanded ? 1 : 0,
        }}
      >
        {isDesktopExpanded && (
          <Text sx={{ opacity: 0.8 }} size="xs">
            {isVrpAdmin ? team.partnerName || 'Team' : 'Team'}
          </Text>
        )}
        <Select
          type="single"
          setValue={(value) => {
            if (!value?.value) return;

            setTeamId(value?.value);

            const hasKeys =
              [...searchParams.values()].filter(hasValue).length > 0;
            if (hasKeys) {
              goto(window.location.pathname);
            }
            toggleTeamOverview(false);
          }}
          infiniteData={{ ...queryData, setSearch }}
          dropdownProps={{
            withinPortal: true,
            position: isDesktopExpanded ? 'bottom' : 'bottom-start',
            width: 260,
          }}
          customActionTop={showTeamOverview ? customActionTop : undefined}
          customActionBottom={
            isPartnerAdmin || isVrpAdmin
              ? {
                  label: 'Add team',
                  icon: <PlusIcon />,
                  onSubmit: (v) => openAddTeamModal(v),
                }
              : undefined
          }
          pinnedItems={pinnedTeams}
          inputProps={{
            loadingQuery: loadingValue,
          }}
          value={value}
        >
          {({ value }) => {
            const isOverview = dashboard === 'overview';

            const label = isOverview
              ? customActionTop?.label
              : (value as any)?.label;
            return (
              <PopoverButton
                canSwitch={!!canSwitch}
                isNavigationExpanded={isDesktopExpanded}
                label={label}
                icon={
                  isOverview ? (
                    <Icon icon="DashboardIcon" size={14} color={'#fff'} />
                  ) : undefined
                }
              />
            );
          }}
        </Select>
      </Stack>
    </>
  );
};

const PopoverButton = forwardRef<
  HTMLDivElement,
  {
    label?: string;
    isNavigationExpanded: boolean;
    canSwitch: boolean;
    icon?: React.ReactNode;
  }
>(({ isNavigationExpanded, canSwitch, label, icon }, ref) => (
  <Group
    wrap="nowrap"
    justify="space-between"
    gap={0}
    ref={ref}
    pb={4}
    pl={isNavigationExpanded ? 8 : 0}
    ml={isNavigationExpanded ? -8 : 0}
    sx={{
      borderRadius: 7,
      maxWidth: 160,
      display: !isNavigationExpanded && !canSwitch ? 'none' : undefined,
      cursor: canSwitch ? 'pointer' : 'default',
      ':hover': {
        backgroundColor: canSwitch ? '#5C617840' : undefined,
      },
      '&, *': {
        overflowY: 'hidden',
      },
    }}
  >
    {isNavigationExpanded && (
      <Group gap={5} wrap="nowrap" pt={4} sx={{ height: '100%' }}>
        {icon && <Center>{icon}</Center>}
        <Text
          component="span"
          sx={{
            maxWidth: '100%',
            display: 'block',
            height: 20,
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: 'normal',
          }}
        >
          {label}
        </Text>
      </Group>
    )}

    {canSwitch && (
      <IconButton
        component="span"
        mb={-5}
        sx={{
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ChevronIcon size={17} color={'#ffffff80'} />
      </IconButton>
    )}
  </Group>
));
