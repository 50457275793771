import { useTeamId, useTrpcMutation } from '@finalytic/data';
import { InputFormSchema } from '@vrplatform/ui-common';

// const v2: {
//   [s: string]: string;
// } = {
//   bookingAutomation: 'legacy',
// };

export type ConnectFn = ReturnType<typeof useConnect>;
export function useConnect() {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('addConnection', {
    invalidateQueryKeys: ['connections', 'setupGuide'],
  });
  // const { mutate: setupConnection } = useTrpcMutation('setupConnection');
  // const connect = useApi('connect');
  // const client = useGqtyClient();
  // const spotlight = useSpotlightContext();

  // const ref = useRef<{ taskId: string | undefined }>({ taskId: undefined });
  return async ({
    sessionKey,
    connectionId,
    appId,
    params,
  }: {
    appId?: string;
    connectionId?: string;
    params: any;
    sessionKey?: string;
  }) => {
    // if (v2[String(appId)]) {
    //   console.log('Use V2');
    //   const workflowInfo = await setupConnection({
    //     queue: spotlight.hypervisorQueue,
    //     appId,
    //     connectionId,
    //     sessionKey: sessionKey,
    //     params,
    //     tenantId: teamId,
    //   });

    //   // Legacy
    //   // const workflowInfo = await client.mutate((mutate) => {
    //   //   const connection = mutate.setupConnection({
    //   //     object: {
    //   //       queue: spotlight.hypervisorQueue,
    //   //       appId,
    //   //       id: connectionId,
    //   //       sessionKey: sessionKey,
    //   //       params,
    //   //       tenantId: teamId,
    //   //       // redirectUri: `${window.location.origin}/callback/`,
    //   //     },
    //   //   });
    //   //   if (!connection) return undefined;
    //   //   return connection.data as { runId: string };
    //   // });
    //   if (!workflowInfo?.runId) throw new Error('Failed to create connection');
    //   ref.current.taskId = workflowInfo.runId;
    //   const result = await waitUntil(
    //     async () => {
    //       return client.query(
    //         (q) => {
    //           if (!workflowInfo.runId)
    //             return { token: undefined, task: undefined };
    //           const token = q.tokenById({
    //             id: workflowInfo.runId,
    //           })!;
    //           const task = q.task({
    //             id: workflowInfo.runId,
    //           })!;

    //           return {
    //             token: {
    //               id: token?.id,
    //               type: token?.type,
    //               payload: token?.payload(),
    //             },
    //             task: {
    //               id: task?.id,
    //               status: task?.status,
    //               message: task?.message,
    //               payload: task?.payload(),
    //               connectionId: task?.connectionId,
    //             },
    //           };
    //         },
    //         {
    //           noCache: true,
    //         }
    //       );
    //     },
    //     ({ task, token }) => {
    //       return (
    //         task?.status === 'success' ||
    //         task?.status === 'fail' ||
    //         (token?.type === 'form' && token?.payload.type === 'request') ||
    //         (token?.type === 'oauth' && token?.payload.type === 'request') ||
    //         token?.type === 'request'
    //       );
    //     },
    //     {
    //       retryEvery: '5s',
    //       timeoutAfter: '5m',
    //     }
    //   );

    //   if (result.task?.status === 'success') {
    //     return { type: 'success', error: undefined, issue: undefined };
    //   } else if (
    //     result.token?.type === 'form' ||
    //     result.token?.type === 'oauth'
    //   ) {
    //     return {
    //       type: 'issue' as const,
    //       issue: {
    //         type: 'issue' as const,
    //         message: result.token?.payload.message,
    //         code:
    //           result.token?.type === 'form'
    //             ? 'ISSUE_REQUEST_FORM'
    //             : 'ISSUE_REQUEST_LINK',
    //         state: {
    //           sessionKey: workflowInfo.runId,
    //           expiresIn: '',
    //         },
    //         action: {
    //           type: result.token?.type,
    //           value: result.token?.payload.value,
    //         },
    //       } as IssueType,
    //       error: undefined,
    //     };
    //   } else if (result.token?.type === 'request') {
    //     return {
    //       type: 'issue' as const,
    //       issue: {
    //         type: 'issue' as const,
    //         code: 'ISSUE_REQUEST_FORM',
    //         message: result.token?.payload.message,
    //         state: {
    //           sessionKey: workflowInfo.runId,
    //           expiresIn: '',
    //         },
    //         action: {
    //           type: result.token?.payload.type,
    //           value: result.token?.payload.value,
    //         },
    //       } as IssueType,
    //       error: undefined,
    //     };
    //   } else {
    //     return {
    //       type: 'error' as const,
    //       error: {
    //         title: 'Error',
    //         message: result.task?.message || 'Error',
    //       },
    //       issue: undefined,
    //     };
    //   }
    // } else if (location.href.includes('localhost')) {
    // me.isAdmin ||
    try {
      const data = await mutate({
        sessionKey,
        tenantId: teamId,
        appId,
        params,
        connectionId,
      });
      if ('id' in data) {
        // await fetchConnection({ input: extractInput });
        return { type: 'success', error: undefined, issue: undefined };
      }
      throw data;
    } catch (res: any) {
      // ISSUE
      if (res.type === 'issue') {
        return { type: 'issue', issue: res as IssueType, error: undefined };
      } else {
        return {
          type: 'error',
          error: { title: res?.name, message: res?.message },
          issue: undefined,
        };
      }
    }
    // } else {
    //   console.log('Use V1');
    //   try {
    //     const data = await connect(params, {
    //       appId,
    //       connectionId,
    //       sessionKey,
    //       sync: true,
    //     });
    //     if (data.id) {
    //       const extractInput: gqlV2.extract_connection_request = {
    //         id: data.id,
    //       };
    //       //await fetchConnection({ input: extractInput });
    //     }
    //     return { type: 'success', error: undefined, issue: undefined };
    //   } catch (res: any) {
    //     // ISSUE
    //     if (res.type === 'issue') {
    //       return { type: 'issue', issue: res as IssueType, error: undefined };
    //     } else {
    //       return {
    //         type: 'error',
    //         error: { title: res?.name, message: res?.message },
    //         issue: undefined,
    //       };
    //     }
    //   }
    // }
  };
}

export type IssueType = {
  type: 'issue';
  code: 'ISSUE_REQUEST_FORM' | 'ISSUE_REQUEST_LINK';
  message: string;
  state: {
    sessionKey: string;
    expiresIn: string;
  };
  action:
    | {
        value: InputFormSchema;
        type: 'form';
      }
    | {
        value: string;
        type: 'link' | 'oauth';
      };
};
