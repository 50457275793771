import { FloatingPosition, Popover as MantinePopover } from '@mantine/core';
import { ReactNode } from 'react';

export type PopoverProps = {
  popoverWidth?: number | string;
  position?: FloatingPosition;
  withinPortal?: boolean;
  withArrow?: boolean;
  offset?: number;
};

export const Popover = ({
  onClose,
  popoverWidth,
  children,
  opened,
  position = 'bottom-end',
  ...props
}: {
  onClose: () => void;
  children: ReactNode;
  opened: boolean;
} & PopoverProps) => (
  <MantinePopover
    opened={opened}
    onClose={onClose}
    width={popoverWidth}
    offset={0}
    withArrow
    trapFocus={false}
    withinPortal
    radius={10}
    position={position}
    shadow="md"
    styles={{
      dropdown: {
        padding: 8,
      },
      arrow: {
        right: '15px !important',
      },
    }}
    {...props}
  >
    {children}
  </MantinePopover>
);
