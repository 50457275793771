import { expression, groupBy, sum } from '@finalytic/utils';
import { NetRevenueColumn, StatementLine } from '../../statements';
import { defaultStatementFormulaFields } from './default-statement-formula-fields';
import { formatColumnsToFormulaFields } from './format-columns-to-formula-fields';

export const executeStatementExpression = (
  value: string,
  {
    statementLines,
    columns,
  }: { statementLines: StatementLine[]; columns: NetRevenueColumn[] }
) => {
  const tree = expression.toTree(value);

  const getTotalByRemoteId = (id: string) =>
    (sum(
      statementLines.filter((i) => i.group?.remoteId === id),
      (x) => x.centTotal
    ) || 0) / 100;

  const fields = [
    ...defaultStatementFormulaFields,
    ...formatColumnsToFormulaFields(columns),
  ];

  return expression.execute(tree, {
    getData: (field) => {
      const fieldType = field.split('.')[0];

      switch (fieldType) {
        case 'acc': {
          // account
          const remoteId = field.split('.').reverse()[0];
          return getTotalByRemoteId(remoteId);
        }
        case 'col': {
          // column
          const columnName = field.slice(4);
          const column = columns.find((col) => col.name === columnName);
          if (!column) break;

          if (column.type === 'sumAccounts') {
            const remoteIds = column.value || [];

            const total = (remoteIds as string[]).reduce(
              (prev, curr) => prev + getTotalByRemoteId(curr),
              0
            );
            return total;
          } else if (column.type === 'sumColumns') {
            const sumsOfColumns = columns
              .filter((col) => column.value.includes(col.id))
              .map((col) =>
                (col.value as string[]).reduce(
                  (prev, curr) => prev + getTotalByRemoteId(curr),
                  0
                )
              );

            return sum(sumsOfColumns);
          } else if (column.type === 'metadata') {
            const metadataKey = column.value;

            const filteredLines =
              statementLines?.filter((line) => line?.metadata?.[metadataKey]) ||
              [];

            const byReservation = groupBy(
              filteredLines,
              (x) => x.reservation?.id || 'missing_reservation'
            );

            const value =
              sum(
                Object.values(byReservation).map(
                  (lines) => lines[0].metadata?.[metadataKey]
                )
              ) || 0;

            return value;
          }
          break;
        }
        default: {
          // default is fields like reservation_nights or reservation_guests
          const defField = fields.find(
            (i) => i.displayValue === `"${fieldType}"`
          );

          if (!defField) break;

          const byReservation = groupBy(
            statementLines,
            (x) => x.reservation?.id || 'missing_reservation'
          );

          const accessObjProperty = <T extends { [key: string]: any }>(
            obj: T,
            accessKey: string
          ) => accessKey.split('.').reduce((p, prop) => p[prop], obj);

          const value = sum(
            Object.values(byReservation).map(
              (lines) => accessObjProperty(lines?.[0] || {}, defField.id) || 0
            )
          );

          return value;
        }
      }

      return undefined;
    },
  });
};
