import { useDashboard } from '@finalytic/data';
import { ArrowLeftIcon, CrossIcon, Grid06Icon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { AppShell, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useNavbarExpanded } from '../../hooks';
import { NavbarAccountingSwitch } from './NavbarAccountingSwitch';
import { FeaturesButton } from './NavbarButtons';
import { NavbarSupportItem } from './NavbarSupportItem';
import { NavbarTeamSwitch } from './NavbarTeamSwitch';
import { SettingsPopoverMenu } from './SettingsPopoverMenu';

export type NavigationComponent = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => JSX.Element;

export type NavbarBaseProps = {
  navigation: NavigationComponent;
};

type NavbarProps = {
  closeMobileMenu?: () => void;
};

export const Navbar = ({
  closeMobileMenu,
  navigation: Navigation,
}: NavbarBaseProps & NavbarProps) => {
  const { isDesktopExpanded: isExpanded } = useNavbarExpanded();

  const [dashboard] = useDashboard();

  return (
    <>
      <NavbarHeader closeMobileMenu={closeMobileMenu} />
      <AppShell.Section
        grow
        component={ScrollArea}
        px="sm"
        mt={isExpanded ? 'md' : undefined}
        sx={{
          width: isExpanded ? 280 : 80,
          height: '100%',
          paddingTop: !isExpanded ? 'sm' : undefined,
          gap: '1rem',
          '&, & > div, & > div > div': {
            height: '100%',
          },
        }}
      >
        <Stack
          h="100%"
          align={isExpanded ? undefined : 'center'}
          pt={!isExpanded ? 'sm' : undefined}
        >
          {dashboard === 'propertyManager' && <NavbarAccountingSwitch />}
          {Navigation && <Navigation navbarIsExpanded={!!isExpanded} />}
          <NavbarSupportItem />
        </Stack>
      </AppShell.Section>

      {/* <SupportButton navbarExtended={!!isExpanded} /> */}
      {/* <OnboardingCallButton navbarExtended={!!isExpanded} /> */}

      {dashboard !== 'owner' && (
        <FeaturesButton navbarExtended={!!isExpanded} />
      )}

      <AppShell.Section
        px="xs"
        pb="xs"
        pt={4}
        sx={{
          borderTop: '1px solid #C6C6C630',
          width: isExpanded ? 280 : 80,
        }}
      >
        <SettingsPopoverMenu
          isNavbarExpanded={!!isExpanded}
          isOwnerDashboard={dashboard === 'owner'}
        />
      </AppShell.Section>
    </>
  );
};

const NavbarHeader = ({
  closeMobileMenu,
}: {
  closeMobileMenu?: () => void;
}) => {
  const { setIsDesktopExpanded, isDesktopExpanded: isExpanded } =
    useNavbarExpanded();

  const location = useLocation();

  const handleNavbarExpand = () => setIsDesktopExpanded?.((e) => !e);

  const isSettingsView = location.pathname.startsWith('/settings');

  return (
    <AppShell.Section
      component={Group}
      wrap="nowrap"
      px="md"
      pt="md"
      gap={!isExpanded ? 0 : undefined}
      justify={isExpanded ? 'apart' : 'center'}
      sx={{
        width: isExpanded ? 280 : 80,
        height: '100%',
        flex: 0,
        paddingBottom: !isExpanded ? 10 : undefined,
        borderBottom: !isExpanded ? '1px solid #C6C6C630' : undefined,
        flexDirection: isExpanded ? undefined : 'column',
      }}
    >
      {isSettingsView ? (
        <Group gap={'xs'} flex={1}>
          <IconButton
            component={Link}
            to="/"
            mb={!isExpanded ? 'xs' : undefined}
          >
            <ArrowLeftIcon size={18} color="#fff" />
          </IconButton>
          {isExpanded && (
            <Text component="span" size="lg" color="white">
              Settings
            </Text>
          )}
        </Group>
      ) : (
        <NavbarTeamSwitch />
      )}

      <IconButton onClick={closeMobileMenu || handleNavbarExpand}>
        {closeMobileMenu ? (
          <CrossIcon size={16} color="#fff" />
        ) : (
          <Grid06Icon color="#ffffff90" size={16} />
        )}
      </IconButton>
    </AppShell.Section>
  );
};
