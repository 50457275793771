import { InputWrapper } from '@finalytic/components';
import { useAccountingConnection } from '@finalytic/data';
import { Avatar, Group, Text, rem } from '@mantine/core';
import { AutomationMappingEditor } from '../../../components';
import { useListingClassMappingInfo } from '../../../hooks';

type Props = {
  listingId: string;
};

export const ListingClassMappingSelect = ({ listingId }: Props) => {
  const { accounting } = useAccountingConnection();
  const accountingName = accounting?.name;
  const {
    automation: { accountingConnectionId, automation, finalyticConnectionId },
  } = useListingClassMappingInfo();

  if (!accountingConnectionId || !finalyticConnectionId || !automation)
    return null;

  return (
    <InputWrapper
      label={
        <Group wrap="nowrap" gap={rem(8)}>
          <Text component="span">
            Select class from {accountingName || 'accounting'}
          </Text>
          {accounting?.icon && <Avatar size="xs" src={accounting?.icon} />}
        </Group>
      }
      description="Select the class from your accounting system that corresponds to this listing."
      inputWrapperOrder={['label', 'input', 'description']}
      mb="xs"
      styles={{
        description: {
          marginTop: rem(4),
        },
      }}
    >
      <Select listingId={listingId} />
    </InputWrapper>
  );
};

const Select = ({ listingId }: Props) => {
  const {
    automation: { accountingConnectionId, automation, finalyticConnectionId },
    mapping: { key, leftType, rightType },
    accounting,
  } = useListingClassMappingInfo();

  if (!automation || !accounting) return null;

  return (
    <AutomationMappingEditor
      automation={{
        automationId: automation.automationId,
        leftConnectionId: finalyticConnectionId,
        rightConnectionId: accountingConnectionId,
      }}
      mapping={{
        isLocal: false,
        label: 'Listing Class Mapping',
        leftType,
        rightType,
        settingKey: key,
        altLeftTypes: [],
        exceptionFilters: undefined,
      }}
      targetId={listingId}
      parentSetting={null}
    />
  );
};
