import { InputSelect } from '@finalytic/components';
import {
  gqlV2,
  useInfiniteQuery,
  useQuery,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { SelectItem } from '@finalytic/ui';
import { useMemo, useState } from 'react';
import { AddTeamDrawer } from '../../../drawers';
import { useNavbarExpanded } from '../../../hooks';

function useTeams({ search: s }: { search: string }) {
  const search = s.trim();

  const queryData = useInfiniteQuery(
    (q, { search }, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = {
        status: { _neq: 'deleted' },
        type: { _nin: ['partner', 'admin'] },
        name: search ? { _ilike: `%${search}%` } : undefined,
      };

      const aggregate = q.tenantAggregate({ where })?.aggregate?.count() || 0;

      const list = q
        .tenant({
          order_by: [{ type: 'asc_nulls_last' }, { name: 'asc' }],
          where,
          limit: limit,
          offset,
        })
        .map<SelectItem>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'teams',
      variables: {
        search,
      },
    }
  );

  const { data, isLoading: loading2 } = useQuery(
    (q) => {
      const canSwitch =
        (q
          .tenantAggregate({ where: { status: { _neq: 'deleted' } } })
          ?.aggregate?.count() || 0) > 1;

      const pinnedTeams = q
        .tenant({
          where: {
            status: { _neq: 'deleted' },
            type: { _in: ['admin', 'partner'] },
          },
          order_by: [{ name: 'asc' }],
        })
        .map<SelectItem<string>>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        canSwitch,
        pinnedTeams,
      };
    },
    {
      queryKey: 'teams',
    }
  );

  return {
    queryData,
    loadingValue: loading2,
    canSwitch: !!data?.canSwitch,
    pinnedTeams: data?.pinnedTeams || [],
  };
}

export const OnboardingTeamSwitch = () => {
  const [team] = useTeam();
  const [, setTeamId] = useTeamId();

  const { isDesktopExpanded } = useNavbarExpanded();

  const [search, setSearch] = useState('');

  const { queryData, loadingValue, canSwitch, pinnedTeams } = useTeams({
    search,
  });

  const value = useMemo(() => {
    return team?.id
      ? {
          label: team?.name || '',
          value: team?.id || '',
        }
      : null;
  }, [team.id]);

  if (!canSwitch) return null;

  return (
    <>
      <InputSelect
        type="single"
        setValue={(value) => value?.value && setTeamId(value?.value)}
        infiniteData={{ ...queryData, setSearch }}
        dropdownProps={{
          withinPortal: true,
          position: isDesktopExpanded ? 'bottom' : 'bottom-start',
          width: 260,
        }}
        pinnedItems={pinnedTeams}
        inputProps={{
          loadingQuery: loadingValue,
        }}
        value={value}
      />
      <AddTeamDrawer />
    </>
  );
};
