import { Select } from '@finalytic/components';
import { useTeamId, useTrpcMutation } from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { tax_statement_status_enum } from '@finalytic/graphql';
import { AlertCircleIcon, ChevronIcon } from '@finalytic/icons';
import {
  ExtendedCustomColors,
  LoadingIndicator,
  SelectItem,
  showWarnNotification,
} from '@finalytic/ui';
import { bankersRoundToCents } from '@finalytic/utils';
import { Badge, Center, rem, useMantineTheme } from '@mantine/core';
import {
  TaxStatementRow,
  useTaxStatementCalculatedAmountQuery,
} from './useTaxStatementListTableQuery';
import {
  TaxStatementFiledIcon,
  TaxStatementInReviewIcon,
  useTaxStatementStatusOptions,
} from './useTaxStatementStatusOptions';

export type TaxStatementStatus = tax_statement_status_enum | 'error';

type Props = {
  statement: TaxStatementRow;
  openFilingConfirmModal: (statement: TaxStatementRow) => void;
  disabled?: boolean;
};

export function usePublishTaxStatementMutation() {
  const { loading, mutate } = useTrpcMutation('publishTaxStatement', {
    invalidateQueryKeys: ['taxStatements'],
    errorMessage: {
      title: 'Failed to update tax statement',
    },
    successMessage: {
      title: 'Tax statement updated',
      message: 'Tax statement status was updated.',
    },
  });

  const { setWorkflowIds } = useRunDrawer();

  return {
    loading,
    mutate: async (args: Parameters<typeof mutate>[0]) => {
      return mutate(args).then(
        (result) =>
          result?.workflowId &&
          setWorkflowIds([result.workflowId], {
            refreshQueryKeys: ['taxStatements'],
          })
      );
    },
  };
}

const badgeColors: Record<TaxStatementStatus, ExtendedCustomColors> = {
  ignored: 'gray',
  draft: 'yellow',
  inReview: 'blue',
  ready: 'teal',
  submitted: 'green',
  error: 'red',
};

export const TaxStatementFiledBadge = () => (
  <Badge
    variant="light"
    color={badgeColors.submitted}
    size="md"
    sx={({ colors }) => ({
      textTransform: 'none',
      fontWeight: 500,
      paddingInline: rem(5),
      color: colors[badgeColors.submitted][9],
    })}
    leftSection={
      <Center>
        <TaxStatementFiledIcon />
      </Center>
    }
  >
    Filed
  </Badge>
);

export const TaxStatementReadyBadge = () => (
  <Badge
    variant="light"
    color={badgeColors.ready}
    size="md"
    sx={({ colors }) => ({
      textTransform: 'none',
      fontWeight: 500,
      paddingInline: rem(5),
      color: colors[badgeColors.ready][9],
    })}
    leftSection={
      <Center>
        <TaxStatementInReviewIcon color="#20C997" />
      </Center>
    }
  >
    Ready
  </Badge>
);

export const TaxStatementStatusSelect = ({
  statement,
  openFilingConfirmModal,
  disabled,
}: Props) => {
  const [teamId] = useTeamId();
  const { colors } = useMantineTheme();

  const currentStatus = statement.status;
  const { data: amountData, isLoading: loadingCalculatedAmount } =
    useTaxStatementCalculatedAmountQuery(statement);

  const { mutate, loading: loadingMutation } = usePublishTaxStatementMutation();

  const options = useTaxStatementStatusOptions(currentStatus, 'single');

  const updateStatement = (newStatus: TaxStatementStatus) => {
    if (newStatus === currentStatus || loadingCalculatedAmount) return;

    if (newStatus === 'submitted') {
      if (currentStatus === 'draft') {
        return showWarnNotification({
          message: 'Please review the statement before filing.',
        });
      }
      return openFilingConfirmModal(statement);
    }

    // just for typescript
    if (newStatus === 'error') return;

    mutate({
      teamId,
      status: newStatus,
      statements: [
        {
          year: statement.year,
          ownerId: statement.owner.id,
          centRentalRevenue: bankersRoundToCents(
            statement.amount || amountData?.calculatedAmount
          ),
          endAt: statement.endAt || undefined,
          startAt: statement.startAt || undefined,
        },
      ],
    });
  };

  if (currentStatus === 'error') {
    return (
      <Badge
        variant="light"
        color="red"
        size="md"
        sx={{
          textTransform: 'none',
          fontWeight: 500,
          paddingInline: rem(5),
          color: colors.red[9],
        }}
        leftSection={
          <Center>
            <AlertCircleIcon size={14} color={colors.red[5]} />
          </Center>
        }
      >
        {statement?.errorMessage || 'Missing info'}
      </Badge>
    );
  }

  if (currentStatus === 'submitted') {
    return <TaxStatementFiledBadge />;
  }

  const currentValue = options.find((o) => o.value === currentStatus) || null;

  return (
    <Select
      data={{
        options: options,
        sort: null,
      }}
      dropdownProps={{
        position: 'bottom-end',
        width: 340,
        withinPortal: true,
      }}
      type="single"
      inputProps={{
        loadingMutation,
        loadingQuery: loadingCalculatedAmount,
        disabled,
      }}
      value={currentValue}
      setValue={(_, update) => {
        if (disabled) return;
        if (update.type === 'removed') return;

        updateStatement(update.value.value as TaxStatementStatus);
      }}
    >
      {({ disabled: dis, value, loadingMutation, loadingQuery }) => {
        const item = value as SelectItem | null;
        const status = item?.value as TaxStatementStatus;

        const disabled = dis || loadingMutation;

        return (
          <Badge
            variant="light"
            component="button"
            disabled={disabled}
            color={badgeColors[status]}
            size="md"
            sx={{
              cursor: disabled ? 'default' : 'pointer',
              paddingInline: rem(5),
              fontWeight: 500,
              textTransform: 'none',
              color: colors[badgeColors[status]][9],
            }}
            styles={{
              root: {
                backgroundColor: `${
                  colors[badgeColors[status]][0]
                }80!important`,
              },
            }}
            leftSection={
              <Center>
                {' '}
                {loadingMutation || loadingQuery ? (
                  <LoadingIndicator
                    size={'0.75rem'}
                    color={colors[badgeColors[status]][6]}
                  />
                ) : (
                  item?.icon
                )}
              </Center>
            }
            rightSection={
              !disabled && (
                <Center>
                  <ChevronIcon
                    size={14}
                    strokeWidth={1.5}
                    color={colors[badgeColors[status]][6]}
                  />
                </Center>
              )
            }
          >
            {(value as SelectItem)?.label}
          </Badge>
        );
      }}
    </Select>
  );
};
