import {
  AddressFormInputs,
  Button,
  Input,
  InputWrapper,
} from '@finalytic/components';
import { LoadingIndicator, useAppName } from '@finalytic/ui';
import { Alert, Box, Group, Stack } from '@mantine/core';
import { Address } from '@vrplatform/ui-common';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type FormInputs = {
  title: string;
  address: Address;
};

export const ListingEditForm = ({
  handleSubmit,
  initialValues,
  onReset,
  listingName,
  isPmsConnection,
  isLoading,
}: {
  handleSubmit: (values: FormInputs) => Promise<void>;
  onReset: () => void;
  initialValues: FormInputs;
  listingName: string;
  isPmsConnection: boolean;
  isLoading: boolean;
}) => {
  const { appName } = useAppName();

  const methods = useForm<FormInputs>({
    values: initialValues,
  });

  if (isLoading) return <LoadingIndicator isFullPageLoading size="sm" />;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
        onSubmit={methods.handleSubmit(handleSubmit)}
        onReset={onReset}
      >
        <Stack>
          {isPmsConnection && (
            <Alert variant="light" color="gray" title="PMS Listing">
              This listing is synced by your PMS. You can overwrite its name
              inside {appName}, however the address is synced and you will need
              to make adjustments inside your PMS.
            </Alert>
          )}

          <Box>
            <Controller
              control={methods.control}
              name="title"
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label="Name"
                  error={error?.message}
                  // description={}
                  inputWrapperOrder={['label', 'input', 'description', 'error']}
                >
                  <Input
                    {...field}
                    placeholder={listingName || 'Name'}
                    autoComplete="off"
                    error={!!error}
                  />
                </InputWrapper>
              )}
            />
          </Box>

          <AddressFormInputs fieldsetDisabled={isPmsConnection} />
        </Stack>

        <Group
          mt="md"
          sx={(theme) => ({
            position: 'sticky',
            bottom: 0,
            height: 60,
            left: 0,
            right: 0,
            paddingBottom: theme.spacing.md,
            backgroundColor: theme.white,
            borderTop: `1px solid ${theme.colors.gray[2]}`,
            paddingTop: theme.spacing.sm,
            paddingInline: theme.spacing.xs,
            marginRight: `-${theme.spacing.xs}`,
            marginLeft: `-${theme.spacing.xs}`,
          })}
        >
          <Button type="reset" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={!methods.formState.isDirty}
            sx={{ flexGrow: '1!important' as any }}
            type="submit"
            variant="primary"
          >
            Save changes
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};
