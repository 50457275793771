import {
  useEnabledFeatures,
  useInfiniteQuery,
  useTeamId,
} from '@finalytic/data';
import { MRT_GroupingState, MRT_SortingState } from '@finalytic/table';
import { day } from '@finalytic/utils';
import {
  formatOwnerName,
  getListingAddress,
  getListingName,
  getSourceDescription,
  whereListingIsExcludedSetting,
  whereListingPeriod,
} from '@vrplatform/ui-common';
import { useWhereListings } from './useWhereListings';
type Params = {
  sorting: MRT_SortingState;
  type: 'mapping' | 'pmDashboard' | 'ownerPortal';
  groupBy: MRT_GroupingState;
  filterByAutomationId?: string;
};

export type ListingRow = NonNullable<
  ReturnType<typeof useListingTableQuery>['data']
>['pages'][number]['list'][number];

export const useListingTableQuery = ({
  sorting,
  type,
  groupBy,
  filterByAutomationId,
}: Params) => {
  const where = useWhereListings({ filterByAutomationId });
  const [teamId] = useTeamId();
  const { NEW_OWNERS, GL } = useEnabledFeatures();

  return useInfiniteQuery(
    (
      q,
      { sorting, where, type, groupBy, NEW_OWNERS, GL },
      { limit, offset }
    ) => {
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      const isPmDashboard = type === 'pmDashboard';

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: groupBy?.[0]
            ? [
                {
                  collection: {
                    name: 'asc_nulls_last',
                  },
                },
                ...order_by,
              ]
            : order_by,
        })
        .map((listing) => {
          const disabledAutomations =
            isPmDashboard && !GL
              ? listing
                  .settingsRight({
                    where: whereListingIsExcludedSetting({
                      automationIds: undefined,
                      listingId: undefined,
                    }),
                    order_by: [{ updated_at: 'desc' }],
                  })
                  .map((setting) => ({
                    settingId: setting.id,
                    automationId: setting.automationId,
                  }))
              : [];

          const ownerships = isPmDashboard
            ? listing
                .ownerships({
                  order_by: [{ createdAt: 'asc_nulls_last' }],
                  where: {
                    newOwnerId: NEW_OWNERS ? { _is_null: false } : undefined,
                  },
                })
                .map((ownership) => {
                  return {
                    ownershipId: ownership.id,
                    rowId: listing.id,
                    listingId: ownership.listingId,
                    listing: {
                      id: ownership.listing.id,
                      name: getListingName(ownership.listing),
                    },
                    role: ownership.role,
                    split: ownership.split,
                    vendor: ownership
                      .settingsLeft({ where: { key: { _eq: 'vendor' } } })
                      .map((setting) => ({
                        label: getSourceDescription(setting?.rightSource),
                        value: setting.value || '',
                        settingId: setting.id,
                      }))?.[0],
                    owner: NEW_OWNERS
                      ? {
                          id: ownership.newOwner?.id,
                          firstName: ownership.newOwner?.firstName,
                          lastName: ownership.newOwner?.name,
                          companyName: ownership.newOwner?.name,
                          type: ownership.newOwner?.type || 'individual',
                        }
                      : {
                          id: ownership.owner?.id,
                          firstName: ownership.owner?.firstName,
                          lastName: ownership.owner?.lastName,
                          companyName: ownership.owner?.companyName,
                          type: (ownership.owner?.type === 'company'
                            ? 'company'
                            : 'individual') as 'company' | 'individual',
                        },
                  };
                })
                .filter((x) => x.owner.id)
            : [];

          const currentPeriod = listing
            .ownershipPeriods({
              limit: 1,
              where: whereListingPeriod({
                date: day().yyyymmdd(),
              }),
            })
            .map((period) => ({
              id: period.id,
              members: period
                .members({
                  order_by: [
                    {
                      owner: {
                        name: 'asc_nulls_last',
                      },
                    },
                  ],
                })
                .map((owner) => ({
                  id: owner.id as string,
                  ownerId: owner.ownerId as string,
                  ownerName: formatOwnerName(owner.owner),
                  ownerSplit: owner.split as number | undefined | null,
                  ownerType: owner.owner.type,
                })),
            }))[0];

          return {
            id: listing.id,
            currentPeriod,
            name: getListingName(listing),
            address: getListingAddress(listing).full,
            collection: {
              id: isPmDashboard ? listing.collectionId : '',
              name: listing.collection?.name || 'Ungrouped',
            },
            tenantId: listing.tenantId,
            pmsConnectionId: listing.connectionId,
            connections: listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                iconRound: conn.connection.app.iconRound,
              })),
            status: listing.status,
            pmsStatus: listing.pmsStatus,
            statements: isPmDashboard
              ? listing
                  .ownerStatements_aggregate({
                    order_by: [{ startAt: 'desc' }],
                    where: {
                      owners: {
                        role: { _eq: 'owner' },
                      },
                    },
                  })
                  ?.aggregate?.count() || 0
              : 0,
            ownerships,
            disabledAutomations,
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'listings',
      variables: {
        sorting,
        where,
        type,
        teamId,
        groupBy,
        NEW_OWNERS,
        GL,
      },
    }
  );
};
