import { useAppName } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { useEditAutomationContext } from '../_context';

export function useMapping(settingKey: string) {
  const { automation, template, forceGlobalMapping } =
    useEditAutomationContext();

  const mapping = template?.mappings?.[settingKey];
  const automationViewSettings = automation?.viewSettings || {};
  const mappingViewSettings = automationViewSettings?.[settingKey] || {};

  // Defining mapping scope value
  const getScope = (): 'local' | 'global' => {
    if (forceGlobalMapping) return 'global';

    if (typeof mappingViewSettings.isLocal === 'boolean') {
      return mappingViewSettings.isLocal ? 'local' : 'global';
    }

    return mapping?.scope === 'local' ? 'local' : 'global';
  };

  const scope = getScope();

  // Defining mapping target overwrite
  const leftTypeOverwrite = automationViewSettings?.[settingKey]?.leftType;
  const defaultLeftType = mapping?.left.schema;
  const left = leftTypeOverwrite || defaultLeftType;

  const right = mapping?.right.schema;

  const mappingTitle = mappingViewSettings?.title || mapping?.label;
  const mappingLeftTypes = mapping?.left?.alt || [];

  const leftLabel = useFormattedMappingColumnHeader({
    mapping: left,
    appName: automation.leftAppName!,
  });
  const rightLabel = useFormattedMappingColumnHeader({
    mapping: mappingTitle,
    appName: automation.rightAppName!,
  });

  return {
    mapping,
    mappingLeftTypes,
    mappingTitleTo: `${leftLabel.label} to ${rightLabel.label}`,
    mappingScope: scope,
    automationViewSettings,
    mappingViewSettings,
    leftTypeOverwrite,
    defaultLeftType,
    left,
    right,
    leftLabel,
    rightLabel,
  };
}
const useFormattedMappingColumnHeader = ({
  appName,
  mapping,
}: {
  mapping: string;
  appName: string;
}) => {
  const { appName: platform } = useAppName();

  return formatMappingLabel({
    appName,
    platformName: platform,
    mapping,
  });
};

export const formatMappingLabel = ({
  appName,
  mapping,
  platformName,
}: { mapping: string; platformName: string; appName: string }) => {
  const app =
    mapping.startsWith('editor.') || appName.toLowerCase().includes('finalytic')
      ? `${platformName} `
      : `${appName} `;

  let label = '';

  if (mapping.startsWith('editor.')) {
    if (mapping.includes('select')) {
      label = 'Options';
    }
    if (mapping.includes('expression')) {
      label = 'Expression';
    }
    if (mapping.includes('percentage')) {
      label = 'Rate';
    }
  } else {
    if (mapping.startsWith('finalytic.')) {
      label = `${toTitleCase(mapping.replace('finalytic.', ''))}`;
    } else {
      label = `${mapping}`;
    }
  }

  return {
    withApp: `${app}${label}`,
    label,
  };
};
