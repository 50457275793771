import { gqlV2, useDashboard, useTeam } from '@finalytic/data';
import { Maybe, ensure } from '@finalytic/utils';
import { Dashboard, whereConnectionStatusDefault } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { usePartnerConnectionsTableFilter } from './PartnerConnectionsTableFilter';

export function useWherePartnerConnectionErrors() {
  const [{ id: teamId }] = useTeam();
  const { filter } = usePartnerConnectionsTableFilter();
  const search = filter?.search?.trim();
  const [dashboard] = useDashboard();

  return useMemo<gqlV2.connection_bool_exp>(() => {
    return wherePartnerConnectionErrors({
      partnerId: teamId,
      search,
      tenantId: filter?.tenantId,
      appId: filter?.appId,
      dashboard,
    });
  }, [teamId, search, filter?.appId, filter.tenantId, dashboard]);
}

type FilterInput = {
  search: Maybe<string>;
  appId: Maybe<string>;
  tenantId: Maybe<string>;
};

export function wherePartnerConnectionErrors(
  input: FilterInput & { partnerId: string; dashboard: Dashboard }
) {
  return ensure<gqlV2.connection_bool_exp>({
    tenant: {
      id: input.tenantId ? { _eq: input.tenantId } : undefined,
      partnerId:
        input.dashboard === 'overview'
          ? undefined
          : {
              _eq: input.partnerId,
            },
    },
    status: whereConnectionStatusDefault,
    appId: input.appId ? { _eq: input.appId } : { _neq: 'finalytic' },
    _or: [
      {
        jobPlans: {
          status: { _eq: 'failed' },
          isCurrentOnConnection: { _eq: true },
          jobs: {
            kind: { _in: ['extract', 'extractLegacy'] },
          },
        },
      },
      {
        status: { _eq: 'error' },
      },
    ],
    _and: input.search
      ? [
          {
            jobs: {
              _or: [
                {
                  title: { _ilike: `%${input.search}%` },
                },
                {
                  connection: { name: { _ilike: `%${input.search}%` } },
                },
              ],
            },
          },
        ]
      : undefined,
  });
}
