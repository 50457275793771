import { useQuery } from '@finalytic/data';
import { SelectItem, showErrorNotification } from '@finalytic/ui';
import { getSourceDescription } from '@vrplatform/ui-common';
import { create } from 'zustand';
import { ListingOwnerRow } from '../../useListingDetailData';

export type OwnershipChange = ListingOwnerRow | null;

export type OwnershipChangeStore = {
  changes: { [ownerId: string]: OwnershipChange };
  numberOfChanges: number;
};

export type LineMasterChangeActions = {
  reset: (ownerId?: string) => void;
  getChange: (ownerId: string) => OwnershipChangeStore['changes'][string];
  getAllChanges: () => OwnershipChangeStore['changes'];
  add: (ownerId: string | undefined, data: OwnershipChange) => void;
};

const initialState: OwnershipChangeStore = {
  changes: {},
  numberOfChanges: 0,
};

export const useOwnershipChangeStore = create<
  OwnershipChangeStore & LineMasterChangeActions
>()((set, get) => ({
  ...initialState,
  getChange: (ownerId) => get().changes[ownerId],
  getAllChanges: () => get().changes,
  reset: (ownerId) =>
    set((store) => {
      if (ownerId) {
        const updatedChanges = { ...store.changes };
        delete updatedChanges[ownerId];
        return { ...store, ...initialState, changes: updatedChanges };
      } else {
        return { ...store, ...initialState };
      }
    }),
  add: (ownerId, rowData) =>
    set((state) => {
      const changes = { ...state.changes };
      if (!ownerId) {
        showErrorNotification({ message: 'Missing row key.' });
        return state;
      }

      changes[ownerId] = rowData;

      return {
        ...state,
        changes,
        numberOfChanges: Object.keys(changes).length,
      };
    }),
}));

export function useVendors({
  listingId,
  listingTeamId,
}: {
  listingId: string;
  listingTeamId: string;
}) {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useQuery(
    (q, args) => {
      const listingTeamId = args.listingTeamId;

      const vendors = listingTeamId
        ? q
            .source({
              where: {
                tenantId: { _eq: listingTeamId },
                type: { _eq: 'vendor' },
              },
              order_by: [{ id: 'asc' }],
            })
            .map<SelectItem>((item) => ({
              label: getSourceDescription(item) || item.remoteId || '',
              value: item.id || '',
            })) || []
        : [];

      return {
        vendors,
      };
    },
    {
      queryKey: ['settings', 'sources', 'settings', 'teams'],
      variables: {
        listingId,
        listingTeamId,
      },
      skip: !listingId || !listingTeamId,
    }
  );

  const vendors = data?.vendors || [];

  return {
    vendors,
    loading,
    refetch,
  };
}
