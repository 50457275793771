import { useRegisterSW } from 'virtual:pwa-register/react';
import { Button } from '@finalytic/components';
import { RefreshCwIcon } from '@finalytic/icons';
import { useAppName } from '@finalytic/ui';
import { Box, Card, Text, Title, rem, useMantineTheme } from '@mantine/core';

export function ReloadPrompt() {
  const { primaryColor, colors } = useMantineTheme();
  const { appName } = useAppName();

  const {
    offlineReady: [_offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log(`SW Registered: ${r}`);
    },
    onRegisterError(error) {
      console.error('SW registration error', error);
    },
    immediate: true,
  });

  if (needRefresh)
    return (
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          margin: 0,
          inset: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          overflow: 'hidden',
        }}
      >
        <Card withBorder maw={400} p={rem(32)} radius="lg">
          <RefreshCwIcon
            size={30}
            color={colors[primaryColor][5]}
            sx={(theme) => ({
              display: 'block',
              marginInline: 'auto',
              marginTop: theme.spacing.md,
              marginBottom: theme.spacing.xl,
            })}
          />
          <Title order={3} c="dark" ta="center" mb="md">
            Update Available
          </Title>
          <Text component="p" ta="center" mb="xl" c="neutral">
            New content available. Update now to use the latest version of{' '}
            {appName}.
          </Text>
          <Button
            onClick={() => updateServiceWorker(true)}
            variant="primary"
            sx={{
              width: '100%',
            }}
          >
            Reload
          </Button>
        </Card>
      </Box>
    );

  return null;
}
