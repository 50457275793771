import { Badge, Button, Collapse, Filter } from '@finalytic/components';
import {
  gqlV2,
  useInfiniteQuery,
  useInvalidateQueries,
  useSubscriptionOrQuery,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useRunDrawer } from '@finalytic/data-ui';
import { action_bool_exp, sourceOp_bool_exp } from '@finalytic/graphql';
import {
  AlertTriangleIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  Loader2Icon,
  LoaderIcon,
  LogIn2Icon,
  LogOut2Icon,
  RefreshCwIcon,
  UserIcon,
} from '@finalytic/icons';
import { InfiniteTable, LazyTable, MRT_SortingState } from '@finalytic/table';
import {
  ExtendedCustomColors,
  IconButton,
  LoadingIndicator,
  Logo,
  useAppName,
} from '@finalytic/ui';
import { Maybe, hasValue, toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  Stack,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { formatUserName } from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { GetIssueWhere, LegacyIssuesTable } from '../../components';
import {
  getActionRow,
  useAutomationHistoryTableColumns,
} from '../../views/automations/views/edit-automation/_tabs/history-tab/useAutomationHistoryTableColumns';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { RerunButtons } from './RerunButtons';

type Props = {
  workflowIds: string[];
  closeDrawer: () => void;
};

const getJob = (
  x: gqlV2.job,
  overwrites: {
    pattern: string;
    message: string;
  }[]
) =>
  getActionRow(
    {
      status: x.status,
      title: x.title,
      id: x.id,
      automationId: x.automationId,
      schema: {
        uniqueRef: 'automation',
      },
      sourceLinks() {
        return [];
      },
      inputJson() {
        return undefined;
      },
      outputJson() {
        return undefined;
      },
    } as any,
    overwrites
  );

function useRunDrawerResultSubscription(workflowIds: string[]) {
  const { refreshKeys } = useRunDrawer();
  const invalidate = useInvalidateQueries();

  const queryData = useSubscriptionOrQuery(
    (q, args) => {
      if (!args.workflowIds.length) return null;

      return q
        .jobPlans({
          where: {
            workflowId: {
              _in: args.workflowIds,
            },
          },
          order_by: [
            {
              createdAt: 'desc',
            },
          ],
        })
        .map((jobPlan) => {
          const leftConnection = jobPlan?.automation?.leftConnection;
          const rightConnection = jobPlan?.automation?.rightConnection;

          const whereErrors: gqlV2.action_bool_exp = {
            status: {
              _eq: 'failed',
            },
          };

          const overwrites = q
            .issueMessageOverwrites({
              order_by: [{ pattern: 'asc_nulls_last' }],
            })
            .map((o) => ({
              pattern: o.pattern || '',
              message: o.message || '',
            }));

          const extractConnection = jobPlan.connection;
          const errorRows = jobPlan
            ?.actions({
              where: whereErrors,
              limit: 5,
              order_by: [
                {
                  createdAt: 'asc_nulls_last',
                },
              ],
            })
            .map((action) => getActionRow(action, overwrites));
          const children = jobPlan
            .jobs({
              where: {
                status: {
                  _eq: 'failed',
                },
              },
            })
            .map((j) => getJob(j, overwrites));

          const errorCount =
            jobPlan
              ?.actions_aggregate({ where: whereErrors })
              .aggregate?.count() || 0;
          const errors = {
            rowData: [...errorRows, ...children],
            aggregate: errorCount + children.length,
          };

          const issuesCount =
            jobPlan
              .issues_aggregate({
                where: {
                  uniqueRef: { _is_null: false },
                  status: { _eq: 'open' },
                },
                distinct_on: ['uniqueRef'],
              })
              .aggregate?.count() || 0;

          console.log('OIOI', jobPlan.hypervisorRef);
          return {
            id: jobPlan.id,
            status: jobPlan.status,
            title: jobPlan.title,
            workflowId: jobPlan.workflowId,
            hypervisorRef: jobPlan.hypervisorRef,
            triggeredBy: formatUserName(jobPlan.user || {}, {
              showEmpty: true,
            }),
            automation: {
              id: jobPlan.automationId,
              name:
                jobPlan.automation?.title ||
                jobPlan?.automation?.ttemplate?.title ||
                '',
            },
            leftConnection: {
              id: leftConnection?.app?.id,
              icon: leftConnection?.app?.iconRound,
              name: leftConnection?.name || leftConnection?.app?.name,
            },
            rightConnection: {
              id: rightConnection?.app?.id,
              icon: rightConnection?.app?.iconRound,
              name: rightConnection?.name || rightConnection?.app?.name,
            },
            extractConnection: {
              id: jobPlan.connectionId,
              icon: extractConnection?.app?.iconRound,
              name: extractConnection?.name || extractConnection?.app?.name,
            },
            isExtractWorkflow:
              !!extractConnection?.id && !jobPlan?.automationId,
            errors,
            issuesCount,
            aggregateSyncedTo:
              jobPlan
                .actions_aggregate({
                  where: { status: { _neq: 'failed' } },
                })
                .aggregate?.count() || 0,
            aggregateSyncedFrom:
              q
                .aggregateSourceOps({
                  where: {
                    job: {
                      plan: {
                        workflowId: { _in: args.workflowIds },
                      },
                    },
                  },
                })
                ?.aggregate?.count() || 0,
          };
        });
    },
    {
      queryKey: ['tasks', 'automations', 'jobPlans'],
      skip: !workflowIds.length,
      variables: {
        workflowIds,
      },
      subscribe: true,
    }
  );

  const statuses = queryData.data?.map((x) => x.status).filter(hasValue);

  const isCompleted =
    !!statuses?.length && statuses.every((x) => x === 'completed');

  useEffect(() => {
    const keys = refreshKeys?.filter(hasValue) || [];

    if (!!keys.length && isCompleted) {
      invalidate(keys as any[]);
    }
  }, [isCompleted, refreshKeys]);

  return queryData;
}

export const RunResultContent = ({ workflowIds, closeDrawer }: Props) => {
  const [activeWorkflowId, setActiveWorkflowId] = useState<string | null>(null);

  const isOneWorkflow = workflowIds.length === 1;

  if (isOneWorkflow || activeWorkflowId)
    return (
      <SingleRunResult
        workflowId={activeWorkflowId || workflowIds[0]}
        hasMultiple={workflowIds.length > 1}
        closeDrawer={closeDrawer}
        resetActiveWorkflowId={() => setActiveWorkflowId(null)}
      />
    );

  if (!workflowIds.length) return null;

  return (
    <MultiRunResult
      workflowIds={workflowIds}
      closeDrawer={closeDrawer}
      setWorkflowId={setActiveWorkflowId}
    />
  );
};

const MultiRunResult = ({
  workflowIds,
  closeDrawer,
  setWorkflowId,
}: {
  workflowIds: string[];
  closeDrawer: () => void;
  setWorkflowId: (id: string) => void;
}) => {
  const { data: jobPlans, isLoading } =
    useRunDrawerResultSubscription(workflowIds);

  const invalidate = useInvalidateQueries(['actions', 'tasks', 'jobPlans']);

  const errors = useMemo(() => {
    const rowData =
      jobPlans?.flatMap((jobPlan) => jobPlan.errors.rowData) || [];
    const aggregate =
      jobPlans?.reduce((acc, current) => acc + current.errors.aggregate, 0) ||
      0;

    return {
      rowData,
      aggregate,
    };
  }, [jobPlans]);

  return (
    <Box
      px="sm"
      pb="sm"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DrawerHeader
        closeDrawer={closeDrawer}
        title={'Multiple Syncs'}
        loading={isLoading}
        type="Sync History"
      >
        <Button
          onClick={() => invalidate()}
          variant="light"
          leftIcon={RefreshCwIcon}
        >
          Refresh data
        </Button>
      </DrawerHeader>
      {isLoading ? (
        <LoadingIndicator size="md" isFullPageLoading />
      ) : !workflowIds.length || !jobPlans?.length ? (
        <Center mih="7vh">
          <h1>History not found</h1>
        </Center>
      ) : (
        <>
          <DrawerInfoCard
            rows={[
              {
                title: 'Triggered by',
                text: jobPlans[0]?.triggeredBy,
                icon: UserIcon,
              },
            ]}
          />
          <DrawerCollapsableTable
            columns={[
              {
                header: 'Name',
                Cell: ({ row }) => {
                  const data = row.original;
                  const errors = data.errors.aggregate;
                  const syncFrom = data.aggregateSyncedFrom;
                  const syncTo = data.aggregateSyncedTo;

                  const total = errors + syncFrom + syncTo;
                  return (
                    <Box>
                      <Text>{data.automation.name}</Text>
                      <Text c="gray" size="xs">
                        Synced {total} entities
                      </Text>
                    </Box>
                  );
                },
              },
              {
                header: 'Status',
                size: 50,
                Cell: ({ row }) => {
                  const data = row.original;
                  return <RunStatusBagde status={data.status} />;
                },
              },
              {
                header: 'arrow',
                size: 20,
                mantineTableBodyCellProps: { align: 'right' },
                Cell: () => {
                  return <ArrowRightIcon size={20} />;
                },
              },
            ]}
            rightSection={null}
            title="Syncs"
            onRowClick={{
              handler: (row) => setWorkflowId(row.original.workflowId!),
            }}
            rowData={jobPlans}
            emptyRowsFallback={'No syncs found'}
          />

          <OverviewErrorTable errors={errors} status={jobPlans[0].status} />
        </>
      )}
    </Box>
  );
};
const SingleRunResult = ({
  workflowId,
  closeDrawer,
  hasMultiple,
  resetActiveWorkflowId,
}: {
  workflowId: string;
  closeDrawer: () => void;
  hasMultiple: boolean;
  resetActiveWorkflowId: () => void;
}) => {
  const { data: jobPlans, isLoading } = useRunDrawerResultSubscription([
    workflowId,
  ]);
  const invalidate = useInvalidateQueries(['actions', 'tasks', 'jobPlans']);

  const [tab, setTab] = useState('overview');

  const jobPlan = jobPlans?.[0];

  const title = useMemo<string>(() => {
    if (jobPlan?.isExtractWorkflow) return 'Fetching data';

    return jobPlan?.automation.name || '';
  }, [jobPlan]);

  return (
    <Box
      px="sm"
      pb="sm"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Group>
        <DrawerHeader
          closeDrawer={closeDrawer}
          title={
            <>
              <Group>
                <Title order={3} display="block">
                  {title}
                </Title>
                {jobPlan?.status === 'started' && (
                  <RunStatusBagde status={jobPlan.status} />
                )}
              </Group>
            </>
          }
          loading={isLoading}
          type="Sync History"
          onReturnClick={
            hasMultiple ? () => resetActiveWorkflowId() : undefined
          }
        >
          <Button
            onClick={() => invalidate()}
            variant="light"
            leftIcon={RefreshCwIcon}
          >
            Refresh data
          </Button>
          <RerunButtons workflowId={workflowId} />
        </DrawerHeader>
      </Group>
      {isLoading ? (
        <LoadingIndicator size="md" isFullPageLoading />
      ) : !workflowId || !jobPlans ? (
        <Center mih="7vh">
          <h1>History not found</h1>
        </Center>
      ) : (
        <Content
          workflowIds={[workflowId]}
          jobPlans={jobPlans}
          setTab={setTab}
          tab={tab}
        />
      )}
    </Box>
  );
};

type JobPlan = NonNullable<
  ReturnType<typeof useRunDrawerResultSubscription>['data']
>[number];

type ContentProps = {
  workflowIds: string[];
  jobPlans: JobPlan[];
  tab: string;
  setTab: (tab: string) => void;
};

const Content = ({ jobPlans, workflowIds, setTab, tab }: ContentProps) => {
  const { errors, syncFrom, syncTo, issues } = useMemo(() => {
    return {
      errors: jobPlans.reduce(
        (acc, current) => acc + current.errors.aggregate,
        0
      ),
      issues: jobPlans.reduce((acc, current) => acc + current.issuesCount, 0),
      syncTo: jobPlans.reduce(
        (acc, current) => acc + current.aggregateSyncedTo,
        0
      ),
      syncFrom: jobPlans.reduce(
        (acc, current) => acc + current.aggregateSyncedFrom,
        0
      ),
    };
  }, [jobPlans]);

  const isLegacy = jobPlans[0]?.hypervisorRef === 'legacy';

  const goToErrors = () => setTab('errors');

  return (
    <Tabs
      value={tab}
      onChange={(tab) => tab && setTab(tab)}
      px="sm"
      keepMounted={false}
      styles={(theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
        panel: {
          height: '100%',
          flex: 1,
          marginTop: theme.spacing.lg,
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      })}
    >
      <Tabs.List>
        {jobPlans.length === 1 && (
          <Tabs.Tab value="overview">Overview</Tabs.Tab>
        )}
        {isLegacy ? null : (
          <Tabs.Tab value="sync-from">Synced from ({syncFrom})</Tabs.Tab>
        )}
        {isLegacy ? null : (
          <Tabs.Tab value="sync-to">Synced to ({syncTo})</Tabs.Tab>
        )}
        {!!errors && <Tabs.Tab value="errors">Errors ({errors})</Tabs.Tab>}
        <HiddenFeatureIndicator permission="ISSUE_UI">
          <Tabs.Tab value="issues">Issues ({issues})</Tabs.Tab>
        </HiddenFeatureIndicator>
      </Tabs.List>

      <Tabs.Panel value="overview">
        <Overview jobPlan={jobPlans[0]} goToErrors={goToErrors} />
      </Tabs.Panel>
      <Tabs.Panel value="sync-from">
        <SyncedFromTable workflowIds={workflowIds} />
      </Tabs.Panel>
      <Tabs.Panel value="sync-to">
        <SyncedToTable workflowIds={workflowIds} type="success" />
      </Tabs.Panel>
      <Tabs.Panel value="errors">
        <SyncedToTable workflowIds={workflowIds} type="failed" />
      </Tabs.Panel>

      <Tabs.Panel value="issues">
        <IssueTable workflowIds={workflowIds} />
      </Tabs.Panel>
    </Tabs>
  );
};

const Overview = ({
  jobPlan,
  goToErrors,
}: { jobPlan: JobPlan; goToErrors: () => void }) => {
  if (!jobPlan) return null;

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: (
              <>
                <RunStatusBagde status={jobPlan.status} />
              </>
            ),
          },
          {
            icon: Loader2Icon,
            title: 'Current step',
            text:
              jobPlan.status === 'started' &&
              jobPlan.isExtractWorkflow &&
              jobPlan?.title ? (
                <Text>{jobPlan.title}</Text>
              ) : null,
          },
          {
            icon: UserIcon,
            title: 'Triggered by',
            text: jobPlan.triggeredBy,
          },
          {
            icon: LogIn2Icon,
            title: 'Synced from',
            text: (
              <AppIcon
                {...(jobPlan.isExtractWorkflow
                  ? jobPlan.extractConnection
                  : jobPlan.leftConnection)}
              />
            ),
          },
          {
            icon: LogOut2Icon,
            title: 'Synced to',
            text: (
              <AppIcon
                {...(jobPlan.isExtractWorkflow
                  ? {
                      id: 'finalytic',
                      icon: undefined,
                      name: '',
                    }
                  : jobPlan.rightConnection)}
              />
            ),
          },
        ]}
      />
      <OverviewErrorTable
        errors={jobPlan.errors}
        status={jobPlan.status}
        goToErrors={goToErrors}
      />
    </>
  );
};

const OverviewErrorTable = ({
  errors,
  goToErrors,
  status,
}: {
  errors: JobPlan['errors'];
  status: JobPlan['status'];
  goToErrors?: () => void;
}) => {
  const { primaryColor } = useMantineTheme();

  const columns = useAutomationHistoryTableColumns();

  const isShowMoreErrors = errors.aggregate > errors.rowData.length;

  return (
    <Box mt="lg">
      <Collapse
        title={
          <Text component="span" size="sm">
            Errors
            <Text color="gray" ml="xs" component="span">
              {errors.aggregate}
            </Text>
          </Text>
        }
        rightSection={
          !!errors.aggregate &&
          goToErrors && (
            <IconButton onClick={goToErrors}>
              <ArrowRightIcon size={18} />
            </IconButton>
          )
        }
        minHeight={30}
        defaultOpened
      >
        {!errors.rowData.length ? (
          <Placeholder status={status} />
        ) : (
          <>
            <LazyTable
              table={{
                columns,
                hideHeader: true,
                hideTopBar: true,
              }}
              data={{
                rowCount: errors.aggregate,
                rows: errors.rowData,
                error: null,
                loading: false,
              }}
            />
            {isShowMoreErrors &&
              (!goToErrors ? (
                <Text size="xs" color="gray" ta="center" pt="md">
                  {errors.aggregate - errors.rowData.length} additional errors
                  <br />
                  View individal syncs for details
                </Text>
              ) : (
                <Button
                  onClick={goToErrors}
                  color={primaryColor}
                  variant="light"
                  sx={(theme) => ({
                    marginInline: 'auto',
                    display: 'block',
                    marginTop: theme.spacing.sm,
                  })}
                  rightIcon={ArrowRightIcon}
                >
                  Show more
                </Button>
              ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};

const Placeholder = ({ status }: { status: Maybe<string> }) => {
  const { colors } = useMantineTheme();

  const text: Record<string, string> = {
    completed: 'No errors, all parts are running smoothly.',
    failed: 'Something went wrong, please try rerunning.',
    started: 'Syncing data...',
  };

  return (
    <Stack mih={200} align="center" justify="center">
      {status === 'started' ? (
        <LoadingIndicator size="sm" />
      ) : status === 'completed' ? (
        <CheckCircleIcon color={colors.green[5]} size={30} />
      ) : (
        <AlertTriangleIcon color={colors.red[5]} size={30} />
      )}
      <Text color="neutral">{text[status || 'started']}</Text>
    </Stack>
  );
};

export const RunStatusBagde = ({ status }: { status: Maybe<string> }) => {
  if (!status) return null;

  const badgeColors: Record<string, ExtendedCustomColors> = {
    failed: 'red',
    started: 'yellow',
    completed: 'green',
  };

  return (
    <Badge color={badgeColors[status] || 'gray'} size="lg">
      {toTitleCase(status)}
    </Badge>
  );
};

const AppIcon = ({
  icon,
  id,
  name,
}: { icon: Maybe<string>; id: Maybe<string>; name: Maybe<string> }) => {
  const { appName } = useAppName();

  const isFinalyticConnection = id === 'finalytic';

  if (!id) return null;

  return (
    <Group gap="xs">
      <Avatar size="sm" radius="xl">
        <Logo
          img={isFinalyticConnection ? undefined : icon}
          width={isFinalyticConnection ? 24 : 22}
        />
      </Avatar>
      <Text component="span">{isFinalyticConnection ? appName : name}</Text>
    </Group>
  );
};

const SyncedFromTable = ({ workflowIds }: { workflowIds: string[] }) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [search, setSearch] = useState('');

  const columns = useAutomationHistoryTableColumns();

  const infiniteData = useInfiniteQuery(
    (q, { workflowIds, search, sorting }, { limit, offset }) => {
      if (!workflowIds.length)
        return {
          aggregate: 0,
          list: [],
        };

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const where: sourceOp_bool_exp = {
        job: {
          plan: {
            workflowId: { _in: workflowIds },
          },
        },
        _or: search
          ? [
              {
                source: {
                  _or: [
                    {
                      remoteId: { _ilike: `%${search}%` },
                    },
                    {
                      description: { _ilike: `%${search}%` },
                    },
                  ],
                },
              },
            ]
          : undefined,
      };

      const aggregate = q.aggregateSourceOps({ where }).aggregate?.count() || 0;

      const list = q
        .sourceOps({
          where,
          limit,
          offset,
          order_by,
        })
        .map<ReturnType<typeof getActionRow>>((sourceOp) => {
          return {
            id: sourceOp.id,
            automationId: sourceOp.job?.automationId,
            createdAt: sourceOp.createdAt,
            date: sourceOp.createdAt,
            hasExternalLinks: false,
            inputJson: undefined,
            outputJson: undefined,
            jobPlanId: sourceOp.job?.planId,
            jobId: sourceOp.job?.id,
            message: sourceOp.source?.description || '',
            status: sourceOp.kind,
            type: toTitleCase(sourceOp.source.type),
            uniqueRef: sourceOp.source.remoteId,
            links: {
              paymentIds: [
                {
                  id: sourceOp.source.payment?.id,
                  title: sourceOp.source.payment?.uniqueRef,
                },
              ].filter((x) => !!x.id),
              reservationIds: [
                {
                  id: sourceOp.source.reservation?.id,
                  title: sourceOp.source.reservation?.guestName,
                },
              ].filter((x) => !!x.id),
            },
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: search.trim(),
        workflowIds,
        sorting,
      },
      queryKey: 'actions',
      subscribe: true,
    }
  );

  const resetFilter = () => {
    setSearch('');
  };

  return (
    <InfiniteTable
      columns={columns}
      table={{
        // columns,
        hideHeader: true,
        emptyRowsFallback: 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      sorting={{
        setSorting,
        sorting,
      }}
      resetFilter={resetFilter}
    >
      <Filter.Search value={search} setValue={setSearch} />
    </InfiniteTable>
  );
};

const IssueTable = ({ workflowIds }: { workflowIds: string[] }) => {
  const getWhere = useMemo<GetIssueWhere>(() => {
    return (baseWhere) => ({
      ...baseWhere,
      jobPlan: {
        workflowId: { _in: workflowIds },
      },
    });
  }, [workflowIds]);

  return <LegacyIssuesTable where={getWhere} />;
};

const SyncedToTable = ({
  workflowIds,
  type,
}: { workflowIds: string[]; type: 'failed' | 'success' }) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [search, setSearch] = useState('');

  const infiniteData = useInfiniteQuery(
    (q, { workflowIds, search, sorting, type }, { limit, offset }) => {
      if (!workflowIds)
        return {
          aggregate: 0,
          list: [],
        };

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const where: action_bool_exp = {
        job: {
          plan: {
            workflowId: { _in: workflowIds },
          },
        },
        status: type === 'failed' ? { _eq: 'failed' } : { _neq: 'failed' },
        _or: search
          ? [
              { title: { _ilike: `%${search}%` } },
              {
                uniqueRef: {
                  _ilike: `%${search}%`,
                },
              },
            ]
          : undefined,
      };

      const aggregate = q.aggregateActions({ where }).aggregate?.count() || 0;

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      const list = q
        .actions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((action) => getActionRow(action, overwrites));

      if (type === 'success') {
        return {
          list,
          aggregate,
        };
      }

      const children = q
        .jobs({
          where: {
            plan: {
              workflowId: { _in: workflowIds },
            },
            status: {
              _eq: 'failed',
            },
          },
        })
        .map((j) => getJob(j, overwrites));
      return {
        list: [...list, ...children],
        aggregate: aggregate + children.length,
      };
    },
    {
      variables: {
        search: search.trim(),
        workflowIds,
        sorting,
        type,
      },
      queryKey: ['actions'],
      subscribe: true,
    }
  );

  const columns = useAutomationHistoryTableColumns();

  const resetFilter = () => {
    setSearch('');
  };

  return (
    <InfiniteTable
      columns={columns}
      table={{
        hideHeader: true,
        emptyRowsFallback:
          type === 'failed'
            ? () => <Placeholder status={'completed'} />
            : 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      sorting={{
        setSorting,
        sorting,
      }}
      resetFilter={resetFilter}
    >
      <Filter.Search value={search} setValue={setSearch} />
    </InfiniteTable>
  );
};
