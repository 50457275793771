// Handle magic link verification results
import { useEffect, useState } from 'react';

import {
  MagicLinkErrorCode,
  isMagicLinkError,
  useClerk,
} from '@finalytic/authentication';
import { Text } from '@mantine/core';
import { Navigate, useLocation } from 'react-router';
import { AuthContainer } from '../components';

// the final step in the magic link flow.
export const MagicLinkVerificationView = ({
  authRedirectUrl,
}: {
  authRedirectUrl: string;
}) => {
  const [verificationStatus, setVerificationStatus] = useState('loading');

  const { handleMagicLinkVerification } = useClerk();

  const { search } = useLocation();

  console.log({ search });

  useEffect(() => {
    async function verify() {
      try {
        await handleMagicLinkVerification({
          redirectUrl: `${authRedirectUrl}${search}`,
          redirectUrlComplete: `${authRedirectUrl}${search}`,
        });
        // If we're not redirected at this point, it means
        // that the flow has completed on another device.
        setVerificationStatus('verified');
      } catch (err: any) {
        // Verification has failed.
        let status = 'failed';
        if (isMagicLinkError(err) && err.code === MagicLinkErrorCode.Expired) {
          status = 'expired';
        }
        setVerificationStatus(status);
      }
    }
    verify();
  }, []);

  if (verificationStatus === 'loading') {
    return <AuthContainer bgColor="#fff" loading={true} />;
  }

  if (verificationStatus === 'failed') {
    return (
      <AuthContainer bgColor="#fff" loading={false}>
        <Text>Magic link verification failed</Text>
      </AuthContainer>
    );
  }

  if (verificationStatus === 'expired') {
    return (
      <AuthContainer bgColor="#fff" loading={false}>
        <Text>Magic link expired</Text>
      </AuthContainer>
    );
  }

  return <Navigate to={`/${search}`} />;
};
