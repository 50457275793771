import {
  NoStatementsTableOverlay,
  Table,
  TableStylingProps,
  useSavedColumnDefs,
} from '@finalytic/ui';
import {
  AgGridReact,
  CellClickedEvent,
  CsvExportParams,
  ExcelExportParams,
  ICellRendererParams,
  NestedFieldPaths,
  ValueFormatterParams,
} from '@finalytic/ui-grid';
import { ensure, formatCurrency, sum, utc } from '@finalytic/utils';
import { Box, Group, Text, Tooltip } from '@mantine/core';
import { formatUserName, getStatementSummary } from '@vrplatform/ui-common';
import { forwardRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ListStatement } from '../../hooks';
import { StatementStatusBadge } from '../badges';

type StatementListTableProps = {
  rowLimit?: number;
  rowData: ListStatement[];
  onRowSelected?: (val: StatementRow[]) => void;
  refetch: () => void;
  selectable: boolean;
} & TableStylingProps;

type StatementRow = ListStatement & ReturnType<typeof getStatementSummary>;

export const StatementListTable = forwardRef<
  AgGridReact,
  StatementListTableProps
>(
  (
    {
      rowLimit,
      rowData,
      onRowSelected,
      refetch,
      selectable,
      ...tableStylingProps
    },
    ref
  ) => {
    const navigate = useNavigate();

    const ownerStatements = useMemo<StatementRow[]>(() => {
      return rowData.map((ownerStatement) => {
        const summary = getStatementSummary(ownerStatement as any)!;
        return {
          ...ownerStatement,
          ...summary,
        };
      });
    }, [rowData]);

    const formatNumberColumn = ({
      data,
      column,
    }: ValueFormatterParams<any, any>) => {
      const colId = column.getId().split('.')[1];

      return data?.formatted?.[colId] || '';
    };

    const columnDefs = useSavedColumnDefs<StatementRow>(
      [
        {
          // field: 'owners',
          headerName: 'Owners',
          colId: 'owner',
          minWidth: 450,
          sort: rowLimit ? undefined : 'asc',
          checkboxSelection: selectable,
          headerCheckboxSelection: selectable,
          valueGetter: ({ data, node }) => {
            if (node?.rowPinned === 'bottom') return 'Total:';

            if (!data?.listing) return;

            return ensure<ListStatement>(data)
              .owners?.map((x) =>
                formatUserName(
                  {
                    firstName: x.owner.firstName,
                    lastName: x.owner.lastName,
                    companyName: x.owner.companyName,
                  },
                  { lastNameFirst: true }
                )
              )
              .join(' & ');
          },
          cellRenderer: (params: ICellRendererParams<ListStatement>) => {
            const listingDisabled = params.data?.listing?.disabled;

            const tooltipText = listingDisabled
              ? 'This listing is currently disabled for owner statements.'
              : undefined;

            return (
              <Box ml="xs" sx={{ display: 'block', overflow: 'hidden' }}>
                <Text
                  size="sm"
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {params.value}
                  <Text
                    size="xs"
                    color={'gray'}
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {listingDisabled && (
                      <Tooltip
                        withArrow
                        withinPortal
                        position="top-start"
                        offset={0}
                        arrowOffset={10}
                        label={tooltipText}
                      >
                        <Box
                          display="inline-block"
                          mr="xs"
                          w={10}
                          h={10}
                          sx={(theme) => ({
                            background: theme.colors.yellow[3],
                            borderRadius: '100%',
                          })}
                        />
                      </Tooltip>
                    )}
                    {params.data?.listing?.name}
                  </Text>
                </Text>
              </Box>
            );
          },
        },

        {
          field: 'listing.name',
          hide: true,
        },
        // { field: 'endAt', hide: true },
        // {
        //   field: 'startAt',
        //   headerName: 'Start / End',
        //   valueGetter: ({ data, node }) => {
        //     if (node?.rowPinned === 'bottom') return undefined;
        //     const formatWithYear = (value: string) =>
        //       day(value).utc().format('MMM DD YYYY');
        //     const formatNoYear = (value: string) =>
        //       day(value).utc().format('MMM DD');
        //     return formatNoYear(data.startAt) + ' - ' + formatWithYear(data.endAt);
        //   },
        // },
        {
          headerName: 'Starting Balance',
          field: 'amounts.startingBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Net Income',
          field: 'amounts.netIncome',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Current Balance',
          field: 'amounts.currentBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Payout',
          field: 'amounts.payout',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Ending Balance',
          field: 'amounts.endingBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          field: 'status',
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
          cellStyle: { alignItems: 'flex-end' },
          cellRenderer: ({
            value,
            node,
          }: ICellRendererParams<ListStatement>) => {
            if (node?.rowPinned === 'bottom') return undefined;

            return <StatementStatusBadge status={value} />;
          },
          type: 'rightAligned',
        },
      ],
      { memoKeys: [rowLimit, selectable], table: 'statements' }
    );

    const getRowStyle = useCallback((params: any) => {
      if (params.node.rowPinned) {
        return { fontWeight: 'bold' };
      }
    }, []);

    const pinnedBottomRow = useMemo(() => {
      const currency = ownerStatements[0]?.currency;
      const totals = ownerStatements.map((i) => ({ ...i.amounts }));

      const getTotal = (
        key: keyof (typeof ownerStatements)[number]['formatted']
      ) => {
        const t = totals || {};

        if (t.some((i) => typeof i[key] === 'number')) {
          return formatCurrency(sum(t, key), currency || undefined);
        } else {
          return '-';
        }
      };

      const currentBalance = getTotal('currentBalance');
      const startingBalance = getTotal('startingBalance');
      const endingBalance = getTotal('endingBalance');
      const netIncome = getTotal('netIncome');
      const payout = getTotal('payout');

      return {
        formatted: {
          currentBalance,
          startingBalance,
          netIncome,
          payout,
          endingBalance,
          currency,
        },
      };
    }, [ownerStatements]);

    const defaultExportParams = useMemo<CsvExportParams & ExcelExportParams>(
      () => ({
        columnKeys: ensure<('owner' | NestedFieldPaths<StatementRow>)[]>([
          'owner',
          'listing.name',
          'amounts.startingBalance',
          'amounts.netIncome',
          'amounts.currentBalance',
          'amounts.payout',
          'amounts.endingBalance',
          'status',
        ]),
      }),
      []
    );

    return (
      <Table<StatementRow>
        ref={ref}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellStyle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          useValueFormatterForExport: true,
        }}
        context={{
          refetch,
        }}
        rowData={ownerStatements}
        groupDisplayType="groupRows"
        groupDefaultExpanded={1}
        // rowGroupPanelShow="always"
        onColumnRowGroupChanged={({ api }) => api.sizeColumnsToFit()}
        groupRowRendererParams={{
          innerRenderer: GroupRowInnerRenderer,
          suppressCount: true,
        }}
        animateRows={true}
        rowStyle={{ cursor: 'pointer' }}
        noRowsOverlayComponent={() => (
          <NoStatementsTableOverlay text="No statements available." />
        )}
        getRowStyle={getRowStyle}
        getRowClass={(params) => {
          if (rowLimit && params.rowIndex > rowLimit) return 'limit-blurred';
        }}
        gridOptions={{
          suppressCellFocus: true,
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          rowHeight: 55,
          defaultExcelExportParams: { allColumns: true },
          defaultCsvExportParams: { allColumns: true },
        }}
        alwaysShowVerticalScroll
        onRowSelected={
          onRowSelected
            ? (event) => {
                const rows = event.api.getSelectedRows();
                onRowSelected(rows);
              }
            : undefined
        }
        defaultCsvExportParams={defaultExportParams}
        defaultExcelExportParams={defaultExportParams}
        onCellClicked={(params: CellClickedEvent<StatementRow>) => {
          if (
            params.node.rowPinned === 'bottom' ||
            params.column.getColId() === 'menu'
          )
            return;

          if (rowLimit && (params.rowIndex || 0) > rowLimit - 1) return;

          const ownerId = params.data?.owners?.[0]?.owner.id;
          const withOwner = ownerId ? `&statementOwner=${ownerId}` : '';

          if (selectable && params.column.getColId() === 'owner') {
            params.node.setSelected(!params.node.isSelected());
          } else {
            navigate(
              `/statements/${params.data?.listing?.id}?date=${utc(
                params.data?.startAt
              ).format('YYYY-MM-DD')}${withOwner}`
            );
          }
        }}
        pinnedBottomRowData={[pinnedBottomRow]}
        suppressAggFuncInHeader={true}
        {...tableStylingProps}
      />
    );
  }
);

const GroupRowInnerRenderer = (params: any) => {
  const currency = params.node.allLeafChildren[0]?.data?.currency;

  const total = params.node.aggData?.centTotal
    ? formatCurrency(params.node.aggData.centTotal / 100, currency)
    : '';

  return (
    <Group justify="space-between" gap={40}>
      <StatementStatusBadge status={params.node.key} />
      <Text fw={'bold'}>{total}</Text>
    </Group>
  );
};
