import { utc } from '@finalytic/utils';

export const getStatementYearlySummaryHref = ({
  date,
  ownerId,
  listingId,
}: {
  listingId?: string;
  ownerId: string;
  date?: string;
}) => {
  const dateFormat = 'YYYY-MM-DD';

  return `/statements/summary?summary=groupByMonth${
    listingId ? `&summaryListing=${listingId}` : ''
  }&summaryOwner=${ownerId}&startAt=${utc(date)
    .startOf('year')
    .format(dateFormat)}&endAt=${utc(date).endOf('year').format(dateFormat)}&${
    date ? `date=${utc(date).format(dateFormat)}` : ''
  }`;
};
