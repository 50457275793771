import { useLocalStorage } from '@mantine/hooks';
import { useEffect } from 'react';
import { useTeam } from './team';

export function useAccountingConnection() {
  const [{ id: teamId, accountingPlatforms }] = useTeam();

  const [accounting, setAccounting] = useLocalStorage<string | undefined>({
    key: `${teamId}_acc`,
    defaultValue: accountingPlatforms[0]?.id,
  });

  useEffect(() => {
    if (!accountingPlatforms.find((a) => a.id === accounting)) {
      setAccounting(accountingPlatforms[0]?.id);
    }
  }, [accountingPlatforms, accounting]);

  return {
    accounting: accountingPlatforms.find((a) => a.id === accounting),
    setAccounting,
    accountingPlatforms,
  };
}
