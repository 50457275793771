import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  useTeam,
} from '@finalytic/data';
import { EmptyView, ListingDetailDrawer } from '@finalytic/data-ui';
import {
  StatementLinesDetailDrawer as LegacyStatementLinesDetailDrawer,
  StatementSummaryDetailDrawer as LegacyStatementSummaryDetailDrawer,
} from '@finalytic/owner-statements';
import { LoadingIndicator } from '@finalytic/ui';
import { theme as defaultTheme } from '@finalytic/ui';
import {
  Center,
  MantineThemeProvider as MantineProvider,
  createTheme,
} from '@mantine/core';
import { getOwnerPortalColor } from '@vrplatform/ui-common';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  AddTeamDrawer,
  AuditDrawer,
  ConnectionDetailDrawer,
  InviteOwnerUserDrawer,
  OwnerDetailDrawer as OwnerDetailDrawerV2,
  NewOwnerDetailDrawer as OwnerDetailDrawerV3,
  OwnerUserDetailDrawer,
  PaymentDetailDrawer as PaymentDetailDrawerV2,
  ReservationDetailDrawer as ReservationDetailDrawerV2,
  RunResultDrawer,
} from '../drawers';
import { AppLayout } from '../layout/AppLayout';
import {
  FinalyticMigrationModal,
  OwnerInviteLoggedInModal,
  OwnerTaxInfoModal,
  RunModal,
  WelcomeUserModal,
} from '../modals';
import {
  OwnerNavigation,
  PartnerNavigation,
  PmNavigation,
  SettingsNavigation,
} from '../navigation';
import { ListingActivityDrawer } from '../views/listings/activity';
import { ListingDetailDrawer as ListingDetailDrawerV2 } from '../views/listings/drawer';
import { OnboardingRoutes } from '../views/onboarding';
import { useSignInToken } from '../views/onboarding/hooks';
import { OwnerActivityDrawer } from '../views/owners-v2/activity';
import EmptyTeamView from '../views/setup-migration/EmptyTeamView';
import { DashboardRoutes } from './dashboard';

export const AppRoutes = () => {
  const user = useMe();
  const [team] = useTeam();
  const { tokenIsActive } = useSignInToken();

  // If user hasn't loaded => show loading
  if (!user?.id) {
    return <EmptyView loading />;
  }
  if (!team?.id) {
    return <EmptyTeamView />;
  }

  // Show loading indicator while user preview token is being taken care off
  if (tokenIsActive) {
    return (
      <Center h="100vh">
        <LoadingIndicator isFullPageLoading size="md" />
      </Center>
    );
  }

  // New users are created with "onboarding" status and shown only onboarding screens
  if (team.isOnboarding) {
    return <Onboarding />;
  }

  if (user.memberships.some((i) => i.status?.toLowerCase() === 'active')) {
    return <Dashboard />;
  }

  return <EmptyView title="This user is not active. Please reach out to us." />;
};

const Onboarding = () => {
  const _ = usePosthogPageViewCapture();

  return (
    <>
      <OnboardingRoutes />
      <WelcomeUserModal />
      <FinalyticMigrationModal />
    </>
  );
};

const Dashboard = () => {
  const [dashboard] = useDashboard();

  if (dashboard === 'owner') {
    return <OwnerPortal />;
  }

  return <DashboardChildren />;
};

function OwnerPortal() {
  const [team] = useTeam();
  const primaryColor = getOwnerPortalColor(team.colorPrimary);

  const theme = useMemo(() => {
    if (!primaryColor) return undefined;

    return createTheme({
      ...defaultTheme,
      primaryColor,
    });
  }, [primaryColor, defaultTheme]);

  if (!theme) return <DashboardChildren />;

  return (
    <MantineProvider theme={theme}>
      <DashboardChildren />
    </MantineProvider>
  );
}

function DashboardChildren() {
  const [dashboard] = useDashboard();
  const location = useLocation();
  const { NEW_OWNERS } = useEnabledFeatures();

  const _ = usePosthogPageViewCapture();

  const getNavigation = () => {
    if (dashboard === 'owner') return OwnerNavigation;
    if (dashboard === 'partner') return PartnerNavigation;
    if (dashboard === 'overview') return PartnerNavigation;
    return PmNavigation;
  };

  const DashboardNavigation = getNavigation();

  const isSettingsView = location.pathname.startsWith('/settings');

  return (
    <>
      <AppLayout
        navigation={isSettingsView ? SettingsNavigation : DashboardNavigation}
        drawers={[
          RunResultDrawer,
          RunModal,
          ConnectionDetailDrawer,
          NEW_OWNERS ? () => <></> : LegacyStatementLinesDetailDrawer,
          NEW_OWNERS ? () => <></> : LegacyStatementSummaryDetailDrawer,
          AuditDrawer,
          PaymentDetailDrawerV2,
          ReservationDetailDrawerV2,
          NEW_OWNERS ? OwnerDetailDrawerV3 : OwnerDetailDrawerV2,
          NEW_OWNERS ? ListingDetailDrawerV2 : ListingDetailDrawer,
          NEW_OWNERS ? InviteOwnerUserDrawer : () => <></>,
          NEW_OWNERS ? OwnerUserDetailDrawer : () => <></>,
          AddTeamDrawer,
          OwnerActivityDrawer,
          ListingActivityDrawer,
        ]}
      >
        <DashboardRoutes />
      </AppLayout>
      <WelcomeUserModal />
      <OwnerTaxInfoModal />
      <OwnerInviteLoggedInModal />
      <FinalyticMigrationModal />
    </>
  );
}

function usePosthogPageViewCapture() {
  const posthog = usePostHog();
  const user = useMe();
  const location = useLocation();

  useEffect(() => {
    if (user.email) {
      posthog?.capture?.('$pageview', {
        email: user?.email,
      });
    }
  }, [location, user?.email]);
}
