import { Badge } from '@finalytic/components';
import { useDashboard, useEnabledFeatures } from '@finalytic/data';
import {
  ListingEllipsisMenuItems,
  ListingEllipsisMenuModals,
} from '@finalytic/data-ui';
import {
  ArchiveIcon,
  ArrowRightCircleIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
} from '@finalytic/icons';
import {
  InfiniteTable,
  MRT_ColumnDef,
  MRT_GroupingState,
  MRT_SortingState,
} from '@finalytic/table';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
  IconButton,
  StringParam,
  useQueryParamSet,
} from '@finalytic/ui';
import { ensure, hasValue } from '@finalytic/utils';
import { Group, Text, Tooltip, useMantineColorScheme } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { InputListingOwners } from '../../../components/InputListingOwners';
import { TableFilterType, useGenericTableStore } from '../../../stores';
import {
  ListingCollectionSelect,
  ListingGroupModals,
  ListingNameCell,
  ListingStatusSelect,
} from '../_components';
import { ListingFilter, useListingFilter } from './ListingFilter';
import { ListingRow, useListingTableQuery } from './useListingTableQuery';

type Props = {
  // Options
  onRowClickType: 'detail' | 'select';
  hideRowMenu: boolean;
  disableInputs: boolean;

  // Filter
  defaultFilterByAutomationId: string | undefined;
  filterType: TableFilterType;
};

export const ListingBaseTable = ({
  onRowClickType,
  hideRowMenu,
  disableInputs,
  defaultFilterByAutomationId: filterByAutomationId,
  filterType,
}: Props) => {
  const { colorScheme } = useMantineColorScheme();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();
  const isOwnerPortal = dashboard === 'owner';
  const [opened, setOpened] = useState<{
    listing: ListingRow;
    modal: 'delete' | 'migrate';
  } | null>(null);

  const [openedGroup, setOpenedGroup] = useState<{
    group: { id: string; name: string };
    modal: 'delete' | 'rename';
  } | null>(null);

  const closeModal = () => setOpened(null);

  const [groupBy, setGroupBy] = useState<MRT_GroupingState>(['collectionId']);

  const { reset } = useListingFilter();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'calculated_title', desc: false },
  ]);

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);

  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );

  const setListing = useQueryParamSet('listing', StringParam);

  const queryData = useListingTableQuery({
    sorting,
    type: isOwnerPortal ? 'ownerPortal' : 'pmDashboard',
    groupBy,
    filterByAutomationId,
  });

  const columns = useMemo(
    () =>
      ensure<(MRT_ColumnDef<ListingRow> | undefined)[]>([
        {
          accessorKey: 'name',
          enableColumnDragging: false,
          header: 'Listing',
          Cell: ({ row }) => <ListingNameCell {...row.original} />,

          AggregatedCell: ({ row }) => {
            const collection = row.original.collection;
            const isGrouped = !!collection?.id;

            return (
              <Group wrap="nowrap" gap="sm">
                {isGrouped ? <HomeIcon size={18} /> : <ArchiveIcon size={18} />}
                <Text component="p" size="sm" fw={500}>
                  {row.original.collection.name}
                </Text>
              </Group>
            );
          },
        },
        isOwnerPortal
          ? undefined
          : {
              id: 'collectionId',
              header: 'Listing Group',
              enableColumnDragging: false,
              getGroupingValue: (row) => row.collection.id,
              enableGrouping: true,
              GroupedCell: ({ row }) => row.original.collection.name,
              maxSize: 70,
              Cell: ({ row }) => {
                const collection = row.original.collection;
                const listingId = row.original.id;

                if (!listingId) return null;

                return (
                  collection?.id && (
                    <ListingCollectionSelect
                      collection={collection}
                      listingId={listingId}
                    />
                  )
                );
              },
              enableHiding: false,
            },
        isOwnerPortal || GL || disableInputs
          ? undefined
          : {
              accessorKey: 'disabledAutomations',
              header: 'Automations',
              enableColumnDragging: false,
              Cell: ({ row }) => <ListingStatusSelect {...row.original} />,
              mantineTableBodyCellProps: {
                align: 'right',
              },

              mantineTableHeadCellProps: {
                align: 'right',
              },
            },
        isOwnerPortal
          ? undefined
          : {
              accessorKey: 'ownerships',
              header: GL ? 'Current Ownership' : 'Owners',
              enableSorting: false,
              enableColumnDragging: false,
              Cell: ({ row }) => {
                const setOwner = useQueryParamSet('owner', StringParam);

                if (!row.original.id) return null;

                if (GL) {
                  const members = row.original.currentPeriod?.members || [];

                  return members.map((member) => (
                    <Badge
                      component="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOwner(member.ownerId, 'push');
                      }}
                      key={member.id}
                      color={
                        member.ownerType === 'company' ? 'violet' : undefined
                      }
                      leftIcon={
                        <Icon
                          icon={
                            member.ownerType === 'company'
                              ? 'OfficeIcon'
                              : 'UserIcon'
                          }
                          size={14}
                          style={{ marginRight: 5 }}
                          color={(theme) =>
                            theme.colors[
                              member.ownerType === 'company'
                                ? 'violet'
                                : theme.primaryColor
                            ][5]
                          }
                        />
                      }
                      sx={() => ({
                        '&:active': {
                          transform: 'translateY(1px)',
                        },
                        color: colorScheme === 'dark' ? undefined : 'black',
                      })}
                    >
                      {member.ownerName}
                    </Badge>
                  ));
                }

                return (
                  <InputListingOwners
                    table="listing"
                    ownerships={row.original.ownerships}
                    rowId={row.original.id}
                    companyOwnerships={[]}
                    inputDisabled={disableInputs}
                  />
                );
              },
            },
        isOwnerPortal || !GL
          ? undefined
          : {
              header: 'To Team',
              Header: () => null,
              enableSorting: false,
              enableColumnDragging: false,
              mantineTableBodyCellProps: { align: 'right' },
              mantineTableHeadCellProps: { align: 'right' },
              maxSize: 20,
              Cell: ({ row }) => {
                const goto = useNavigate();

                return (
                  <Tooltip label="Go to detail" withArrow withinPortal>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        goto(`/listing/${row.original.id}`);
                      }}
                      variant="outline"
                      sx={(theme) => ({
                        borderColor: theme.colors.gray[3],
                      })}
                    >
                      <ArrowRightCircleIcon size={16} />
                    </IconButton>
                  </Tooltip>
                );
              },
            },
      ]).filter(hasValue),
    [isOwnerPortal, disableInputs, GL]
  );

  return (
    <>
      <InfiniteTable
        table={{
          key: 'listings',
          hideExpandColumn: true,
          emptyRowsFallback: 'No listings found',
          onRowClick: isOwnerPortal
            ? undefined
            : {
                handler: (row) => {
                  if (onRowClickType === 'detail') {
                    return setListing(row.original.id);
                  }

                  if (onRowClickType === 'select') {
                    const canSelect = row.getCanSelect();

                    if (canSelect) {
                      row.toggleSelected();
                    }
                  }
                },
                disabled: (row) => row.getIsGrouped(),
              },
        }}
        columns={columns}
        queryData={queryData}
        groupBy={
          isOwnerPortal
            ? undefined
            : {
                groupBy,
                setGroupBy,
                defaultExpanded: true,
                allowExpanding: false,
                menuItems: ({ row }) => {
                  if (!row.original.collection.id) return null;

                  return (
                    <>
                      <EllipsisMenuItem
                        customIcon={<Edit3Icon size={16} />}
                        onClick={() =>
                          setOpenedGroup({
                            group: row.original.collection,
                            modal: 'rename',
                          })
                        }
                      >
                        Rename
                      </EllipsisMenuItem>
                      <EllipsisMenuDangerItem
                        onClick={() =>
                          setOpenedGroup({
                            group: row.original.collection,
                            modal: 'delete',
                          })
                        }
                      >
                        Delete
                      </EllipsisMenuDangerItem>
                    </>
                  );
                },
              }
        }
        sorting={{
          sorting,
          setSorting,
        }}
        selecting={
          isOwnerPortal
            ? undefined
            : {
                rowSelection: {
                  rows: rowSelection,
                  allPagesSelected,
                },
                setAllPagesSelected,
                setRowSelection,
              }
        }
        styles={{
          row: ({ row }, theme) => {
            if (row.getIsGrouped())
              return {
                backgroundColor:
                  theme.colors.neutral?.[colorScheme === 'dark' ? 9 : 1],
                height: 60,
              };

            return {};
          },
        }}
        rowMenu={
          isOwnerPortal || hideRowMenu
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const data = row.original;

                  const isPmsListing = !!data?.pmsConnectionId;

                  return (
                    <ListingEllipsisMenuItems
                      handlers={{
                        openDeleteModal: () =>
                          setOpened({ listing: data, modal: 'delete' }),
                        openMigrationModal: () =>
                          setOpened({ listing: data, modal: 'migrate' }),
                      }}
                      listing={{
                        isPmsListing,
                        listingConnections: data.connections.length,
                        listingId: data.id,
                        ownerStatements: data.statements,
                      }}
                    />
                  );
                },
              }
        }
        resetFilter={reset}
      >
        <ListingFilter
          hide={filterByAutomationId ? ['automation', 'status'] : undefined}
        />
      </InfiniteTable>
      <ListingEllipsisMenuModals
        listing={opened?.listing || null}
        deleteModal={{
          opened: opened?.modal === 'delete' && !!opened?.listing,
          closeModal,
        }}
        migrationModal={{
          opened: opened?.modal === 'migrate' && !!opened?.listing,
          closeModal,
        }}
      />
      <ListingGroupModals
        listingGroup={openedGroup?.group || null}
        deleteModal={{
          opened: openedGroup?.modal === 'delete',
          closeModal: () => setOpenedGroup(null),
        }}
        renameModal={{
          opened: openedGroup?.modal === 'rename',
          closeModal: () => setOpenedGroup(null),
        }}
      />
    </>
  );
};
