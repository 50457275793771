const defaultHasuraUri =
  /*location.hostname === 'localhost' || location.hostname.endsWith('.pages.dev')
    ? 'https://hasura.vrplatform.app/v1/graphql'
    : */ 'https://finalytic.hasura.app/v1/graphql';

export const GRAPHQL_URI = `${
  import.meta.env.VITE_GRAPHQL_URI || defaultHasuraUri
}`;

export const AUTH_REDIRECT_URL = import.meta.env.DEV
  ? 'https://tolocalhost.com/verification'
  : `https://${window.location.host}/verification`;

export const NEW_SIGN_IN = false;

export const PLATFORM: 'finalytic' | 'vrplatform' = import.meta.env
  .VITE_PLATFORM_NAME;

// Google Maps api key

export const STATUS_PAGE =
  PLATFORM === 'finalytic'
    ? 'https://status.finalytic.io'
    : 'https://status.vrplatform.app';
