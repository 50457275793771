import { Select } from '@finalytic/ui';
import { ICellEditorParams } from '@finalytic/ui-grid';
import { Box } from '@mantine/core';
import { NetRevenueColumn } from '@vrplatform/ui-common';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useCellSelectTypes } from './_utils';

export const NetRevenueTemplateTypeEditor = forwardRef(
  (
    {
      ...params
    }: ICellEditorParams<NetRevenueColumn, NetRevenueColumn['type']>,
    ref
  ) => {
    const cellValue = params?.value;

    const [singleSelectValue, setSingleSelectValue] = useState(cellValue);
    const { selectOptions, value } = useCellSelectTypes(cellValue);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return singleSelectValue;
        },
      };
    });

    useEffect(() => {
      if (singleSelectValue !== cellValue) {
        params.stopEditing();
      }
    }, [singleSelectValue]);

    return (
      <Box w="100%">
        <Select
          data={selectOptions}
          searchPlaceholder="Choose type..."
          setValue={(item) =>
            setSingleSelectValue(item.value as NetRevenueColumn['type'])
          }
          removeValue={() => params.stopEditing()}
          value={value}
          onClose={params.stopEditing}
          popoverWidth="target"
          preventClose
          defaultOpen
          withSearch
        />
      </Box>
    );
  }
);
