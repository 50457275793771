import { Box, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { DangerButton, TransparentButton } from '../../../components';
import { useColors } from '../../../styles';
import { Modal } from '../Modal';
import { ModalDeleteIcon } from './_icons';

type DeleteModalProps = {
  opened: boolean;
  onClose: () => void;
  title: ReactNode;
  subtitle: ReactNode;
  onSubmit: () => void;
  submitTitle?: string;
  size?: number;
  zIndex?: number;
  icon?: ReactNode;
};

export const DeleteModal = ({
  opened,
  onClose,
  title,
  subtitle,
  onSubmit,
  size,
  zIndex,
  submitTitle = 'Delete',
  icon = <ModalDeleteIcon />,
}: DeleteModalProps) => {
  const { gray } = useColors();

  const onDangerSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={size || 'sm'}
      withCloseButton={false}
      zIndex={zIndex}
      styles={{ body: { paddingBottom: 15 } }}
    >
      <Stack align="center" mb={20}>
        <Box mt={20}>{icon}</Box>
        <Box mb={10} sx={{ textAlign: 'center' }}>
          <Text fw={500} mb={10}>
            {title}
          </Text>
          <Text size="sm">{subtitle}</Text>
        </Box>
      </Stack>
      <DangerButton
        onClick={onDangerSubmit}
        data-testid="delete-submit"
        fullWidth
        mb={10}
        sx={{ height: 40 }}
      >
        {submitTitle}
      </DangerButton>
      <TransparentButton onClick={onClose} fullWidth sx={{ color: gray.dark }}>
        Cancel
      </TransparentButton>
    </Modal>
  );
};
