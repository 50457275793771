import { Icon } from '@finalytic/icons';
import { List } from '@mantine/core';
import { SidebarCard } from './SidebarCard';
import { SidebarContentContainer } from './SidebarContentContainer';

export const SidebarPricing = () => {
  const features = [
    'Automate your vacation rental Trust accounting',
    'Streamline generating monthly owner statements',
    'Invite owners to your branded online portal',
    'Live chat support by industry experts.',
  ];

  return (
    <SidebarContentContainer
      title="Get full access to VRPlatform for 14 days"
      subtitle="Try our accounting automation and owner statement tools with no risk and no commitment. We won't charge you until after your free 14 days and you can cancel anytime."
      gap={100}
    >
      <SidebarCard>
        <List
          spacing="md"
          size="sm"
          center
          styles={{ item: { color: 'white' } }}
          icon={
            <Icon icon={'CheckIcon'} size={18} color={(theme) => theme.white} />
          }
        >
          {features.map((feature) => (
            <List.Item key={feature}>{feature}</List.Item>
          ))}
        </List>
      </SidebarCard>
    </SidebarContentContainer>
  );
};
