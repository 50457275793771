import { StringParam, useQueryParams } from '@finalytic/ui';

export const useExtractModal = () => {
  const [{ extractConnectionId, extractType }, setExtract] = useQueryParams({
    extractConnectionId: StringParam,
    extractType: StringParam,
  });

  const close = () =>
    setExtract({ extractConnectionId: undefined, extractType: undefined });

  const open = ({
    connectionId,
    extractType,
  }: { connectionId: string; extractType: string | undefined }) =>
    setExtract({ extractConnectionId: connectionId, extractType });

  return {
    opened: !!extractConnectionId,
    close,
    open,
    data: { extractType, extractConnectionId },
  };
};
