import { AreaChartByDate } from './AreaChartByDate';

type Props = {
  startDate: string;
  endDate: string;
};

export const ActiveListingsCard = ({ endDate, startDate }: Props) => {
  return (
    <AreaChartByDate
      title="Active Listings Created"
      href="/listings"
      total="sum"
      query={{
        measures: ['Listing.createdAtCount'],
        timeDimensions: [
          {
            dimension: 'Listing.createdAt',
            granularity: 'day',
            dateRange: [startDate, endDate],
          },
        ],
        order: {
          'Listing.createdAt': 'asc',
        },
      }}
    />
  );
};
