import { Select, SelectItem } from '@finalytic/ui';
import { ICellEditorParams } from '@finalytic/ui-grid';
import { ensure, expression, hasValue } from '@finalytic/utils';
import { Box, Flex } from '@mantine/core';
import { NetRevenueColumn, NetRevenueColumnType } from '@vrplatform/ui-common';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { StatementFormulaEditor } from '../../../../../components';
import { field_select_options } from './_utils';

export const NetRevenueTemplateValueEditor = forwardRef(
  (params: ICellEditorParams, ref) => {
    const gridData: NetRevenueColumn[] = [];
    params.api.forEachNode((node) => gridData.push(node.data));

    const availableColumns = gridData
      .filter((row) => row.type === 'sumAccounts' && row.id !== params.data.id)
      .map((row) => ({ value: row.id, label: row.name }));

    const collectionColumns = gridData;

    const rowType: NetRevenueColumnType = params.data.type;
    const value: (string | undefined) | string[] = params?.value;

    const [singleSelectValue, setSingleSelectValue] = useState<string>(
      value?.toString() || ''
    );

    const [multiSelectValue, setMultiSelectValue] = useState<string[]>(
      value?.toString()?.split(',') || []
    );

    const activeValues = useMemo(() => {
      switch (rowType) {
        case 'sumColumns':
          return availableColumns.filter((i) =>
            multiSelectValue.includes(i.value)
          );
        case 'subtractColumns':
          return (multiSelectValue
            ?.map((id: string) => availableColumns.find((i) => i.value === id))
            .filter((i) => Boolean(i?.value)) || []) as SelectItem[];
        default:
          return [];
      }
    }, [rowType, availableColumns, multiSelectValue]);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(
      ref,
      () => {
        return {
          // the final value to send to the grid, on completion of editing
          getValue() {
            if (rowType === 'field') return singleSelectValue;
            if (
              ensure<NetRevenueColumnType[]>([
                'formula',
                'formula.percentage',
                'formula.number',
                'formula.currency',
              ]).includes(rowType)
            ) {
              try {
                // beautify formula when correct
                const tree = expression.toTree(singleSelectValue);
                return expression.toString(tree);
              } catch (_error) {
                return singleSelectValue;
              }
            }

            // keep data type to string => ag grid can handle only one data type for a column
            return multiSelectValue.filter(hasValue).join(',');
          },
        };
      },
      [singleSelectValue, multiSelectValue, rowType]
    );

    useEffect(() => {
      if (rowType === 'field' && singleSelectValue !== value) {
        params.stopEditing();
      }
    }, [singleSelectValue, value, rowType, params]);

    if (rowType === 'field') {
      return (
        <Box w="100%">
          <Select
            data={field_select_options}
            setValue={(item) => setSingleSelectValue(item.value)}
            removeValue={() => params.stopEditing()}
            value={field_select_options.find((i) => i.value === value)}
            popoverWidth="target"
            preventClose
            defaultOpen
            withSearch
            withBorder={false}
            onClose={params.stopEditing}
            offset={10}
          />
        </Box>
      );
    }

    if (
      ensure<NetRevenueColumnType[]>([
        'formula',
        'formula.percentage',
        'formula.number',
        'formula.currency',
      ]).includes(rowType)
    ) {
      return (
        <Flex direction="row" sx={{ '&, > * ': { width: '100%' } }}>
          <StatementFormulaEditor
            collectionColumns={collectionColumns}
            value={singleSelectValue}
            setValue={setSingleSelectValue}
            cellWidth={params.eGridCell.clientWidth}
            onEnter={params.stopEditing}
          />
        </Flex>
      );
    }

    if (rowType === 'subtractColumns' || rowType === 'sumColumns') {
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            minWidth: 400,
            '& > button ': { margin: '0!important', height: 40 },
          }}
        >
          <Select
            multiple
            data={availableColumns.filter(
              (i) => !multiSelectValue.includes(i.value)
            )}
            value={activeValues}
            setValue={(item) => {
              if (item?.value) setMultiSelectValue((e) => [...e, item.value]);
            }}
            removeValue={(item) => {
              if (item?.value) {
                setMultiSelectValue((e) =>
                  e.filter((i: string) => i !== item.value)
                );
              }
            }}
            offset={10}
            popoverWidth="target"
            onClose={params.stopEditing}
            withSearch
            defaultOpen
            withBorder={false}
          />
        </Box>
      );
    }

    return null;
  }
);
