import { MantineSize } from '@mantine/core';
import { AreaChartByDate } from './AreaChartByDate';

type Props = {
  startDate: string;
  endDate: string;
  borderRadius?: MantineSize;
};

export const ReservationVolumeCard = ({
  endDate,
  startDate,
  borderRadius,
}: Props) => {
  return (
    <AreaChartByDate
      title="Reservation Volume"
      href="/reservations"
      total="sum"
      isCurrencyAmount
      borderRadius={borderRadius}
      query={{
        measures: ['Reservation.amountTotal'],
        order: {
          'Reservation.checkIn': 'asc',
        },
        timeDimensions: [
          {
            dimension: 'Reservation.checkIn',
            granularity: 'day',
            dateRange: [startDate, endDate],
          },
        ],
      }}
    />
  );
};
