import { CalendarFilterDateType, CheckInOutType } from '@finalytic/ui';
import { day, ensure, hasValue } from '@finalytic/utils';
import { useReservationFilter } from './ReservationFilter';

import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  usePartnerTeams,
  useTeamId,
} from '@finalytic/data';
import {
  ReservationsFilterParams,
  whereReservations,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';

export function useWhereReservations(opts: {
  filterByAutomationId: string | undefined;
}) {
  const filter = useFormattedReservationFilter();

  const onlyEnabledListingByAutomationId = opts.filterByAutomationId;

  return useWhereReservationsBase(
    onlyEnabledListingByAutomationId
      ? { ...filter, onlyEnabledListingByAutomationId }
      : filter
  );
}

export function useFormattedReservationFilter() {
  const { filter } = useReservationFilter();

  const getDate = (date: string | undefined | null) => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    return ensure<CalendarFilterDateType>([
      start ? day(start).toDate() : null,
      end ? day(end).toDate() : null,
    ]);
  };

  return {
    bookingChannels: filter.bookingChannels?.filter(hasValue) || undefined,
    connectionIds: filter.connectionIds?.filter(hasValue) || undefined,
    date: getDate(filter.date),
    dateType: (filter.dateType as CheckInOutType) || 'all',
    listingIds: filter.listingIds?.filter(hasValue) || undefined,
    search: filter.search || undefined,
    listingStatus: (filter.listingStatus as any) || undefined,
    status: filter.status || undefined,
    listingCollectionId: filter.listingCollectionId || undefined,
    onlyEnabledListingByAutomationId: undefined,
  };
}

export function useWhereReservationsBase(filter: ReservationsFilterParams) {
  const [currentTeamId] = useTeamId();
  const [partnerTeams] = usePartnerTeams();
  const [dashboard] = useDashboard();
  const { NEW_OWNERS } = useEnabledFeatures();
  const { ownerships, id: meId } = useMe();

  const partnerTeamIds = useMemo(
    () => partnerTeams.map((t) => t.id),
    [partnerTeams]
  );

  const where = useMemo(
    () =>
      whereReservations({
        currentTeamId,
        dashboard,
        ownerships,
        partnerTeamIds,
        includeLines: false,
        meId,
        v2Owners: NEW_OWNERS,
        ...filter,
      }),
    [
      JSON.stringify(filter),
      currentTeamId,
      dashboard,
      partnerTeamIds,
      ownerships,
      meId,
      NEW_OWNERS,
    ]
  );

  return where;
}
