import { useEnabledFeatures, useMe, useQuery, useTeam } from '@finalytic/data';
import {
  CalendarEventIcon,
  ClipboardTextIcon,
  FileInvoiceIcon,
  FolderOpenIcon,
  HomeIcon,
} from '@finalytic/icons';
import { utc } from '@finalytic/utils';
import { getStatementYearlySummaryHref } from '@vrplatform/ui-common';
import { useNavbarExpanded } from '../hooks';
import { NavbarRoute } from '../layout/navbar';
import { NavigationSection } from './NavigationSection';

export const OwnerNavigation = () => {
  const [{ id: teamId, membershipStatus }] = useTeam();
  const { isDesktopExpanded } = useNavbarExpanded();

  const { id: meId } = useMe();
  const { NEW_OWNERS } = useEnabledFeatures();
  const { data } = useQuery(
    (q, args) => {
      const showReservationSetting =
        q
          .setting({
            where: {
              key: { _eq: 'tenantSettings' },
              target: { _eq: 'showReservations' },
              tenant_id: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map((setting) => ({
            id: setting.id,
            target: setting.target,
          }))[0] || null;

      const statements = q
        .ownerStatements({
          where: {
            tenantId: { _eq: args.teamId },
            status: { _in: ['posted', 'published'] },
          },
          limit: 1,
          order_by: [{ updatedAt: 'desc_nulls_last' }],
        })
        .map((statement) => ({
          id: statement.id,
          startAt: statement.startAt,
          owners: statement
            .owners({
              where: {
                newOwnerId: { _is_null: false },
              },
            })
            .map((x) => x.newOwnerId),
        }));

      return {
        showReservationSetting,
        statements,
      };
    },
    {
      variables: { teamId },
      skip: !teamId,
      queryKey: ['settings', 'ownerStatements'],
    }
  );

  const statements = data?.statements || [];
  const date = statements[0]?.startAt || utc();
  const ownerId = NEW_OWNERS ? statements[0]?.owners[0] : meId;

  const isArchived = membershipStatus === 'archived';
  const showReservations = !!data?.showReservationSetting && !isArchived;

  const iconSize = 18;

  return (
    <>
      <NavigationSection>
        <NavbarRoute
          title="Statements"
          link="/statements"
          icon={<ClipboardTextIcon size={iconSize} />}
          navbarIsExpanded={isDesktopExpanded}
          v2Icon
          end
        />

        <NavbarRoute
          title="Summary"
          link={getStatementYearlySummaryHref({
            ownerId,
            date,
          })}
          icon={<FileInvoiceIcon size={iconSize} />}
          navbarIsExpanded={isDesktopExpanded}
          v2Icon
          end
        />
      </NavigationSection>

      {!isArchived && (
        <NavigationSection>
          {showReservations && (
            <NavbarRoute
              title="Reservations"
              link="/reservations"
              icon={<CalendarEventIcon size={iconSize} />}
              navbarIsExpanded={isDesktopExpanded}
              v2Icon
            />
          )}

          <NavbarRoute
            title="Listings"
            link="/listings"
            icon={<HomeIcon size={iconSize} />}
            navbarIsExpanded={isDesktopExpanded}
            v2Icon
          />
        </NavigationSection>
      )}

      <NavigationSection>
        <NavbarRoute
          title="Files"
          link="/files"
          icon={<FolderOpenIcon size={iconSize} />}
          navbarIsExpanded={isDesktopExpanded}
          v2Icon
        />
      </NavigationSection>
    </>
  );
};
