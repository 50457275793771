import {
  EllipsisMenu,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  EllipsisMenuLabel,
} from '@finalytic/ui';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { Maybe } from '@finalytic/utils';
import {
  faArrowsCross,
  faCopy,
  faPercent,
} from '@fortawesome/pro-solid-svg-icons';
import { useClipboard } from '@mantine/hooks';
import { HiddenFeatureIndicator } from '../../../../indicators';
import { ListingOwnerRow } from '../../useListingDetailData';
import { useOwnershipChangeStore } from './_hooks';

export const RowMenu = ({
  data,
  node,
  api,
  hasSplitAutomation,
  hasVendorAutomation,
}: ICellRendererParams<ListingOwnerRow> & {
  hasVendorAutomation: boolean;
  hasSplitAutomation: boolean;
}) => {
  const ownershipId = data?.ownershipId as Maybe<string>;
  const withOwnerSplitReset = data?.role === 'owner';

  const addChange = useOwnershipChangeStore((store) => store.add);

  const resetOwnerSplit = () => {
    node.setDataValue('ownerSplit', null);
    addChange(data!.ownerId, { ...data!, ownerSplit: null });
  };

  const swapRoles = () => {
    const value = data?.role === 'owner' ? 'spectator' : 'owner';
    node.setDataValue('role', value);

    if (!data) return;

    const spectatorRowData: ListingOwnerRow = {
      ...data,
      role: 'spectator',
      ownerSplit: null,
      vendor: { ...data.vendor, value: '' },
    };

    const ownerRowData: ListingOwnerRow = {
      ...data,
      role: 'owner',
    };

    addChange(
      data!.ownerId,
      value === 'owner' ? ownerRowData : spectatorRowData
    );

    api.redrawRows({ rowNodes: [node] });
  };

  const removeOwnership = () => {
    if (!data) return;
    addChange(data.ownerId, null);
    api.applyTransaction({
      remove: [data],
    });
  };

  const { copy } = useClipboard();

  if (!data) return;

  return (
    <EllipsisMenu>
      <EllipsisMenuLabel>General</EllipsisMenuLabel>
      {withOwnerSplitReset && hasSplitAutomation && (
        <EllipsisMenuItem icon={faPercent} onClick={resetOwnerSplit}>
          Reset owner split
        </EllipsisMenuItem>
      )}
      {hasVendorAutomation && (
        <EllipsisMenuItem icon={faArrowsCross} onClick={swapRoles}>
          Change role
        </EllipsisMenuItem>
      )}
      <EllipsisMenuDivider />
      <EllipsisMenuDangerItem onClick={removeOwnership}>
        Remove ownership
      </EllipsisMenuDangerItem>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuDivider />
        <EllipsisMenuLabel>Super Admin</EllipsisMenuLabel>
        <EllipsisMenuItem icon={faCopy} onClick={() => copy(ownershipId)}>
          Copy listing owner ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </EllipsisMenu>
  );
};
