import { useUser } from '@finalytic/authentication';
import { useLocalStorage } from '@mantine/hooks';
import * as Sentry from '@sentry/react';
import {
  AutomationMappings,
  AutomationRunSettings,
  AutomationViewSettings,
  InputFormSchema,
  VRP_TENANT_ID,
  formatUserName,
  getUserAddress,
  whereConnectionStatusDefault,
} from '@vrplatform/ui-common';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useQuery } from '../graphql';
import { useTrpcQuery } from '../trpc';
import { OpenAPI } from '../trpc-api/requests';
import { useExtension } from './useExtension';

// TODO: Hardcode superadmins for now, until we fix the payment link automation
const vrpadmins = [
  'l.behrenberg@gmail.com',
  'michael@vrplatform.app',
  'adam@ximplifi.com',
  'jesse@ximplifi.com',
];

function __useMe(args: {
  userId?: string;
  realUserId?: string;
  activeTeamId?: string;
  role?: string;
}) {
  const intercom = useIntercom();
  const posthog = usePostHog();

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useQuery(
    (q, { userId, activeTeamId, VRP_TENANT_ID }) => {
      if (!userId) return null;

      const currentTeam = q
        .tenant({
          limit: 1,
          where: activeTeamId ? { id: { _eq: activeTeamId } } : undefined,
        })
        .map((item) => {
          const membership = item
            .members({ where: { userId: { _eq: userId } } })
            .map((x) => ({ id: x.id, role: x.role, status: x.status }))[0];

          const finalyticConnectionId = item
            .connections({
              where: {
                appId: { _eq: 'finalytic' },
              },
              limit: 1,
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => connection.id)[0] as string;

          const accountingPlatforms = item
            .connections({
              where: {
                status: whereConnectionStatusDefault,
                app: { category: { _eq: 'accountingPlatform' } },
              },
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => ({
              appId: connection.appId,
              name: connection.name,
              type: connection.app.type,
              category: connection.app.category,
              id: connection.id,
              icon: connection.app.iconRound,
            }));

          const propertyManagementSystems = item
            .connections({
              where: {
                status: whereConnectionStatusDefault,
                app: { category: { _eq: 'propertyManagementSystem' } },
              },
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => ({
              appId: connection.appId,
              name: connection.app.name,
              type: connection.app.type,
              category: connection.app.category,
              id: connection.id,
            }));

          const automations =
            args.role === 'owner'
              ? []
              : item
                  .automations({
                    order_by: [{ title: 'asc_nulls_last' }],
                  })
                  .map((automation) => ({
                    automationId: automation.id,
                    status: automation.status,
                    createdAt: automation.createdAt,
                    templateId: automation.ttemplate?.id,
                    viewSettings: (automation.viewSettings() ||
                      {}) as AutomationViewSettings,
                    runSettings: (automation.runSettings() ||
                      {}) as AutomationRunSettings,
                    template: {
                      uniqueRef: automation.ttemplate?.uniqueRef,
                      type: automation.ttemplate?.type,
                      title: automation.ttemplate?.title,
                      input: automation.ttemplate?.input,
                      params: automation.ttemplate?.params(),
                      visibility: automation.ttemplate?.visibility,
                    },
                    title: automation?.title || automation.ttemplate?.title,
                    mappings: (automation.ttemplate?.mappings() ||
                      {}) as AutomationMappings,
                    settings: (automation.ttemplate?.settings() ||
                      {}) as InputFormSchema,
                    leftConnectionId: automation.leftConnectionId,
                    leftConnection: {
                      id: automation.leftConnection?.id,
                      name: automation.leftConnection?.name,
                      icon: automation.leftConnection?.app?.iconRound,
                    },
                    rightConnectionId: automation.rightConnectionId,
                    rightConnection: {
                      id: automation.rightConnection?.id,
                      name: automation.rightConnection?.name,
                      icon: automation.rightConnection?.app?.iconRound,
                    },
                    connections: {
                      [automation.leftConnection?.appId || '']:
                        automation?.leftConnectionId,
                      [automation.rightConnection?.appId || '']:
                        automation?.rightConnectionId,
                    },
                  }));

          const getAutomationMappings = (
            targetKey: 'listing' | 'listingOwner'
          ) => {
            type Mapping = any;

            return automations
              .flatMap((automation) => {
                // get only mappings where the leftType is the target/overwrite && isGlobal
                const mappings = Object.entries(automation.mappings || {})
                  .filter(([mappingKey, mapping]: [string, Mapping]) => {
                    const viewSettings = automation.viewSettings?.[mappingKey];

                    const isGlobal = !viewSettings?.isLocal;
                    const overwrite = viewSettings?.leftType;
                    const leftType = overwrite || mapping.left.schema;

                    return (
                      isGlobal &&
                      leftType?.split('.').reverse()[0] === targetKey
                    );
                  })
                  .map(([mappingKey, mapping]: [string, Mapping]) => ({
                    mappingKey,
                    ...mapping,
                  }));

                return mappings.map((mapping) => {
                  return {
                    mappingKey: mapping.mappingKey,
                    leftType: mapping.left.schema,
                    rightType: mapping.right.schema,
                    automationTemplateType: automation.template?.type,
                    automationId: automation.automationId,
                    leftConnectionId: automation.leftConnectionId,
                    rightConnectionId: automation.rightConnectionId,
                  };
                });
              })
              .filter((mapping, index, arr) => {
                // remove duplicates where mappingKey, leftType & rightType are the same
                return (
                  arr.findIndex(
                    (m) =>
                      m.mappingKey === mapping.mappingKey &&
                      m.leftType === mapping.leftType &&
                      m.rightType === mapping.rightType
                  ) === index
                );
              });
          };

          return {
            role: membership?.role,
            membershipId: membership?.id,
            membershipStatus: membership?.status,
            id: item.id!,
            logo: item.logo!,
            name: item.name!,
            billingSubscriptionStatus: (item.billingSubscriptionStatus ||
              null) as 'active' | 'canceled' | 'pending' | null,
            status: item.status! as 'active' | 'inactive',
            isOnboarding: !!item.isOnboarding,
            createdAt: item.createdAt!,
            colorPrimary: item.colorPrimary!,
            partnerId: item.partnerId!,
            partnerName: item.partner?.name,
            enabledFeatures: item.enabledFeatures()?.map((x) => ({
              featureId: x.featureId,
              status: x.status,
              updatedAt: x.updatedAt,
            })),
            forceTwoFactorAuth: !!item
              .settings({
                where: {
                  key: { _eq: 'tenantSettings' },
                  target: { _eq: 'forceTwoFactorAuth' },
                },
                limit: 1,
              })
              ?.map((x) => x.id)[0],
            featureFlags: item.featureFlags({})! as string[],
            type: item.type,
            finalyticConnectionId,
            accountingPlatforms,
            propertyManagementSystems,
            automations,
            globalMappings: {
              listingOwner: getAutomationMappings('listingOwner'),
              listing: getAutomationMappings('listing'),
            },
          };
        })
        .find((x) => x);

      const partnerTeams = activeTeamId
        ? q
            .tenant({
              where: { partnerId: { _eq: activeTeamId } },
            })
            .map((item) => {
              const membership = item
                .members({ where: { userId: { _eq: userId } } })
                .map((x) => ({ id: x.id, role: x.role }))[0];
              return {
                role: membership?.role,
                membershipId: membership?.id,
                id: item.id!,
                tenantId: item.id!,
                logo: item.logo!,
                name: item.name!,
                colorPrimary: item.colorPrimary!,
                partnerId: item.partnerId,
              };
            })
        : undefined;

      return (
        q
          .user({
            where: {
              id: { _eq: userId },
            },
            limit: 1,
          })
          .map((user) => {
            const memberships = user?.memberships().map((membership) => ({
              status: membership?.status,
              id: membership?.tenantId,
              role: membership?.role,
              teamType: membership?.tenant?.type,
            }));

            const isPartnerAdmin =
              memberships.find((x) => x.teamType === 'partner')?.role ===
              'admin';

            const isVrpAdmin =
              user.isAdmin || memberships.some((x) => x.id === VRP_TENANT_ID);

            return {
              partnerTeams,
              currentTeam,
              id: user.id!,
              notificationPreferences:
                user.notificationPreferences() as string[],
              type: user.type!,
              name: user.name!,
              taxId: user.taxId,
              address: { ...getUserAddress(user).values, id: user.address_id },
              memberships,
              firstName: user.firstName as string | undefined,
              lastName: user.lastName as string | undefined,
              companyName: user.companyName as string | undefined,
              partnerId: user.partnerId,
              isAdmin: user.isAdmin!,
              isPartnerAdmin: user.isAdmin || isPartnerAdmin,
              isVrpAdmin,
              email: user.email!,
              createdAt: user.createdAt!,
              featureApprovals: user.featureApprovals().map((approval) => ({
                id: approval.id,
                status: approval.status,
                featureId: approval.featureId,
              })),
              ownerships: user
                .ownerships({
                  where: activeTeamId
                    ? { listing: { tenantId: { _eq: activeTeamId } } }
                    : undefined,
                })
                .map((item) => ({
                  id: item.id!,
                  listingId: item.listingId!,
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !args.userId,
      variables: {
        userId: args.userId,
        activeTeamId: args.activeTeamId,
        VRP_TENANT_ID: VRP_TENANT_ID,
      },
      queryKey: ['users', 'teams', 'featureApprovals'],
    }
  );

  const { data } = useTrpcQuery(
    'posthogAnalytics',
    {
      tenantId: user?.currentTeam?.id,
    },
    {
      skip: import.meta.env.DEV || !user?.currentTeam?.id,
    }
  );

  const intercomIdentifyId = data?.intercom_user_hash;

  // Posthog tracking
  React.useEffect(() => {
    if (user?.id) {
      posthog?.identify(user.id, {
        email: user.email,
      });

      if (user.currentTeam) {
        const tenantId = user.currentTeam.id;
        posthog?.group('tenant', tenantId);
        OpenAPI.HEADERS = {
          ...OpenAPI.HEADERS,
          'x-tenant-id': tenantId,
        };
      } else {
        OpenAPI.HEADERS = {
          ...OpenAPI.HEADERS,
          'x-tenant-id': '',
        };
      }

      const isRecording = posthog.sessionRecordingStarted();

      if (!isRecording) {
        posthog?.startSessionRecording();
      }
    }

    return () => {
      posthog.stopSessionRecording();
      posthog?.reset(true);
      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        'x-tenant-id': '',
      };
    };
  }, [user?.id, user?.currentTeam?.id]);

  // Intercom & Sentry
  React.useEffect(() => {
    if (!user || !intercomIdentifyId) return () => {};

    intercom?.boot({
      email: user.email,
      userHash: intercomIdentifyId,
      userId: user.id,
      name: formatUserName(
        {
          firstName: user.firstName,
          lastName: user.lastName,
        },
        { lastNameFirst: false, showEmpty: true }
      ),
      customLauncherSelector: '#intercom-widget',
      hideDefaultLauncher: !user?.currentTeam?.isOnboarding,
      alignment: 'right',
      horizontalPadding: 40,
      verticalPadding: 40,
    });

    Sentry.setUser({
      name: user.name,
      email: user.email,
      createdAt: user.createdAt,
      id: user.id,
    });

    return () => {
      intercom?.shutdown();
      Sentry.setUser(null);
    };
  }, [user?.id, user?.currentTeam?.isOnboarding, intercomIdentifyId]);

  React.useEffect(() => {
    Sentry?.setTags({
      tenant_id: user?.currentTeam?.id || null,
      tenant_name: user?.currentTeam?.name || null,
    });
  }, [user?.currentTeam?.id]);

  return {
    user: user ? { ...user, realUserId: args.realUserId } : undefined,
    loading: isLoading,
    error,
    refetch,
  };
}

type User = NonNullable<ReturnType<typeof __useMe>['user']>;
export type Team = NonNullable<User['currentTeam']>;
type PartnerTeam = NonNullable<User['partnerTeams']>[number];

export const userContext = React.createContext<User>(undefined as any);
export const teamIdContext = React.createContext<{
  teamId: string;
  setTeamId: (value: string | null) => void;
}>({
  teamId: '',
  setTeamId: () => undefined,
});
export const teamContext = React.createContext<{
  teamId: string;
  setTeamId: (value: string) => void;
  team: Team;
  partnerTeams: PartnerTeam[];
  role: string;
  isAdmin: boolean;
  isPartnerAdmin: boolean;
  isVrpAdmin: boolean;
  isSuperAdmin: boolean;
  refetchTeam: () => Promise<void>;
  refetchPartnerTeams: () => void;
}>({
  isAdmin: false,
  isPartnerAdmin: false,
  isVrpAdmin: false,
  isSuperAdmin: false,
  role: 'user',
  team: {} as any,
  partnerTeams: [],
  teamId: '',
  setTeamId: () => undefined,
  refetchTeam: async () => undefined,
  refetchPartnerTeams: () => undefined,
});

export function DBUserProvider({ children }: { children: React.ReactNode }) {
  const { user: u } = useUser();

  // const { ownerPreview } = useOwnerPreviewId();
  const realUserId = `${u?.publicMetadata.user_id || ''}`;
  // const ownerPreviewUserId = ownerPreview?.userId?.trim() || '';
  // const ownerPreviewTeamId = ownerPreview?.teamId?.trim() || '';

  const userId = `${u?.publicMetadata.user_id || ''}` || undefined;
  // Ream
  const [_teamId, setTeamId] = useLocalStorage<string | undefined>({
    key: `${userId}_tid`,
    defaultValue: undefined,
  });

  const { user, refetch } = __useMe({
    userId,
    activeTeamId: _teamId,
    role: `${u?.publicMetadata.role || ''}`,
    realUserId,
  });

  // For webextension
  React.useEffect(() => {
    if (u) localStorage.setItem('cid', u.id);
    else localStorage.removeItem('cid');
    if (userId) localStorage.setItem('uid', userId);
    else localStorage.removeItem('uid');

    const tid = _teamId || user?.currentTeam?.id;
    if (tid) localStorage.setItem('tid', tid);
    else localStorage.removeItem('tid');
  }, [userId, u?.id, _teamId, user?.currentTeam?.id]);

  const teamIdValue = React.useMemo(() => {
    return {
      teamId: _teamId || user?.currentTeam?.id,
      setTeamId,
    };
  }, [_teamId || user?.currentTeam?.id, setTeamId]);

  const teamValue = React.useMemo(() => {
    return {
      teamId: user?.currentTeam?.id,
      setTeamId,
      team: user?.currentTeam || {},
      partnerTeams: user?.partnerTeams || [],
      role: user?.currentTeam?.role,
      isAdmin: user?.isAdmin || user?.currentTeam?.role === 'admin',
      isPartnerAdmin: user?.isAdmin || user?.isPartnerAdmin,
      isVrpAdmin:
        user?.isAdmin ||
        user?.isVrpAdmin ||
        vrpadmins.includes(user?.email || ''),
      isSuperAdmin: user?.isAdmin || false,
      refetchTeam: refetch,
      refetchPartnerTeams: () => undefined,
    };
  }, [user?.currentTeam, setTeamId]);

  return (
    <userContext.Provider value={user!}>
      <teamIdContext.Provider value={teamIdValue as any}>
        <teamContext.Provider value={teamValue as any}>
          {children}
        </teamContext.Provider>
      </teamIdContext.Provider>
    </userContext.Provider>
  );
}

export function useMe() {
  let user = React.useContext(userContext);
  if (!user) user = {} as any;
  return user;
}

export function useTeamId() {
  const { sendMessage } = useExtension();
  const goto = useNavigate();

  let tenant = React.useContext(teamIdContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;

  const setTeamId = async (teamId: string | null) => {
    tenant.setTeamId(teamId);

    if (
      window.location.pathname.includes('statement') &&
      !window.location.search?.includes('sti=')
    ) {
      goto('/statements');
    }

    try {
      sendMessage({ message: 'team_changed', data: { teamId } });
    } catch (error) {
      console.log(error);
    }
  };

  return [tenant.teamId, setTeamId] as [string, (value: string | null) => void];
}

export function useTeam() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;
  return [tenant.team, tenant.refetchTeam] as [
    typeof tenant.team,
    typeof tenant.refetchTeam,
  ];
}

export function usePartnerTeams() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [[], () => undefined] as any;
  return [tenant.partnerTeams, tenant.refetchPartnerTeams] as [
    typeof tenant.partnerTeams,
    typeof tenant.refetchPartnerTeams,
  ];
}

export function useTeamRole() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;
  return {
    teamRole: tenant.role,
    isTeamAdmin: tenant.isPartnerAdmin || tenant.isAdmin,
    isPartnerAdmin: tenant.isPartnerAdmin,
    isSuperAdmin: tenant.isSuperAdmin,
    isVrpAdmin: tenant.isVrpAdmin,
  };
}
