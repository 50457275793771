import { Card, CardProps } from '@mantine/core';
import { ReactNode } from 'react';

export const SidebarCard = ({
  children,
  sx,
  ...props
}: { children: ReactNode } & CardProps) => {
  return (
    <Card
      mb={0}
      p="xl"
      radius="md"
      sx={
        {
          color: 'white',
          position: 'relative',
          zIndex: 10,
          background:
            'linear-gradient(147.33deg, rgba(249, 249, 249, 0.1) 7.22%, rgba(255, 255, 255, 0.05) 93.2%)',
          ...sx,
        } as any
      }
      {...props}
    >
      {children}
    </Card>
  );
};
