import { gqlV2, useMutation, useTeam } from '@finalytic/data';
import { ActionButton, Button, showErrorNotification } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { formatUserName } from '@vrplatform/ui-common';
import { ListingOwnerRow } from '../../useListingDetailData';
import { useOwnershipChangeStore } from './_hooks';

type Props = {
  refetch: () => void;
  rowData: ListingOwnerRow[] | undefined;
  listingId: string;
  listingTeamId: string;
  hasVendorAutomation: boolean;
};

type RowUpdates = {
  inserts: ListingOwnerRow[];
  updated: ListingOwnerRow[];
  deleted: { ownershipId: string; ownerId: string }[]; // ownerIds
};

export const SubmitButtons = ({
  refetch,
  rowData,
  listingId,
  listingTeamId,
  hasVendorAutomation,
}: Props) => {
  const numberOfChanges = useOwnershipChangeStore(
    (store) => store.numberOfChanges
  );

  const getChanges = useOwnershipChangeStore((store) => store.getAllChanges);
  const reset = useOwnershipChangeStore((store) => store.reset);

  const { loading, mutate } = useListingOwnerMutation();

  const submit = async () => {
    const originalRows = rowData || [];
    const changedRows = getChanges();

    const changes = Object.entries(changedRows);

    // check in changes
    // => if role === owner => vendor must be set
    // => if role === spectator => vendor must NOT be set & split === NULL

    let passing = true;

    const merged = [
      ...originalRows.filter((i) => !changes.some((c) => c[0] === i.ownerId)),
      ...changes.filter((i) => !!i[1]).map(([_ownerId, row]) => row),
    ];

    const filtered = merged.filter((row, index) => {
      // filter rows where there is not a row with same ownerId later in the arr
      const ownerId = row?.ownerId;
      const laterRow = merged.find(
        (r, i) => i > index && r?.ownerId === ownerId
      );
      return !laterRow;
    });

    filtered.forEach((rowData) => {
      if (!hasVendorAutomation || !passing || !rowData) return;

      const role = rowData?.role;
      if (role === 'owner') {
        if (!rowData?.vendor?.value) {
          passing = false;
          showErrorNotification({
            message: `Please set a vendor for owner ${formatUserName(
              rowData
            )}.`,
          });
        }
      } else {
        if (rowData?.ownerSplit !== null) {
          passing = false;
          showErrorNotification({
            message: `Please remove split for spectator ${formatUserName(
              rowData
            )}.`,
          });
        } else if (rowData?.vendor?.value) {
          passing = false;
          showErrorNotification({
            message: `Please remove vendor for spectator ${formatUserName(
              rowData
            )}.`,
          });
        }
      }
    });

    if (!passing) return;

    const updates = changes.reduce<RowUpdates>(
      (prev, curr) => {
        const ownerId = curr[0];
        const rowData = curr[1];

        // deleted
        if (!rowData) {
          const previous = originalRows.find((row) => row.ownerId === ownerId);
          if (previous) {
            prev.deleted = [
              ...prev.deleted,
              { ownerId, ownershipId: previous.ownershipId },
            ];

            return prev;
          }
        } else {
          // not deleted
          const oldRow = originalRows.find((row) => row.ownerId === ownerId);
          // and new row
          if (!oldRow) {
            prev.inserts = [...prev.inserts, rowData];
          } else {
            prev.updated = [...prev.updated, rowData];
          }
          return prev;
        }

        return prev;
      },
      {
        inserts: [],
        updated: [],
        deleted: [],
      }
    );

    const _res = await mutate({ listingId, listingTeamId, updates });

    reset();
    refetch();
  };

  return (
    <>
      <Button
        onClick={() => {
          refetch();
          reset();
        }}
        disabled={!numberOfChanges}
      >
        Cancel
      </Button>
      <ActionButton
        loading={loading}
        disabled={!numberOfChanges}
        onClick={submit}
      >
        Save
      </ActionButton>
    </>
  );
};

const useListingOwnerMutation = () => {
  const [{ globalMappings }] = useTeam();

  const mappingKey = 'vendor';

  const mapping = globalMappings.listingOwner.find(
    (mapping) => mapping.mappingKey === mappingKey
  );

  const leftType = mapping?.leftType;
  const rightType = mapping?.rightType;
  const leftConnectionId = mapping?.leftConnectionId;
  const rightConnectionId = mapping?.rightConnectionId;
  const automationId = mapping?.automationId;

  const { loading, mutate } = useMutation(
    (
      q,
      args: {
        updates: RowUpdates;
        listingId: string;
        listingTeamId: string;
        setting: {
          leftType: string;
          rightType: string;
          mappingKey: string;
          leftConnectionId: string;
          rightConnectionId: string;
          automationId: string;
        };
      }
    ) => {
      const { deleted, inserts, updated } = args.updates;
      const listingId = args.listingId;
      const listingTeamId = args.listingTeamId;
      const {
        automationId,
        leftConnectionId,
        leftType,
        mappingKey,
        rightConnectionId,
        rightType,
      } = args.setting;

      const formatVendorSetting = ({
        ownershipId,
        vendorId,
        settingId,
        listingTeamId,
      }: {
        ownershipId: string;
        vendorId: string;
        settingId: string | undefined;
        listingTeamId: string;
      }) => {
        return ensure<gqlV2.setting_insert_input>({
          id: settingId,
          value: vendorId,
          tenant_id: listingTeamId,
          group: leftType,
          key: mappingKey,
          leftType,
          rightType,
          target: ownershipId,
          leftConnectionId,
          rightConnectionId,
          automationId,
        });
      };

      // for deleted
      // => by ownerId remove listingOwners & delete settings for old ownerships
      // for updated
      // => by ownerId update split & role and vendor setting
      // for inserts
      // => insert ownership with ownerId, listingId, split, role & vendor setting

      if (deleted.length) {
        const ownershipIds = args.updates.deleted.map((row) => row.ownershipId);

        q.deleteListingOwners({
          where: {
            id: { _in: ownershipIds },
            listingId: { _eq: listingId },
          },
        })?.affected_rows || 0;

        q.delete_setting({
          where: { target: { _in: ownershipIds } },
        })?.affected_rows || 0;
      }

      if (inserts.length) {
        const withVendor = inserts.filter((row) => row.vendor?.value);
        const withoutVendor = inserts.filter((row) => !row.vendor?.value);
        const allInserts = [...withVendor, ...withoutVendor];
        const ids = allInserts.map(() => crypto.randomUUID());

        q.insertListingOwners({
          objects: allInserts.map((row, index) => {
            const uuid = ids[index];

            return {
              id: uuid,
              listingId,
              ownerId: row.ownerId,
              role: row.role,
              split: row.ownerSplit,
            };
          }),
        })?.affected_rows || 0;

        q.insert_setting({
          objects: withVendor.map((row, index) => {
            return formatVendorSetting({
              listingTeamId,
              ownershipId: ids[index],
              settingId: undefined,
              vendorId: row.vendor?.value,
            });
          }),
        })?.affected_rows || 0;
      }

      if (updated.length) {
        q
          .update_listing_owner_many({
            updates: updated.map((row) => ({
              where: {
                ownerId: { _eq: row.ownerId },
                listingId: { _eq: listingId },
              },
              _set: {
                split: row.ownerSplit,
                role: row.role,
              },
            })),
          })
          ?.map((res) => res?.affected_rows || 0)
          .reduce<number>((prev, curr) => prev + (curr || 0), 0) || 0;

        // delete settings
        const deleteSettingIds = updated
          .filter((row) => !!row.vendor?.settingId && !row.vendor?.value)
          .map((row) => row.vendor.settingId);

        q.delete_setting({
          where: { id: { _in: deleteSettingIds } },
        })?.affected_rows || 0;

        // update settings
        const updateSettings = updated
          .filter((row) => !!row.vendor?.settingId && !!row.vendor?.value)
          .map((row) => ({
            settingId: row.vendor?.settingId,
            vendorId: row.vendor?.value,
            ownershipId: row.ownershipId,
          }));

        q
          .update_setting_many({
            updates: updateSettings.map((row) => ({
              where: { id: { _eq: row.settingId } },
              _set: formatVendorSetting({ ...row, listingTeamId }),
            })),
          })
          ?.map((update) => update?.affected_rows || 0)
          ?.reduce<number>((prev, curr) => prev + (curr || 0), 0) || 0;

        // insert settings
        const insertSettings = updated
          .filter((row) => !row.vendor?.settingId && !!row.vendor?.value)
          .map((row) => ({
            vendorId: row.vendor?.value,
            ownershipId: row.ownershipId,
            settingId: undefined,
          }));

        q.insert_setting({
          objects: insertSettings.map((row) =>
            formatVendorSetting({ ...row, listingTeamId })
          ),
        })?.affected_rows || 0;
      }
    },
    {
      successMessage: { message: 'Updates saved' },
    }
  );

  return {
    mutate: (args: Omit<Parameters<typeof mutate>[0]['args'], 'setting'>) => {
      return mutate({
        args: {
          ...args,
          setting: {
            automationId,
            leftConnectionId,
            leftType,
            mappingKey,
            rightConnectionId,
            rightType,
          },
        },
      });
    },
    loading,
  };
};
