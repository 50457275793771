import { useTeam } from '@finalytic/data';
import { Maybe } from '@finalytic/graphql';
import {
  IconButton,
  NoOwnersTableOverlay,
  SelectItem,
  StringParam,
  Table,
  showErrorNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  AgGridReact,
  ColDef,
  EditableCallbackParams,
  GetRowIdParams,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
} from '@finalytic/ui-grid';
import { toTitleCase } from '@finalytic/utils';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useMemo, useRef } from 'react';
import { ListingOwnerRow } from '../../useListingDetailData';
import { OwnershipSplitCell } from '../OwnershipSplitCell';
import { TableTitle } from '../TableTitle';
import { AddRowButton } from './AddRowButton';
import { NameCell } from './NameCell';
import { RowMenu } from './RowMenu';
import { SelectVendor } from './SelectVendor';
import { SubmitButtons } from './SubmitButtons';
import { useOwnershipChangeStore, useVendors } from './_hooks';

type Props = {
  rowData: ListingOwnerRow[] | undefined;
  refetch: () => void;
  listingId: string;
  listingTeamId: string;
};

export const ListingOwnerTableV2 = ({
  rowData,
  refetch,
  listingId,
  listingTeamId,
}: Props) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const { classes } = useTableStyles();

  const gridRef = useRef<AgGridReact>(null);

  const [{ automations, globalMappings }] = useTeam();

  const addChange = useOwnershipChangeStore((state) => state.add);

  const { vendors } = useVendors({ listingId, listingTeamId });

  const hasOwnerSplitAutomation = automations.some((automation) =>
    automation.template?.uniqueRef?.toLowerCase().endsWith('ownerstatements')
  );

  const hasVendorAutomation = globalMappings.listingOwner.some(
    (mapping) => mapping.mappingKey === 'vendor'
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'lastName',
        sort: 'asc',
        headerName: 'Owners',
        flex: 1,
        minWidth: 200,
        cellRenderer: NameCell,
      },

      {
        field: 'role',
        sortable: false,
        valueFormatter: ({ value }: ValueFormatterParams<ListingOwnerRow>) =>
          toTitleCase(value) || 'Owner',
        width: 120,
        hide: !hasVendorAutomation,
      },
      { field: 'vendor.label', hide: true },
      {
        field: 'vendor.value',
        headerName: 'Vendor',
        sortable: false,
        hide: !hasVendorAutomation,
        editable: (params: EditableCallbackParams<ListingOwnerRow>) =>
          params?.data?.role === 'owner',
        minWidth: 150,
        flex: 1,
        cellStyle: () => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
        }),
        valueFormatter: ({ data }: ValueFormatterParams<ListingOwnerRow>) =>
          data?.role === 'owner' ? data?.vendor?.label || '' : '-',
        onCellValueChanged: ({
          data,
          newValue,
          context,
          node,
          oldValue,
        }: NewValueParams<ListingOwnerRow>) => {
          console.log({ oldValue, newValue });

          if (!newValue)
            return showErrorNotification({
              message: 'Please select a vendor.',
              color: 'yellow',
            });

          const vendors: SelectItem[] = context?.vendors || [];
          const vendor = vendors.find((v) => v.value === newValue)!;

          if (!vendor)
            return showErrorNotification({
              message: 'Missing vendor.',
              color: 'yellow',
            });

          node?.setDataValue('vendor.label', vendor.label);

          addChange(data?.ownerId, {
            ...data,
            vendor: { ...vendor, settingId: data?.vendor?.settingId },
          });
        },
        cellEditor: SelectVendor,
      },
      {
        field: 'ownerSplit',
        sortable: false,
        type: 'numericColumn',
        minWidth: 100,
        hide: !hasOwnerSplitAutomation,
        editable: (params: EditableCallbackParams<ListingOwnerRow>) =>
          params?.data?.role === 'owner',
        singleClickEdit: true,
        cellEditor: OwnershipSplitCell,
        cellRenderer: ({
          value,
          data,
        }: ICellRendererParams<ListingOwnerRow, Maybe<number>>) => {
          if (data?.role === 'spectator') return '-';

          return (
            <Box>
              <Text ta="right" w="100%">
                {typeof value === 'number' ? value : 'AUTO'} %
              </Text>
            </Box>
          );
        },
        onCellValueChanged: ({
          newValue,
          data,
        }: NewValueParams<ListingOwnerRow>) => {
          addChange(data?.ownerId, { ...data, ownerSplit: parseInt(newValue) });
        },
      },

      {
        type: 'rightAligned',
        colId: 'menu',
        width: 50,
        pinned: 'right',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: (props: ICellRendererParams<ListingOwnerRow>) => (
          <RowMenu
            {...props}
            hasSplitAutomation={hasOwnerSplitAutomation}
            hasVendorAutomation={hasVendorAutomation}
          />
        ),
      },
      {
        type: 'rightAligned',
        colId: 'arrow',
        width: 50,
        pinned: 'right',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: ({ data }: ICellRendererParams<ListingOwnerRow>) => (
          <IconButton onClick={() => setOwner(data?.ownerId, 'push')}>
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </IconButton>
        ),
      },
    ],
    [hasOwnerSplitAutomation, hasVendorAutomation]
  );

  return (
    <>
      <Group mt="lg" mb="xs" justify="space-between">
        <TableTitle>Owners</TableTitle>
        <Group>
          <AddRowButton
            currentOwnerIds={rowData?.map((i) => i.id) || []}
            teamId={listingTeamId}
            gridRef={gridRef}
          />
          <SubmitButtons
            refetch={() => {
              refetch();
              gridRef?.current?.api?.setRowData(rowData || []);
            }}
            rowData={rowData}
            listingId={listingId}
            listingTeamId={listingTeamId}
            hasVendorAutomation={hasVendorAutomation}
          />
        </Group>
      </Group>
      <Box>
        <Table
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ resizable: false }}
          pagination={false}
          suppressCellFocus={true}
          rowHeight={55}
          rowClass={classes.row}
          className={classes.table}
          domLayout="autoHeight"
          singleClickEdit
          animateRows
          getRowId={({ data }: GetRowIdParams<ListingOwnerRow>) => data?.id}
          stopEditingWhenCellsLoseFocus
          suppressCsvExport
          suppressExcelExport
          noRowsOverlayComponent={() => (
            <NoOwnersTableOverlay size={50} text="No Owners Mapped" />
          )}
          context={{
            refetchTable: refetch,
            vendors,
          }}
        />
      </Box>
    </>
  );
};

const useTableStyles = createStyles(() => ({
  row: {
    cursor: 'pointer',
    '&.ag-row-first': {
      borderTop: 'none',
    },
    '&.ag-row-last': {
      borderBottom: 'none',
    },
  },
  table: {
    '.ag-header': {
      borderBottom: 'none',
    },
  },
}));
