import { expression } from '@finalytic/utils';
import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Group,
  Popover,
  PopoverProps,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  useDebouncedValue,
  useFocusReturn,
  useScrollIntoView,
} from '@mantine/hooks';
import prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { useEffect, useMemo, useRef, useState } from 'react';
import Editor from 'react-simple-code-editor';
import { InputFormulaContent } from './InputFormulaContent';
import { FormulaField } from './_types';

const functionFields: FormulaField[] = [
  {
    id: 'sum',
    label: 'SUM()',
    type: 'function',
    displayValue: 'SUM(',
    description: (
      <>
        Returns a sum of numbers.
        <br />
        Equivalent to number1 + number2 + ...
      </>
    ),
  },
];
type Props = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  fields: FormulaField[];
  debugMode?: boolean;
  hideIcon?: boolean;
  onSearchInput?: (searchInput: string) => void;
  onEnter?: () => void;
  loading?: boolean;
  popoverWidth?: PopoverProps['width'];
} & Shared;

export const InputFormula = ({
  value = '',
  setValue,
  fields,
  debugMode = false,
  placeholder = 'Enter a formular',
  rounded = true,
  disabled = false,
  withBorder = true,
  hideIcon = false,
  loading = false,
  onSearchInput,
  autoFocus = false,
  popoverWidth = 'target',
  onEnter,
}: Props) => {
  // const [value, setValue] = useState(initialValue);
  const [opened, setOpened] = useState(false);
  const [popoverSearch, setPopoverSearch] = useState('');

  const editorRef = useRef<Editor | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRefs = useRef<{ [key: string]: HTMLButtonElement }>({});

  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    undefined
  );

  const { classes } = useStyles({ opened, rounded, withBorder });

  const popFields = useMemo<FormulaField[]>(
    () => [...functionFields, ...fields],
    [functionFields, fields]
  );

  const { scrollIntoView, targetRef, scrollableRef } =
    useScrollIntoView<HTMLButtonElement>({
      duration: 0,
      offset: 40,
      cancelable: false,
      isList: true,
    });

  const scrollSelectedItemIntoView = ({
    scrollDown,
  }: {
    scrollDown: boolean;
  }) => {
    window.setTimeout(() => {
      targetRef.current =
        itemsRefs.current[
          filteredValues[
            highlightedIndex === undefined ? 0 : highlightedIndex
          ]?.id
        ];
      scrollIntoView({ alignment: scrollDown ? 'end' : 'start' });
    }, 0);
  };

  const returnFocus = useFocusReturn({ opened });

  const onFieldAdd = (field: FormulaField) => {
    if (!field) return;
    // const trailingSpace = field.type !== 'function' ? '' : '';
    const cursorPosition: number = (editorRef.current as any).selectionStart;
    const searchLength = popoverSearch.length;

    setValue((e) => {
      // remove search input at cursor position
      // insert new value at cursor position minus search length
      const newInputPosition = cursorPosition - searchLength;
      const t = e.slice(0, newInputPosition) + e.slice(cursorPosition);

      const r = [
        t.slice(0, newInputPosition),
        field.displayValue,
        t.slice(newInputPosition),
      ].join('');

      return r;
    });
    setHighlightedIndex(undefined);
    setPopoverSearch('');
    returnFocus();
  };

  const { filteredValues } = useMemo(() => {
    setHighlightedIndex(undefined);

    const filteredValues = popoverSearch
      ? popFields.filter(
          (i) =>
            i.label.toLowerCase().includes(popoverSearch.toLowerCase()) ||
            i.id.toLowerCase().includes(popoverSearch.toLowerCase())
        )
      : popFields;

    return {
      filteredValues,
    };
  }, [popoverSearch, popFields]);

  useEffect(() => {
    if (onSearchInput) onSearchInput(popoverSearch);
  }, [onSearchInput, popoverSearch]);

  if (disabled) {
    return (
      <Group wrap="nowrap" className={classes.inputWrapper} gap={0}>
        <EditorInput
          editorRef={editorRef}
          opened={opened}
          rounded={opened}
          setOpened={setOpened}
          withBorder={withBorder}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          onFieldAdd={onFieldAdd}
          scrollSelectedItemIntoView={scrollSelectedItemIntoView}
          filteredValues={filteredValues}
          highlightedIndex={highlightedIndex}
          setHighlightedIndex={setHighlightedIndex}
          onEnter={onEnter}
          setParentSearch={setPopoverSearch}
          setParentValue={setValue}
          parentValue={value}
          parentSearch={popoverSearch}
        />
        <ValidationIcon inputValue={value} hideIcon={hideIcon} />
      </Group>
    );
  }

  return (
    <Popover
      width={popoverWidth}
      opened={opened}
      shadow="md"
      radius="md"
      withinPortal
    >
      <Popover.Target>
        <Group wrap="nowrap" className={classes.inputWrapper} gap={0}>
          <DebugSearchTooltip
            debouncedSearch={popoverSearch}
            debugMode={debugMode}
            opened={opened}
          />
          <EditorInput
            editorRef={editorRef}
            opened={opened}
            rounded={opened}
            setOpened={setOpened}
            withBorder={withBorder}
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
            onFieldAdd={onFieldAdd}
            scrollSelectedItemIntoView={scrollSelectedItemIntoView}
            filteredValues={filteredValues}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            onEnter={onEnter}
            setParentSearch={setPopoverSearch}
            setParentValue={setValue}
            parentValue={value}
            parentSearch={popoverSearch}
          />
          <ValidationIcon inputValue={value} hideIcon={hideIcon} />
        </Group>
      </Popover.Target>
      <Popover.Dropdown p={5}>
        {loading ? (
          <>
            <Skeleton height={25} radius="lg" />
            <Skeleton height={25} mt={10} radius="lg" />
            <Skeleton height={25} mt={10} radius="lg" />
            <Skeleton height={25} mt={10} radius="lg" />
            <Skeleton height={25} mt={10} radius="lg" />
          </>
        ) : (
          <>
            <InputFormulaContent
              data={filteredValues}
              onAdd={onFieldAdd}
              containerRef={containerRef}
              itemRefs={itemsRefs as any}
              scrollableRef={scrollableRef}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
            />
          </>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

type Shared = {
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  rounded?: boolean;
  withBorder?: boolean;
  onEnter?: () => void;
};

type EditorInputProps = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  editorRef: React.MutableRefObject<Editor | null>;
  scrollSelectedItemIntoView: (t: { scrollDown: boolean }) => void;
  highlightedIndex: number | undefined;
  setHighlightedIndex: (index: number | undefined) => void;
  onFieldAdd: (field: FormulaField) => void;
  filteredValues: FormulaField[];
  setParentSearch: (search: string) => void;
  setParentValue: (value: string) => void;
  parentValue: string;
  parentSearch: string;
};

const EditorInput = ({
  autoFocus,
  // disabled,
  placeholder,
  rounded = true,
  withBorder = true,
  opened,
  editorRef,
  setOpened,
  scrollSelectedItemIntoView,
  highlightedIndex,
  setHighlightedIndex,
  onEnter,
  onFieldAdd,
  filteredValues,
  setParentSearch,
  setParentValue: setValue,
  parentValue: value,
  parentSearch,
}: EditorInputProps & Shared) => {
  const [popoverSearch, setPopoverSearch] = useState('');
  const { classes } = useStyles({ rounded, withBorder, opened });

  const [debouncedSearch] = useDebouncedValue(popoverSearch, 300);

  useEffect(() => setParentSearch(debouncedSearch), [debouncedSearch]);

  useEffect(() => {
    setPopoverSearch(parentSearch);
  }, [parentSearch]);

  return (
    <Editor
      autoFocus={autoFocus}
      value={value}
      // disabled={disabled}
      onValueChange={(code) => setValue(code)}
      highlight={(code) =>
        prism.highlight(code || '', prism.languages.sql, 'sql')
      }
      onFocus={(event) => {
        const target = event.target as EventTarget & HTMLTextAreaElement;

        if (target) {
          target.selectionStart = target.value?.length || 0;
          target.selectionEnd = target.value?.length || 0;
        }
      }}
      placeholder={placeholder}
      padding={10}
      className={classes.input}
      onKeyDown={(
        event:
          | React.KeyboardEvent<HTMLDivElement>
          | React.KeyboardEvent<HTMLTextAreaElement>
      ) => {
        const key = event.key;
        const code = event.code;
        const target = event.target as any;
        const currentInputValue: string | undefined = target?.value;
        const cursorPosition = target?.selectionStart || 0;

        editorRef.current = target;

        // Managing search and popover open state
        if (key.includes('Meta')) {
          setPopoverSearch('');
          setOpened(false);
        } else if (key === 'Backspace') {
          const deletedCharacter = currentInputValue?.slice(
            cursorPosition - 1,
            cursorPosition
          );

          if (deletedCharacter === `"`) {
            // find character at cursor position and delete everying until the same character at the next previous position
            const previousPosition = cursorPosition - 1 || 0;

            if (
              !previousPosition ||
              previousPosition === 1 ||
              !currentInputValue
            ) {
              setValue(currentInputValue?.slice(previousPosition) || '');
            } else {
              let pos = previousPosition - 1;
              while (currentInputValue[pos] !== `"` && pos > -1) {
                pos--;
              }

              if (pos === -1) {
                const newValue =
                  currentInputValue.slice(0, previousPosition + 1) +
                  currentInputValue.slice(cursorPosition);
                setValue(newValue);
              } else {
                const newValue =
                  currentInputValue.slice(0, pos + 1) +
                  currentInputValue.slice(cursorPosition);
                setValue(newValue);
              }
            }
          }

          if (popoverSearch) {
            setPopoverSearch((e) => e.slice(0, -1));
          }
        }
        // if the search string is not equal to the word before the cursor position in the input, reset search input
        // else if (
        //   !currentInputValue ||
        //   (currentInputValue &&
        //     currentInputValue.slice(
        //       cursorPosition - popoverSearch.length,
        //       cursorPosition
        //     ) !== popoverSearch)
        // ) {
        //   setPopoverSearch('');
        // }
        else if (/^[a-zA-Z0-9\s]{1}$/.test(key)) {
          setPopoverSearch((e) => {
            const newValue = `${e}${key}`;
            return newValue.trim() ? newValue : '';
          });
          if (!opened) {
            setOpened(true);
          }
        }

        // Managing popover current focus
        switch (code) {
          case 'Enter':
            if (highlightedIndex !== undefined) {
              event.preventDefault();
              onFieldAdd(filteredValues[highlightedIndex]);
            } else {
              event.preventDefault();
              if (onEnter) onEnter();
            }
            break;
          case 'Space':
            if (!currentInputValue?.trim()) {
              event.preventDefault();
            }
            setOpened(true);
            break;
          case 'ArrowUp': {
            event.preventDefault();
            if (highlightedIndex === 0) {
              setHighlightedIndex(undefined);
            } else if (highlightedIndex !== undefined) {
              if (highlightedIndex >= filteredValues.length) {
                setHighlightedIndex(filteredValues.length - 1);
              } else {
                setHighlightedIndex(highlightedIndex - 1);
              }
              scrollSelectedItemIntoView({ scrollDown: false });
            }
            break;
          }
          case 'ArrowDown': {
            event.preventDefault();
            if (highlightedIndex === undefined) {
              setHighlightedIndex(0);
            } else if (
              highlightedIndex + 1 >= 0 &&
              highlightedIndex + 1 < filteredValues.length
            ) {
              const newValue = highlightedIndex + 1;
              if (newValue >= 0 && newValue < filteredValues.length) {
                setHighlightedIndex(newValue);
              }
              scrollSelectedItemIntoView({ scrollDown: true });
            }
            break;
          }
          default:
            setHighlightedIndex(undefined);
        }
      }}
      onBlur={() => {
        setOpened(false);
        if (!opened) {
          onEnter?.();
        }
      }}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
      }}
    />
  );
};

const ValidationIcon = ({
  inputValue,
  hideIcon,
}: {
  inputValue: string;
  hideIcon: boolean;
}) => {
  const [validate, setValidate] = useState(true);

  const [debouncedValue] = useDebouncedValue(inputValue, 500);

  useEffect(() => {
    function validate() {
      try {
        const tree = expression.toTree(debouncedValue);
        if (tree) return setValidate(true);
      } catch (error) {
        console.error(error);

        return setValidate(false);
      }
    }

    validate();
  }, [debouncedValue]);

  if (hideIcon || !debouncedValue) return null;

  return (
    <Tooltip
      label={validate ? 'Your formula is correct.' : 'Incorrect validation.'}
      withinPortal
      withArrow
    >
      <FontAwesomeIcon
        icon={validate ? faCheck : faX}
        color={validate ? 'green' : 'red'}
      />
    </Tooltip>
  );
};

const DebugSearchTooltip = ({
  debouncedSearch,
  opened,
  debugMode,
}: {
  debouncedSearch: string;
  opened: boolean;
  debugMode: boolean;
}) => {
  if (!debugMode) return null;

  return (
    <Tooltip
      label={`Debug Active Search: "${debouncedSearch}"`}
      withinPortal
      position="left"
      withArrow
      offset={10}
      opened={opened}
      color="gray"
    >
      <Box w={'5px'} maw="5px" sx={{ visibility: 'hidden' }} />
    </Tooltip>
  );
};

const useStyles = createStyles(
  (
    theme,
    args: {
      opened: boolean;
      rounded: boolean;
      withBorder: boolean;
    }
  ) => {
    return {
      inputWrapper: {
        maxHeight: '100%',
        height: '100%',
        border: args.withBorder ? '1px solid' : undefined,
        borderColor: args.opened ? theme.colors.gray[5] : theme.colors.gray[4],
        boxShadow: args.opened
          ? `0px 0px 0px 1px ${theme.colors.gray[5]}`
          : undefined,
        borderRadius: args.rounded ? theme.radius.md : undefined,
        '> div': {
          flex: 1,
          width: '100%',
        },
        paddingRight: 5,
      },
      input: {
        lineHeight: 'normal !important',
        textarea: {
          border: '1px solid',
          borderColor: theme.colors.gray[4],
          borderRadius: theme.radius.md,
          '&:focus': {
            outline: 'none',
            borderRadius: theme.radius.md,
            border: 'none',
          },
        },
      },
    };
  }
);
