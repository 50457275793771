import { Button } from '@finalytic/components';
import {
  useDashboard,
  useMe,
  useSubscription,
  useTeamRole,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Button as MantineButton } from '@mantine/core';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

export const FeaturesButton = ({
  navbarExtended,
}: {
  navbarExtended: boolean;
}) => {
  const [dashboard] = useDashboard();
  const { realUserId } = useMe();
  const { isPartnerAdmin } = useTeamRole();

  const skip = !isPartnerAdmin || dashboard === 'owner';

  const { data: issues } = useSubscription(
    (q) => {
      return (
        q
          .featureAggregate({
            where: {
              status: { _nin: ['deployed', 'ready'] },
            },
          })
          ?.aggregate?.count() || 0
      );
    },
    { userId: realUserId },
    {
      skip,
      queryKey: ['features', 'featureApprovals'],
    }
  );

  if (skip || !issues) return null;

  return (
    <MantineButton
      variant="light"
      component={Link}
      to={'/features'}
      color="yellow"
      sx={{
        background: 'none',
        border: 'none',
        height: 30,
        '&:disabled': {
          background: 'transparent',
        },
      }}
      px="xs"
      fw={500}
      radius={'md'}
      styles={{
        label: navbarExtended
          ? {
              marginRight: 'auto',
              opacity: '1 !important',
            }
          : {
              fontSize: 16,
              opacity: '1 !important',
            },
        rightIcon: {
          fontSize: 16,
        },
      }}
      mx="xs"
      mb={4}
      size="xs"
      leftSection={
        navbarExtended && (
          <Icon
            icon="AlertCircleIcon"
            size={16}
            color={(theme) => theme.colors.yellow[6]}
          />
        )
      }
      rightSection={navbarExtended && issues?.toString()}
    >
      {navbarExtended ? 'Features in Testing' : issues || 0}
    </MantineButton>
  );
};

const NavbarSupportButton = forwardRef<
  HTMLButtonElement,
  {
    href?: string;
    onClick?: () => void;
    navbarExtended: boolean;
    title: string;
    icon: IconDefinition;
  }
>(({ href, navbarExtended, title, onClick }, ref) => {
  return (
    <Button
      variant="light"
      ref={ref}
      component={href ? 'a' : undefined}
      onClick={onClick}
      href={href || ''}
      sx={{
        inner: navbarExtended
          ? {
              justifyContent: 'space-between',
              background: 'none',
              border: 'none',
              height: 30,
              '&:disabled': {
                background: 'transparent',
              },
            }
          : {
              background: 'none',
              border: 'none',
              height: 30,
              '&:disabled': {
                background: 'transparent',
              },
            },
      }}
      size="xs"
      rightIcon={navbarExtended ? 'CommentQuestionIcon' : undefined}
    >
      {navbarExtended ? title : <Icon icon="CommentQuestionIcon" size={16} />}
    </Button>
  );
});

NavbarSupportButton.displayName = 'NavbarSupportButton';
