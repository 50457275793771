import { useEnabledFeatures, useQuery, useTrpcMutation } from '@finalytic/data';
import { Edit3Icon, Icon, RouteIcon } from '@finalytic/icons';
import {
  DeleteModal,
  EllipsisMenu,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  useQueryParam,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  faArrowRightArrowLeft,
  faCopy,
} from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mantine/core';
import { useClipboard, useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { deleteCheckListing } from '@vrplatform/ui-common';
import { ReactNode, useMemo } from 'react';
import { HiddenFeatureIndicator } from '../../../indicators';
import { ListingMigrateModal, SharedProps } from './modals';

type Props = {
  isPmsListing: boolean;
  listingConnections: { id: string }[];
  ownerStatements: { id: string }[];
  refetch: () => void;
};

export const ListingMenuButton = ({
  listingId,
  listingName,
  listingTeamId,
  closeDrawer,
  isPmsListing,
  listingConnections,
  refetch,
  ownerStatements,
}: Props & SharedProps) => {
  const [opened, handlers] = useDisclosure(false);
  const [openedMigration, handlersMigration] = useDisclosure(false);

  return (
    <>
      <EllipsisMenu width={200}>
        <ListingEllipsisMenuItems
          listing={{
            listingId,
            isPmsListing,
            ownerStatements: ownerStatements.length || 0,
            listingConnections: listingConnections.length,
          }}
          handlers={{
            openDeleteModal: handlers.open,
            openMigrationModal: handlersMigration.open,
          }}
        />
      </EllipsisMenu>
      <ListingEllipsisMenuModals
        deleteModal={{
          closeModal: handlers.close,
          opened,
          onSuccess: () => {
            refetch();
            closeDrawer();
          },
        }}
        migrationModal={{
          closeModal: handlersMigration.close,
          opened: openedMigration,
          onSuccess: () => {
            refetch();
            closeDrawer();
          },
        }}
        listing={{
          id: listingId,
          name: listingName,
          tenantId: listingTeamId,
        }}
      />
    </>
  );
};

export const ListingEllipsisMenuModals = ({
  deleteModal,
  listing: initial,
  migrationModal,
}: {
  listing: {
    id: string;
    name: Maybe<string>;
    tenantId: string;
  } | null;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
    onSuccess?: () => void;
  };
  migrationModal: {
    opened: boolean;
    closeModal: () => void;
    onSuccess?: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const listing = initial || debounced;

  const { mutate } = useTrpcMutation('deleteListing', {
    successMessage: { message: 'Successfully deleted listing.' },
    invalidateQueryKeys: ['listings'],
  });

  if (!listing) return null;

  const removeListing = async () => {
    const res = await mutate({
      foreignObjectId: listing.id,
      tenantId: listing.tenantId,
    });

    if (res?.ok) {
      deleteModal.onSuccess?.();
    }
  };

  return (
    <>
      <DeleteModal
        opened={deleteModal.opened}
        onClose={deleteModal.closeModal}
        title={`Are you sure to delete ${
          listing.name ? listing.name : 'this listing'
        }?`}
        subtitle="Once deleted all data will be lost."
        onSubmit={() => removeListing()}
      />
      <ListingMigrateModal
        close={migrationModal.closeModal}
        opened={migrationModal.opened}
        listingId={listing.id}
        listingTeamId={listing.tenantId}
        listingName={listing.name}
        closeDrawer={() => migrationModal?.onSuccess?.()}
      />
    </>
  );
};

export const ListingEllipsisMenuItems = ({
  listing: { isPmsListing, listingConnections, ownerStatements, listingId },
  handlers: { openDeleteModal, openMigrationModal },
}: {
  listing: {
    listingId: string;
    isPmsListing: boolean;
    ownerStatements: number;
    listingConnections: number;
  };
  handlers: {
    openDeleteModal: () => void;
    openMigrationModal: () => void;
  };
}) => {
  const [, setListingId] = useQueryParam('listing_activity', StringParam);

  const { NEW_OWNERS } = useEnabledFeatures();
  const setView = useQueryParamsSet({
    view: StringParam,
    listing: StringParam,
  });

  const { copy } = useClipboard();

  const { data, isLoading: loading } = useQuery(
    (q, { listingId }) => deleteCheckListing(q, { listingId }),
    {
      queryKey: 'listing',
      variables: {
        listingId,
      },
    }
  );

  const canBeDeleted = !!data?.canDelete;

  const deleteTooltipLabel = useMemo(() => {
    if (isPmsListing) {
      return 'This listing is synced with a PMS and cannot be deleted.';
    } else {
      if (ownerStatements && listingConnections)
        return 'This listing has owner statements and listing connections. Remove them first to delete this listing.';

      if (ownerStatements)
        return 'Remove the owner statements from this listing to delete it.';

      if (listingConnections)
        return 'Remove the listing connections from this listing to delete it.';

      return null;
    }
  }, [listingConnections, ownerStatements, isPmsListing]);

  const disableRemoving = !!listingConnections || isPmsListing || !canBeDeleted;

  return (
    <>
      {NEW_OWNERS && (
        <EllipsisMenuItem
          onClick={() => setView({ listing: listingId, view: 'edit' })}
          customIcon={<Edit3Icon size={16} />}
        >
          Edit listing
        </EllipsisMenuItem>
      )}

      {NEW_OWNERS && (
        <HiddenFeatureIndicator permission="ACTIVITY_UI">
          <EllipsisMenuItem
            customIcon={<Icon icon="HistoryIcon" size={16} />}
            onClick={() => setListingId(listingId, 'push')}
          >
            View activity
          </EllipsisMenuItem>
        </HiddenFeatureIndicator>
      )}

      <EllipsisMenuItem
        onClick={openMigrationModal}
        icon={faArrowRightArrowLeft}
        customIcon={NEW_OWNERS ? <RouteIcon size={16} /> : undefined}
      >
        Migrate Listing
      </EllipsisMenuItem>

      {NEW_OWNERS && <EllipsisMenuDivider />}

      <DisabledTooltip label={deleteTooltipLabel} disabled={!disableRemoving}>
        <Box>
          <EllipsisMenuDangerItem
            onClick={openDeleteModal}
            loading={loading}
            disabled={disableRemoving}
          >
            Delete listing
          </EllipsisMenuDangerItem>
        </Box>
      </DisabledTooltip>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuItem icon={faCopy} onClick={() => copy(listingId)}>
          Copy listing id
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

const DisabledTooltip = ({
  disabled,
  label,
  children,
}: {
  disabled: boolean;
  label: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Tooltip label={label} withArrow withinPortal disabled={disabled}>
      <Box
        sx={{
          cursor: disabled ? 'not-allowed' : undefined,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
