import { usePartnerTeams, useTeamId } from '@finalytic/data';
import { CalendarFilterDateType } from '@finalytic/ui';
import { day, ensure, hasValue } from '@finalytic/utils';
import { PaymentsFilterParams, wherePayments } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { usePaymentFilter } from './PaymentFilter';

export function useWherePaymentsBase(input: PaymentsFilterParams) {
  const [currentTeamId] = useTeamId();
  const [teams] = usePartnerTeams();

  const defaultTeamFilter = teams?.length
    ? teams.map((i) => i.id)
    : [currentTeamId];

  const filter = useMemo(
    () =>
      wherePayments({
        ...input,
        tenantIds: input.tenantIds?.length
          ? input.tenantIds
          : defaultTeamFilter,
      }),
    [JSON.stringify(input), defaultTeamFilter]
  );
  return filter;
}

export function useWherePayments() {
  const filter = useFormattedPaymentFilter();

  return useWherePaymentsBase(filter);
}

export function useFormattedPaymentFilter(): PaymentsFilterParams {
  const { filter } = usePaymentFilter();

  const getDate = (date: string | undefined | null) => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    return ensure<CalendarFilterDateType>([
      start ? day(start).toDate() : null,
      end ? day(end).toDate() : null,
    ]);
  };

  const getAmount = (
    amount: { lt?: string; gt?: string } | undefined | null
  ) => {
    if (!amount) return undefined;

    const { lt, gt } = amount;
    console.log(amount);

    console.log({
      lt: lt && lt !== 'undefined' ? Number(lt) : undefined,
      gt: gt && gt !== 'undefined' ? Number(gt) : undefined,
    });

    return {
      lt: lt && lt !== 'undefined' ? Number(lt) : undefined,
      gt: gt && gt !== 'undefined' ? Number(gt) : undefined,
    };
  };

  return {
    date: getDate(filter.date),
    amount: getAmount(filter.amount),
    connectionName: filter.connectionId || undefined,
    connectionApp: filter.appId || undefined,
    listingIds: filter.listingIds?.filter(hasValue) || undefined,
    type2: filter.lineTypes?.filter(hasValue) || undefined,
    search: filter.search || undefined,
    automationStatus: filter.automationResult || undefined,
    listingCollectionId: filter.listingCollectionId || undefined,
  };
}
