import {
  ConnectionWithIconCell,
  CopyIdIcon,
  NoConnectionsTableOverlay,
  Table,
  showSuccessNotification,
  useColors,
} from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { Box, Group, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useClipboard } from '@mantine/hooks';
import { useState } from 'react';
import { TableTitle } from './TableTitle';

type ListingConnectionsTableProps = {
  rowData?: any[];
};

export const ListingConnectionsTable = ({
  rowData,
}: ListingConnectionsTableProps) => {
  const { purple } = useColors();
  const { classes } = useRowStyling();
  const clipboard = useClipboard({ timeout: 500 });

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'name',
      flex: 1,
      sort: 'asc',
      headerName: 'Connection',
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
      }),
      cellRenderer: (params: ICellRendererParams) => (
        <ConnectionWithIconCell
          title={params.value}
          icon={params.data?.iconRound}
        />
      ),
    },
    {
      field: 'uniqueRef',
      headerName: '',
      width: 75,
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }),
      cellRenderer: () => {
        return (
          <Group wrap="nowrap" gap={5} className="copy-ref" sx={{ opacity: 0 }}>
            <CopyIdIcon color={purple.base} />
            <Text size="xs" color={purple.base}>
              Copy
            </Text>
          </Group>
        );
      },
    },
  ]);

  return (
    <>
      <TableTitle mt="md" mb="xs">
        Listing Connections
      </TableTitle>
      <Box>
        <Table
          columnDefs={columnDefs}
          defaultColDef={{ resizable: false }}
          noRowsOverlayComponent={() => (
            <NoConnectionsTableOverlay
              size={50}
              text="No Connection Mapped"
              pt={50}
            />
          )}
          rowData={rowData}
          pagination={false}
          suppressCellFocus={true}
          domLayout="autoHeight"
          rowHeight={55}
          headerHeight={0}
          rowClass={classes.row}
          className={classes.table}
          getRowId={({ data }) => data?.id}
          onRowClicked={({ data }) => {
            clipboard.copy(data?.uniqueRef);
            showSuccessNotification({
              message: (
                <>
                  Unique reference to{' '}
                  <Text component="span" fw={500}>
                    {data?.name}
                  </Text>{' '}
                  was copied.
                </>
              ),
            });
          }}
          animateRows
        />
      </Box>
    </>
  );
};

const useRowStyling = createStyles(() => ({
  row: {
    cursor: 'pointer',
    '&:hover .copy-ref': {
      color: 'red',
      opacity: 1,
      transition: '0.3s opacity ease-out',
    },
    '&.ag-row-first': {
      borderTop: 'none',
    },
    '&.ag-row-last': {
      borderBottom: 'none',
    },
  },
  table: {
    '.ag-header': {
      borderBottom: 'none',
    },
  },
}));
