import { Button } from '@finalytic/components';
import { useExtension } from '@finalytic/data';
import { InputsBySchema } from '@finalytic/data-ui';
import { StringParam, useAppName, useQueryParam } from '@finalytic/ui';
import { Anchor, Box, Stack, Text } from '@mantine/core';
import { InputFormSchema } from '@vrplatform/ui-common';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';

type SchemaInputType = {
  [key: string]: string | number | undefined;
};

type AuthType = {
  schema?: InputFormSchema;
  type?: string;
  uri?: string;
  extension?: { url: string };
};

export const ConnectionConfirmModalScreen = () => {
  const { app, connectApp, resetView } = useConnectionModalContext();
  const auth = app?.authentication as AuthType;

  const { extension } = useExtension();
  const { themeName } = useAppName();

  const [connectionId] = useQueryParam('connectionId', StringParam);

  const methods = useForm({ mode: 'onChange' });

  const addConnection = (data: SchemaInputType) => {
    if (auth?.type === 'credentials' && auth.schema) {
      // Credentials auth
      connectApp({ input: data });
    } else if (auth?.uri) {
      // oAuth redirect
      window.location.href = auth.uri;
    }
  };
  useEffect(() => {
    if ((auth && !auth.type) || app?.id === 'hostfully') {
      connectApp({ input: {} });
    }
  }, [auth]);

  const isReconnect = !!connectionId;

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        <>
          <Text size="md">{isReconnect ? 'Reconnect' : 'Connecting'} to</Text>
          <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            {app?.name || 'Empty'}
          </Text>
        </>
      }
    >
      {auth?.extension ? (
        <Text my="sm" size="lg" component="p" m={0} ta="center">
          Please connect to {app?.name} using our{' '}
          <Anchor
            onClick={resetView}
            href={extension.store_url}
            target="_blank"
            fw={'bold'}
            sx={{ textDecoration: 'underline' }}
          >
            browser extension
          </Anchor>
          .
        </Text>
      ) : (
        <Box
          component="form"
          onSubmit={methods.handleSubmit(addConnection)}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100%',
            [`@media (min-width: ${theme.breakpoints.xs})`]: {
              minWidth: 380,
            },
          })}
        >
          {auth?.schema && (
            <InputsBySchema
              schema={auth.schema}
              methods={methods}
              // Hide for sage intacct :()
              hideFields={
                themeName === 'vrplatform'
                  ? ['senderId', 'senderPassword']
                  : undefined
              }
            />
          )}
          {/* Buttons */}
          <Stack mt={!auth?.schema ? 40 : 10}>
            <Button
              variant="primary"
              id="submit-connection"
              type="submit"
              sx={{ height: 40 }}
              disabled={
                auth?.type === 'credentials'
                  ? !methods.formState.isValid
                  : undefined
              }
            >
              Connect
            </Button>
          </Stack>
        </Box>
      )}
    </ConnectingContainer>
  );
};
