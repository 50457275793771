import { useQuery } from '@finalytic/data';
import {
  Select,
  SelectItem,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import { AgGridReact } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatUserName } from '@vrplatform/ui-common';
import { RefObject, useMemo, useState } from 'react';
import { ListingOwnerRow } from '../../useListingDetailData';
import { useOwnershipChangeStore } from './_hooks';

type QueryProps = {
  teamId: string;
};

type Props = {
  currentOwnerIds: string[];
  gridRef: RefObject<AgGridReact>;
} & QueryProps;

function useData({
  teamId,
  search,
}: QueryProps & {
  search: string;
}) {
  const limit = 50;

  const s = search?.trim();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery(
    (q, args) => {
      return q
        .tenantUser({
          where: {
            tenantId: { _eq: args.teamId },
            role: { _eq: 'owner' },
            _or: args.search
              ? [
                  { user: { firstName: { _ilike: `%${args.search}%` } } },
                  { user: { lastName: { _ilike: `%${args.search}%` } } },
                  { user: { companyName: { _ilike: `%${args.search}%` } } },
                ]
              : undefined,
          },
          order_by: [{ user: { firstName: 'asc' } }],
          limit: args.limit,
        })
        .map((tenantUser) => ({
          id: tenantUser.userId,
          firstName: tenantUser.user?.firstName,
          lastName: tenantUser.user?.lastName,
          companyName: tenantUser.user?.companyName,
          email: tenantUser.user?.email,
          name: formatUserName(tenantUser.user, { lastNameFirst: true }),
        }));
    },
    {
      skip: !teamId,
      queryKey: ['tenantUsers'],
      variables: { teamId, limit, search: s },
    }
  );

  return {
    data: data || [],
    loading,
    refetch,
  };
}

export const AddRowButton = ({ teamId, currentOwnerIds, gridRef }: Props) => {
  const { purple } = useColors();

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const { data } = useData({ teamId, search });

  const changes = useOwnershipChangeStore((store) => store.changes);
  const addChange = useOwnershipChangeStore((store) => store.add);

  const addOwnership = (value: string) => {
    const owner = data?.find((owner) => owner.id === value);

    if (!owner)
      return showErrorNotification({
        message: 'Failed to find owner',
        color: 'yellow',
      });

    const change: ListingOwnerRow = {
      id: value,
      ownerId: value,
      ownershipId: undefined,
      ownerSplit: null,
      role: 'owner',
      vendor: { label: '', value: '', settingId: undefined },
      email: owner.email,
      firstName: owner.firstName,
      lastName: owner.lastName,
      companyName: owner.companyName,
    };

    addChange(value, change);

    gridRef.current?.api?.applyTransaction({
      add: ensure<[ListingOwnerRow]>([change]),
    });

    setOpen(false);
  };

  const values = useMemo<SelectItem[]>(() => {
    const activeIds = [...Object.keys(changes), ...(currentOwnerIds || [])];
    return (
      data
        ?.filter((i) => !activeIds.includes(i.id))
        .map((i) => ({ label: i.name || '', value: i.id })) || []
    );
  }, [data, currentOwnerIds, changes]);
  return (
    <>
      <Select
        data={values}
        withSearch
        setValue={(value) => addOwnership(value.value)}
        onSearchInput={(s) => setSearch(s)}
        opened={open}
        onClose={() => setOpen(false)}
        removeValue={() => undefined}
        searchPlaceholder="Search ..."
        customTarget={
          <TransparentButton
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e: any) => {
                e.stopPropagation(); // this works
                setOpen((o) => !o);
              };
            }}
            leftSection={<FontAwesomeIcon icon={faPlus} />}
            sx={{
              height: 25,
              '& .mantine-Button-label': {
                opacity: 1,
                fontSize: 13,
              },
            }}
            color={purple.base}
          >
            Add owner
          </TransparentButton>
        }
      />
    </>
  );
};
