import { Box, Text } from '@mantine/core';
import { PropsWithChildren, ReactNode } from 'react';

export type SidebarContentContainerProps = {
  title: ReactNode;
  subtitle: ReactNode;
  gap?: number;
};

export const SidebarContentContainer = ({
  subtitle,
  title,
  gap = 200,
  children,
}: PropsWithChildren<SidebarContentContainerProps>) => {
  return (
    <>
      <Box mb={gap}>{children}</Box>
      <Text
        component="h2"
        size={'1.5rem'}
        fw={500}
        mb="xl"
        color="white"
        ta="center"
      >
        {title}
      </Text>
      <Text
        component="p"
        color="white"
        ta="center"
        maw={460}
        lh={1.7}
        mx="auto"
      >
        {subtitle}
      </Text>
    </>
  );
};
