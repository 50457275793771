import { useQuery } from '@finalytic/data';
import { hasValue } from '@finalytic/utils';
import { formatUserName, getListingName } from '@vrplatform/ui-common';
import { getSourceDescription } from '@vrplatform/ui-common';
import { ListingStatementRow } from './_components';

export type ListingOwnerRow = ReturnType<
  typeof useListingDetailsData
>['ownerships'][number];

export function useListingDetailsData(listingId?: string | null) {
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery(
    (q, { listingId: id }) => {
      const listingId = id!;

      if (!listingId) return null;

      const listing = q
        .listings({ where: { id: { _eq: listingId } } })
        .map((listing) => ({
          id: listing.id,
          name: getListingName(listing),
          status: listing.status,
          title: listing.title,
          address: listing.address,
          tenantId: listing.tenantId,
          connection:
            listing
              .connections({ order_by: [{ name: 'asc' }] })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                uniqueRef: conn.uniqueRef,
                iconRound: conn.connection.app.iconRound,
              })) || [],
          pmsConnectionId: listing.connectionId,
          ownerships: listing
            .ownerships({
              order_by: [{ owner: { firstName: 'asc' } }],
              where: {
                ownerId: { _is_null: false },
              },
            })
            .map((ownership) => {
              return {
                id: ownership.ownerId,
                ownershipId: ownership.id,
                ownerId: ownership.owner?.id,
                firstName: ownership.owner?.firstName,
                lastName: ownership.owner?.lastName,
                email: ownership.owner?.email,
                role: ownership.role || 'owner',
                companyName: ownership.owner?.companyName,
                ownerSplit: ownership.split || null,
                vendor: ownership
                  .settingsLeft({ where: { key: { _eq: 'vendor' } }, limit: 1 })
                  .map((setting) => ({
                    label: getSourceDescription(setting?.rightSource),
                    value: setting.value || '',
                    settingId: setting.id,
                  }))?.[0] || { label: '', value: '', settingId: undefined },
              };
            })
            .filter(hasValue),
          statements: listing
            .ownerStatements({
              order_by: [{ startAt: 'desc' }],
              where: {
                owners: {
                  role: { _eq: 'owner' },
                },
              },
            })
            .map<ListingStatementRow>((statement) => ({
              id: statement.id,
              listingId,
              month: statement.startAt,
              ownerName:
                statement
                  .owners({ where: { role: { _eq: 'owner' } } })
                  .map((ship) =>
                    formatUserName({
                      firstName: ship.owner?.firstName || '',
                      companyName: ship.owner?.companyName || '',
                      lastName: ship.owner?.lastName || '',
                    })
                  )
                  .join(', ') || '',
            })),
        }))[0];

      return {
        listing,
      };
    },
    {
      queryKey: 'listings',
      variables: { listingId },
      skip: !listingId,
    }
  );

  const listing = data?.listing;

  return {
    listing,
    ownerships: data?.listing?.ownerships || [],
    // vendors,
    loading: loading,
    refetch: () => {
      refetch();
    },
  };
}
