import { AgGridReact, AgGridReactProps } from '@finalytic/ui-grid';
import { Box } from '@mantine/core';
import { ReactNode, forwardRef } from 'react';
import { useStyles } from './_styles';

type TableTheme = 'alpine';

export type TableStylingProps = {
  isBottomRounded?: boolean;
  isTopRounded?: boolean;
  noBorder?: boolean;
  withMinHeight?: boolean;
  minHeight?: number;
};

// Allow to pass generic to forwardRef
// declare module 'react' {
//   function forwardRef<T, P = {}>(
//     render: (props: P, ref: React.Ref<T>) => React.ReactElement<P> | null
//   ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
// }

type TableProps<TData> = AgGridReactProps<TData> & {
  rowData?: { [key: string]: string | number }[] | any[] | null;
} & TableStylingProps;
type PropsWithForwardRef<T> = TableProps<T> & {
  forwardedRef: React.Ref<AgGridReact>;
};
type PropsWithStandardRef<T> = TableProps<T> & { ref?: React.Ref<AgGridReact> };

const empty: any[] = [];
// eslint-disable-next-line react/display-name
function TableComponent<TData>({
  isBottomRounded = true,
  isTopRounded = true,
  noBorder = false,
  pagination = true,
  withMinHeight = false,
  defaultColDef,
  gridOptions,
  rowData,
  minHeight = 0,
  noRowsOverlayComponent: NoRowOverlay,
  forwardedRef,
  ...props
}: PropsWithForwardRef<TData>) {
  const theme: TableTheme = 'alpine';

  const { classes } = useStyles({
    isEmpty:
      withMinHeight || !!minHeight || (rowData ? rowData.length < 1 : true),
    isBottomRounded,
    isTopRounded,
    noBorder,
    minHeight,
    noHeader: props.headerHeight === 0,
    hasRowAutoHeight: (props.columnDefs || [])
      ?.flatMap((c) => Object.keys(c))
      .includes('autoHeight'),
  });

  rowData = rowData || (empty as any);
  return (
    <div
      className={`ag-theme-${theme} ${classes.table}`}
      style={{ width: '100%', height: '100%' }}
    >
      <WorkAround value={rowData}>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <AgGridReact
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            filter: false,
            resizable: true,
            suppressMovable: true,
            ...defaultColDef,
          }}
          // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          noRowsOverlayComponent={() =>
            NoRowOverlay ? <NoRowOverlay /> : <Box pt={50}>No Rows To Show</Box>
          }
          gridOptions={{
            ...gridOptions,
          }}
          // getRowId={(data) => (data as any).id}
          pagination={pagination}
          suppressRowVirtualisation
          suppressColumnVirtualisation
          enableCellTextSelection
          {...props}
          ref={forwardedRef} // Ref for accessing Grid's API
        />
      </WorkAround>
    </div>
  );
}

export const Table: <T>(props: PropsWithStandardRef<T>) => ReactNode =
  forwardRef((props, ref) => <TableComponent {...props} forwardedRef={ref} />);

function WorkAround({ children, value }: { children: any; value: any }) {
  if (
    value &&
    value.length === 1 &&
    value[0] &&
    'id' in value[0] &&
    value[0].id === undefined
  ) {
    return null;
  }
  return children;
}
