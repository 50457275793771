import { StatementsIcon } from '../../icons';
import { OverlayContainer, OverlayContainerProps } from './_components';

type Props = { text: string } & Omit<
  OverlayContainerProps,
  'icon' | 'children'
>;

export const NoStatementsTableOverlay = ({ text, ...stackProps }: Props) => (
  <OverlayContainer icon={<StatementsIcon />} {...stackProps}>
    {text}
  </OverlayContainer>
);
