import { listing_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';
import { whereAddress } from './whereAddress';
import { whereListingIsExcluded } from './whereListingIsExcluded';

export const listingsFilterInput = {
  tenantId: z.string().optional(),
  search: z.string().nullish(),
  ownerIds: z.array(z.string().nullish()).nullish(),
  status: z.enum(['inactive', 'active']).nullish(),
  collectionId: z.string().nullish(),
  disabledByAutomationId: z.union([z.string(), z.array(z.string())]).nullish(),
};

const listingsFilter = z.object(listingsFilterInput);

export type ListingsFilterParams = z.infer<typeof listingsFilter>;

type BaseWhere = {
  partnerTeamIds: string[];
  dashboard: Dashboard;
  currentTeamId: string;
  v2Owners?: boolean;
  ownerUserId?: string;
};

export function whereListings({
  search: s,
  currentTeamId,
  dashboard,
  partnerTeamIds,
  tenantId,
  disabledByAutomationId,
  status,
  ownerIds,
  v2Owners,
  ownerUserId,
  collectionId,
}: BaseWhere & ListingsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<listing_bool_exp[]>([
        {
          address: { _ilike: `%${search}%` },
        },
        {
          uniqueRef: { _ilike: `%${search}%` },
        },
        {
          calculated_title: { _ilike: `%${search}%` },
        },
        {
          ownerships: {
            owner: {
              _or: [
                { firstName: { _ilike: `%${search}%` } },
                { lastName: { _ilike: `%${search}%` } },
                { companyName: { _ilike: `%${search}%` } },
              ],
            },
          },
        },
        {
          connections: {
            name: { _ilike: `%${search}%` },
          },
        },
        {
          addressRelation: whereAddress({ search }),
        },
      ])
    : undefined;

  const statusFilter: listing_bool_exp =
    status === 'inactive'
      ? {
          _or: [
            {
              // TODO: VRP-4868 remove enabled/disabled
              status: { _in: ['inactive', 'disabled'] },
            },
            {
              // TODO: VRP-4868 remove enabled/disabled
              pmsStatus: { _in: ['disabled', 'inactive'] },
              status: { _is_null: true },
            },
          ],
        }
      : {
          _or: [
            {
              status: { _is_null: true },
              pmsStatus: { _is_null: true },
            },
            {
              // TODO: VRP-4868 remove enabled/disabled
              pmsStatus: { _nin: ['disabled', 'inactive'] },
              status: { _is_null: true },
            },
            {
              // TODO: VRP-4868 remove enabled/disabled
              status: { _in: ['active', 'enabled'] },
            },
          ],
        };

  return ensure<listing_bool_exp>({
    tenantId: tenantId
      ? { _eq: tenantId }
      : { _in: dashboard === 'partner' ? partnerTeamIds : [currentTeamId] },
    collectionId: collectionId
      ? collectionId === 'null'
        ? {
            _is_null: true,
          }
        : { _eq: collectionId }
      : undefined,
    ownerships: ownerIds?.length
      ? v2Owners
        ? {
            newOwnerId: { _in: ownerIds },
          }
        : { ownerId: { _in: ownerIds } }
      : v2Owners && dashboard === 'owner'
        ? {
            newOwner: {
              userAccesses: {
                userId: { _eq: ownerUserId },
              },
            },
          }
        : undefined,
    _and: disabledByAutomationId?.length
      ? [whereListingIsExcluded(disabledByAutomationId)]
      : [statusFilter],
    _or: searchQuery,
  });
}
