import { useAuditDrawer, useQuery } from '@finalytic/data';
import { ArrowLeftIcon, CrossIcon } from '@finalytic/icons';
import { Button, Drawer, IconButton, LoadingIndicator } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Center,
  Collapse,
  Divider,
  Group,
  Table as MTable,
  Tabs,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatUserName } from '@vrplatform/ui-common';
import { ReactNode } from 'react';
import { AuditDrawerTable } from './AuditDrawerTable';

export const AuditDrawer = () => {
  const { close, opened, auditId, setAuditId } = useAuditDrawer();

  return (
    <>
      <Drawer opened={!!opened} onClose={close} size={900}>
        <Group justify="space-between" px={5}>
          <Group gap={4}>
            {auditId && (
              <IconButton onClick={() => setAuditId(undefined)}>
                <ArrowLeftIcon size={18} />
              </IconButton>
            )}
            <Text component="h2" size="xl">
              History
            </Text>
          </Group>
          <Group>
            <IconButton onClick={close}>
              <CrossIcon size={22} />
            </IconButton>
          </Group>
        </Group>

        <Tabs
          value={auditId ? 'audit-view' : 'table-view'}
          styles={{
            root: {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            },
            panel: {
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            },
          }}
        >
          <Tabs.Panel value="audit-view">
            <AuditDetailView auditId={auditId} />
          </Tabs.Panel>
          <Tabs.Panel value="table-view">
            <AuditDrawerTable />
          </Tabs.Panel>
        </Tabs>
      </Drawer>
    </>
  );
};

const AuditDetailView = ({
  auditId,
}: {
  auditId: string | undefined | null;
}) => {
  const [opened, handlers] = useDisclosure(false);

  const { data: audit, isLoading: loading } = useQuery(
    (query, args) => {
      const audit = query.auditLog({ id: args.auditId });

      const user = {
        id: audit?.actorUser?.id,
        firstName: audit?.actorUser?.firstName,
        lastName: audit?.actorUser?.lastName,
        email: audit?.actorUser?.email,
      };

      return {
        auditId: audit?.id,
        user: `${formatUserName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        })} ${user?.email ? `(${user?.email})` : ''}`,
        operation: audit?.op,
        tableName: audit?.tableName,
        objectId: audit?.objectId,
        deltaJson: audit?.deltaJson(),
      };
    },
    {
      variables: { auditId },
      queryKey: 'auditLogs',
      skip: !auditId,
    }
  );

  return (
    <>
      {audit && (
        <>
          <Title loading={loading}>Description</Title>

          <MTable>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(audit)
                .filter(([key]) => key !== 'deltaJson')
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{toTitleCase(key)}</td>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </MTable>

          <Divider my={30} />

          <Group justify="space-between">
            <Title>Changes</Title>
            <Button onClick={handlers.toggle}>Toggle JSON</Button>
          </Group>
          <MTable>
            <thead>
              <tr>
                <th>Key</th>
                <th>Type</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {audit.deltaJson?.map(
                (element: {
                  key: string;
                  type: string;
                  oldValue: any;
                  value: any;
                }) => {
                  const formatValue = (val: any) => {
                    if (element?.key.includes('cent'))
                      return ['number', 'bigint'].includes(typeof val)
                        ? val / 100
                        : val;

                    return `${
                      ['string', 'number', 'bigint'].includes(typeof val)
                        ? val
                        : val || ''
                    }`;
                  };

                  const formatKey = (key: string | undefined) => {
                    return key && toTitleCase(key.replace('cent', ''));
                  };

                  return (
                    <tr key={element?.key}>
                      <td>{formatKey(element?.key)}</td>
                      <td>{element?.type}</td>
                      <td>{formatValue(element?.oldValue)}</td>
                      <td>{formatValue(element?.value)}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </MTable>
          <Collapse in={opened}>
            <pre>{JSON.stringify(audit?.deltaJson, null, 2)}</pre>
          </Collapse>
        </>
      )}

      {!audit && (
        <Center sx={{ height: '100%' }}>
          {loading ? <LoadingIndicator /> : <Text>Missing Audit.</Text>}
        </Center>
      )}
    </>
  );
};

const Title = ({
  children,
  loading,
}: {
  children: ReactNode;
  loading?: boolean;
}) => (
  <Group justify="space-between">
    <Text size="xl" fw={500} ml={4}>
      {children}
    </Text>
    {loading && <LoadingIndicator size="sm" />}
  </Group>
);
