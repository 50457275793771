import { hasValue } from '@finalytic/utils';
import { NetRevenueColumn } from '../../statements/_types';

export const formatColumnsToFormulaFields = (columns: NetRevenueColumn[]) =>
  columns
    .map((col) => {
      if (col.type === 'sumAccounts') {
        return {
          displayValue: `"col.${col.name}"`,
          id: col.id, // random UUID generated when adding new row
          label: col.name, //
          type: 'collection',
          description: 'Collections are the sum of accounts selected.',
        };
      } else if (col.type === 'sumColumns') {
        return {
          displayValue: `"col.${col.name}"`,
          id: col.id, // random UUID generated when adding new row
          label: col.name, //
          type: 'sum of columns',
          description: 'The sum of multiple columns.',
        };
      } else if (col.type === 'metadata') {
        return {
          displayValue: `"col.${col.name}"`,
          id: col.id, // random UUID generated when adding new row
          label: col.name, //
          type: 'metadata',
          description: 'Access metadata in statement lines.',
        };
      }

      return null;
    })
    .filter(hasValue);
