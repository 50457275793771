import { useAuth, useUser } from '@finalytic/authentication';
import {
  Button,
  Input,
  InputAddress,
  InputWrapper,
} from '@finalytic/components';
import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { EmailIcon, RocketIcon } from '@finalytic/icons';
import {
  InputPassword,
  Modal,
  StringParam,
  Switch,
  passwordRegex,
  showErrorNotification,
  showSuccessNotification,
  useAppName,
  useQueryParam,
} from '@finalytic/ui';
import {
  Box,
  Card,
  Center,
  Divider,
  Group,
  Stack,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import {
  Address,
  getCountryByIsoCode,
  getStateByIsoCode,
  validateTin,
} from '@vrplatform/ui-common';
import { Controller, useForm } from 'react-hook-form';

type FormInputs = {
  firstName: string;
  lastName: string;
  address?: Address;
  taxId?: string;
  password: string;
  passwordConfirm: string;
  emailNotifications: boolean;
};

export function useIsWelcomeModalOpen() {
  const { user } = useUser();
  const { actor } = useAuth();

  const [resetPasswordParam] = useQueryParam('resetPassword', StringParam);

  if (actor?.sub) return false;

  return (
    !!user?.unsafeMetadata?.needPasswordChange ||
    !!user?.unsafeMetadata?.need_password_change ||
    !!resetPasswordParam
  );
}

export const WelcomeUserModal = () => {
  const [teamId] = useTeamId();
  const { user } = useUser();
  const { mutate: updateClerkUser } = useTrpcMutation('updateClerkUser', {
    errorMessage: {
      title: 'Failed to update password',
      message: (error) => error.message.replace('Clerk Error: ', ''),
    },
  });
  const { userId: clerkUserId } = useAuth();
  const { NEW_OWNERS } = useEnabledFeatures();
  const { firstName, lastName, id, notificationPreferences, address, taxId } =
    useMe();
  const [{ role }] = useTeam();
  const { updateUserName: updateDbUser } = useUserMutation();

  const [resetPasswordParam, setResetPasswordParam] = useQueryParam(
    'resetPassword',
    StringParam
  );

  const opened = useIsWelcomeModalOpen();

  const type = resetPasswordParam ? 'password-reset' : 'welcome';

  const { primaryColor, colors } = useMantineTheme();
  const { appName } = useAppName();
  const [dashboard] = useDashboard();
  const showAddressTaxIdInputs =
    dashboard === 'owner' && !NEW_OWNERS && type === 'welcome';

  const { control, handleSubmit, setError, formState } = useForm<FormInputs>({
    values: {
      emailNotifications: true,
      firstName: firstName || '',
      lastName: lastName || '',
      password: '',
      passwordConfirm: '',
      address: address
        ? {
            city: address.city || '',
            line1: address.line1 || '',
            line2: address.line2 || '',
            postcode: address.postcode || '',
            stateCode: address.stateCode || '',
            countryCode: address.countryCode || '',
          }
        : undefined,
      taxId,
    },
  });

  const submit = async ({
    password,
    firstName,
    lastName,
    passwordConfirm,
    emailNotifications,
    address: newAddress,
    taxId: newTaxId,
  }: FormInputs) => {
    if (!user) {
      return showErrorNotification({
        title: 'Internal Error',
        message: 'User not found. Please refresh the page.',
        color: 'yellow',
      });
    }

    if (password.trim() !== passwordConfirm.trim()) {
      return setError('passwordConfirm', {
        type: 'validation',
        message: 'Password and Confirm Password must be the same.',
      });
    }

    const welcomePromise = async () => {
      if (type !== 'welcome') return Promise.resolve();

      const settingKey = 'owner-statements.status-change.email';
      let notifications = [...notificationPreferences];

      if (!emailNotifications) {
        notifications = notifications.filter((n) => n !== settingKey);
      } else {
        if (!notifications.includes(settingKey)) {
          notifications.push(settingKey);
        }
      }

      await updateDbUser({
        id,
        firstName,
        lastName,
        notificationPreferences: notifications,
        address: newAddress?.line1?.trim() ? newAddress : null,
        addressId: address?.id,
        taxId: newTaxId,
      });
    };

    if (!clerkUserId)
      return Promise.reject(
        'Please refresh the page. If the problem persists, contact support.'
      );

    return await Promise.all([
      updateClerkUser({
        teamId,
        clerkUserId,
        data: {
          pw: password,
          first_name: type === 'welcome' ? firstName : undefined,
          last_name: type === 'welcome' ? lastName : undefined,
          unsafe_metadata: {},
        },
      }),
      welcomePromise(),
    ]).then(() => {
      showSuccessNotification({
        title: type === 'password-reset' ? 'Success' : 'Welcome to VRP!',
        id: `${type}-success-notification`,
        message:
          type === 'password-reset'
            ? 'Your password was changed.'
            : "You're all set up and ready to go.",
      });

      user.reload();
      setResetPasswordParam(undefined);
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={() => null}
      withCloseButton={false}
      size={500}
      styles={{
        body: {
          paddingBlock: rem(40),
          paddingInline: rem(40),
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(submit, (errors) => {
          console.log(errors);
        })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(48),
        }}
      >
        {type === 'welcome' && (
          <Center>
            <RocketIcon
              color={colors[primaryColor][6]}
              size={45}
              strokeWidth={1}
            />
          </Center>
        )}
        <Box>
          <Title ta="center" size={rem(20)} mb={rem(24)}>
            {type === 'welcome' ? `Welcome to ${appName}` : 'Reset password'}
          </Title>
          <Text c="neutral" ta="center" size="sm">
            {type === 'welcome'
              ? 'We are thrilled to have you on board. Setup your name, password and email preferences.'
              : 'Please enter your new password. You will logged in automatically and can start using the platform.'}
          </Text>
        </Box>

        <Stack>
          {type === 'welcome' && (
            <Group
              wrap="nowrap"
              align="flex-start"
              sx={{
                '> div': {
                  flex: 1,
                },
              }}
            >
              <Controller
                control={control}
                name="firstName"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter your first name.',
                  },
                }}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputWrapper
                      label={'First name'}
                      error={error?.message}
                      required
                    >
                      <Input
                        value={value}
                        setValue={onChange}
                        name={name}
                        placeholder="First name"
                        error={error?.message}
                        autoComplete="given-name"
                        type="text"
                        required
                      />
                    </InputWrapper>
                  );
                }}
              />
              <Controller
                control={control}
                name="lastName"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter your first name.',
                  },
                }}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputWrapper
                      label={'Last name'}
                      error={error?.message}
                      required
                    >
                      <Input
                        value={value}
                        setValue={onChange}
                        name={name}
                        placeholder="Last name"
                        error={error?.message}
                        autoComplete="family-name"
                        type="text"
                        required
                      />
                    </InputWrapper>
                  );
                }}
              />
            </Group>
          )}

          {showAddressTaxIdInputs && (
            <>
              <Controller
                control={control}
                name="address"
                render={({ field: { value, onChange }, fieldState }) => {
                  return (
                    <InputWrapper
                      error={fieldState.error?.message}
                      label="Address"
                    >
                      <InputAddress
                        value={{
                          city: value?.city || '',
                          line1: value?.line1 || '',
                          line2: value?.line2 || '',
                          countryCode: value?.countryCode || '',
                          postcode: value?.postcode || '',
                          stateCode: value?.stateCode || '',
                        }}
                        onChange={(newAddress) =>
                          onChange(
                            !newAddress?.line1?.trim()
                              ? {
                                  city: '',
                                  line1: '',
                                  countryCode: '',
                                  postcode: '',
                                  stateCode: '',
                                }
                              : newAddress
                          )
                        }
                        dropdownProps={{
                          withinPortal: true,
                          position: 'right-start',
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
              <Controller
                control={control}
                name="taxId"
                defaultValue=""
                rules={{
                  validate: (value) => {
                    if (!value?.trim()) return true;

                    return validateTin(value);
                  },
                }}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputWrapper
                      label={'Tax Identification Number (TIN)'}
                      error={error?.message}
                    >
                      <Input
                        value={value}
                        setValue={onChange}
                        name={name}
                        placeholder="TIN"
                        error={error?.message}
                        type="number"
                        autoComplete="off"
                      />
                    </InputWrapper>
                  );
                }}
              />
              <Divider mt="sm" />
            </>
          )}

          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: 'Please enter a password.',
              },
              pattern: {
                value: passwordRegex,
                message:
                  'Please enter a lower/uppercase letter, one number, one special symbol and at least 8 characters.',
              },
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => {
              return (
                <InputWrapper
                  label={'Password'}
                  error={error?.message}
                  required
                >
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    name={name}
                    error={error?.message}
                    autoComplete="new-password"
                    v2
                  />
                </InputWrapper>
              );
            }}
          />

          <Controller
            control={control}
            name="passwordConfirm"
            rules={{
              required: {
                value: true,
                message: 'Please confirm your password.',
              },
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => {
              return (
                <InputWrapper
                  label={'Confirm password'}
                  error={error?.message}
                  required
                >
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    name={name}
                    error={error?.message}
                    withPopover={false}
                    autoComplete="off"
                    v2
                  />
                </InputWrapper>
              );
            }}
          />
        </Stack>

        {type === 'welcome' && role === 'owner' && (
          <Card
            withBorder
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              gap: rem(12),
            }}
          >
            <Center>
              <EmailIcon size={18} />
            </Center>

            <Controller
              control={control}
              name="emailNotifications"
              rules={{
                required: true,
              }}
              defaultValue={true}
              render={({ field: { value, onChange, name } }) => {
                return (
                  <Switch
                    checked={!!value}
                    onChange={onChange}
                    name={name}
                    label="I want to receive important platform notifications by the email"
                    labelPosition="left"
                    styles={{
                      body: {
                        alignItems: 'center',
                      },
                    }}
                  />
                );
              }}
            />
          </Card>
        )}

        <Button
          variant="primary"
          type="submit"
          loading={formState.isSubmitting}
        >
          {type === 'welcome' ? `Start with ${appName}` : 'Reset password'}
        </Button>
      </Box>
    </Modal>
  );
};

function useUserMutation() {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('updateOwner');

  return {
    updateUserName: ({
      firstName,
      id,
      lastName,
      notificationPreferences,
      address,
      addressId,
      taxId,
    }: {
      id: string;
      firstName: string;
      lastName: string;
      notificationPreferences: string[];
      addressId: string | undefined;
      address: Address | null;
      taxId: string | undefined;
    }) =>
      mutate({
        ownerId: id || 'x',
        tenantId: teamId,
        input: {
          firstName,
          lastName,
          notificationPreferences,
          taxId: taxId || '',
          address: !address
            ? null
            : {
                id: addressId,
                line1: address.line1.trim(),
                line2: address.line2?.trim() || '',
                city: address.city.trim(),
                postcode: address.postcode.trim(),
                countryCode: address.countryCode,
                stateCode: address.stateCode || '',
                country: getCountryByIsoCode(address.countryCode)?.name || '',
                state:
                  getStateByIsoCode(address.stateCode, address.countryCode)
                    ?.name || '',
              },
        },
      }),
  };
}
