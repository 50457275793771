import cubejs from '@cubejs-client/core';
import { CubeProvider as CProvider, CubeContext } from '@cubejs-client/react';
import { useTeamId, useTrpcQuery } from '@finalytic/data';
import { ReactNode, useContext } from 'react';

export const CubeProvider = ({
  children,
  loadingPlaceholder,
}: { children: ReactNode; loadingPlaceholder?: ReactNode }) => {
  const [teamId] = useTeamId();
  const { data, error, isInitialLoading } = useTrpcQuery(
    'cubeDev',
    { teamId },
    { keepPreviousData: true }
  );

  if (isInitialLoading) return loadingPlaceholder || null;

  if (!data?.token || error) return <div>Failed to load charts</div>;

  const cubejsApi = cubejs(data?.token, {
    apiUrl:
      'https://ethnic-medina.aws-eu-central-1.cubecloudapp.dev/cubejs-api/v1',
  });

  return <CProvider cubeApi={cubejsApi}>{children}</CProvider>;
};

export const useCube = () => {
  const context = useContext(CubeContext);

  return context;
};
