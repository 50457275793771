import { Button } from '@finalytic/components';
import {
  captureSentryError,
  useSubscription,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { showErrorNotification } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';

export const RerunButtons = ({ workflowId }: { workflowId: Maybe<string> }) => {
  const [teamId] = useTeamId();
  const { setWorkflowIds } = useRunDrawer();

  const { mutate: mutateCancel, loading: loadingCancel } = useTrpcMutation(
    'cancelWorkflow',
    {
      successMessage: {
        title: 'Workflow cancelled',
        message: 'The workflow has been cancelled and will no longer run.',
      },
    }
  );

  const { mutate: mutateRetry, loading: loadingRetry } =
    useTrpcMutation('retryAutomation');

  const { data, isLoading: loading } = useSubscription(
    (q, { workflowId }) => {
      if (!workflowId) return null;

      return (
        q
          .jobPlans({
            where: { workflowId: { _eq: workflowId } },
            order_by: [
              {
                createdAt: 'asc',
              },
            ],
          })
          .map((plan) => {
            return {
              id: plan.id,
              automationId: plan.automationId,
              params: plan.params(),
              status: plan.status,
              jobs: plan.jobs().map((item) => ({
                id: item.id,
                status: item.status || '',
                title: item.params()?.title || '',
                message: item.title || 'Running',
              })),
              hasFailedActions: !!(
                plan
                  .actions_aggregate({
                    where: {
                      status: { _eq: 'failed' },
                    },
                  })
                  .aggregate?.count() || 0
              ),
            };
          })?.[0] || {}
      );
    },
    {
      workflowId,
    },
    {
      skip: !workflowId,
    }
  );
  const isCompleted = ['completed', 'failed'].includes(
    data?.status?.toLowerCase() || ''
  );

  const cancelWorkflow = () => {
    if (!data?.id)
      return showErrorNotification({
        message: 'No workflow id found',
        color: 'yellow',
      });

    mutateCancel({ jobPlanIds: [data.id], tenantId: teamId });
  };

  const retryWorkflow = (onlyFails: boolean) => {
    const jobPlanId = data?.id;
    if (!jobPlanId) {
      captureSentryError('No jobPlanId found for retry');
      return showErrorNotification({
        message: 'No run found',
        color: 'yellow',
      });
    }

    mutateRetry({
      jobPlanId,
      tenantId: teamId,
      onlyFailedActions: onlyFails,
    }).then((res) => {
      setWorkflowIds([res.workflowId]);
    });
  };

  if (loading) return null;

  return (
    <>
      {isCompleted ? (
        <>
          <Button
            leftIcon={'RefreshCwIcon'}
            disabled={!data?.automationId}
            loading={loadingRetry}
            variant={data?.hasFailedActions ? 'default' : 'primary'}
            onClick={() => retryWorkflow(false)}
          >
            Rerun
          </Button>
          {data?.hasFailedActions && (
            <Button
              variant="primary"
              leftIcon={'LightningIcon'}
              loading={loadingRetry}
              disabled={!data?.id || !data.automationId}
              onClick={() => retryWorkflow(true)}
            >
              Retry Fails
            </Button>
          )}
        </>
      ) : (
        <Button
          color="red"
          loading={loadingCancel}
          leftIcon={'CrossCircleIcon'}
          onClick={cancelWorkflow}
        >
          Cancel
        </Button>
      )}
    </>
  );
};
