import { Box } from '@mantine/core';
import {
  DatePicker as MantineCalendar,
  DatePickerProps as MantineCalendarProps,
} from '@mantine/dates';
import { defaultStyles } from './_styles';

type CalendarProps = {
  value: Date | null | undefined;
  onChange: (value: Date | null) => void;
};

export const Calendar = ({
  value,
  onChange,
  ...props
}: CalendarProps & MantineCalendarProps) => {
  return (
    <Box sx={{ minHeight: 315 }}>
      <MantineCalendar
        value={value || null}
        onChange={onChange as any}
        level="month"
        maxLevel="month"
        numberOfColumns={2}
        styles={defaultStyles}
        // initialMonth={day().subtract(1, 'month').toDate()} // this puts the current month on the right hand side
        // paginateBy={1}
        {...props}
      />
    </Box>
  );
};
