import {
  captureSentryError,
  gqlV2,
  useMutation,
  usePlaywright,
  useQuery,
  useTeam,
} from '@finalytic/data';
import { BooleanParam, useAppName, useQueryParam } from '@finalytic/ui';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { usePosthogCapture } from '../../../hooks';
import {
  AddConnectionModal,
  IntegrationType,
} from '../../../modals/add-connection-modal';
import { OnboardingIntegration } from '../components';
import { MissingIntegrationModal } from '../components/MissingIntegrationModal';
import { useOnboardingDevMode } from '../hooks';
import { ConnectView } from '../views';

const modalSearchKey = 'add-integration';

function useAddConnectionModal() {
  const [opened, setOpened] = useQueryParam(modalSearchKey, BooleanParam);

  const close = () => setOpened(false);
  const open = () => setOpened(true);

  return {
    opened,
    close,
    open,
  };
}

export const OnboardingConnectRoute = ({
  type,
}: {
  type: 'Accounting' | 'PMS';
}) => {
  const [search, setSearch] = useState('');
  const [
    { id: teamId, propertyManagementSystems, accountingPlatforms },
    refetchTeam,
  ] = useTeam();
  const { appName } = useAppName();
  const [debouncedSearch] = useDebouncedValue(search, 400);
  const location = useLocation();
  const capture = usePosthogCapture();

  const {
    close: closeAddConnectionModal,
    open: openAddConnectionModal,
    opened,
  } = useAddConnectionModal();

  const goto = useNavigate();

  const isDevMode = useOnboardingDevMode();
  const [app, setApp] = useState<IntegrationType>();

  const [openModalMissingIntegration, handleModalMissingIntegration] =
    useDisclosure(false);

  const { mutate: updateTeam } = useMutation(
    (q, args: { teamId: string }) => {
      const res = q.updateTenantById({
        pk_columns: { id: args.teamId },
        _set: { status: 'active', isOnboarding: false },
      });

      return {
        ok: !!res?.id,
      };
    },
    {
      successMessage: {
        id: 'onboarding.connect.success',
        title: "You're all set up!",
        message: `You are connected and ready to explore ${appName}!`,
      },
    }
  );

  const finishOnboarding = async () => {
    const res = await updateTeam({ args: { teamId } });

    if (res?.ok) {
      await refetchTeam();
      goto('/');
    }
  };

  const { integrations, loading: loadingIntegrations } = useIntegrations({
    querySearch: debouncedSearch.trim().toLowerCase(),
    type,
  });

  const connect = (integration: IntegrationType) => {
    setApp(integration);
    openAddConnectionModal();
  };

  const submitMissingIntegration = async (integrationWebsite: string) => {
    try {
      class IntegrationRequestError extends Error {
        constructor(readonly website: string) {
          super(`Integration missing: ${website}`);
          this.name = 'IntegrationRequestError';
        }
      }

      captureSentryError(new IntegrationRequestError(integrationWebsite), {
        level: 'info',
        tags: {
          integrationWebsite,
          sendIntegrationNotification: 'true',
          type,
        },
      });

      capture('connection_requested', {
        type: type.toLowerCase(),
        website: integrationWebsite,
      });

      handleModalMissingIntegration.close();
      return { ok: true };
    } catch (error: any) {
      return { ok: false, errorMessage: error?.message };
    }
  };

  useEffect(() => {
    if ((accountingPlatforms || []).length > 0) finishOnboarding();
  }, [accountingPlatforms]);

  // Close Modal when navigating to page
  useEffect(() => closeAddConnectionModal(), []);

  const addModalSearchQueryRegex = new RegExp(`${modalSearchKey}=1`, 'g');

  const removeModalSearch = (path: string) => {
    const t = `${path}${location.search}`.replace(addModalSearchQueryRegex, '');
    console.log(t);

    return t;
  };

  if (propertyManagementSystems.length > 0 && type === 'PMS') {
    return <Navigate to={removeModalSearch('/connect-accounting')} />;
  }

  if (type === 'Accounting') {
    if (propertyManagementSystems.length < 1 && !isDevMode)
      return <Navigate to={removeModalSearch('/connect-pms')} />;
  }

  const Connect =
    type === 'Accounting' ? (
      <ConnectView
        integrations={integrations || []}
        onIntegrationSubmit={connect}
        onSearch={(s) => setSearch(s)}
        type="Accounting"
        onMissingIntegrationSubmit={handleModalMissingIntegration.open}
        handleSkipOnboarding={finishOnboarding}
        loading={loadingIntegrations}
      />
    ) : (
      <ConnectView
        integrations={integrations || []}
        onIntegrationSubmit={connect}
        onSearch={(s) => setSearch(s)}
        type="PMS"
        onMissingIntegrationSubmit={handleModalMissingIntegration.open}
        loading={loadingIntegrations}
      />
    );

  return (
    <>
      {/* ConnectView */}
      {Connect}

      {/* Add connection Modal */}
      <ConnectionModal
        integrations={integrations || []}
        activeIntegration={app}
        removeActiveIntegration={() => setApp(undefined)}
        close={closeAddConnectionModal}
        open={openAddConnectionModal}
        opened={opened}
      />

      {/* SubmitMissingIntegration */}
      <MissingIntegrationModal
        opened={openModalMissingIntegration}
        close={handleModalMissingIntegration.close}
        type={type}
        onSubmit={submitMissingIntegration}
      />
    </>
  );
};

const ConnectionModal = ({
  integrations,
  activeIntegration,
  removeActiveIntegration,
  close,
  open,
  opened,
}: {
  integrations: IntegrationType[];
  activeIntegration: IntegrationType | undefined;
  removeActiveIntegration: () => void;
} & ReturnType<typeof useAddConnectionModal>) => {
  const [_, refetchTeam] = useTeam();

  const closeModal = () => {
    close();
    setTimeout(() => {
      removeActiveIntegration();
    }, 500);
  };

  // useEffect(() => {
  //   if (activeIntegration) open();
  // }, [activeIntegration]);

  return (
    <AddConnectionModal
      integrations={integrations}
      opened={!!opened}
      close={closeModal}
      open={open}
      onSuccess={refetchTeam}
      presetApp={activeIntegration}
    />
  );
};

const useIntegrations = ({
  type,
  querySearch,
}: {
  querySearch: string;
  type: 'PMS' | 'Accounting';
}) => {
  const { isPlaywright } = usePlaywright();

  const query = useQuery(
    (q, args) => {
      const getIntegration = (app: gqlV2.app) => ({
        id: app.id!,
        name: app.name!,
        type: app.type,
        icon: app.iconRound!,
        iconRound: app.iconRound!,
        authentication: app.authentication(),
      });

      if (args.type === 'Accounting') {
        const integrations = q
          .app({
            where: {
              name: args.querySearch
                ? { _ilike: `%${querySearch}%` }
                : undefined,
              category: {
                _eq: 'accountingPlatform',
              },
            },
            order_by: [
              {
                name: 'asc',
              },
            ],
          })
          .map<OnboardingIntegration>((app) => getIntegration(app));

        return {
          integrations,
        };
      }

      const integrations = q
        .app({
          where: {
            name: args.querySearch ? { _ilike: `%${querySearch}%` } : undefined,
            id: args.isPlaywright
              ? undefined
              : {
                  _nin: ['testPms', 'blankPms'],
                },
            category: {
              _eq: 'propertyManagementSystem',
            },
          },
          order_by: [
            {
              name: 'asc',
            },
          ],
        })
        .map<OnboardingIntegration>((app) => getIntegration(app));

      return {
        integrations,
      };
    },
    {
      variables: {
        querySearch,
        type,
        isPlaywright,
      },
      queryKey: ['apps'],
    }
  );

  return {
    integrations: query.data?.integrations || [],
    loading: query.isLoading,
    refetch: query.refetch,
  };
};
