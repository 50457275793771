import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  ArrayParam,
  ObjectParam,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { Group } from '@mantine/core';
import {
  getListingName,
  whereConnectionStatusDefault,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useGenericTableStore } from '../../stores';

export const usePaymentFilter = () => {
  const [queryFilter, setQueryFilter] = useQueryParams({
    search: StringParam,
    listingIds: ArrayParam,
    connectionId: StringParam,
    listingCollectionId: StringParam,
    appId: StringParam,
    date: StringParam,
    lineTypes: ArrayParam,
    amount: ObjectParam,
    automationResult: StringParam,
  });

  const filterType = useGenericTableStore((s) => s.filterType);
  const stateFilter = useGenericTableStore((s) => s.filter);
  const setStateFilter = useGenericTableStore((s) => s.setFilter);

  const filter = useMemo(() => {
    if (filterType === 'query-params') {
      return queryFilter;
    }
    return stateFilter as typeof queryFilter;
  }, [filterType, stateFilter, queryFilter]);

  return {
    filter,
    setFilter: (filter: Partial<typeof stateFilter>) => {
      if (filterType === 'query-params') {
        setQueryFilter(filter);
      } else {
        setStateFilter(filter);
      }
    },
    reset: () => {
      const initial = {
        search: undefined,
        listingIds: undefined,
        listingCollectionId: undefined,
        connectionId: undefined,
        appId: undefined,
        date: undefined,
        lineTypes: undefined,
        amount: undefined,
        automationResult: undefined,
      };

      setStateFilter(initial);
      setQueryFilter(initial);
    },
  };
};

export const PaymentFilter = () => {
  return (
    <Group>
      <SearchFilter />
      <DateFilter />
      <AmountFilter />
      <ConnectionFilter />
      <AppFilter />
      <LineTypeFilter />
      <ListingGroupFilter />
      <ListingFilter />
      <AutomationFilter />
    </Group>
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = usePaymentFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const DateFilter = () => {
  const { filter, setFilter } = usePaymentFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const AmountFilter = () => {
  const { filter, setFilter } = usePaymentFilter();

  return (
    <Filter.Amount
      value={filter.amount}
      setValue={(amount) => setFilter({ amount })}
    />
  );
};

const ConnectionFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = usePaymentFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.connection_bool_exp = {
        tenantId: { _eq: teamId },
        payments: {},
        app: {
          id: { _neq: 'finalytic' },
        },
        status: whereConnectionStatusDefault,
        _or: search
          ? [
              {
                name: { _ilike: `%${search}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .connection({
          where,
          order_by: [{ name: 'asc' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.name || 'Missing connection name',
        }));

      const aggregate =
        q.connectionAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['payments', 'connections'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { connectionId }) => {
      const value = q
        .connection({ where: { id: { _eq: connectionId } } })
        .map<SelectItem>((item) => ({
          label: item.name || '',
          value: item.id,
        }))?.[0];

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['payments', 'connections'],
      keepPreviousData: true,
      variables: {
        connectionId: filter.connectionId,
      },
    }
  );

  const value = filter.connectionId ? data?.value || null : null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ connectionId: v?.value })}
      label="Connection"
      type="single"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const LineTypeFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = usePaymentFilter();

  const { data, isLoading: loading } = useQuery(
    (q, { teamId }) => {
      const where: gqlV2.payment_line_bool_exp = {
        tenantId: { _eq: teamId },
        type2: { _is_null: false },
      };

      const data = q
        .paymentLines({
          where,
          order_by: [{ type2: 'asc' }],
          distinct_on: ['type2'],
        })
        .map<SelectItem>((res) => ({
          value: res.type2!,
          label: res.type2!,
        }));

      return {
        data,
      };
    },
    {
      skip: !teamId,
      queryKey: ['payments', 'paymentLines'],
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );

  const value =
    filter.lineTypes?.filter(hasValue).map<SelectItem>((lineType) => ({
      value: lineType,
      label: lineType,
    })) || [];

  const options = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ lineTypes: v.map((i) => i.value) })}
      label="Line Type"
      type="multiple"
      withinPortal
      data={{
        options,
        loading,
      }}
      popoverWidth={350}
    />
  );
};

const ListingFilter = () => {
  const { ownerships } = useMe();
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = usePaymentFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search, ownerships, dashboard }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = {
        tenantId: { _eq: teamId },
        id:
          dashboard === 'owner'
            ? { _in: ownerships.map((i) => i.listingId) || [] }
            : undefined,
        name: search
          ? { _ilike: `%${search}%` }
          : { _is_null: false, _neq: '' },
      };

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['payments', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        ownerships,
        dashboard,
      },
    }
  );
  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['payments', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: filter.listingIds || [],
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const ListingGroupFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = usePaymentFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.listing_collection_bool_exp = {
        tenantId: { _eq: teamId },
        name: search
          ? { _ilike: `%${search}%` }
          : { _is_null: false, _neq: '' },
      };

      const list = q
        .listingCollections({
          where,
          order_by: [{ name: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.name || 'No name',
        }));

      const aggregate =
        q.listingCollectionAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const values = queryData.data?.pages.flatMap((page) => page.list) || [];
  const value =
    values.find((v) => v.value === filter.listingCollectionId) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingCollectionId: v?.value })}
      label="Listing Group"
      type="single"
      withinPortal
      noOptionsText="No listing groups found"
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const AppFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = usePaymentFilter();

  const { data, isLoading: loading } = useQuery(
    (q, { teamId }) => {
      const where: gqlV2.connection_bool_exp = {
        tenantId: { _eq: teamId },
        payments: {},
        app: {
          id: { _neq: 'finalytic' },
        },
        appId: { _is_null: false },
        status: whereConnectionStatusDefault,
      };

      const data = q
        .connection({
          where,
          order_by: [{ appId: 'asc' }],
          distinct_on: ['appId'],
        })
        .map<SelectItem>((res) => ({
          value: res.appId!,
          label: res.app.name || '',
        }));

      return {
        data,
      };
    },
    {
      keepPreviousData: true,
      skip: !teamId,
      queryKey: ['payments', 'apps'],
      variables: {
        teamId,
      },
    }
  );

  const options = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  const value = useMemo(() => {
    return filter.appId
      ? options.find((i) => i.value === filter.appId) || null
      : null;
  }, [filter.appId, options]);

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ appId: v?.value })}
      label="Integration"
      type="single"
      withinPortal
      data={{
        options,
        loading,
      }}
    />
  );
};

const AutomationFilter = () => {
  const { filter, setFilter } = usePaymentFilter();

  const options = [
    // { label: 'Any Automations', value: '' },
    { label: 'Failed Automations', value: 'failed' },
  ];

  const value = filter.automationResult ? options[0] : null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ automationResult: v?.value })}
      label="Automations"
      type="single"
      withinPortal
      data={{
        options,
      }}
    />
  );
};
