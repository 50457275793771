import { useDashboard, useEnabledFeatures, useMe } from '@finalytic/data';
import { day } from '@finalytic/utils';
import { PostHog, useActiveFeatureFlags, usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useIsWelcomeModalOpen } from './WelcomeUserModal';

function getFeatureSessionStorageKey(featureFlagName: string, userId: string) {
  return `ph-popup-${userId}-${featureFlagName}`;
}

function shouldShowPopup(featureFlagName: string, userId: string) {
  // The feature flag should have be disabled for the user once the popup has been shown
  // This is a second check for shorter-term preventing of the popup from showing
  const flagNotShownBefore = !localStorage.getItem(
    getFeatureSessionStorageKey(featureFlagName, userId)
  );

  return flagNotShownBefore;
}

function sendPopupEvent(event: string, flag: string, posthog: PostHog) {
  posthog.capture(event, {
    flag: flag,
  });
}

function closePopUp(
  featureFlagName: string,
  userId: string,
  setShowPopup: (val: boolean) => void,
  posthog: PostHog
) {
  setShowPopup(false);
  localStorage.setItem(
    getFeatureSessionStorageKey(featureFlagName, userId),
    'true'
  );
  posthog.people.set({ [`$${featureFlagName}`]: new Date().toDateString() });

  sendPopupEvent('Owner Tax Info Modal Submitted', featureFlagName, posthog);
}

function initPopUp(
  flag: string,
  userId: string,
  setActiveFlag: (val: string) => void,
  setShowPopup: (val: boolean) => void,
  posthog: PostHog
) {
  if (shouldShowPopup(flag, userId)) {
    setActiveFlag(flag);
    setShowPopup(true);

    sendPopupEvent('popup shown', flag, posthog);
  }
}

const featureFlagKey = 'owner-tax-info';

export function useOwnerTaxInfoModal() {
  const [showPopup, setShowPopup] = useState(false);
  const [activeFlag, setActiveFlag] = useState<null | string>(null);
  const posthog = usePostHog();
  const { NEW_OWNERS } = useEnabledFeatures();
  const activeFlags = useActiveFeatureFlags();
  const isWelcomeModalOpen = useIsWelcomeModalOpen();
  const [dashboard] = useDashboard();
  const { id: userId, createdAt } = useMe();

  const isNewUser = createdAt && day().diff(createdAt, 'days') < 7;

  const isOwner = dashboard === 'owner';

  const preventOpen =
    !NEW_OWNERS || isWelcomeModalOpen || !isOwner || isNewUser;

  useEffect(() => {
    if (!activeFlags || !NEW_OWNERS) {
      return;
    }
    for (const flag of activeFlags) {
      const isEnabled = posthog?.isFeatureEnabled(flag);

      if (flag === featureFlagKey && isEnabled) {
        initPopUp(flag, userId, setActiveFlag, setShowPopup, posthog);
        return;
      }
    }
  }, [activeFlags, posthog, NEW_OWNERS, featureFlagKey, userId]);

  return {
    opened: preventOpen ? false : showPopup,
    close: () =>
      activeFlag && closePopUp(activeFlag, userId, setShowPopup, posthog),
    featureFlagKey,
  };
}
