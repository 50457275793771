import {
  IconButton,
  Table,
  TableContainer,
  WhiteTagCell,
  useColors,
} from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { formatCurrency, sum, utc } from '@finalytic/utils';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Tooltip } from '@mantine/core';
import { useMemo } from 'react';

type Props = {
  rowData?: any[];
  currency?: string;
};

export const StatementLinesTable = ({ rowData, currency }: Props) => {
  const { gray, black } = useColors();

  const bottomRowTotal = useMemo(() => {
    return sum(rowData || [], 'centTotal');
  }, [rowData, currency]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: 'id', hide: true },
      {
        field: 'role',
        headerName: '',
        rowGroup: true,
        showRowGroup: true,
        maxWidth: 50,
        resizable: false,
        cellRendererSelector: (params) => {
          if (!params.node.footer) {
            return { component: 'agGroupCellRenderer' };
          }

          return { component: () => null };
        },
      },
      {
        field: 'issues',
        hide: true,
        headerName: '',
        cellRenderer: () => (
          <Tooltip
            label="Needs fixing"
            color="dark"
            position="top-start"
            offset={-10}
            radius={4}
            styles={{
              tooltip: {
                marginLeft: -3,
              },
              arrow: {
                marginLeft: -3,
              },
            }}
            withArrow
          >
            <Box
              sx={{
                display: 'inline-block',
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: '#FFE066',
                marginRight: 5,
              }}
            />
          </Tooltip>
        ),
        width: 10,
        resizable: false,
      },
      {
        field: 'date',
        headerName: 'Date',
        valueFormatter: ({ value, node, data }) => {
          if (!data && !node?.footer) {
            const role = node?.allLeafChildren?.[0].data?.role;
            return `${role || 'None'} (${node?.allLeafChildren?.length || 0})`;
          }

          if (node?.footer) return '';
          if (node?.rowPinned === 'bottom') return 'Total:';
          return value ? utc(value).format('DD MMM YYYY') : '';
        },
        sort: 'asc',
        maxWidth: 140,
      },
      {
        field: 'description',
      },
      {
        field: 'group.description',
        headerName: 'Account',
        cellRenderer: ({ value, node }: ICellRendererParams) => {
          if (node?.footer || !value) return '';
          return <WhiteTagCell>{value}</WhiteTagCell>;
        },
        minWidth: 50,
      },
      {
        field: 'centTotal',
        headerName: 'Total',
        type: 'numericColumn',
        aggFunc: 'sum',
        flex: 1,
        maxWidth: 110,
        valueFormatter: (params) =>
          formatCurrency(params.value / 100 || 0, currency),
      },
      {
        field: 'icon',
        maxWidth: 50,
        suppressMovable: true,
        resizable: false,
        sortable: false,
        width: 60,
        minWidth: 60,
        headerName: '',
        cellStyle: { display: 'grid', placeContent: 'center' },
        cellRenderer: (params: ICellRendererParams) => {
          const link = params.data?.externalLink;

          if (!link) return null;

          return (
            <IconButton
              component="a"
              href={link}
              target="_blank"
              radius={100}
              size={25}
              sx={{ ':hover': { backgroundColor: gray.dark + 30 } }}
            >
              <Center>
                <FontAwesomeIcon
                  icon={faUpRightFromSquare}
                  size="sm"
                  color={black.base}
                />
              </Center>
            </IconButton>
          );
        },
      },
    ],
    [currency, rowData]
  );

  return (
    <TableContainer mb="sm" mih={600}>
      <Table
        animateRows
        getRowId={({ data }) => data?.id}
        columnDefs={columnDefs}
        defaultColDef={{ resizable: true, flex: 1, suppressMovable: true }}
        rowData={rowData as any}
        pagination={true}
        groupDefaultExpanded={1}
        groupDisplayType="custom"
        // groupIncludeTotalFooter
        paginateChildRows
        suppressAggFuncInHeader
        pinnedBottomRowData={[{ centTotal: bottomRowTotal }]}
        gridOptions={{
          getRowStyle: (params) => {
            if (params.node.footer || params.node.rowPinned) {
              return {
                fontWeight: 'bold',
              };
            }
          },
          suppressCellFocus: true,
        }}
      />
    </TableContainer>
  );
};
