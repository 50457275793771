import { useAuditDrawer, useQuery } from '@finalytic/data';
import { InfiniteTable, MRT_ColumnDef, MRT_Row } from '@finalytic/table';
import { LoadingIndicator } from '@finalytic/ui';
import { day, toTitleCase } from '@finalytic/utils';
import { getSourceDescription } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { AuditFilter } from './AuditFilter';
import { AuditRow, useAuditTableQuery } from './useAuditTableQuery';

export const AuditDrawerTable = () => {
  const { filter, showFilter, setAuditId, resetFilter } = useAuditDrawer();

  const queryData = useAuditTableQuery(filter);

  const columns = useMemo<MRT_ColumnDef<AuditRow>[]>(
    () => [
      {
        accessorKey: 'when',
        header: 'When',
        maxSize: 220,
        Cell: ({ row }) =>
          row.original.when
            ? day(row.original.when).format('MMM DD YYYY, hh:mm:ss a')
            : '',
      },
      { accessorKey: 'who', header: 'Who', maxSize: 150 },
      {
        accessorKey: 'op',
        header: 'Operation',
        maxSize: 130,
      },
      { accessorKey: 'what', header: 'What', flex: 1, Cell: WhatCell },
      ...(!showFilter
        ? []
        : [
            {
              accessorKey: 'table',
              header: 'Table',
            },
          ]),
    ],
    [showFilter]
  );

  if (showFilter) {
    return (
      <InfiniteTable
        columns={columns}
        queryData={queryData}
        table={{
          emptyRowsFallback: 'No History Available',
          onRowClick: {
            handler: (row) => {
              setAuditId(row.original.id);
            },
          },
        }}
        resetFilter={resetFilter}
      >
        <AuditFilter />
      </InfiniteTable>
    );
  }

  return (
    <InfiniteTable
      columns={columns}
      queryData={queryData}
      table={{
        emptyRowsFallback: 'No History Available',
        hideTopBar: true,
        onRowClick: {
          handler: (row) => {
            setAuditId(row.original.id);
          },
        },
      }}
    />
  );
};

type Delta = { key: string; type: string; value: string }[];

const WhatCell = ({ row }: { row: MRT_Row<AuditRow> }) => {
  const data = row.original;

  const table = data?.table;
  const delta = data?.what as unknown as Delta;

  const generalGroup = delta.find((i) => i.key === 'group')?.value;
  const group = generalGroup?.includes('finalytic')
    ? generalGroup?.split('.').reverse()[0]
    : 'source';
  const key = delta.find((i) => i.key === 'key')?.value;
  const targetUuid = delta.find((i) => i.key === 'target_uuid')?.value;
  const target = delta.find((i) => i.key === 'target')?.value;
  const sourceId = delta.find((i) => i.key === 'source_id')?.value;
  const value = delta.find((i) => i.key === 'value')?.value;

  const { data: queryData, isLoading: loading } = useQuery(
    (q, args) => {
      let leftSide: any;
      const rightSource = sourceId
        ? q.sourceById({ id: args.sourceId })
        : undefined;

      const rightSide = getSourceDescription(rightSource);

      // Left side => target/group
      switch (args.group) {
        case 'listing':
          if (!args.targetUuid) break;
          leftSide = q.listing({ id: args.targetUuid })?.name;
          break;

        case 'app':
          if (!args.targetUuid) break;
          leftSide = q.appById({ id: args.targetUuid! })?.name;
          break;

        case 'listingOwner':
          if (!args.targetUuid) break;
          leftSide = q.listingOwner({ id: args.targetUuid })?.listing?.name;
          break;

        case 'source': {
          if (!args.targetUuid) break;
          const leftSource = sourceId
            ? q.sourceById({ id: args.targetUuid })
            : undefined;
          leftSide = getSourceDescription(leftSource);
          break;
        }

        case 'connection':
          if (!args.targetUuid) break;
          leftSide = q.connectionById({ id: args.targetUuid })?.name;
          break;

        default:
          break;
      }

      return {
        leftSide: leftSide || args.target,
        rightSide:
          rightSide ||
          (['rate', 'formula'].includes(args?.key || '') && args.value),
      };
    },
    {
      skip: table !== 'setting' || (delta?.length || 0) < 1 || !generalGroup,
      queryKey: ['sources', 'listings', 'listingOwners', 'connections'],
      variables: {
        group,
        key,
        targetUuid,
        target,
        sourceId,
        value,
      },
    }
  );

  if (loading) return <LoadingIndicator size="xs" />;
  if (queryData)
    return (
      <>
        {toTitleCase(queryData.leftSide)} &rarr; {queryData.rightSide}
      </>
    );

  return delta.map((v) => toTitleCase(v.key.replace('cent', ''))).join(', ');
};
