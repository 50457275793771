import { LoadingIndicator } from '@finalytic/ui';
import { Center } from '@mantine/core';
import { ReactNode } from 'react';

export const AuthContainer = ({
  bgColor,
  children,
  loading,
}: {
  bgColor: string;
  children?: ReactNode;
  loading: boolean;
}) => {
  return (
    <Center
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: bgColor,
      }}
    >
      {loading ? <LoadingIndicator isFullPageLoading={false} /> : children}
    </Center>
  );
};
