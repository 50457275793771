import { useDashboard, useEnabledFeatures } from '@finalytic/data';
import {
  InfiniteTable,
  MRT_ColumnDef,
  MRT_SortingState,
} from '@finalytic/table';
import { StringParam, useQueryParamSet } from '@finalytic/ui';
import { ensure, formatCurrency, hasValue, utc } from '@finalytic/utils';
import { Avatar, Group, Text } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { PaymentsReservationsActionIcons } from '../../components';
import { TableFilterType, useGenericTableStore } from '../../stores';
import { PaymentFilter, usePaymentFilter } from './PaymentFilter';
import { PaymentRow, usePaymentTableQuery } from './usePaymentTableQuery';

type Props = {
  onRowClickType: 'detail' | 'select';
  filterType: TableFilterType;
};

export const PaymentsBaseTable = ({ onRowClickType, filterType }: Props) => {
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();
  const { reset } = usePaymentFilter();

  const setPayment = useQueryParamSet('payment', StringParam);

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'payedAt', desc: true },
  ]);

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );

  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  const queryData = usePaymentTableQuery({ sorting });

  const columns = useMemo(
    () =>
      ensure<(MRT_ColumnDef<PaymentRow> | undefined)[]>([
        {
          accessorKey: 'connection',
          enableSorting: false,
          header: 'Connection',
          Cell: ({ row }) => {
            const data = row.original;

            return (
              <Group wrap="nowrap" gap="sm">
                <Avatar
                  src={data.connection?.app?.iconRound}
                  size="sm"
                  styles={{
                    placeholder: { visibility: 'hidden' },
                  }}
                />
                <Text size="sm" component="p" m={0}>
                  {data.connection.name}
                </Text>
              </Group>
            );
          },
        },
        GL
          ? undefined
          : {
              header: 'Automations',
              accessorKey: 'sourceId',
              Cell: ({ row }) => {
                const data = row.original;
                return (
                  <PaymentsReservationsActionIcons
                    id={data.id}
                    sourceId={data.sourceId}
                  />
                );
              },
            },
        {
          accessorKey: 'payedAt',
          header: 'Date',
          maxSize: 100,
          Cell: ({ row }) => utc(row.original.payedAt).format('D MMM YYYY'), // '25/01/2019'
        },

        {
          accessorKey: 'centTotal',
          header: 'Amount',
          maxSize: 50,
          mantineTableBodyCellProps: { align: 'right' },
          mantineTableHeadCellProps: { align: 'right' },
          Cell: ({ row }) =>
            formatCurrency(
              (row.original.centTotal || 0) / 100,
              row.original.currency as any
            ),
        },
      ]).filter(hasValue),
    [dashboard, GL]
  );

  return (
    <InfiniteTable
      columns={columns}
      table={{
        key: 'payments',
        onRowClick: {
          handler: (row) => {
            if (onRowClickType === 'detail') {
              return setPayment(row.original.id);
            }

            if (onRowClickType === 'select') {
              const canSelect = row.getCanSelect();

              if (canSelect) {
                row.toggleSelected();
              }
            }
          },
        },
      }}
      queryData={queryData}
      sorting={{
        sorting,
        setSorting,
      }}
      selecting={{
        rowSelection: {
          rows: rowSelection,
          allPagesSelected,
        },
        setAllPagesSelected,
        setRowSelection,
      }}
      resetFilter={reset}
    >
      <PaymentFilter />
    </InfiniteTable>
  );
};
