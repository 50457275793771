import { AmountFilter } from './AmountFilter';
import { DateFilter } from './DateFilter';
import { MonthFilter } from './MonthFilter';
import { SearchFilter } from './SearchFilter';
import { SelectFilter } from './SelectFilter';

export const Filter = {
  Search: SearchFilter,
  Select: SelectFilter,
  Date: DateFilter,
  Amount: AmountFilter,
  Month: MonthFilter,
};
