import {
  accountClassification_enum,
  accountType_enum,
  account_bool_exp,
} from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';

export function whereAccounts({
  search,
  classification,
  type,
  tenantId,
}: {
  tenantId: string;
  search: Maybe<string>;
  type?: Maybe<accountType_enum>;
  classification?: Maybe<accountClassification_enum>;
}) {
  return ensure<account_bool_exp>({
    classification: classification ? { _eq: classification } : undefined,
    type: type ? { _eq: type } : undefined,
    tenantId: { _eq: tenantId },
    _or: search
      ? [
          {
            title: { _ilike: `%${search}%` },
          },
          {
            uniqueRef: { _ilike: `%${search}%` },
          },
        ]
      : undefined,
  });
}
