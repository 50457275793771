import { useClerk, useUser } from '@finalytic/authentication';
import { useTeam, useTeamId } from '@finalytic/data';
import { Button, LoadingIndicator } from '@finalytic/ui';
import { Center, Group } from '@mantine/core';
import { useEffect } from 'react';

export const EmptyView = ({
  title = 'No team available for this user.',
  loading = false,
}: {
  title?: string;
  loading?: boolean;
}) => {
  const { signOut } = useClerk();
  const [teamId, setTeamId] = useTeamId();
  const [, refreshTeam] = useTeam();
  const { user } = useUser();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (loading) {
      interval = setInterval(async () => {
        await user?.reload(); // refetch on interval clerk public metadata with tenant_ids
        if (teamId || user?.publicMetadata?.user_id) refreshTeam();
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <Center sx={{ height: '100vh', flexDirection: 'column' }}>
      {loading ? (
        <LoadingIndicator isFullPageLoading message="Loading Dashboard..." />
      ) : (
        <>
          <p>{title}</p>
          <Group>
            <Button
              onClick={() => {
                setTeamId(null);
              }}
            >
              Reset Team
            </Button>
            <Button
              onClick={() => {
                signOut();
              }}
            >
              Logout
            </Button>
          </Group>
        </>
      )}
    </Center>
  );
};
