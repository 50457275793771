import { Center, Image } from '@mantine/core';
import { useAppName, useColors } from '../../styles';

export const Logo = ({
  color,
  img,
  width = 30,
  background = 'white',
}: {
  color?: `#${string}`;
  img?: string | null;
  width?: number;
  background?: string;
}) => {
  const { black } = useColors();
  const { themeName } = useAppName();
  const cl = color || black.base || 'white';

  if (img) {
    return (
      <Image
        src={img}
        width={width}
        height={width}
        fit="contain"
        alt="Team logo"
      />
    );
  }

  if (themeName === 'vrplatform')
    return (
      <Center w={width} h={width} sx={{ background }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.2549 8.25161C18.2549 5.89704 20.1636 3.98828 22.5182 3.98828C24.8728 3.98828 26.7815 5.89704 26.7815 8.25161C26.7815 10.6062 24.8728 12.5149 22.5182 12.5149C20.1636 12.5149 18.2549 10.6062 18.2549 8.25161Z"
            fill="#172554"
          />
          <path
            d="M7.02441 8.46593C7.02441 6.01867 9.20858 3.99805 11.6558 3.99805C14.1031 3.99805 15.0558 5.59785 16.5612 7.52728C17.1957 8.34054 20.7921 13.3413 20.7921 13.3413C20.7921 13.3413 21.566 14.6863 21.566 15.7894C21.566 18.5287 17.1993 22.8381 17.1993 22.8381L7.66841 10.6799C7.66841 10.6799 7.02441 10.0502 7.02441 8.46593Z"
            fill="#172554"
            fillOpacity="0.8"
          />
          <path
            d="M1.24707 8.42687C1.24707 5.97961 3.43123 3.95898 5.8785 3.95898C8.32576 3.95898 9.27845 5.55879 10.7839 7.48824C11.4184 8.30147 14.6041 12.4888 16.8247 15.5727C18.3585 17.703 18.474 17.705 18.474 20.3439C18.474 22.9827 15.1592 24.1379 14.1483 24.1914C12.0203 24.304 10.5418 22.4681 10.5418 22.4681L1.89106 10.6408C1.89106 10.6408 1.24707 10.0111 1.24707 8.42687Z"
            fill="#172554"
          />
        </svg>
      </Center>
    );

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 200 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="200" rx="10" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.427 152.426L86.0002 110L100.142 95.8579L142.569 138.284L128.427 152.426Z"
        fill={cl}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1422 138L72 152.142L57.8579 138L72 123.858L86.1422 138Z"
        fill={cl}
      />
      <path
        d="M43 125.569L28.8579 111.426L85.4265 54.8579L99.5001 41L169.853 111.569L155.711 125.711L99.5686 69L43 125.569Z"
        fill={cl}
      />
    </svg>
  );
};
