import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import { defaultStyles } from './_styles';

export const InputDate = ({
  styles,
  value,
  onChange,
  ...props
}: DatePickerInputProps) => {
  return (
    <DatePickerInput
      color="dark"
      styles={{
        ...defaultStyles,
        ...styles,
      }}
      {...props}
      onChange={onChange as any}
      value={value as any}
    />
  );
};
