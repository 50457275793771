import { connection_bool_exp } from '@finalytic/graphql';

// TODO: VRP-4868 remove enabled/disabled
export const connectionHiddenStatusDefault = [
  'deleted',
  'archived',
  'inactive',
];

export const whereConnectionStatusDefault: connection_bool_exp['status'] = {
  _nin: connectionHiddenStatusDefault,
};
