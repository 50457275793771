import { AreaChartByDate } from './AreaChartByDate';

type Props = {
  startDate: string;
  endDate: string;
};
export const PaymentVolumeCard = ({ endDate, startDate }: Props) => {
  return (
    <AreaChartByDate
      title="Payment Volume"
      href="/payments"
      total="sum"
      isCurrencyAmount
      query={{
        measures: ['Payment.centTotal'],
        order: {
          'Payment.payedAt': 'asc',
        },
        timeDimensions: [
          {
            dimension: 'Payment.payedAt',
            granularity: 'day',
            dateRange: [startDate, endDate],
          },
        ],
      }}
    />
  );
};
