import { SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { NetRevenueColumnType } from '@vrplatform/ui-common';
import { useMemo } from 'react';

interface ColumnTypeSelectOption extends SelectItem {
  value: NetRevenueColumnType;
  label: string;
}

export function useCellSelectTypes(
  activeValue: Maybe<NetRevenueColumnType>,
  options?: {
    v2: boolean;
  }
) {
  const selectOptions = useMemo<ColumnTypeSelectOption[]>(
    () =>
      type_select_options.filter((option) => {
        if (options?.v2)
          return ![
            'sumAccounts',
            'sumColumns',
            'subtractColumns',
            'collection',
            'metadata',
          ].includes(option.value);

        return option.value !== 'collection';
      }),
    []
  );

  // Overwrite legacy formula type here with new formula.currency
  const value = useMemo<ColumnTypeSelectOption | undefined>(
    () =>
      selectOptions.find((option) => {
        if (activeValue === 'formula')
          return option.value === 'formula.currency';

        return option.value === activeValue;
      }),
    [activeValue, selectOptions]
  );

  return {
    selectOptions,
    value,
  };
}

export const field_select_options = [
  { value: 'reservation.guestName', label: 'Guest name' },
  { value: 'reservation.confirmationCode', label: 'Confirmation code' },
  { value: 'reservation.checkIn', label: 'Reservation Dates' },
  { value: 'reservation.status', label: 'Reservation Status' },
  { value: 'reservation.bookingChannel', label: 'Booking Channel' },
  { value: 'reservation.bookingChannelIcon', label: 'Booking Channel Icon' },
  { value: 'total', label: 'Total' },
];

export const type_select_options: ColumnTypeSelectOption[] = [
  { value: 'field', label: 'Field' },
  { value: 'metadata', label: 'Metadata' },
  { value: 'sumAccounts', label: 'Collections' },
  { value: 'sumColumns', label: 'Sum of columns' },
  { value: 'subtractColumns', label: 'Difference of columns' },
  { value: 'formula.number', label: 'Formula' },
  { value: 'formula.currency', label: 'Formula ($)' },
  { value: 'formula.percentage', label: 'Formula (%)' },
  { value: 'collection', label: 'Collection' },
];

export const CELL_HEIGHT = 48;
