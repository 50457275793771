import { hasValue, utc } from '@finalytic/utils';
import { formatUserName } from '../../utils/format-user-name';
import {
  FormattedStatement,
  Statement,
  StatementSummaryUnion,
} from '../_types';

export const getFormattedStatementHeader = ({
  ownerId,
  statements,
  groupedBy,
  v2Owners,
}: {
  statements: Statement[];
  ownerId: string | undefined;
  groupedBy: StatementSummaryUnion;
  v2Owners: boolean;
}): {
  header: FormattedStatement['header'] | undefined;
  footer: FormattedStatement['footer'];
  ownerSplit: number | undefined;
} => {
  const statementOwnerStatement: Statement | undefined = statements.find(
    (i) => !!i.statementOwners.find((i) => i.owner.id === ownerId)
  );

  const statement = statementOwnerStatement || statements[0];

  const hasMultipleListings =
    statements
      ?.map((st) => st.listing?.id)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      }).length > 1;

  if (!statement)
    return {
      header: undefined,
      ownerSplit: undefined,
      footer: '',
    };

  const statementOwner = ownerId
    ? statement.statementOwners.find((i) => i.owner.id === ownerId)
    : statement.statementOwners[0];

  const month =
    groupedBy !== 'groupByReservation'
      ? undefined
      : statement?.startAt
        ? utc(statement?.startAt).format('MMMM YYYY')
        : 'Missing Month';

  const header: FormattedStatement['header'] = {
    statement: {
      month,
      startAt: statement?.startAt,
      subline: `Owner Statement | ${month || 'Summary'}`,
      statementId: statement?.id,
      periodId: statement?.periodId,
      currency: statement?.currency || undefined,
      status: statement?.status,
    },
    team: {
      name: statement.tenant?.name || statement.tenant?.companyName || '',
      logo: statement.tenant.logo,
      info: {
        address: statement.tenant.address,
        email: statement.tenant.email,
        phone: statement.tenant.phone,
        taxId: statement.tenant.taxId,
      },
    },
    listing: {
      id: hasMultipleListings ? '' : statement.listing?.id,
      name: hasMultipleListings ? '' : statement.listing?.name,
      address: hasMultipleListings ? '' : statement.listing?.address,
    },
    owner: statementOwner?.id
      ? v2Owners
        ? {
            companyName: statementOwner.owner.firstName
              ? undefined
              : statementOwner.owner.lastName,
            // companyNameV2: statementOwner.owner.companyName?.trim(),
            name: statementOwner.owner.firstName
              ? formatUserName(
                  {
                    firstName: statementOwner.owner.firstName,
                    lastName: statementOwner.owner.lastName,
                  },
                  { showEmpty: true }
                )
              : '',
            // nameV2: formatUserName(
            //   {
            //     firstName: statementOwner.owner.firstName,
            //     lastName: statementOwner.owner.lastName,
            //   },
            //   {
            //     showEmpty: true,
            //   }
            // ),
            address: statementOwner.owner.address,
            email: statementOwner.owner.email,
            phone: statementOwner.owner.phone,
            id: statementOwner.owner.id,
          }
        : {
            companyName: statementOwner.owner.companyName?.trim(),
            // companyNameV2: statementOwner.owner.companyName?.trim(),
            name: formatUserName(
              {
                firstName: statementOwner.owner.firstName,
                lastName: statementOwner.owner.lastName,
              },
              {
                showEmpty: true,
              }
            ),
            // nameV2: formatUserName(
            //   {
            //     firstName: statementOwner.owner.firstName,
            //     lastName: statementOwner.owner.lastName,
            //   },
            //   {
            //     showEmpty: true,
            //   }
            // ),
            address: statementOwner.owner.address,
            email: statementOwner.owner.email,
            phone: statementOwner.owner.phone,
            id: statementOwner.owner.id,
          }
      : undefined,
  };

  const footerValues = [
    header.team.name,
    header.team.info.address,
    header.team.info.phone,
    header.team.info.email,
    header.team.info.taxId,
  ].filter(hasValue);

  return {
    header,
    ownerSplit: statementOwner?.split || 0,
    footer: footerValues.length > 0 ? `©︎ ${footerValues.join(', ')}` : '',
  };
};
