import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { whereListingIsExcludedSetting } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useStatementRunDrawer } from '../components';
import { StatementFilterParams } from '../types/statement-types';

export type ListStatement = ReturnType<
  typeof useStatementsList
>['ownerStatements'][number];

export function useStatementsList({
  filter,
  billingLimit,
}: {
  billingLimit: number | undefined;
  filter: StatementFilterParams;
}) {
  const { statementAutomations } = useStatementRunDrawer();
  const { NEW_OWNERS } = useEnabledFeatures();
  const statementAutomationId = statementAutomations?.find(
    (i) => i.status === 'active'
  )?.automationId;

  const [teamId] = useTeamId();

  const where = useWhereStatementList(filter);

  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery(
    (q, args) => {
      // skip works kinda iffy - so manually skipping here
      if (!args.startAt)
        return {
          ownerStatements: [],
          ownerStatementsAggregateCount: 0,
        };

      const ownerStatements = q
        .ownerStatements({
          where: args.where,
          order_by: [{ owner: { lastName: 'asc_nulls_first' } }],
        })
        .map((statement) => {
          const disabledAutomation = statement.listing?.settingsRight({
            where: whereListingIsExcludedSetting({
              automationIds: args.statementAutomationId
                ? [args.statementAutomationId]
                : [],
              listingId: undefined,
            }),
          })[0]?.id;

          return {
            id: statement.id,
            status: statement.status,
            startAt: statement.startAt,
            endAt: statement.endAt,
            currency: statement.currency,
            centTotal: statement.centTotal,
            centBalanceStart: statement.centBalanceStart,
            centPayedOut: statement.centPayedOut,
            centBalanceEnd: statement.centBalanceEnd,
            templateId: statement.templateId,
            owners: statement
              .owners({
                where: {
                  role: { _eq: 'owner' },
                  newOwnerId: args.NEW_OWNERS ? { _is_null: false } : undefined,
                },
              })
              .map((ship) => ({
                id: ship.id,
                owner: args.NEW_OWNERS
                  ? {
                      id: ship.newOwner?.id,
                      name: '',
                      firstName:
                        ship.newOwner?.type === 'individual'
                          ? ship.newOwner?.firstName
                          : '',
                      lastName:
                        ship.newOwner?.type === 'individual'
                          ? ship.newOwner?.name
                          : '',
                      companyName:
                        ship.newOwner?.type !== 'individual'
                          ? ship.newOwner?.name
                          : '',
                    }
                  : {
                      id: ship.owner?.id,
                      name: ship.owner?.name,
                      firstName: ship.owner?.firstName,
                      lastName: ship.owner?.lastName,
                      companyName: ship.owner?.companyName,
                    },
              })),
            listing: {
              id: statement.listing?.id,
              name: statement.listing?.title || statement.listing?.name,
              disabled:
                statement.listing?.status === 'disabled' || disabledAutomation,
              disabledAutomation,
            },
          };
        });

      const ownerStatementsAggregateCount =
        q.ownerStatementAggregate({ where: args.where }).aggregate?.count() ||
        0;

      return {
        ownerStatements,
        ownerStatementsAggregateCount,
      };
    },
    {
      queryKey: 'ownerStatements',
      skip: !filter.startAt || !teamId,
      variables: {
        statementAutomationId,
        limit: billingLimit,
        where,
        startAt: filter.startAt,
        NEW_OWNERS,
      },
    }
  );

  const statements = data?.ownerStatements || [];

  const statementsAmount =
    billingLimit && (data?.ownerStatementsAggregateCount || 0) > billingLimit
      ? data?.ownerStatementsAggregateCount
      : billingLimit;

  const ownerStatements: typeof statements = billingLimit
    ? [
        ...statements,
        ...new Array(statementsAmount! - billingLimit).fill({
          ...statements[0],
          centTotal: 0,
          centBalanceStart: 0,
          centPayedOut: 0,
          centBalanceEnd: 0,
        }),
      ]
    : statements;

  return {
    refetch,
    ownerStatements,
    loading,
  };
}

export const useWhereStatementList = ({
  search: s,
  ...args
}: StatementFilterParams) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { ownerships } = useMe();
  const search = s?.trim();

  return useMemo<gqlV2.owner_statement_bool_exp>(
    () =>
      getWhereStatementList({ ...args, teamId, dashboard, search, ownerships }),
    [args, teamId, dashboard, search, ownerships]
  );
};

export const getWhereStatementList = (
  args: StatementFilterParams & {
    teamId: string;
    dashboard: string;
    ownerships: { id: string; listingId: string }[];
  }
): gqlV2.owner_statement_bool_exp => {
  return {
    tenantId: { _eq: args.teamId },
    id: args.statementIds?.length ? { _in: args.statementIds } : undefined,
    owners:
      args.vendorSourceId || args.ownerId
        ? {
            vendorSourceId: args.vendorSourceId
              ? { _eq: args.vendorSourceId }
              : undefined,
            ownerId: args.ownerId ? { _eq: args.ownerId } : undefined,
          }
        : undefined,
    status:
      args.dashboard === 'owner'
        ? { _in: ['published', 'posted'] }
        : args.status
          ? { _eq: args.status }
          : undefined,
    startAt: { _eq: args.startAt },
    listingId:
      args.dashboard === 'owner'
        ? { _in: args.ownerships.map((i) => i.listingId) }
        : args.listingIds?.length
          ? { _in: args.listingIds }
          : undefined,
    listing: args.listingGroup
      ? { collectionId: { _eq: args.listingGroup } }
      : undefined,
    _or: args.search
      ? [
          {
            listing: {
              ownerships: {
                owner: {
                  _or: [
                    {
                      lastName: { _ilike: `%${args.search}%` },
                    },
                    {
                      firstName: { _ilike: `%${args.search}%` },
                    },
                    {
                      companyName: { _ilike: `%${args.search}%` },
                    },
                  ],
                },
              },
            },
          },
          {
            listing: {
              _or: [
                { calculated_title: { _ilike: `%${args.search}%` } },
                { address: { _ilike: `%${args.search}%` } },
              ],
            },
          },
        ]
      : undefined,
  };
};
