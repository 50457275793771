import { automation as Automation } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';

export const getAutomationName = (automation: Maybe<Automation>) => {
  if (!automation) return '';

  return (
    automation.title || automation.ttemplate?.title || 'No automation name'
  );
};
