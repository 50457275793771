import {
  Input as MantineInput,
  InputProps as MantineInputProps,
} from '@mantine/core';
import { ComponentPropsWithRef, forwardRef } from 'react';
import { defaultInputStyles } from '../_styles';

type InputProps = {
  type: string;
  placeholderText: string;
  inputName: string;
  error?: boolean;
};

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & MantineInputProps & ComponentPropsWithRef<'input'>
>(({ type, placeholderText, inputName, styles, sx, error, ...props }, ref) => {
  return (
    <MantineInput
      ref={ref}
      type={type}
      id={inputName}
      name={inputName}
      styles={{ ...defaultInputStyles, ...styles }}
      placeholder={placeholderText}
      sx={(theme) => ({
        input: error ? { borderColor: theme.colors.red[4] } : undefined,
        ...(sx as any),
      })}
      radius={8}
      {...props}
    />
  );
});

Input.displayName = 'Input';
