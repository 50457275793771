import {
  ExtendedCustomColors,
  QueryParamProvider,
  ReactRouter6Adapter,
} from '@finalytic/ui';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Auth } from './Auth';
import { RootWrapper } from './RootWrapper';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Auth />
          </QueryParamProvider>
        </>
      }
    />
  )
);

export function App({
  themePrimaryColor,
}: {
  themePrimaryColor: ExtendedCustomColors;
}) {
  return (
    <RootWrapper themePrimaryColor={themePrimaryColor}>
      <RouterProvider router={router} />
    </RootWrapper>
  );
}
