import { CountBagde } from '@finalytic/components';
import { CommentQuestionIcon } from '@finalytic/icons';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { useIntercom } from 'react-use-intercom';
import { useNavbarExpanded } from '../../hooks';
import { useIntercomStore } from '../../stores';

export const NavbarSupportItem = () => {
  const { show, hide, isOpen } = useIntercom();

  const { colors } = useMantineTheme();
  const count = useIntercomStore((state) => state.unreadCount);

  const color = colors.neutral[0];

  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Group
      onClick={isOpen ? hide : show}
      gap="xs"
      px={8}
      sx={(theme) => ({
        marginTop: 'auto',
        marginBottom: theme.spacing.sm,
        cursor: 'pointer',
        ':active': {
          transform: 'translateY(1px)',
        },
      })}
    >
      <CommentQuestionIcon size={18} color={color} />
      {isDesktopExpanded && (
        <>
          <Text color={color} component="span" size={'sm'}>
            Help & Support
          </Text>
          {!!count && (
            <CountBagde
              count={count}
              size={'0.75rem'}
              sx={{
                marginLeft: 'auto',
              }}
            />
          )}
        </>
      )}
    </Group>
  );
};
