type Options = {
  // sources: Source[];
  getValue: (value: {
    type: keyof typeof parseOptions;
    displayValue: string;
  }) => Promise<string | undefined>;
};

const parseOptions = {
  account: {
    valuePrefix: /"itm\.([^"]*)"/g,
    formulaPrefix: /"\[\d*\].*?"/g,
  },
  collection: {
    valuePrefix: /"col\.([^"]*)"/g,
    formulaPrefix: /"Col\.([^"]*)"/g,
  },
};

export const parseFormula = async (
  type: 'to-db' | 'to-display',
  formula: string,
  options: Options
) => {
  // get all the values that are wrapped in quotes
  // if value is of type account , get the account from options and replace it with the account id
  // allow parsing to replace more types in the future
  // return the parsed formula

  const parsing = Object.entries(parseOptions);

  const replaceValues: { match: string; type: keyof typeof parseOptions }[] =
    [];

  parsing.forEach(([key, option]) => {
    const prefix =
      type === 'to-display' ? option.valuePrefix : option.formulaPrefix;

    const matches = formula.match(prefix);

    if (matches) {
      matches.forEach((match) => {
        replaceValues.push({ match, type: key as keyof typeof parseOptions });
      });
    }
  });

  await Promise.all(
    replaceValues.map(async ({ match, type }) => {
      const account = await options.getValue({
        type: type as keyof typeof parseOptions,
        displayValue: match,
      });

      if (account) {
        formula = formula.replace(match, account);
      }
    })
  );

  return formula;
};
