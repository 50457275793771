import { ActionIcon, ActionIconProps } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export const IconButton = forwardRef(
  (
    {
      children,
      sx,
      component,
      ...props
    }: ActionIconProps & {
      component?: any;
      href?: string;
      target?: string;
      to?: string;
      sx?: EmotionSx;
    } & ComponentPropsWithoutRef<'button'>,
    ref
  ) => {
    return (
      <ActionIcon
        ref={ref}
        variant="transparent"
        component={component}
        sx={(theme, u) =>
          ({
            borderRadius: '20%' as any,
            ':hover': {
              backgroundColor: '#00000000',
            },
            ...(typeof sx === 'function' ? sx(theme, u) : sx),
          }) as any
        }
        {...props}
      >
        {children}
      </ActionIcon>
    );
  }
);

IconButton.displayName = 'IconButton';
