import { useTeamId, useTrpcMutation } from '@finalytic/data';
import { TaskConfirmModal } from '@finalytic/data-ui';
import {
  ActionButton,
  Calendar,
  ModalFooter,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { Center, Group, Text } from '@mantine/core';
import { Controller } from 'react-hook-form';

type FetchConnectionModalProps = {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  connectionId?: string | null;
};

export const FetchConnectionModal = ({
  opened,
  onClose,
  connectionId,
  onSuccess,
}: FetchConnectionModalProps) => {
  const { gray } = useColors();

  const [teamId] = useTeamId();

  const { mutate: extract, loading } = useTrpcMutation('extract', {
    successMessage: {
      title: 'Refetch Success!',
      message: 'Your data was successfully fetched.',
    },
  });

  const handleSubmit = async (args: { date?: string }) => {
    if (!connectionId) {
      showErrorNotification({ message: 'Missing connection.' });
      return undefined;
    }

    extract({
      connectionId: connectionId,
      teamId,
      range: args.date
        ? {
            start: args.date,
            end: day().toISOString(),
          }
        : undefined,
    });
  };

  return (
    <TaskConfirmModal
      opened={opened}
      size={600}
      title={'Fetch Data'}
      onSubmit={handleSubmit}
      onClose={onClose}
      onSuccess={onSuccess}
      withCloseButton
      customSubmitBody={({
        methods: { control, watch, formState },
        submit,
      }) => {
        const dateLimit = watch('date');

        return (
          <>
            <Center mb={'md'}>
              <Controller
                name="date"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    value={new Date(value)}
                    onChange={(value) => onChange(value?.toString() || '')}
                  />
                )}
              />
            </Center>

            <ModalFooter sx={{ marginBottom: '-0.75rem' }}>
              <Group justify="space-between" my={5}>
                <Text size="sm" color={gray.dark} sx={{ opacity: 0.8 }}>
                  Fetch your connection data since:{' '}
                  <Text component="span" size="sm" fw={500}>
                    {dateLimit && day(dateLimit).format('DD MMM YYYY')}
                  </Text>
                </Text>
                <Group>
                  <TransparentButton onClick={onClose} color={gray.dark}>
                    Cancel
                  </TransparentButton>
                  <ActionButton
                    disabled={!formState.isValid}
                    loading={loading}
                    onClick={() => submit()}
                  >
                    Refetch Data
                  </ActionButton>
                </Group>
              </Group>
            </ModalFooter>
          </>
        );
      }}
    />
  );
};
