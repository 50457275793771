import { BooleanParam, useQueryParam } from '@finalytic/ui';

export function useListingWasUpdated() {
  const [listingEdited, setListingEdited] = useQueryParam('le', BooleanParam); // listingEdited

  return {
    listingEdited,
    setListingEdited,
  };
}
