import { useV2UI } from '@finalytic/ui';
import React from 'react';
import { teamContext, useTeamRole, userContext } from './team';

export function useEnabledFeatures() {
  const user = React.useContext(userContext);
  const { team } = React.useContext(teamContext);
  const { isV2 } = useV2UI();

  return React.useMemo<Record<keyof typeof features, boolean>>(
    () =>
      Object.entries(features).reduce((state, [key, value]) => {
        const returnState = (value: boolean) => {
          state[key] = value;
          return state;
        };

        // Hirarchy:
        // 1. SPOTLIGHT_V2_UI => any feature with "_UI" suffix
        // 2. Tester
        // 3. Enabled team
        const isSpotlightV2_UI = key.endsWith('_UI') && isV2;

        if (isSpotlightV2_UI) return returnState(true);

        const testerStatus = user.featureApprovals.find(
          (approval) => approval.featureId === value
        )?.status;

        // if tester and always enabled
        if (testerStatus) {
          if (testerStatus === 'all') return returnState(true);
          if (testerStatus === 'disabled') return returnState(false);
        }

        const teamStatus = team.enabledFeatures.find(
          (enabledFeature) => enabledFeature.featureId === value
        )?.status;

        // if team is testing
        if (teamStatus) {
          if (teamStatus === 'all') return returnState(true);
          if (teamStatus === 'partially' && testerStatus === 'partially')
            return returnState(true);
          if (teamStatus === 'disabled') return returnState(false);
        }

        return returnState(false);
      }, {} as any),
    [user.featureApprovals, team.enabledFeatures, isV2]
  );
}

export function useHasEnabledFeatures() {
  const user = React.useContext(userContext);
  const { isSuperAdmin, isPartnerAdmin } = useTeamRole();

  return React.useMemo<boolean>(() => {
    if (isSuperAdmin || isPartnerAdmin) return true;

    return !!user.featureApprovals.length;
  }, [user.featureApprovals, isSuperAdmin, isPartnerAdmin]);
}

const features = {
  // to be decided
  OVERVIEW_UI: 'xxx',
  // RUN_DRAWER_REFACTOR_UI: 'xxx',
  ISSUE_UI: '42d3e905-544e-4e65-9b65-ed564c4eaef8',
  // needed in production to handle views on team basis
  NEW_OWNERS: '5f0b906c-d81b-4e95-8fa7-d7b59b3b2793',
  BILLING: '9e38781a-3c44-4bed-a4c5-33c5917aee0b',
  // Currently in testing
  SETUP_GUIDE_UI: '43715fd0-1b82-49c4-b393-0ac97d21986d',
  // OWNER_IMPORT_UI: 'c79bc752-4987-4ddd-9f76-79eb72ac0f14',
  // BULK_INVITE_OWNERS_UI: '26f77b2b-eae3-4550-9aae-3ea22987b937',
  // FORMULA_EDITOR_UI: 'eb15d3dc-dfcc-4a24-b19a-9a86f8ba384f',
  ACTIVITY_UI: 'e42f8c8d-98b9-462a-8857-8157382126eb',
  // new accounts and owner statement templates under the same feature flag ID
  GL: 'e60dcc9e-73ee-4b02-b700-d1804aaa49fc',
  CUSTOM_FEE_MULTI_CURRENCY_INPUTS: '1e5982bd-b597-4288-b9d1-51cb54440e5e',
} as const;
