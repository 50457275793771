import { LoadingIndicator } from '@finalytic/ui';
import {
  DatePickerInput,
  DatePickerInputProps,
  DatePickerType,
} from '@mantine/dates';

export const InputDay = <DType extends DatePickerType = 'default'>({
  loading,
  type = 'default',
  styles,
  ...props
}: DatePickerInputProps<DType> & {
  loading?: boolean;
  placeholder?: string;
}) => {
  return (
    <DatePickerInput
      color="dark"
      type={type}
      numberOfColumns={2}
      styles={(theme, c, t) => ({
        input: {
          '&:focus': {
            border: `1px solid ${theme.colors.gray[4]}`,
          },
          '&[aria-expanded=true]': {
            border: `1px solid ${theme.colors[theme.primaryColor][6]}`,
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
          },
          '.mantine-CloseButton-root path': {
            fill: theme.colors.gray[5],
          },
        },
        ...(typeof styles === 'function' ? styles(theme, c, t) : styles),
      })}
      radius="md"
      disabled={loading}
      rightSection={loading && <LoadingIndicator size="xs" />}
      {...props}
      popoverProps={{
        shadow: 'md',
        radius: 'md',
        ...props?.popoverProps,
      }}
    />
  );
};
