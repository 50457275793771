import { Icon, IconDefinition } from '@finalytic/icons';
import { Text } from '@mantine/core';
import {
  Center,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';

type IssueProps = {
  issues?: number | string;
  status: 'warning' | 'error' | 'success' | 'disabled';
  tooltip?: string;
};

export const IssueBadge = ({ issues, status, tooltip }: IssueProps) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const getColor = () => {
    if (status === 'error') return 'red';
    if (status === 'warning') return 'yellow';
    if (status === 'disabled') return 'gray';
    return 'green';
  };

  const getIcon = (): IconDefinition => {
    if (status === 'error') return 'CrossIcon';
    return 'CheckIcon';
  };

  const color = getColor();
  const icon = getIcon();

  return (
    <Tooltip label={tooltip} disabled={!tooltip} withArrow withinPortal>
      <Center
        sx={(theme) => ({
          backgroundColor:
            theme.colors[color][0] + (colorScheme === 'dark' ? '10' : ''),
          width: !issues ? 25 : undefined,
          paddingInline: !issues ? undefined : 5,
          height: 25,
          borderRadius: !issues ? '100%' : theme.radius.lg,
        })}
      >
        <Center
          sx={(theme) => ({
            backgroundColor: theme.colors[color][color === 'gray' ? 6 : 8],
            borderRadius: 20,
            padding: 2,
          })}
        >
          {status === 'warning' ? (
            <Center w={11} h={11}>
              <Text
                component="span"
                c="white"
                ta="center"
                sx={{
                  lineHeight: 0.8,
                }}
              >
                !
              </Text>
            </Center>
          ) : (
            <Icon icon={icon} size={12} color={'#fff'} />
          )}
        </Center>
        {!!issues && (
          <Text
            component="span"
            c={colors[color][8]}
            size="xs"
            display={'inline-block'}
            ml={rem(6)}
            fw={500}
            ta="center"
            sx={{
              lineHeight: 0.8,
            }}
          >
            {issues}
          </Text>
        )}
      </Center>
    </Tooltip>
  );
};
