import { useDashboard, useEnabledFeatures } from '@finalytic/data';
import {
  AppShell,
  LoadingOverlay,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode, Suspense } from 'react';
import { ErrorBoundary, PmErrorIssueNotification } from '../components';
import { useNavbarExpanded } from '../hooks';
import { ExtractModal } from '../modals';
import { Header } from './Header';
import { OwnerImpersonationNotification } from './OwnerImpersonationNotification';
import { Navbar, NavbarBaseProps } from './navbar';

export const AppLayout = ({
  children,
  drawers,
  ...navbarProps
}: {
  children: ReactNode;
  drawers: (() => JSX.Element)[];
} & NavbarBaseProps) => {
  const matches = useMediaQuery('(max-width: 755px)');
  const { isDesktopExpanded: isExpanded } = useNavbarExpanded();
  const { ISSUE_UI } = useEnabledFeatures();
  const [dashboard] = useDashboard();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const navBgColor =
    colorScheme === 'dark'
      ? theme.colors.dark[8]
      : theme.colors[theme.primaryColor][8];

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingOverlay visible />}>
        <AppShell
          header={{
            height: 60,
            collapsed: !matches,
          }}
          navbar={{
            collapsed: {
              desktop: false,
              mobile: true,
            },
            breakpoint: 755,
            width: matches ? 280 : isExpanded ? 310 : 110,
          }}
          styles={(theme) => ({
            main: {
              overflow: 'scroll',
            },
            navbar: {
              width: isExpanded ? 280 : 80,
              transition: 'all 0.3s ease-in-out',
              backgroundColor: navBgColor,
              height: `calc(100vh - 2*${theme.spacing.sm})`,
              margin: theme.spacing.sm,
              borderRadius: theme.spacing.md,
              overflowX: 'hidden',
              border: 'none',
              '@media (max-width: 755px)': {
                // Hide Sidebar on mobile
                display: (navbarProps as any)?.closeMobileMenu
                  ? undefined
                  : 'none',
              },
            },
          })}
        >
          {matches && <Header {...navbarProps} />}

          <AppShell.Navbar bg={navBgColor} c="white">
            <Navbar {...navbarProps} />
          </AppShell.Navbar>

          <AppShell.Main
            py="sm"
            pr="sm"
            display="flex"
            h="100%"
            style={{
              flexDirection: 'column',
              position: 'relative',
              maxHeight: '100vh',
              overflowX: 'hidden',
            }}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </AppShell.Main>
        </AppShell>

        {/* // ** Dynamically added drawers depending on app */}
        {drawers.map((Drawer, i) => (
          <Drawer key={i} />
        ))}

        <OwnerImpersonationNotification />

        {dashboard === 'propertyManager' && ISSUE_UI && (
          <PmErrorIssueNotification type="affix" />
        )}

        <ExtractModal />
      </Suspense>
    </ErrorBoundary>
  );
};
