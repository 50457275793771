import { faArrowLeft, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '../../';
import { StringParam, useQueryParams } from '../../../hooks';

export const DrawerHeader = ({
  title,
  children,
  onClose,
}: {
  title: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}) => {
  const [{ id, date }, setQuery] = useQueryParams({
    id: StringParam,
    date: StringParam,
  });

  const { state } = useLocation();
  const navigate = useNavigate();

  const closeDrawer = () => {
    if (onClose) return onClose();

    setQuery({ date, id }, 'replace');
  };

  const locationState = state as any;

  return (
    <Group justify="space-between" px={5} wrap="nowrap">
      <Group gap={5} wrap="nowrap" sx={{ flex: 1, minHeight: 70 }}>
        {locationState?.canGoBack && (
          <IconButton onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        {typeof title === 'string' ? (
          <Text component="h2" size="xl">
            {title}
          </Text>
        ) : (
          title
        )}
      </Group>
      <Group>
        {children}
        <IconButton onClick={closeDrawer}>
          <FontAwesomeIcon icon={faXmarkLarge} size="sm" />
        </IconButton>
      </Group>
    </Group>
  );
};
