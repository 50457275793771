import { Avatar, Box, Group, Text } from '@mantine/core';

export const ConnectionWithIconCell = ({
  icon,
  title,
  subtitle,
}: {
  icon: string;
  title: string;
  subtitle?: string;
}) => {
  return (
    <Group
      gap={10}
      wrap="nowrap"
      sx={{ overflow: 'hidden', maxWidth: '100%', height: '100%' }}
    >
      <Avatar size={25} src={icon || null} radius={100} />
      <Box sx={{ overflow: 'hidden', maxWidth: '100%', display: 'block' }}>
        <Text
          size="sm"
          lineClamp={1}
          sx={{ overflow: 'hidden', display: 'block' }}
        >
          {title}
        </Text>
        <Text
          size="xs"
          color="gray"
          lineClamp={1}
          sx={{ overflow: 'hidden', display: 'block' }}
        >
          {subtitle}
        </Text>
      </Box>
    </Group>
  );
};
