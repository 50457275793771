import { Button } from '@finalytic/components';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { Text } from '@mantine/core';
import { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';

export const ConnectionPlaidView = () => {
  const { app, connectApp, issue } = useConnectionModalContext();

  const [connectionId] = useQueryParam('connectionId', StringParam);
  const isReconnect = !!connectionId;

  const token = (issue?.action.value || '') as string;

  const onSuccess = useCallback(
    async (code: string) => {
      await connectApp({ input: { code } });
    },
    [connectApp]
  );

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
  });

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        <>
          <Text size="md">{isReconnect ? 'Reconnect' : 'Connect'} your</Text>
          <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            Bank account using Plaid
          </Text>
        </>
      }
    >
      <Button variant="primary" onClick={() => open()} loading={!ready}>
        Connect now
      </Button>
    </ConnectingContainer>
  );
};
