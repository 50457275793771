import { faCaretDown, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Group, Text } from '@mantine/core';
import { MouseEvent, forwardRef } from 'react';
import { useColors } from '../../../styles';
import { IconButton } from '../../buttons';
import { SelectItem } from '../_types';
import { BaseButtonProps } from './_button-types';

type Props = {
  activeValues: SelectItem[];
  onRemoveClicked: (value: SelectItem) => void;
} & BaseButtonProps;

export const MultiSelectButton = forwardRef<HTMLButtonElement, Props>(
  (
    { onClick, activeValues, onRemoveClicked, withBorder, placeholder, opened },
    ref
  ) => {
    const { border } = useColors();

    const borderColor = border.input;

    return (
      <Box
        ref={ref}
        component={'button'}
        type="button"
        onClick={onClick}
        p={5}
        mt={3}
        mb={6}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 35,
          background: 'white',
          textAlign: 'left',

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          border: '1px solid',
          outlineColor: `${borderColor}!important`,
          outline: opened ? '1px solid' : undefined,
          borderColor: withBorder ? borderColor : 'transparent',
          borderRadius: withBorder ? 8 : undefined,
        }}
      >
        <Group gap={5} sx={{ alignItems: 'flex-start' }}>
          {activeValues.length > 0 ? (
            activeValues.map((item) => {
              const onRemove = (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                onRemoveClicked(item);
              };

              return (
                <Center
                  pl="sm"
                  key={item.value}
                  sx={{
                    minHeight: 25,
                    lineHeight: '1rem',
                    border: '1px solid #EEEFF1',
                    borderRadius: 10,
                    backgroundColor: '#fff !important',
                    fontWeight: 500,
                    color: '#5C6178',
                    fontSize: 13,
                  }}
                >
                  {item.label}
                  <IconButton pt={2} ml={-4} onClick={onRemove}>
                    <FontAwesomeIcon
                      icon={faX}
                      width={6}
                      style={{ opacity: 0.8 }}
                    />
                  </IconButton>
                </Center>
              );
            })
          ) : (
            <Text fw={500} color="#BEC0C9" size="xs" pl="sm" my={4}>
              {placeholder || ''}
            </Text>
          )}
        </Group>
        <FontAwesomeIcon
          icon={faCaretDown}
          color={border.input}
          width={40}
          style={{ flexShrink: 0 }}
        />
      </Box>
    );
  }
);

MultiSelectButton.displayName = 'MultiSelectButton';
