import { AreaChartByDate } from './AreaChartByDate';

type Props = {
  startDate: string;
  endDate: string;
};

export const AverageDailyRateCard = ({ endDate, startDate }: Props) => {
  return (
    <AreaChartByDate
      title="Average Daily Rate"
      href="/reservations"
      total="average"
      isCurrencyAmount
      query={{
        measures: ['Reservation.nightsRate'],
        timeDimensions: [
          {
            dimension: 'Reservation.createdAt',
            granularity: 'day',
            dateRange: [startDate, endDate],
          },
        ],
        order: {
          'Reservation.createdAt': 'asc',
        },
      }}
    />
  );
};
