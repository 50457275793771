export const StatementsIcon = ({
  size = 24,
  color = 'white',
}: { size?: number; color?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15625 2.375H9.25C10.1617 2.375 11.036 2.73716 11.6807 3.38182C12.3253 4.02648 12.6875 4.90082 12.6875 5.8125V7.53125C12.6875 8.48092 13.4575 9.25 14.4062 9.25H16.125C17.0367 9.25 17.911 9.61216 18.5557 10.2568C19.2003 10.9015 19.5625 11.7758 19.5625 12.6875V19.9062C19.5625 20.855 18.7925 21.625 17.8438 21.625H6.15625C5.70041 21.625 5.26324 21.4439 4.94091 21.1216C4.61858 20.7993 4.4375 20.3621 4.4375 19.9062V4.09375C4.4375 3.14408 5.2075 2.375 6.15625 2.375ZM9.9375 16.8125C9.9375 16.6302 9.86507 16.4553 9.73614 16.3264C9.6072 16.1974 9.43234 16.125 9.25 16.125C9.06766 16.125 8.8928 16.1974 8.76386 16.3264C8.63493 16.4553 8.5625 16.6302 8.5625 16.8125V17.5C8.5625 17.6823 8.63493 17.8572 8.76386 17.9861C8.8928 18.1151 9.06766 18.1875 9.25 18.1875C9.43234 18.1875 9.6072 18.1151 9.73614 17.9861C9.86507 17.8572 9.9375 17.6823 9.9375 17.5V16.8125ZM12 14.0625C12.1823 14.0625 12.3572 14.1349 12.4861 14.2639C12.6151 14.3928 12.6875 14.5677 12.6875 14.75V17.5C12.6875 17.6823 12.6151 17.8572 12.4861 17.9861C12.3572 18.1151 12.1823 18.1875 12 18.1875C11.8177 18.1875 11.6428 18.1151 11.5139 17.9861C11.3849 17.8572 11.3125 17.6823 11.3125 17.5V14.75C11.3125 14.5677 11.3849 14.3928 11.5139 14.2639C11.6428 14.1349 11.8177 14.0625 12 14.0625ZM15.4375 12.6875C15.4375 12.5052 15.3651 12.3303 15.2361 12.2014C15.1072 12.0724 14.9323 12 14.75 12C14.5677 12 14.3928 12.0724 14.2639 12.2014C14.1349 12.3303 14.0625 12.5052 14.0625 12.6875V17.5C14.0625 17.6823 14.1349 17.8572 14.2639 17.9861C14.3928 18.1151 14.5677 18.1875 14.75 18.1875C14.9323 18.1875 15.1072 18.1151 15.2361 17.9861C15.3651 17.8572 15.4375 17.6823 15.4375 17.5V12.6875Z"
      fill="url(#paint0_linear_1721_39730)"
    />
    <path
      d="M14.0625 5.81238C14.0641 4.65605 13.6477 3.5381 12.8901 2.66455C14.4239 3.06785 15.8231 3.87132 16.9446 4.99278C18.0661 6.11424 18.8695 7.51345 19.2728 9.0473C18.3993 8.28966 17.2813 7.87328 16.125 7.87488H14.4062C14.3151 7.87488 14.2276 7.83867 14.1632 7.7742C14.0987 7.70974 14.0625 7.6223 14.0625 7.53113V5.81238Z"
      fill="url(#paint1_linear_1721_39730)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1721_39730"
        x1="4.4375"
        y1="3.44444"
        x2="19.3842"
        y2="21.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} stopOpacity="0.6" />
        <stop offset="1" stopColor={color} stopOpacity="0.35" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1721_39730"
        x1="12.8901"
        y1="3.01915"
        x2="17.5163"
        y2="10.0292"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} stopOpacity="0.6" />
        <stop offset="1" stopColor={color} stopOpacity="0.35" />
      </linearGradient>
    </defs>
  </svg>
);
