import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rem } from '@mantine/core';
import { useColors } from '../../../styles';
import { TransparentButton } from '../../buttons';
import { CalendarFilterDateType } from '../../calendar';
import { DateFilter } from './DateFilter';

type Props = {
  defaultButtonLabel: string;
  date: CalendarFilterDateType;
  setDate: (value: CalendarFilterDateType) => void;
};

export const DateFilterV2 = ({ date, setDate, defaultButtonLabel }: Props) => {
  const { gray, themeColors } = useColors();

  return (
    <DateFilter
      defaultButtonLabel={defaultButtonLabel}
      filterValue={date}
      setFilterValue={(val) => setDate(val || [null, null])}
      customButton={(props) => (
        <TransparentButton
          onClick={props.togglePopover}
          ml="sm"
          opacity={1}
          color={themeColors.gray[7]}
          sx={{
            fontSize: rem(14),
            fontWeight: 400,
            '&:hover': {
              backgroundColor: gray.light + 30,
            },
          }}
          rightSection={
            <FontAwesomeIcon color={gray.dark} icon={faChevronDown} />
          }
        >
          {props.buttonLabel}
        </TransparentButton>
      )}
    />
  );
};
