import { useTeamId } from '@finalytic/data';
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from '@finalytic/ui';

export function useAuditDrawer() {
  const [teamId] = useTeamId();
  const [opened, setOpened] = useQueryParam('audits', BooleanParam);
  const [showFilter, setShowFilter] = useQueryParam(
    'saf', //showAuditFilter
    BooleanParam
  );
  const [auditId, setAuditId] = useQueryParam('auditId', StringParam);

  const [filter, setFilter] = useQueryParams({
    objectId: StringParam,
    table: StringParam,
    teamId: StringParam,
  });

  const resetFilter = () => setFilter({});

  const open = (objectId?: string) => {
    if (objectId) {
      setFilter({ objectId });
    }
    if (auditId) {
      setAuditId(undefined);
    }
    setOpened(true);
  };

  const openWithFilter = () => {
    setShowFilter(true);
    setOpened(true);
  };

  const close = () => {
    setOpened(false);
    setShowFilter(false);
    resetFilter();
  };

  const openByTable = (table: string) => {
    setFilter({ table, teamId });
    setOpened(true);
  };

  return {
    opened,
    open,
    close,
    filter,
    setFilter,
    resetFilter,
    auditId,
    setAuditId,
    openWithFilter,
    openByTable,
    showFilter,
  };
}
