import { owner_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';

const ownersFilterInput = {
  search: z.string().optional().nullable(),
  ownerUserId: z.string().optional().nullable(),
  type: z.enum(['individual', 'company']).optional().nullable(),
  status: z.string().nullish(),
};

const ownersFilter = z.object(ownersFilterInput);

export type OwnersFilterParamsV2 = z.infer<typeof ownersFilter>;

type BaseWhere = {
  teamId: string;
};

export function whereOwnersV2({
  search: s,
  teamId,
  type,
  ownerUserId,
  status,
}: BaseWhere & OwnersFilterParamsV2) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<owner_bool_exp[]>([
        { name: { _ilike: `%${search}%` } },
        { firstName: { _ilike: `%${search}%` } },
        { name: { _ilike: `%${search}%` } },
        { email: { _ilike: `%${search}%` } },
        { phone: { _ilike: `%${search}%` } },
        {
          ownerships: {
            listing: {
              _or: [
                {
                  name: { _ilike: `%${search}%` },
                },
                {
                  title: { _ilike: `%${search}%` },
                },

                {
                  address: { _ilike: `%${search}%` },
                },
              ],
            },
          },
        },
      ])
    : undefined;

  const statusFilter: owner_bool_exp =
    status === 'inactive'
      ? {
          _or: [
            {
              status: { _eq: 'inactive' },
            },
            {
              pmsStatus: { _eq: 'inactive' },
              status: { _is_null: true },
            },
          ],
        }
      : {
          _or: [
            {
              status: { _is_null: true },
              pmsStatus: { _is_null: true },
            },
            {
              pmsStatus: { _neq: 'inactive' },
              status: { _is_null: true },
            },
            {
              status: { _eq: 'active' },
            },
          ],
        };

  return ensure<owner_bool_exp>({
    type: type
      ? { _eq: type === 'individual' ? 'individual' : 'company' }
      : undefined,
    tenantId: { _eq: teamId },
    userAccesses: ownerUserId
      ? {
          userId: { _eq: ownerUserId },
        }
      : undefined,
    _or: searchQuery,
    _and: [statusFilter],
  });
}
